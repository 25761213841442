export const radiation_questions = [
    {
        number: 1,
        description: "Which of the following types of radiation has the highest ionizing energy and the greatest potential for causing biological damage?",
        choices: [
            "A. Alpha radiation",
            "B. Beta radiation",
            "C. Gamma radiation",
            "D. X-rays"
        ],
        answer: "A",
        explain: "Among the options listed, alpha radiation has the highest ionizing energy and the greatest potential for causing biological damage. Alpha particles are relatively large and heavy, consisting of two protons and two neutrons, which gives them strong ionizing power due to their larger size and charge. However, their ability to penetrate matter is limited, so they are primarily dangerous if ingested or inhaled, as they can cause significant damage to cells and tissues in close proximity.\n\nBeta radiation, gamma radiation, and X-rays also have ionizing energy and can cause biological damage, but alpha radiation is generally more damaging when in direct contact with living tissues due to its larger size and charge."
    },
    {
        number: 2,
        description: "What is the radiation intensity at a distance of 20 feet from the source if the radiation dose rate is 20 mRem/hour at a distance of 10 feet?",
        choices: [
            "A. 5 mRem/hour",
            "B. 15 mRem/hour",
            "C. 25 mRem/hour",
            "D. 20 mRem/hour"
        ],
        answer: "A",
        explain: "Use Formula 1 in Radiation. When the distance doubled. The radiation intensity is ¼ of the original."
    },
    {
        number: 3,
        description: "For a dose of 50 Rad, what is the Roentgen equivalent man (rem) when considering the quality factor of 20 for alpha particles?",
        choices: [
            "A. 500 Rem",
            "B. 1000 Rem",
            "C. 2000 Rem",
            "D. 4000 Rem"
        ],
        answer: "B",
        explain: "Use Formula 2 in Radiation. Rem = 50 * 20 = 1000 Rem."
    },
    {
        number: 4,
        description: "If the Gamma ray constant is 0.78 R/mCi-hr, what is the exposure rate at a distance of 100 cm from a 200 mCi source?",
        choices: [
            "A. 0.78 R/hour",
            "B. 0.156 R/hour",
            "C. 0.0156 R/hour",
            "D. 1.56 R/hour"
        ],
        answer: "C",
        explain: "Use Formula 3 in Radiation. D = exposure rate, R/hour. Γ= gamma ray constant, R/mCi-hr. A = source activity, mCi d = distance from emitter, cm. Therefore, D = 0.78 *200/100\u00B2 = 0.0156 R/hour."
    },
    {
        number: 5,
        description: "If the initial radioactivity is 10 mCi and the half-life is 2 hours, what is the remaining radioactivity after 4 hours?",
        choices: [
            "A. 20 mCi",
            "B. 10 mCi",
            "C. 5 mCi",
            "D. 2.5 mCi"
        ],
        answer: "D",
        explain: "Use Formula 4 or 6 in Radiation. A = 10 * 0.5^(4/2) = 2.5 mCi."
    },
    {
        number: 6,
        description: "What is the radioactivity of iodine that has a half-life of 13.2 hours, given that the quantity is 10\u207B\u00B2\u00B2 moles?",
        choices: [
            "A. 0.316 disintegration/hour",
            "B. 316 disintegration/hour",
            "C. 3.16 disintegration/hour",
            "D. 0.16 disintegration/hour"
        ],
        answer: "C",
        explain: "Use Formula 5 in Radiation. A = radioactivity, disintegration per unit of time. Ni = the number of atoms = 6.023 * 10\u00B2\u00B3 * 10\u207B\u00B2\u00B2 =60.23\n\n Ai = 0.693/13.2 * 60.23 = 3.16 disintegration/hour."
    },
    {
        number: 7,
        description: "After being shielded with a lead shield containing four half layers, what is the revised emission rate of a radioactive source that initially emits 160 mRem/hour?",
        choices: [
            "A. 10 mRem/hour",
            "B. 40 mRem/hour",
            "C. 160 mRem/hour",
            "D. 2.5 mRem/hour"
        ],
        answer: "A",
        explain: "Use Formula 7 in Radiation. A= the number of half-value layers. I = 160 * (1/2)\u2074 = 10 mRem/hour."
    },
    {
        number: 8,
        description: "After being shielded with a lead shield containing 2 tenth layers, what is the revised emission rate of a radioactive source that initially emits 160 mRem/hour?",
        choices: [
            "A. 32 mRem/hour",
            "B. 16 mRem/hour",
            "C. 160 mRem/hour",
            "D. 1.6 mRem/hour"
        ],
        answer: "D",
        explain: "Use Formula 8 in Radiation. B= the number of tenth layers. I = (1/10)\u00B2 * 160 = 1.6 mRem/hour."
    },
    {
        number: 9,
        description: "If the half-value layer thickness is 1.25 cm, what is the new emission rate of a radioactive source emitting 160 mRem/hour after being shielded with 5 cm of lead?",
        choices: [
            "A. 10 mRem/hour",
            "B. 20 mRem/hour",
            "C. 40 mRem/hour",
            "D. 160 mRem/hour"
        ],
        answer: "A",
        explain: "Use Formula 9 in Radiation. X = total thickness of layers. HVL = thickness of the half-value layer. I\u2082 = 160/2^(5/1.25) = 10 mRem/hour."
    },
    {
        number: 10,
        description: "If the tenth-value layer thickness is 1.25 cm, what is the new emission rate of a radioactive source emitting 160 mRem/hour after being shielded with 5 cm of lead?",
        choices: [
            "A. 0.16 mRem/hour",
            "B. 0.016 mRem/hour",
            "C. 16 mRem/hour",
            "D. 32  mRem/hour"
        ],
        answer: "B",
        explain: "Use Formula 10 in Radiation. X = total thickness of layers. HVL = thickness of the tenth-value layer. I2 = 160/10^(5/1.25) = 0.016 mRem/hour."
    },
    {
        number: 11,
        description: "An industrial hygienist wants to decrease the dose rate from a radioactive source, which initially has an intensity of 1000 mRem/hr, to 100 mRem/hr. If the half-value layer for concrete is 4 cm, what thickness of concrete shielding should be used for achieving the desired dose rate reduction?",
        choices: [
            "A. 1.328 cm",
            "B. 10 cm",
            "C. 132.8 cm",
            "D. 13.28 cm"
        ],
        answer: "D",
        explain: "Use Formula 11 in Radiation. X = total thickness of layers = 3.32log(1000/100)*4 = 13.28 cm."
    },
    {
        number: 12,
        description: "If the intensity at the source is 100 mRem/hr and the linear attenuation coefficient of a gamma source is 0.2 per cm, what would be the attenuated radiation exposure rate when the attenuator has a thickness of 10 cm and a buildup factor of 1.1?",
        choices: [
            "A. 14.88 mRem/hr",
            "B. 12.88 mRem/hr",
            "C. 11.88 mRem/hr",
            "D. 10.88 mRem/hr"
        ],
        answer: "A",
        explain: "Use Formula 12 in Radiation. B = buildup factor. u= linear attenuation coefficient per cm. x=thickness of attenuator in cm. Therefore, I = 100*1.1*e^(-0.2*10) = 14.88 mRem/hr."
    },
    {
        number: 13,
        description: "For an element with a radiological half-life of 12 days and a biological half-life of 40 days, what is the value of the effective half-life?",
        choices: [
            "A. 12 days",
            "B. 9.2 days",
            "C. 40 days",
            "D. 1.2 days"
        ],
        answer: "B",
        explain: "Use Formula 13 or 14 in Radiation. 1/Teff = 1/12 + 1/40. Teff = 9.2 days."
    },
    {
        number: 14,
        description: "What is the electrical power density of an electric field with a strength of 110 V/m?",
        choices: [
            "A. 1.2 mW/cm\u00B2",
            "B. 2.2 mW/cm\u00B2",
            "C. 3.2 mW/cm\u00B2",
            "D. 4.2 mW/cm\u00B2"
        ],
        answer: "C",
        explain: "Use Formula 15 in Radiation. S = electrical power density, mW/cm\u00B2. E = electric field strength, V/m. Therefore, S = 110\u00B2/3770 = 3.2 mW/cm\u00B2."
    },
    {
        number: 15,
        description: "What is the magnetic power density of a magnetic field with a strength of 0.7 A/m?",
        choices: [
            "A. 8.5 mW/cm\u00B2",
            "B. 0.7 mW/cm\u00B2",
            "C. 28.5 mW/cm\u00B2",
            "D. 18.5 mW/cm\u00B2"
        ],
        answer: "D",
        explain: "Use Formula 16 in Radiation. S = 37.7 * 0.7\u00B2 = 18.5 mW/cm\u00B2."
    },
    {
        number: 16,
        description: "What is the near field power density for a circular antenna with a radius of 2 meters and an input power of 140 W?",
        choices: [
            "A. 44.6 W/m\u00B2",
            "B. 34.6 W/m\u00B2",
            "C. 24.6 W/m\u00B2",
            "D. 14.6 W/m\u00B2"
        ],
        answer: "A",
        explain: "Use Formula 17 in Radiation. P = radiated power from antenna in W. A = area of antenna, in square meters. Therefore, S = 4*140/(2\u00B2π ) = 44.6 W/m\u00B2."
    },
    {
        number: 17,
        description: "Considering an antenna with an absolute gain of 120 and a power emission of 2000000 mW, what would be the estimated safe distance based on an exposure limit of 10 mW/cm²?",
        choices: [
            "A. 1382 cm",
            "B. 2382 cm",
            "C. 3382 cm",
            "D. 4382 cm"
        ],
        answer: "A",
        explain: "Use Formula 18 in Radiation. r = estimated safe distance from non-ionizing radiation in cm. P = emitted power in mW. G = absolute gain. EL = exposure limit, mW/cm\u00B2. Therefore r = sqrt(2000000*120/(4π*10))= 1382 cm."
    },
    {
        number: 18,
        description: "Given a laser beam with a radiant power of 200 kW, an emergent beam diameter of 3 cm, a divergence of 0.001 rad, and an exposure limit of 0.2 W/cm², what would be the recommended safe viewing distance?",
        choices: [
            "A. 4128375 cm",
            "B. 3128375 cm",
            "C. 1128375 cm",
            "D. 2128375 cm"
        ],
        answer: "C",
        explain: "Use Formula 19 in Radiation. rNHZ = nominal hazard zone in cm. θ=divergence of emergent beam in radians. Φ=total radiant power output of laser in W . EL = exposure limit. a=emergent beam diameter in cm. Therefore, rNHZ = 1/0.001*sqrt(4*200000/(π*0.2) - 3\u00B2) = 1128375 cm."
    },
    {
        number: 19,
        description: "What is the safe viewing distance from a laser beam if the radiant laser power is 2000 W, the focal length is 20 cm, the diameter of laser beam incident on focusing lens is 2 cm, and the exposure limit is 0.2 W/cm\u00B2?",
        choices: [
            "A. 1129 cm",
            "B. 2129 cm",
            "C. 3129 cm",
            "D. 4129 cm"
        ],
        answer: "A",
        explain: "Use Formula 20 in Radiation. rNHZ = nominal hazard zone in cm. Φ= total radiant power output of laser in W. fo=focal length of lens in cm. bo=diameter of laser beam incident on focusing lens in cm. EL = exposure limit. Therefore, rNHZ=20/2*sqrt(4*2000/(π*0.2))=1129 cm."
    },
    {
        number: 20,
        description: "Considering a laser beam with a radiant power of 1000 W, a viewing angle of 0 degrees, a 80% diffuse reflecting surface, and an exposure limit of 0.2 W/cm², what would be the estimated nominal hazard zone for the laser beam?",
        choices: [
            "A. 15.7 cm",
            "B. 25.7 cm",
            "C. 45.7 cm",
            "D. 35.7 cm"
        ],
        answer: "D",
        explain: "Use Formula 21 in Radiation. rNHZ = nominal hazard zone in cm. Φ= total radiant power output of laser in W. ρ=effectiveness of diffuse reflecting surface. θ= angle from normal for the viewing surface. Therefore, rNHZ =(0.8*1000*cos(0)/(0.2*π))^0.5= 35.7 cm."
    },
    {
        number: 21,
        description: "Considering a laser beam with a radiant power of 2000 W, a beam divergence of 0.001 rad, a higher beam diameter of 0.5 cm, and a threshold limit value for the barrier of 20 W/cm², what would be the required separation distance for the barrier to ensure safety?",
        choices: [
            "A. 11273 cm",
            "B. 1274 cm",
            "C. 274 cm",
            "D. 9274 cm"
        ],
        answer: "A",
        explain: "Use Formula 22 in Radiation. Ds = separation distance for barrier in cm. Φ= total radiant power output of laser in W. θ= divergence of the emergent beam in rad. TL= threshold limit value for barrier in W/cm2. a= emergent beam diameter in cm. Therefore, Ds = 1/0.001 * sqrt(4*2000/(π*20) - 0.5\u00B2) = 11273 cm."
    },
    {
        number: 22,
        description: "Three magnetic field measurements are taken in different locations and yield the following results: 50 A/m, 60 A/m, and 70 A/m. Calculate the spatial average value of the magnetic field based on these measurements.",
        choices: [
            "A. 14.9",
            "B. 60.55",
            "C. 3.9",
            "D. 342.9"
        ],
        answer: "B",
        explain: "Use Formula 23 in Radiation. Spatial ave = sqrt((50\u00B2+60\u00B2+70\u00B2)/3) = 60.55 A/m."
    },
    {
        number: 23,
        description: "What is the maximum allowable exposure time during an eight-hour shift for an effective irradiance of 0.3 mW/cm²?",
        choices: [
            "A. 40 sec",
            "B. 30 sec",
            "C. 20 sec",
            "D. 10 sec"
        ],
        answer: "D",
        explain: "Use Formula 24 in Radiation. Pay attention to the unit. Eeff = effctive irradiance in W/cm\u00B2. t=exposure time in sec = 0.003/(0.3*10\u207B\u00B3) = 10 sec."
    },
    {
        number: 24,
        description: "What is the maximum allowable exposure time during an eight-hour shift when the exposure limit is of 0.5 mW/cm² and the measured exposure level is 50 mW/cm²?",
        choices: [
            "A. 0.01 hour",
            "B. 0.001 hour",
            "C. 0.1 hour",
            "D. 1 hour"
        ],
        answer: "B",
        explain: "Use Formula 25 in Radiation. t=time for acceptable exposure level. EL = exposure limit. ML = measured level. Therefore, t= 0.5/50*0.1 = 0.001 hour."
    },
    {
        number: 25,
        description: "What is the minimum optical density required to protect against potential exposure of 0.04 J/cm², given that the permissible exposure is 0.000004 J/cm²?",
        choices: [
            "A. 2",
            "B. 3",
            "C. 4",
            "D. 5"
        ],
        answer: "C",
        explain: "Use Formula 26 in Radiation. O.D = optical density. I\u2080 = irradiance before filter. I = irradiance after beam. Therefore, O.D = log(0.04/0.000004) = 4."
    },
    {
        number: 26,
        description: "What would be the diameter of a laser beam at a distance of 1 km, considering that the emergent beam diameter is 1 cm and the beam divergence is 0.0001 rad?",
        choices: [
            "A. 8 cm",
            "B. 5 cm",
            "C. 1 cm",
            "D. 10 cm"
        ],
        answer: "D",
        explain: "Use Formula 27 in Radiation. DL = laser beam diameter at distance r in cm. a=emergent beam diameter in cm. Θ = emergency beam divergence in rad. r=distance in cm. Therefore,  DL=sqrt(1\u00B2+ 0.0001\u00B2*(1*10\u2075)\u00B2)= 10 cm."
    },
    {
        number: 27,
        description: "What is the absolute gain of an antenna with a gain of 30 dB?",
        choices: [
            "A. 1000",
            "B. 100",
            "C. 10",
            "D. 10000"
        ],
        answer: "A",
        explain: "Use Formula 28 in Radiation. G=absolute gain. g=gain for an antenna in dB. Therefore, G = 10^(30/10)=1000."
    },
    {
        number: 28,
        description: "Which of the following statements is true about atomic mass and atomic number? ",
        choices: [
            "A. Atomic mass is the number of protons in an atom’s nucleus, while atomic number is the total number of protons and neutrons in an atom’s nucleus. ",
            "B. Atomic mass is the total number of protons and neutrons in an atom’s nucleus, while atomic number is the number of protons in an atom’s nucleus.",
            "C. Atomic mass and atomic number are the same thing. ",
            "D. Atomic mass is the number of electrons in an atom’s nucleus, while atomic number is the total number of protons and neutrons in an atom’s nucleus."
        ],
        answer: "B",
        explain: "Atomic mass and atomic number are two important concepts in chemistry. Atomic mass is the total number of protons and neutrons in an atom’s nucleus. It is measured in atomic mass units (amu) and is approximately equal to the mass number of an atom. On the other hand, atomic number is the number of protons in an atom’s nucleus. It is represented by the symbol Z and determines the element to which an atom belongs.\n\nFor example, carbon has an atomic number of 6 because it has 6 protons in its nucleus.Its atomic mass is approximately 12 amu because it has 6 protons and 6 neutrons in its nucleus."
    },
    {
        number: 29,
        description: "Which of the following statements is true about radioactive decay?",
        choices: [
            "A. Radioactive decay is the process by which an unstable atomic nucleus loses energy by emitting radiation.",
            "B. Radioactive decay only occurs in man-made elements.",
            "C. Radioactive decay can be stopped by placing the radioactive material in a container.",
            "D. The stability of an atomic nucleus decreases after radioactice decay."
        ],
        answer: "A",
        explain: "Radioactive decay is the process by which an unstable atomic nucleus loses energy by emitting radiation. This can happen in several ways, including alpha decay, beta decay, and gamma decay. During alpha decay, the nucleus emits an alpha particle, which consists of two protons and two neutrons. During beta decay, a neutron in the nucleus is transformed into a proton and an electron, and the electron is emitted from the nucleus. During gamma decay, the nucleus emits a high-energy photon known as a gamma ray.\n\nRadioactive decay is a random process and cannot be stopped or controlled. However, the rate of decay can be predicted using the concept of half-life. The half-life of a radioactive substance is the time it takes for half of its atoms to undergo radioactive decay.\n\nUnstable atomic nuclei undergo radioactive decay in order to transform into more stable configurations. During radioactive decay, these nuclei release energy in the form of radiation (such as alpha particles, beta particles, or gamma rays) as they change into a more stable state. The goal of the decay process is to reach a more balanced and stable arrangement of protons and neutrons in the nucleus."
    },
    {
        number: 30,
        description: "Which of the following is an example of ionizing radiation?",
        choices: [
            "A. Gamma rays",
            "B. Infrared radiation",
            "C. Microwaves",
            "D. Radio waves"
        ],
        answer: "A",
        explain: "Ionizing radiation refers to any form of radiation that carries enough energy to remove tightly bound electrons from atoms or molecules, resulting in the formation of charged particles called ions. This process is known as ionization. Gamma rays is an example of ionizing radiation because it has sufficient energy to ionize atoms or molecules.\n\nIonizing radiation can come from various sources, including natural sources such as cosmic rays and radioactive materials present in the Earth's crust, as well as artificial sources like X-rays, gamma rays, and certain types of nuclear reactions. It is important to note that while ionizing radiation has many beneficial uses in medicine, industry, and research, it also poses potential health risks.\n\nExposure to ionizing radiation can damage living tissues and DNA within cells, leading to potential health effects such as radiation sickness, increased risk of cancer, and genetic mutations. The risks associated with ionizing radiation depend on factors such as the dose received, the duration of exposure, and the type of radiation involved.\n\nTo ensure the safe use of ionizing radiation, various regulations, guidelines, and safety measures are in place, including radiation protection standards, shielding, and dose limits, to minimize the potential risks to human health and the environment."
    },
    {
        number: 31,
        description: "Which of the following types of radiation is classified as non-ionizing?",
        choices: [
            "A. X-rays",
            "B. Gamma rays",
            "C. Ultraviolet (UV) radiation",
            "D. Alpha particles"
        ],
        answer: "C",
        explain: "Non-ionizing radiation refers to radiation that does not have enough energy to remove tightly bound electrons from atoms, therefore, it does not directly produce ions. Ultraviolet (UV) radiation is classified as non-ionizing radiation. It has lower energy compared to X-rays and gamma rays, which are considered ionizing radiation. UV radiation can still have biological effects, such as causing sunburn or long-term skin damage, but it does not have the same ionizing properties as X-rays, gamma rays, or alpha particles."
    },
    {
        number: 32,
        description: "Which of the following processes involves the addition or removal of an electron from an atom, resulting in the formation of ions?",
        choices: [
            "A. Ionization",
            "B. Excitation",
            "C. Radiation",
            "D. Radioactivity"
        ],
        answer: "A",
        explain: "Ionization is the process that involves the addition or removal of an electron from an atom, resulting in the formation of ions. When an atom gains or loses one or more electrons, it becomes charged and forms an ion. This process can occur due to various factors, such as exposure to ionizing radiation or collisions with other particles. \n\nExcitation (B) refers to the process where an electron is elevated to a higher energy state within an atom, but it does not involve the formation of ions."
    },
    {
        number: 33,
        description: "Which of the following represents the correct order of electromagnetic waves in decreasing order of wavelength?",
        choices: [
            "A. Radio waves, Microwaves, Infrared, Visible light, Ultraviolet, X-rays, Gamma rays",
            "B. Gamma rays, X-rays, Ultraviolet, Visible light, Infrared, Microwaves, Radio waves",
            "C. Radio waves, Visible light, Infrared, Microwaves, Ultraviolet, X-rays, Gamma rays",
            "D. Gamma rays, X-rays, Ultraviolet, Infrared, Visible light, Microwaves, Radio waves"
        ],
        answer: "A",
        explain: "The electromagnetic spectrum is a range of electromagnetic waves, ordered by decreasing wavelength or increasing frequency. In the given options, the correct order of electromagnetic waves in decreasing order of wavelength is as follows:A) Radio waves, Microwaves, Infrared, Visible light, Ultraviolet, X-rays, Gamma rays."
    },
    {
        number: 34,
        description: "What is the primary function of a Geiger counter?",
        choices: [
            "A. Detecting and measuring ionizing radiation",
            "B. Measuring temperature and humidity",
            "C. Detecting and measuring sound waves",
            "D. Measuring electrical resistance"
        ],
        answer: "A",
        explain: "A Geiger counter, also known as a Geiger–Müller counter or G-M counter, is an electronic instrument used for detecting and measuring ionizing radiation. It is widely used in applications such as radiation dosimetry, radiological protection, experimental physics, and the nuclear industry. The Geiger counter detects ionizing radiation such as alpha particles, beta particles, and gamma and X-rays using the ionization effect produced in a Geiger–Müller tube."
    }
    ,
    {
        number: 35,
        description: "What are the skin hazards associated with welding and radiation?",
        choices: [
            "A. Infrared radiation causes severe skin burns.",
            "B. Long-term exposure to ultraviolet (UV) radiation can cause skin cancer.",
            "C. Visible light from welding arcs causes skin irritation.",
            "D. UV radiation only affects the skin when directly exposed, not when reflected."
        ],
        answer: "B",
        explain: "Welding arcs and flames emit intense visible, ultraviolet (UV), and infrared (IR) radiation. UV radiation in a welding arc will burn unprotected skin just like UV radiation in sunlight. This hazard is true for direct exposure to UV radiation as well as radiation that is reflected from metal surfaces, walls, and ceilings. Long-term exposure to UV radiation can cause skin cancer. Infrared radiation and visible light normally have very little effect on the skin. Therefore, the skin hazard associated with welding and radiation mentioned in the question is B) Long-term exposure to ultraviolet (UV) radiation can cause skin cancer."
    }
    ,
    {
        number: 36,
        description: "Isotopes are different forms of an element that have:",
        choices: [
            "A. Different atomic numbers",
            "B. Different numbers of protons",
            "C. Different numbers of electrons",
            "D. Different numbers of neutrons"
        ],
        answer: "D",
        explain: "Isotopes are different forms of an element that have the same number of protons (atomic number) but different numbers of neutrons. The number of protons determines the element's identity, while isotopes of the same element have varying numbers of neutrons, which can affect their stability and other properties. Different isotopes of an element share the same chemical properties but may have different atomic masses due to the varying number of neutrons."
    },
    {
        number: 37,
        description: "What is an alpha particle?",
        choices: [
            "A. A high-energy electron",
            "B. A positively charged helium nucleus",
            "C. A photon of electromagnetic radiation",
            "D. A type of non-ionizing radiation emitted by unstable atoms"
        ],
        answer: "B",
        explain: "An alpha particle is a type of subatomic particle that consists of two protons and two neutrons, making it identical to a helium-4 nucleus. It carries a positive charge and is relatively large compared to other subatomic particles. Alpha particles are emitted during certain types of radioactive decay, such as alpha decay, where a nucleus undergoes a transformation and releases an alpha particle. \n\nDue to their size and charge, alpha particles have limited penetrating power and can be easily stopped by a few centimeters of air or a sheet of paper. However, if inhaled or ingested, they can cause significant damage to living tissues."
    },
    {
        number: 38,
        description: "What is a beta particle?",
        choices: [
            "A. A high-energy electron",
            "B. A positively charged helium nucleus",
            "C. A photon of electromagnetic radiation",
            "D. A type of non-ionizing radiation emitted by unstable atoms"
        ],
        answer: "A",
        explain: "A beta particle is a high-energy electron or positron emitted during the process of beta decay. Beta decay occurs when an unstable atom undergoes a transformation, and either an electron (beta-minus particle) or a positron (beta-plus particle) is emitted from the nucleus.\n\nIn beta-minus decay, a neutron within the nucleus converts into a proton, and an electron is emitted. This results in an increase in the atomic number by one. In beta-plus decay, a proton within the nucleus converts into a neutron, and a positron is emitted. This leads to a decrease in the atomic number by one.\n\nBeta particles have the ability to penetrate matter more deeply than alpha particles but are still relatively easily stopped by materials like aluminum or thick layers of clothing. They are a form of ionizing radiation and can cause damage to living tissues if not properly shielded or controlled."
    },
    {
        number: 39,
        description: "What are gamma waves?",
        choices: [
            "A. High-frequency sound waves",
            "B. Electromagnetic waves of visible light",
            "C. Electromagnetic waves of radio frequency",
            "D. High-energy photons emitted during radioactive decay"
        ],
        answer: "D",
        explain: "Gamma rays are high-energy photons emitted during radioactive decay. Unlike alpha and beta particles, gamma rays are a form of electromagnetic radiation, meaning they have no mass and no charge. Because of their high energy and lack of mass, gamma rays have great penetrating power and can pass through most materials, including human tissue, which makes them both useful and hazardous."
    },
    {
        number: 40,
        description: "Which of the following best describes radiation?",
        choices: [
            "A. The emission of energy as electromagnetic waves",
            "B. The emission of energy as subatomic particles",
            "C. The emission of energy as both electromagnetic waves and subatomic particles",
            "D. The emission of energy as neither electromagnetic waves nor subatomic particles"
        ],
        answer: "C",
        explain: "Radiation is the emission of energy in the form of electromagnetic waves (such as light, X-rays, and gamma rays) and/or subatomic particles (such as alpha and beta particles). This dual nature of radiation includes a wide range of phenomena, from the visible light we see every day to the more energetic forms like X-rays and gamma rays, as well as the particles emitted during radioactive decay."
    }
    ,
    {
        number: 41,
        description: "What does ALARA stand for in the context of radiation protection?",
        choices: [
            "A. Accurate Level of Authorized Radiation Allowance",
            "B. Action Limit for Acceptable Radiation Assessment",
            "C. As Low as Reasonably Achievable",
            "D. Appropriate Limit for Adequate Radiation Application"
        ],
        answer: "C",
        explain: "ALARA is a fundamental principle in radiation protection that emphasizes the goal of minimizing radiation exposure to workers and the general public. It recognizes that all radiation exposure carries some level of risk, and efforts should be made to keep radiation doses as low as reasonably achievable while still allowing for the conduct of necessary tasks and operations \n\nThe ALARA principle is based on three key concepts:\n\nJustification: The use of radiation should be justified, meaning that the benefits of using radiation should outweigh the potential risks. The necessity of using radiation should be carefully evaluated, and alternative non-radiation methods should be considered when feasible.\n\nOptimization: Once the use of radiation is justified, it should be optimized to minimize radiation doses. This involves implementing controls and practices to reduce radiation exposure, such as using shielding, maintaining proper distances, and limiting the time spent in radiation areas.\n\nDose limitation: Despite optimization efforts, there are still regulatory limits on the permissible radiation doses that individuals can receive. These dose limits serve as an upper bound to ensure that radiation exposure is kept within acceptable levels and prevent potential harm.\n\nALARA serves as a guiding principle in developing and implementing radiation protection programs, ensuring that radiation exposures are kept as low as reasonably achievable while maintaining a balance with operational needs."
    },
    {
        number: 42,
        description: "Which of the following statements about radon is true?",
        choices: [
            "A. Radon is a type of non-ionizing radiation.",
            "B. Radon is a radioactive gas produced by the decay of uranium in soil and rocks.",
            "C. Radon is commonly found in outdoor air but rarely present indoors.",
            "D. Radon exposure is not associated with any health risks."
        ],
        answer: "B",
        explain: "Radon is a colorless, odorless, and tasteless radioactive gas that occurs naturally in the environment. It is formed by the decay of uranium, which is present in varying amounts in soil, rocks, and water. As uranium breaks down, it releases radon gas into the surrounding air.\n\nOption A is incorrect because radon is a type of ionizing radiation, not non-ionizing radiation. Radon decay products can emit alpha particles, which are ionizing in nature.\n\nOption C is incorrect because radon is commonly found indoors, and its levels can be significantly higher indoors than outdoors. Radon can enter buildings through cracks in foundations, gaps in floors, or openings around pipes, and it can accumulate in enclosed spaces.\n\nOption D is incorrect because radon exposure is indeed associated with health risks. Prolonged exposure to high levels of radon is a known cause of lung cancer, and it is considered the second leading cause of lung cancer after smoking. Mitigation measures and proper ventilation are important in reducing radon concentrations to minimize health risks."
    },
    {
        number: 43,
        description: "What is a film badge dosimeter used for in radiation monitoring?",
        choices: [
            "A. To measure the dose of ionizing radiation in real-time",
            "B. To detect the presence of radioactive materials",
            "C. To monitor the exposure of individuals to ionizing radiation over a period of time",
            "D. To shield against non-ionizing radiation"
        ],
        answer: "C",
        explain: "A film badge dosimeter is a type of radiation dosimeter that is used to measure and monitor the accumulated radiation exposure of individuals over a specific period. It consists of a small film strip, typically made of photographic film, housed in a protective badge. The film is sensitive to ionizing radiation and changes its opacity or color when exposed to radiation.\n\nThe film badge is worn by individuals who may be exposed to ionizing radiation in their work environment, such as radiation workers or industrial hygienists. After a designated period, typically a month, the film is developed, and the degree of darkening or exposure is analyzed. This provides information about the cumulative radiation dose received by the individual over that time period."
    },
    {
        number: 44,
        description: "Which of the following correctly describes the relationships between the units becquerel (Bq), curie (Ci), gray (Gy), and rad (rad)?",
        choices: [
            "A. Bq and Ci measure the amount of radiation absorbed by a material, while Gy and rad measure the rate of radioactive decay.",
            "B. Bq and Ci measure the rate of radioactive decay, while Gy and rad measure the biological effects of radiation exposure.",
            "C. Bq and Ci measure radioactivity, while Gy and rad measure the absorbed dose of radiation.",
            "D. Bq and Ci measure the type of radiation emitted, while Gy and rad measure the amount of energy deposited per unit mass."
        ],
        answer: "C",
        explain: "Becquerel (Bq) and curie (Ci) are units that measure radioactivity, indicating the rate of radioactive decay. Gray (Gy) and rad (rad) are units that measure the absorbed dose of radiation, which is the amount of energy deposited by radiation in a material."
    },
    {
        number: 45,
        description: "Which laser classification is considered the most dangerous, capable of setting fire to materials and hazardous even when viewing diffuse reflections?",
        choices: [
            "A. Class 1",
            "B. Class 2",
            "C. Class 3R",
            "D. Class 4"
        ],
        answer: "D",
        explain: "Class 4 is the highest and most dangerous class of laser radiation. Radiation in this class is extremely hazardous, and viewing even the diffuse reflections can be dangerous. Class 4 laser beams are also capable of setting fire to materials.\n\nClass 1: Safe under all conditions. Class 1M: Safe without the use of optical instruments.\n\nClass 2: Safe for accidental exposure for less than 0.25 seconds. Class 2M: Safe for accidental exposure for less than 0.25 seconds without optical instruments.\n\nClass 3R: Low risk but not entirely safe. Class 3B: Hazardous, though viewing diffuse reflections is safe."
    }
    ,
    {
        number: 46,
        description: "Which of the following factors plays a significant role in determining the degree of eye injury produced by laser light?",
        choices: [
            "A. Laser power and duration of exposure",
            "B. Laser color and beam diameter",
            "C. Ambient lighting conditions and room temperature",
            "D. Operator's level of experience and training"
        ],
        answer: "A",
        explain: "The degree of eye injury produced by laser light is primarily influenced by the power of the laser and the duration of exposure. Higher-powered lasers can cause more severe eye injuries, and longer exposure times increase the risk of damage. Laser power is typically measured in terms of watts (W) or milliwatts (mW), and exposure duration is measured in seconds."
    },
    {
        number: 47,
        description: "Which of the following statements accurately describes the use of americium in smoke detectors?",
        choices: [
            "A. Americium is used as a source of ionizing radiation in smoke detectors.",
            "B. Americium is a non-radioactive element used to enhance the sensitivity of smoke detectors.",
            "C. Americium is used as a chemical agent to suppress fires in smoke detectors.",
            "D. Americium is used to generate heat and power smoke detectors."
        ],
        answer: "A",
        explain: "Americium-241, an isotope of americium, is commonly used in ionization-type smoke detectors. It emits alpha particles, which ionize the air inside the detector's chamber. When smoke enters the chamber, it disrupts the ionization process, triggering the smoke detector's alarm. The ionizing radiation from the americium source helps detect the presence of smoke particles in the air."
    },
    {
        number: 48,
        description: "Which of the following types of radiation in sunlight is primarily responsible for causing sunburn and skin damage?",
        choices: [
            "A. Infrared radiation",
            "B. Ultraviolet (UV) radiation",
            "C. X-ray radiation",
            "D. Gamma ray radiation"
        ],
        answer: "B",
        explain: "Ultraviolet (UV) radiation is primarily responsible for causing sunburn and skin damage. When the skin is exposed to excessive UV radiation from the sun, it can cause sunburn, premature skin aging, and an increased risk of skin cancer. Infrared radiation, X-ray radiation, and gamma ray radiation are not typically present in sunlight at levels that can cause sunburn or skin damage.\n\nUltraviolet (UV) radiation from the sun provides several benefits, including:\n\nVitamin D synthesis: UV radiation triggers the production of vitamin D in the skin. Vitamin D is essential for bone health, immune function, and overall well-being.\n\nMood enhancement: Exposure to sunlight and UV radiation can promote the release of serotonin, a neurotransmitter associated with mood regulation. This can help improve mood and alleviate symptoms of seasonal affective disorder (SAD) or low mood during darker months.\n\nTreatment of certain skin conditions: Controlled exposure to UV radiation, such as in phototherapy, can be beneficial in treating certain skin conditions like psoriasis, vitiligo, and eczema.\n\nSterilization and disinfection: UV radiation has germicidal properties and is used for sterilizing surfaces, water, and air. It is commonly used in hospitals, water treatment plants, and air purification systems.\n\nEnhancement of plant growth: UV radiation plays a role in stimulating plant growth and development. It influences photosynthesis and can affect the production of secondary metabolites in plants."
    },
    {
        number: 49,
        description: "Which of the following types of PPE is commonly used to protect against external exposure to ionizing radiation?",
        choices: [
            "A. Safety goggles",
            "B. Earplugs",
            "C. Lead apron",
            "D. Chemical-resistant gloves"
        ],
        answer: "C",
        explain: "A lead apron is commonly used as personal protective equipment (PPE) to protect against external exposure to ionizing radiation. The lead apron provides shielding and helps reduce the dose of radiation to the body, particularly in areas such as the torso, where vital organs are located."
    },
    {
        number: 50,
        description: "When used properly, which of the following statements about microwave ovens and radiation exposure is true?",
        choices: [
            "A. Microwave ovens emit ionizing radiation, which can be harmful to human health.",
            "B. Microwave ovens are completely radiation-free and pose no risk of exposure.",
            "C. Microwave ovens can emit non-ionizing radiation, but when used properly, they pose minimal risk of exposure.",
            "D. Microwave ovens are only safe if used with additional shielding materials to block all radiation."
        ],
        answer: "C",
        explain: "When used properly, microwave ovens emit non-ionizing radiation, specifically in the form of microwaves. Non-ionizing radiation does not have enough energy to remove electrons from atoms or molecules, which means it does not have the ability to cause ionization or damage DNA. Microwave ovens are designed to contain the radiation within the oven, and with proper usage and maintenance, they pose minimal risk of exposure to harmful levels of radiation.\n\nIt's important to note that although microwave ovens are generally safe when used properly, it's recommended to follow manufacturers' instructions, use microwave-safe containers, and avoid standing directly in front of the microwave while it is operating to minimize any potential risk."
    },
    {
        number: 51,
        description: "What is the primary reason pregnant women are advised to receive lower radiation exposure levels than regular workers?",
        choices: [
            "A. Fetal sensitivity to radiation",
            "B. Increased risk of maternal cancer",
            "C. Potential harm to the placenta",
            "D. Genetic abnormalities in the mother"
        ],
        answer: "A",
        explain: "Pregnant women are advised to receive lower radiation exposure levels than regular workers because the fetus is more sensitive to radiation than adults. Radiation can cause birth defects, miscarriage, and cancer in the fetus. The risk of these effects is greatest during the first trimester, when the fetus is developing its organs."
    },
    {
        number: 52,
        description: "Which of the following statements is true regarding the interaction of beta particles with lead shielding?",
        choices: [
            "A. Lead completely absorbs all beta particles without any secondary radiation production.",
            "B. Bremsstrahlung radiation is the primary radiation emitted when beta particles interact with lead.",
            "C. The thickness of the lead shielding has no effect on the production of secondary radiation.",
            "D. Lead is not effective in attenuating beta particles."
        ],
        answer: "B",
        explain: 'Bremsstrahlung radiation, also known as braking or deceleration radiation, refers to a type of electromagnetic radiation emitted by charged particles when they are decelerated or accelerated by the influence of an external force or field, typically in the presence of a high atomic number material. The term "Bremsstrahlung" is derived from the German words "bremsen" meaning "to brake" and "Strahlung" meaning "radiation." \n\nBremsstrahlung radiation can be emitted when high-energy beta particles interact with high atomic number materials such as lead.The production of Bremsstrahlung radiation as a result of beta interactions with lead is a secondary effect. When shielding beta radiation, lead is typically NOT used. \n\nTo provide effective shielding against beta radiation, materials with appropriate stopping power, such as plastics or other low atomic number materials, may be used as the primary shielding, followed by an additional layer of high atomic number material like lead to attenuate any secondary radiation that may be produced, including Bremsstrahlung.'
    },
    {
        number: 53,
        description: "Electric and magnetic fields are proportional in the:",
        choices: [
            "A. Near field",
            "B. Near field for radio waves, but far field for microwaves",
            "C. Far field for microwaves, but near field for radio waves",
            "D. Far field"
        ],
        answer: "D",
        explain: "In the far field region, the electric and magnetic fields are proportional and propagate as an electromagnetic wave. In the near field region, the relationship between the electric and magnetic fields is more complex and not necessarily proportional."
    }
    ,
    {
        number: 54,
        description: "Which of the following correctly describes the relationship between dose equivalent units sievert (Sv) and rem with absorbed dose units gray (Gy) and rad (rad)?",
        choices: [
            "A. Sievert (Sv) and rem measure the amount of energy deposited in a material, while gray (Gy) and rad (rad) measure the rate of radioactive decay.",
            "B. Sievert (Sv) and rem measure the rate of radioactive decay, while gray (Gy) and rad (rad) measure the biological effects of radiation exposure.",
            "C. Sievert (Sv) and rem measure the biological effect of radiation exposure, while gray (Gy) and rad (rad) measure the absorbed dose of radiation.",
            "D. Sievert (Sv) and rem measure the type of radiation emitted, while gray (Gy) and rad (rad) measure the amount of radiation absorbed by a material."
        ],
        answer: "C",
        explain: "Sievert (Sv) and rem are units that measure the biological effect of radiation exposure, taking into account the type of radiation and its impact on living tissue. Gray (Gy) and rad (rad) are units that measure the absorbed dose of radiation, which is the amount of energy deposited in a material by the radiation. The formula to convert absorbed dose to dose equivalent is Dose Equivalent (Sv or rem) = Absorbed Dose (Gy or rad) × Quality Factor (QF). For example, the quality factor for alpha particles is 20, while for beta particles it is 1, reflecting the difference in biological impact."
    }
    ,
    {
        number: 55,
        description: "How can prolonged exposure to UV radiation contribute to the development of cataracts?",
        choices: [
            "A. By causing inflammation of the cornea",
            "B. By directly damaging the retina",
            "C. By inducing changes in the lens of the eye",
            "D. By increasing tear production"
        ],
        answer: "C",
        "explain": "Prolonged exposure to UV radiation can contribute to the development of cataracts by inducing changes in the lens of the eye. UV radiation damages the proteins in the lens, causing them to clump together and form cloudy areas, which eventually develop into cataracts. This process occurs over time and is a result of cumulative UV exposure. Therefore, the correct answer is C) By inducing changes in the lens of the eye."
    }

    ,
    {
        number: 56,
        description: "What is the recommended distance between a worker and a radiation source to minimize exposure?",
        choices: [
            "A. As close as possible to facilitate efficient work",
            "B. At least 1 meter (3 feet) away from the source",
            "C. Reduce distance to ensure accurate measurements",
            "D. No specific distance requirement, but appropriate shielding is necessary"
        ],
        answer: "B",
        explain: "The amount of radiation exposure decreases rapidly with distance from the source. The inverse square law states that the radiation intensity is inversely proportional to the square of the distance from the source. This means that if you double the distance between yourself and the source, the radiation intensity will be one-quarter as much.\n\nSo, the best way to minimize your exposure to radiation is to stay as far away from the source as possible. The Occupational Safety and Health Administration (OSHA) recommends that workers stay at least 1 meter (3 feet) away from a radiation source. If this is not possible, you should use appropriate shielding to protect yourself."
    },
    {
        number: 57,
        description: "What is the main principle of operation for a Geiger-Muller counter?",
        choices: [
            "A. Scintillation of crystals",
            "B. Ionization of gas molecules",
            "C. Luminescence of phosphors",
            "D. Magnetic induction"
        ],
        answer: "B",
        explain: "The main principle of operation for a Geiger-Muller counter involves the ionization of gas molecules. When an ionizing particle passes through the gas-filled chamber of a Geiger-Muller counter, it ionizes the gas atoms or molecules in the chamber. The resulting ionization creates a chain reaction known as an ionization avalanche, leading to the production of a detectable electrical pulse. This pulse can be counted and measured to determine the presence and intensity of ionizing radiation."
    },
    {
        number: 58,
        description: "What is the difference between photokeratitis and cataracts caused by UV exposure?",
        choices: [
            "A. Photokeratitis is a temporary condition affecting the cornea, while cataracts are a permanent clouding of the lens.",
            "B. Photokeratitis is caused by UVA rays, while cataracts are caused by UVB rays.",
            "C. Photokeratitis affects the lens of the eye, while cataracts affect the retina.",
            "D. Photokeratitis is a painless condition, while cataracts cause immediate pain."
        ],
        answer: "A",
        explain: "Photokeratitis is a temporary and painful condition affecting the cornea, often referred to as 'sunburn of the eye,' and is caused by exposure to UV rays, particularly UVB. Cataracts, on the other hand, are a permanent condition characterized by the clouding of the eye's lens, which can lead to vision impairment and are caused by cumulative exposure to both UVA and UVB rays over time."
    }
    ,
    {
        number: 59,
        description: "What are the key differences between UVA, UVB, and UVC rays in terms of their wavelengths, effects on human skin, and presence in sunlight?",
        choices: [
            "A. UVA rays have the shortest wavelengths, penetrate deep into the skin, and are mostly absorbed by the Earth's atmosphere.",
            "B. UVB rays have intermediate wavelengths, primarily affect the epidermis, and are the main cause of sunburn.",
            "C. UVC rays have the longest wavelengths, are the dominant tanning rays, and are present in tanning beds.",
            "D. UVA rays have the longest wavelengths, contribute to skin aging and wrinkling, and are used in tanning beds."
        ],
        answer: "D",
        explain: "UVA rays (320-400 nm) have the longest wavelengths among the three types of UV radiation. They can penetrate deep into the skin, contributing to skin aging and wrinkling, and are used in tanning beds. UVB rays (290-320 nm) have shorter wavelengths and primarily affect the outer layer of the skin (epidermis), causing sunburn and playing a key role in the development of skin cancer and cataracts. UVC rays (100-290 nm) have the shortest wavelengths and are almost completely absorbed by the Earth's atmosphere, making them less of a concern for human health from sunlight exposure."
    }
    ,
    {
        number: 60,
        description: "Which of the following types of radiation has no charge and consists of electromagnetic waves?",
        choices: [
            "A. Alpha particles",
            "B. Beta particles",
            "C. Gamma rays",
            "D. Neutrons"
        ],
        answer: "C",
        explain: "Gamma rays are a type of electromagnetic radiation, which means they have no charge and consist of photons. They are the highest-energy form of electromagnetic radiation, and they can penetrate deep into matter.\n\nAlpha particles are a type of ionizing radiation, which means they have a positive charge and consist of two protons and two neutrons. They are the least penetrating type of ionizing radiation, and they can only travel a few centimeters in air.\n\nBeta particles are a type of ionizing radiation, which means they have a negative charge and consist of an electron. They are more penetrating than alpha particles, but they are still less penetrating than gamma rays.\n\nNeutrons are a type of subatomic particle that has no charge. They are not considered to be radiation, but they can be emitted by radioactive materials. Neutrons are very penetrating, and they can cause significant damage to cells.They are not associated with electromagnetic radiation but rather belong to the category of particles known as baryons, which are composed of quarks."
    }
]