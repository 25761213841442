export const basic_science_questions = [
    {
        number: 1,
        description: "Which of the following is an example of an element?",
        choices: [
            "A. Water",
            "B. Carbon dioxide",
            "C. Gold",
            "D. Sugar",
        ],
        answer: "C",
        explain: "An element is a substance that cannot be broken down into simpler substances by chemical means. Each element has a unique set of properties that distinguishes it from other elements. Substances like water and sugar are not elements because they are made up of multiple elements chemically combined together. Water, for example, is made up of hydrogen and oxygen atoms in a specific ratio (H2O), while sugar is made up of carbon, hydrogen, and oxygen atoms arranged in a specific way."
    },
    {
        number: 2,
        description: "Which of the following is an example of a chemical change?",
        choices: [
            "A. Melting ice",
            "B. Freezing water",
            "C. Burning paper",
            "D. Dissolving salt in water",
        ],
        answer: "C",
        explain: "A chemical change is a process in which one or more substances are transformed into new substances with different properties through a chemical reaction. In the case of burning paper, the paper undergoes a chemical reaction with oxygen in the air, producing new substances such as carbon dioxide. This is different from a physical change, such as melting ice or freezing water, which does not involve the formation of new substances."
    },
    {
        number: 3,
        description: "What is sublimation in chemistry? ",
        choices: [
            "A. The process of converting a solid into a gas without going through the liquid phase",
            "B. The process of converting a liquid into a solid without going through the gas phase ",
            "C. The process of converting a gas into a solid without going through the liquid phase ",
            "D. The process of converting a gas into a liquid without going through the solid phase "
        ],
        answer: "A",
        explain: "Sublimation is the process of a solid substance directly transitioning to a gas phase without passing through an intermediate liquid phase. This occurs when the substance is heated and the vapor pressure of the solid is greater than the surrounding pressure. When the vapor pressure is equal to the surrounding pressure, the solid will begin to evaporate into the gas phase. Sublimation is the opposite process of deposition, where a gas transforms directly into a solid phase without going through a liquid phase. "
    },

    {
        number: 4,
        description: "Which of the following elements is a noble gas? ",
        choices: [
            "A. Sodium",
            "B. Carbon",
            "C. Helium",
            "D. Oxygen"
        ],
        answer: "C",
        explain: "A noble gas is a type of element that is characterized by its very low reactivity with other elements, particularly under normal conditions. The reason for this is that noble gasses have a full outer shell of electrons, which makes them very stable and unreactive. This also means that they are very unlikely to form compounds with other elements. \n\nHelium, which is one of the options in the multiple-choice question, is a noble gas. It is the second lightest element and has an atomic number of 2, meaning it has two protons in its nucleus. It is the only element that does not solidify when it is cooled to absolute zero and is commonly used in balloons and blimps because of its low density.\n\nThe noble gases include helium (He), neon (Ne), argon (Ar), krypton (Kr), xenon (Xe), and radon (Rn). They are all located in Group 18 (VIII A) of the periodic table, also known as the 'noble gas group' or 'inert gas group'."
    },

    {
        number: 5,
        description: "Which of the following is the smallest particle of an element that retains the properties of that element?",
        choices: [
            "A. Proton",
            "B. Neutron",
            "C. Electron",
            "D. Atom"
        ],
        answer: "D",
        explain: "An atom is the fundamental building block of matter and consists of three main subatomic particles: protons, neutrons, and electrons.\n\nProtons are positively charged particles found in the nucleus (central core) of an atom. They have a relative mass of 1 atomic mass unit (amu) and are responsible for determining the atomic number of an element, which defines its identity. Each element has a unique number of protons in its nucleus.\n\nNeutrons, on the other hand, are uncharged particles also located in the nucleus of an atom. They have a relative mass similar to that of protons (approximately 1 amu). Neutrons contribute to the mass of the atom but do not directly influence its chemical properties. Different isotopes of an element can have varying numbers of neutrons.\n\nElectrons are negatively charged particles that orbit the nucleus of an atom in specific energy levels or shells. They have a much smaller mass compared to protons and neutrons (approximately 1/1836 amu). Electrons determine the chemical behavior and bonding properties of an element. The arrangement of electrons in the atom's energy levels is responsible for the formation of chemical bonds and the overall reactivity of the element.\n\nWhile protons, neutrons, and electrons are all essential components of an atom, it is the combination and arrangement of these particles that determines the unique properties of each element. Thus, the atom, as a whole, is the smallest particle that retains the characteristic properties of a specific element."
    },
    {
        number: 6,
        description: "Which of the following correctly represents the logarithmic rule for log base 10 of 100?",
        choices: [
            "A. log100 = 1",
            "B. log100 = 2",
            "C. log100 = 0",
            "D. log100 = 10"
        ],
        answer: "B",
        explain: "The logarithmic rule for base 10 states that log10(x) equals the exponent to which 10 must be raised to produce x. In the case of log10(100), the equation asks: 'To what power must 10 be raised to get 100?' Since 10^2 = 100, the answer is log10(100) = 2. Often, the base 10 is implied and can be hidden, so log(100) is also equal to 2."
    },
    {
        number: 7,
        description: "Which of the following correctly represents the natural logarithm (log base e) of the number e?",
        choices: [
            "A. ln(e) = 1",
            "B. ln(e) = 0",
            "C. ln(e) = e",
            "D. ln(e) = 2"
        ],
        answer: "A",
        explain: "The natural logarithm, denoted as ln(x), is the logarithm with base e, where e is an irrational number approximately equal to 2.71828. The equation ln(e) asks: 'To what power must e be raised to get e?' Since e^1 = e, the answer is ln(e) = 1."
    },
    {
        number: 8,
        description: "Which of the following correctly describes a general rule for calculating exponents?",
        choices: [
            "A. A^0 = 1",
            "B. A^B × A^C = A^(B+C)",
            "C. (A^B)^C = A^(B×C)",
            "D. All of the above"
        ],
        answer: "D",
        explain: "The following are fundamental rules of exponents:\n\n- A^0 = 1: Any non-zero base A raised to the power of zero equals one.\n- A^B × A^C = A^(B+C): When multiplying like bases with exponents, you add the exponents.\n- (A^B)^C = A^(B×C): When raising a power to another power, you multiply the exponents.\n\nThese rules are key to simplifying expressions and solving mathematical problems involving exponents."
    },
    {
        number: 9,
        description: "What is the name of the process by which a liquid is converted into a gas at the surface of the liquid?",
        choices: [
            "A. Evaporation",
            "B. Sublimation",
            "C. Condensation",
            "D. Deposition "
        ],
        answer: "A",
        explain: "Evaporation is the process by which a liquid is converted into a gas at the surface of the liquid. This occurs when the molecules at the surface of the liquid gain enough energy to overcome the attractive forces that hold them in the liquid phase, and escape into the gas phase. Evaporation occurs at any temperature, but it occurs more rapidly at higher temperatures because the molecules of the liquid have greater kinetic energy and are more likely to escape into the gas phase. "
    },
    {
        number: 10,
        description: "Which of the following is a type of radiation that can be stopped by a sheet of paper? ",
        choices: [
            "A. Alpha radiation",
            "B. Beta radiation",
            "C. Gamma radiation",
            "D. X-rays"
        ],
        answer: "A",
        explain: "Alpha particles are the least penetrating type of radiation. They can be stopped by a sheet of paper or even the outer layer of skin. Beta particles are more penetrating than alpha particles, but they can still be stopped by a thin sheet of metal, such as aluminium. Gamma rays and X-rays are the most penetrating types of radiation. They can travel through thick layers of metal and even through the human body.\n\nAlpha particles are made up of two protons and two neutrons. They are relatively large and slow-moving, which is why they can be stopped by a sheet of paper. Beta particles are made up of electrons. They are smaller and faster-moving than alpha particles, but they can still be stopped by a thin sheet of metal. Gamma rays and X-rays are made up of photons. They are the smallest and fastest-moving type of radiation, which is why they can travel through thick layers of metal and even through the human body."
    },
    {
        number: 11,
        description: "Which type of radiation consists of high-energy electrons or positrons emitted from the nucleus of an atom during radioactive decay?",
        choices: [
            "A. Alpha radiation",
            "B. Beta radiation",
            "C. Gamma radiation",
            "D. X-rays"
        ],
        answer: "B",
        explain: "Beta radiation is a type of ionizing radiation that consists of high-energy electrons or positrons emitted by the radioactive decay of an atomic nucleus during a process called beta decay. Beta particles have a mass of about 1/1836 that of a proton, and a charge of either -1 (electron) or +1 (positron)."
    },
    {
        number: 12,
        description: "Which of the following correctly describes a general rule for calculating logarithms with a base of 10?",
        choices: [
            "A. log10(1) = 0",
            "B. log10(A × B) = log10(A) + log10(B)",
            "C. log10(A/B) = log10(A) - log10(B)",
            "D. All of the above"
        ],
        answer: "D",
        explain: "The following are key rules for calculating logarithms with a base of 10:\n\n- log10(1) = 0: The logarithm of 1 with any base equals zero, because 10^0 = 1.\n- log10(A × B) = log10(A) + log10(B): The logarithm of a product is the sum of the logarithms.\n- log10(A/B) = log10(A) - log10(B): The logarithm of a quotient is the difference of the logarithms.\n\nThese rules are fundamental for simplifying logarithmic expressions and solving equations involving logarithms."
    },
    {
        number: 13,
        description: "Which of the following is a measure of the amount of heat required to raise the temperature of a substance by one degree Celsius?",
        choices: [
            "A. Heat capacity",
            "B. Enthalpy",
            "C. Entropy",
            "D. Free energy"
        ],
        answer: "A",
        explain: "Heat capacity is the measure of the amount of heat required to raise the temperature of a substance by one degree Celsius. It is defined as the ratio of the amount of heat energy transferred to an object to the resulting temperature change. The units of heat capacity are typically J/K (joules per Kelvin) or J/°C (joules per degree Celsius). \n \n Heat capacity is an extensive property, meaning it depends on the amount of material present. For example, a larger quantity of a substance will have a higher heat capacity than a smaller quantity of the same substance. \n \nHeat capacity can also be defined as specific heat, which is the amount of heat energy required to raise the temperature of one gram of a substance by one degree Celsius. The specific heat of a substance is an intensive property, meaning it is independent of the amount of material present. The units of specific heat are typically J/gK or J/g°C."
    },
    {
        number: 14,
        description: "Which of the following is a measure of the acidity or basicity of a solution?",
        choices: [
            "A. pH",
            "B. Molarity",
            "C. Molality",
            "D. Purity"
        ],
        answer: "A",
        explain: "The pH is a measure of the acidity or basicity of a solution. It is defined as the negative logarithm (base 10) of the hydrogen ion concentration in moles per liter. A pH of 7 is considered neutral, while a pH less than 7 is acidic and a pH greater than 7 is basic. The pH scale ranges from 0 to 14, with 0 being the most acidic and 14 being the most basic. The pH of a solution can be measured using a pH meter or through the use of pH indicator solutions."
    },
    {
        number: 15,
        description: "Which of the following is a type of bond in which electrons are shared between atoms?",
        choices: [
            "A. Ionic bond",
            "B. Covalent bond",
            "C. Metallic bond",
            "D. Hydrogen bond"
        ],
        answer: "B",
        explain: "A covalent bond is a chemical bond in which two or more atoms share electrons to form a molecule. In a covalent bond, the shared electrons are attracted to the nuclei of both atoms, which holds the atoms together. Covalent bonds can be polar or nonpolar, depending on the electronegativity difference between the atoms involved in the bond. \n\nPolar covalent bonds involve a partial transfer of electrons from one atom to another, resulting in a slight charge separation and the formation of a dipole moment, while nonpolar covalent bonds involve an equal sharing of electrons between the atoms. \n\nCovalent bonds are typically stronger than other types of bonds, such as ionic or hydrogen bonds."
    },
    {
        number: 16,
        description: "Which of the following is a type of bond that occurs between a metal and a nonmetal?",
        choices: [
            "A. Covalent bond",
            "B. Ionic bond",
            "C. Metallic bond",
            "D. Hydrogen bond"
        ],
        answer: "B",
        explain: "An ionic bond is a type of chemical bond that involves the transfer of electrons from a metal atom to a nonmetal atom to achieve stability. In this type of bond, the metal atom loses one or more electrons to become a positively charged ion, or cation, while the nonmetal atom gains one or more electrons to become a negatively charged ion, or anion. The resulting ions are held together by electrostatic forces, creating a bond known as an ionic bond. This type of bond is typically found in compounds between metals and nonmetals, such as sodium chloride (NaCl)."
    },
    {
        number: 17,
        description: "Which of the following correctly describes a general rule for calculating square roots?",
        choices: [
            "A. √(A × B) = √A × √B",
            "B. √(A/B) = √A / √B",
            "C. √(A^2) = A",
            "D. All of the above"
        ],
        answer: "D",
        explain: "The following are key rules for calculating square roots:\n\n- √(A × B) = √A × √B: The square root of a product is the product of the square roots.\n- √(A/B) = √A / √B: The square root of a quotient is the quotient of the square roots.\n- √(A^2) = A: The square root of a number squared returns the original number.\n\nThese formulas help simplify mathematical expressions and solve problems involving square roots."
    },
    {
        number: 18,
        description: "Which of the following correctly describes general rules for expressing exponents and roots?",
        choices: [
            "A. 1/x = x⁻¹",
            "B. The Nth root of x = x¹/ᴺ",
            "C. (xᵐ)¹/ᵐ = x",
            "D. All of the above"
        ],
        answer: "D",
        explain: "These are fundamental rules for expressing exponents and roots:\n\n- 1/x = x⁻¹: The reciprocal of x can be expressed as x raised to the power of -1.\n- The Nth root of x = x¹/ᴺ: The Nth root of a number can be expressed as that number raised to the power of 1/N.\n- (xᵐ)¹/ᵐ = x: Raising a number to a power and then taking the root that corresponds to that power returns the original number.\n\nThese rules are crucial for simplifying mathematical expressions involving exponents and roots."
    },
    {
        number: 19,
        description: "Which of the following correctly describes the relationship between parts per million (ppm), parts per billion (ppb), and percentage (%)?",
        choices: [
            "A. 1 ppm = 1000 ppb = 0.0001%",
            "B. 1 ppm = 100 ppb = 0.001%",
            "C. 1 ppm = 1000 ppb = 0.0001%",
            "D. 1 ppm = 1000 ppb = 0.0001%"
        ],
        answer: "C",
        explain: "The correct relationships are as follows:\n\n- **1 ppm = 1000 ppb**: ppm and ppb are related by a factor of 1000, meaning that 1 ppm is equal to 1000 ppb.\n- **1 ppm = 0.0001%**: To convert ppm to a percentage, divide by 10,000, because 1% is equivalent to 10,000 ppm."
    },
    {
        number: 20,
        description: "Which of the following is a type of energy associated with the motion of an object?",
        choices: [
            "A. Potential energy",
            "B. Electrical energy",
            "C. Thermal energy",
            "D. Kinetic energy"
        ],
        answer: "D",
        explain: "Kinetic energy is the energy possessed by an object due to its motion. The amount of kinetic energy an object has depends on its mass and velocity, and is given by the equation KE = 0.5mv\u00B2, where m is the mass of the object and v is its velocity. The faster an object is moving, or the greater its mass, the more kinetic energy it has. Kinetic energy can be transferred between objects through collisions or other interactions."
    },
    {
        number: 21,
        description: "Which of the following correctly describes the equivalence between different units of pressure?",
        choices: [
            "A. 1 atm = 760 mm Hg = 101,325 Pa = 47 inches of water column",
            "B. 1 atm = 760 mm Hg = 101,325 Pa = 406.6 inches of water column",
            "C. 1 atm = 760 mm Hg = 101,325 Pa = 29.92 inches of water column",
            "D. 1 atm = 760 mm Hg = 101,325 Pa = 27.7 inches of water column"
        ],
        answer: "B",
        explain: "The correct relationships are as follows:\n\n- **1 atm** (atmosphere) is a standard unit of pressure.\n- **760 mm Hg** (millimeters of mercury) is the pressure exerted by a 760 mm column of mercury at 0°C.\n- **101,325 Pa** (Pascals) is the pressure equivalent to 1 atm in SI units.\n- **406.6 inches of water column** is another equivalent measure, commonly used in HVAC and other applications to express pressure."
    },
    {
        number: 22,
        description: "Which of the following best describes vapor pressure?",
        choices: [
            "A. The pressure exerted by a vapor in equilibrium with its liquid at a given temperature.",
            "B. The maximum pressure a liquid can withstand before boiling.",
            "C. The pressure required to compress a gas into a liquid at a specific temperature.",
            "D. The atmospheric pressure needed to condense a gas into its liquid form."
        ],
        answer: "A",
        explain: "Vapor pressure is defined as the pressure exerted by a vapor in equilibrium with its liquid phase at a specific temperature. It represents the tendency of particles to escape from the liquid phase into the gaseous phase."
    },
    {
        number: 23,
        description: "Which of the following is a type of reaction in which two or more substances combine to form a single, more complex substance?",
        choices: [
            "A. Decomposition reaction",
            "B. Combustion reaction",
            "C. Synthesis reaction",
            "D. Acid-base reaction"
        ],
        answer: "C",
        explain: "A synthesis reaction, also known as a combination reaction or direct combination, is a type of chemical reaction where two or more substances combine to form a single, more complex product. In a synthesis reaction, the reactants react together to create a new compound.\n\nThe general form of a synthesis reaction can be represented as:\n\nA + B → AB\n\nHere, A and B are the reactants, and AB is the product formed by their combination. Synthesis reactions can occur between elements, compounds, or a combination of both.\n\nLet's look at a few examples of synthesis reactions:\n\nCombination of Elements: 2H₂ + O₂ → 2H₂O In this example, hydrogen gas (H₂) and oxygen gas (O₂) combine to form water (H₂O).\n\nCombination of a Metal and Non-metal: CaO + CO₂ → CaCO₃ In this reaction, calcium oxide (CaO) reacts with carbon dioxide (CO₂) to produce calcium carbonate (CaCO₃).\n\nCombination of Compounds: 2NaOH + H₂SO₄ → Na₂SO₄ + 2H₂O Here, sodium hydroxide (NaOH) reacts with sulfuric acid (H₂SO₄) to yield sodium sulfate (Na₂SO₄) and water (H₂O)."
    },
    {
        number: 24,
        description: "Which of the following is a measure of the ability of a material to conduct electricity?",
        choices: [
            "A. Resistance",
            "B. Capacitance",
            "C. Conductivity",
            "D. Voltage"
        ],
        answer: "C",
        explain: "The measure of the ability of a material to conduct electricity is called conductivity. It is the inverse of electrical resistance, which is the measure of how much a material resists the flow of electrical current. Conductivity is dependent on factors such as the type of material, its temperature, and its structure. Materials that are good conductors of electricity, such as metals, have high conductivity, while insulators, such as rubber or plastic, have low conductivity."
    },
    {
        number: 25,
        description: "Which of the following is a type of chemical reaction that involves the transfer of electrons from one substance to another?",
        choices: [
            "A. Redox reaction",
            "B. Acid-base reaction",
            "C. Synthesis reaction",
            "D. Decomposition reaction"
        ],
        answer: "A",
        explain: "Redox reaction involves the transfer of electrons from one substance to another. In a redox reaction, the substance that loses electrons is said to be oxidized, while the substance that gains electrons is said to be reduced. Redox reactions are important in many natural and industrial processes, such as photosynthesis, corrosion, and the production of electricity in batteries.\n\nLet's briefly explain the other options:\n\nB. Acid-base reaction: In an acid-base reaction, also known as a neutralization reaction, an acid reacts with a base to produce a salt and water. This type of reaction does not necessarily involve the transfer of electrons.\n\nC. Synthesis reaction: A synthesis reaction, also called a combination reaction, occurs when two or more reactants combine to form a single product. In a synthesis reaction, there may or may not be a transfer of electrons between the reactants.\n\nD. Decomposition reaction: In a decomposition reaction, a single compound breaks down into two or more simpler substances. Like synthesis reactions, decomposition reactions do not necessarily involve electron transfer."
    },
    {
        number: 26,
        description: "Which of the following is a type of bond in which electrons are shared unequally between different atoms?",
        choices: [
            "A. Ionic bond",
            "B. Nonpolar covalent bond",
            "C. Metallic bond",
            "D. Polar covalent bond"
        ],
        answer: "D",
        explain: "Polar covalent bond is a type of bond in which electrons are shared unequally between atoms. In a polar covalent bond, one atom has a slightly negative charge and the other has a slightly positive charge, creating a dipole moment. This type of bond is intermediate between an ionic bond, where electrons are completely transferred from one atom to another, and a nonpolar covalent bond, where electrons are shared equally between atoms. Examples of compounds with polar covalent bonds include water (H\u2082O) and ammonia (NH\u2083)."
    },
    {
        number: 27,
        description: "Which of the following is a type of bond in which electrons are shared equally between atoms?",
        choices: [
            "A. Ionic bond",
            "B. Polar covalent bond",
            "C. Metallic bond",
            "D. Nonpolar covalent bond"
        ],
        answer: "D",
        explain: "In a covalent bond, atoms share electrons in order to achieve a stable electron configuration. In a nonpolar covalent bond, the atoms share the electrons equally, resulting in a molecule with no net dipole moment. This occurs when the atoms involved in the bond have similar electronegativities, meaning they have similar abilities to attract electrons. \n\nIn contrast, a polar covalent bond occurs when the atoms have different electronegativities, resulting in an unequal sharing of electrons and a molecule with a net dipole moment. An ionic bond involves the transfer of electrons from one atom to another, resulting in the formation of ions that are held together by electrostatic attraction. A metallic bond involves the sharing of delocalized electrons by a lattice of positively charged metal ions, resulting in a strong and flexible bond."
    },
    {
        number: 28,
        description: "Which of the following statements about the boiling point of a liquid is correct?",
        choices: [
            "A. The boiling point of a liquid is the temperature at which its vapor pressure equals atmospheric pressure.",
            "B. The boiling point of a liquid decreases as atmospheric pressure increases.",
            "C. The boiling point is the temperature at which all molecules in a liquid simultaneously turn into vapor.",
            "D. The boiling point is the same for all liquids at standard atmospheric pressure."
        ],
        answer: "A",
        explain: "The boiling point of a liquid is the temperature at which its vapor pressure equals the surrounding atmospheric pressure. At this temperature, the liquid begins to turn into vapor. Boiling points vary between different liquids and are influenced by changes in atmospheric pressure. For example, at higher altitudes, where atmospheric pressure is lower, liquids boil at lower temperatures."
    },
    {
        number: 29,
        description: "Which of the following best defines the flash point of a substance?",
        choices: [
            "A. The lowest temperature at which a liquid emits enough vapor to ignite in the presence of an ignition source.",
            "B. The temperature at which a liquid spontaneously combusts without an external ignition source.",
            "C. The temperature at which a liquid starts boiling.",
            "D. The lowest temperature at which a liquid emits visible vapors."
        ],
        answer: "A",
        explain: "The flash point of a substance is the lowest temperature at which it can vaporize to form an ignitable mixture in air. At this temperature, the vapor may ignite if an ignition source is present. The flash point is a critical parameter in assessing the flammability and safety of handling and storing flammable liquids."
    },
    {
        number: 30,
        description: "Which of the following is a measure of the average kinetic energy of the particles in a substance?",
        choices: [
            "A. Temperature",
            "B. Heat",
            "C. Thermal energy",
            "D. Specific heat"
        ],
        answer: "A",
        explain: "Temperature is a measure of the average kinetic energy of the particles within a substance. It represents the degree of hotness or coldness of an object or a system. In other words, it quantifies how fast the particles are moving or vibrating on average.\n\nThe kinetic energy of particles is related to their motion. At higher temperatures, particles have greater kinetic energy, meaning they move or vibrate faster. Conversely, at lower temperatures, particles have lower kinetic energy and move or vibrate more slowly.\n\nIt's important to note that temperature is a macroscopic property of a substance, meaning it describes the collective behavior of a large number of particles. It provides an indication of the average thermal energy of the particles within a system, rather than the energy of individual particles."
    },
    {
        number: 31,
        description: "A room measuring 5 meters wide, 10 meters long, and 2 meters high contains 1 cubic meter of carbon monoxide gas. What is the concentration of carbon monoxide in parts per million (ppm)?",
        choices: [
            "A. 10,000 ppm",
            "B. 10,0000 ppm",
            "C. 5,000 ppm",
            "D. 50000 ppm"
        ],
        answer: "A",
        explain: "Use Equation 1 in General Science. ppm = 1/(5*10*2) * 10\u2076 = 10,000 ppm."
    },
    {
        number: 32,
        description: "At a certain temperature, the vapor pressure of toluene is 150 mmHg. If the total pressure in the container is 1 atm, what is the equilibrium concentration of toluene in the air in parts per million (ppm)?",
        choices: [
            "A. 1.97*10\u2075 ppm",
            "B. 1.97*10\u00B3 ppm",
            "C. 1.5 *10\u2075 ppm",
            "D. 1.5 *10\u00B3 ppm"
        ],
        answer: "A",
        explain: "Use the constant in equation sheeet. At 1 atm, the vapor pressure is 760 mmHg (can be found in CIH equation sheet, under Constant and Conversions). Concentration of toluene = 150 mmHg/ 760 mmHg = 1.97*10\u2075 ppm."
    },
    {
        number: 33,
        description: "If the concentration of carbon dioxide in a room is 2 ppm, what is the mass per volume concentration of carbon dioxide in mg/m\u00B3. The molar mass of carbon dioxide is 44 g/mol. Assuming a temperature of 25°C and a pressure of 1 atm.",
        choices: [
            "A. 5.59 mg/m\u00B3",
            "B. 3.59 mg/m\u00B3",
            "C. 4.59 mg/m\u00B3",
            "D. 4.44 mg/m\u00B3"
        ],
        answer: "B",
        explain: "Use Equation 3 in General Science. Substituting ppm with 2; m.w(molar weight) with 44. The concentration (mg/m\u00B3) is 2*44/24.45 = 3.59. This equation is for Standard Ambient Temperature and Pressure (1 atm and 25°C), which is not standard temperature and pressure (1 atm and 0°C)"
    },
    {
        number: 34,
        description: "An ideal gas at a pressure of 1 atm and temperature of 300 K is compressed to a volume of 1/3 of its original volume while its temperature is increased to 900K. What is the new pressure of the gas relative to its initial pressure?",
        choices: [
            "A. 3 atm",
            "B. 6 atm",
            "C. 9 atm",
            "D. 1/9 atm"
        ],
        answer: "C",
        explain: "Use Equation 4 in general science. Substituting these values into the ideal gas law, we can solve for P\u2082: P\u2082 = (P\u2081V\u2081T\u2082) / (V\u2082T\u2081) =(1 x 900 ) / (1/3 x 300) * 1 atm = 9 atm."
    },
    {
        number: 35,
        description: "What is the terminal settling velocity of particles with an average diameter of 1 um in still air, given the density of the particle is 0.8 g/cm\u00B3, the density of air is 0.001 g/cm\u00B3, and its viscosity is 0.0002 poise? The gravitational acceleration is 981 cm/sec\u00B2.",
        choices: [
            "A. 0.0012 cm/sec",
            "B. 0.0062 cm/sec",
            "C. 0.0042 cm/sec",
            "D. 0.0022 cm/sec"
        ],
        answer: "D",
        explain: "Use Equation 5 in General Science. Vts = terminal setting velocity of particle in cm/sec; g=acceleration due to gravity; dp = diameter of particle in cm; ρp = density of particle, g/cm\u00B3, ρa = density of fluid, g/cm\u00B3. η= viscosity of fluid, in poise. Vts = 981 * 0.0001\u00B2 * (0.8-0.001)/(18 * 0.0002) = 0.0022."
    },
    {
        number: 36,
        description: "What is the Reynolds number for a perfume spray that generates particles with a diameter of 50 um, given the atmospheric density is 0.001 g/cm\u00B3, the particle velocity is 5 cm/sec, and its viscosity is 0.0005 P?",
        choices: [
            "A. 0.5",
            "B. 0.09",
            "C. 0.05 ",
            "D. 5"
        ],
        answer: "C",
        explain: "Use Equation 6 in General Science. The Reynolds number (Re) is a dimensionless quantity used in fluid mechanics to predict the flow behavior of fluids in different scenarios. It is named after Osborne Reynolds, a British physicist who pioneered work in this area. The Reynolds number is defined as the ratio of inertial forces to viscous forces in a fluid flow, and it is given by the following equation: Re = (ρ * v * d) / μ  \n \n where:ρ is the density of the fluid, in g/cm\u00B3. \n \n v is the velocity of the fluid in cm/sec \n \n d is a the diameter of particle, in cm.\n \n μ is the viscosity of the fluid in poise. \n \n Substituting ρ = 0.001, v= 5, d=0.005, μ=0.0005P, we get Re = 0.05.\n \n The Reynolds number is used to predict the type of flow that will occur in a fluid, such as laminar, transitional, or turbulent flow. When the Reynolds number is below a certain critical value, the flow is laminar, with smooth and ordered movement of the fluid particles. When the Reynolds number exceeds the critical value, the flow becomes turbulent, with chaotic and random movement of the fluid particles. The transitional region between laminar and turbulent flow is characterized by fluctuations and irregularities in the flow."
    },
    {
        number: 37,
        description: "A solution is placed in a cuvette with a path length of 1 cm. The solution reduces the intensity of light passing through it to 1/5th of the incident intensity. If the molar absorptivity of the solute is 3 L/mol/cm, what is the concentration of the solution in g/l?",
        choices: [
            "A. 2.3 g/l",
            "B. 0.53 g/l",
            "C. 0.93 g/l",
            "D. 0.23 g/l"
        ],
        answer: "D",
        explain: "Use Equation 7 in General Science. We can use the Beer-Lambert Law to determine the concentration of the solution:\n \n log(I\u2080/I) = abc where:\n\na is the molar absorptivity constant (3 L/g/cm) \n\n b is the path length (1 cm) \n\n c is the concentration of absorbing material in g/L. Substituting the given values into the equation, we get: \n\n log(5)=0.699=(3 L/mol/cm) * (1 cm) * c \n\n C = 0.23 g/l."
    },
    {
        number: 38,
        description: "What is the pH value for a solution with a concentration of 0.1 M of a given substance?",
        choices: [
            "A. 1",
            "B. 10",
            "C. 0.1",
            "D. 7"
        ],
        answer: "A",
        explain: "Use Equation 8 in General Science. H\u207A = hydrogen ion centration in moles/liter(molarity). pH = -log\u2081\u2080(0.1) = 1."
    },
    {
        number: 39,
        description: "What is the acid dissociation constant, Ka, of a chlorhydric acid solution given that it has a hydrogen ion concentration of 0.008 M and a concentration of non-ionized acid of 0.8 M?",
        choices: [
            "A. 2 x 10\u207B\u2075",
            "B. 4 x 10\u207B\u2075",
            "C. 5 x 10\u207B\u2075",
            "D. 8 x 10\u207B\u2075"
        ],
        answer: "D"
        ,
        explain: "Use Equation 9 in General Science. [H\u207A] represents the hydrogen ion concentration, [A\u207B] represents the concentration of the conjugate base (chloride ion, Cl\u207B in this case), and [HA] represents the concentration of the non-ionized acid. The ratio of moles of Cl\u207B to H\u207A is 1:1. So substituting the given values, we get:\n\nKa = (0.008M)(0.008M) / (0.8M) \n\n Simplifying the equation:\n\n Ka = 8 x 10\u207B\u2075. \n\nKa, the acid dissociation constant, is a quantitative measure of the strength of an acid in solution. It describes the extent to which an acid ionizes or dissociates into its constituent ions in water."
    },
    {
        number: 40,
        description: "What is the base dissociation constant, Kb, of a sodium hydroxide solution with a hydroxide ion concentration of 0.008 M and a concentration of non-ionized base of 0.8 M?",
        choices: [
            "A. 8 x 10\u207B\u00B3",
            "B. 8 x 10\u207B\u2077",
            "C. 8 x 10\u207B\u2075",
            "D. 4 x 10\u207B\u2075"
        ],
        answer: "C",
        explain: "Use Equation 10 in General Science. [OH\u207B] represents the hydroxide ion concentration, [BH\u207A] represents the concentration of the conjugate acid (sodium ion, Na\u207A in this case), and [B] represents the concentration of the non-ionized base. The ratio of moles of OH\u207B to Na\u207A is 1:1. So substituting the given values, we get:\n\n Kb = (0.008M)(0.008M)/(0.8M) \n\n Simplifying the equation:\n\n Kb = 8 x 10\u207B\u2075.\n\nThe base dissociation constant, often denoted as Kb, is a measure of the strength of a base in solution. It describes the extent to which a base ionizes or dissociates into its constituent ions in water. Similar to the acid dissociation constant Ka, the base dissociation constant is an equilibrium constant."
    },
    {
        number: 41,
        description: "Calculate the vapor pressure of a mixture containing 4 moles of ethanol (vapor pressure 43.9 mmHg) and 6 moles of water (vapor pressure 17.5 mmHg)",
        choices: [
            "A. 58.06 mmHg",
            "B. 28.06 mmHg",
            "C. 58.06 mmHg",
            "D. 68.06 mmHg"
        ],
        answer: "B",
        explain: "Use Equation 11 in General Science. Ptotal = total pressure of the gas mixture in mmHg. X\u1D62 = mole fraction of gas i. Pi = vapor pressure of gas i in mmHg. The mole fraction of ethanol = number of moles of ethanol / total number of moles in solution = 4 / (4 + 6) = 0.4. Similarly, the mole fraction of water = 6 / (4 + 6) = 0.6.\n\n Now, we can use Raoult's Law to calculate the vapor pressure of the solution: P = 0.4 * 43.9 + 0.6 * 17.5 = 28.06 mmHg."
    },
    {
        number: 42,
        description: "What is  the VHR (Vapor Hazard Ratio) for toluene (PEL = 50 ppm). The saturated vapor concentration of toluene is 38000 ppm.",
        choices: [
            "A. 50",
            "B. 760",
            "C. 38000",
            "D. 500"
        ],
        answer: "B",
        explain: "Use Equation 12 in General Science. The Vapor Hazard Ratio (VHR) is a ratio used to evaluate the potential health hazard of a volatile chemical in the air. It is calculated by dividing the actual concentration of a chemical in the air by its permissible exposure limit (PEL), which is the maximum concentration allowed for a worker to be exposed to the chemical during an 8-hour work shift without suffering adverse health effects.\n\nIf the VHR is greater than 1, it indicates that the actual concentration of the chemical in the air is higher than the PEL, which suggests a potential health hazard and the need for appropriate protective measures. On the other hand, if the VHR is less than 1, it indicates that the actual concentration of the chemical in the air is lower than the PEL, which suggests that the exposure level is safe for workers.\n\nIn the question, VHR = 38000/50 =760."
    },
    {
        number: 43,
        description: "In a chemical plant, the air contains 15 ppm of ethyl acetate (TLV 400 ppm), 10 ppm of methanol (TLV 200 ppm), and 5 ppm of acetone (TLV 1000 ppm).  What is the combined TLV  in this environment?",
        choices: [
            "A. 0.3",
            "B. 0.5",
            "C. 0.8",
            "D. 0.1"
        ],
        answer: "D",
        explain: "Use Equation 13 in General Science. TLV means threshold limit value. C means measured concentration. TLVmix = (Actual Concentration of Ethyl Acetate / TLV of Ethyl Acetate) + (Actual Concentration of Methanol / TLV of Methanol) + (Actual Concentration of Acetone / TLV of Acetone). \n\n Substituting the given values, we get: TLVmix = (15 ppm / 400 ppm) + (10 ppm / 200 ppm) + (5 ppm / 1000 ppm) =0.0925.\n\n Since the TLVmix is less than 1, it indicates that the combined TLV of the three chemicals is not exceeded in this environment."
    },
    {
        number: 44,
        description: "What is the TLVmix of a liquid mixture of a 30/70 mixture of toluene (TLV = 375 mg/m\u00B3) and cyclohexane (TLV = 800 mg/m\u00B3)?",
        choices: [
            "A. 597 mg/m\u00B3",
            "B. 392 mg/m\u00B3",
            "C. 800 mg/m\u00B3",
            "D. 375 mg/m\u00B3"
        ],
        answer: "A",
        explain: "Use Equation 14 in General Science. Fn = volumn fraction of gas n. Substituting F\u2081 = 0.3, TLV\u2081= 375, F\u2082=0.7, TLV\u2082 = 800. TLVmix =597 mg/m\u00B3."
    },
    {
        number: 45,
        description: "A chemical has a PEL of 100 ppm. What is the permissible limit for a ten-hour shift?",
        choices: [
            "A. 100 ppm",
            "B. 70 ppm",
            "C. 80 ppm",
            "D. 90 ppm"
        ],
        answer: "B",
        explain: "Use Equation 15 in General Science. Reduction Factor = 8/10*(24-10)/16 = 0.7. This means the PEL reduced to 0.7 of 100 ppm."
    },
    {
        number: 46,
        description: "A chemical has a PEL of 100 ppm. What is the permissible limit for a 48 hour workweek?",
        choices: [
            "A. 100 ppm",
            "B. 78 ppm",
            "C. 80 ppm",
            "D. 90 ppm"
        ],
        answer: "B",
        explain: "Use Equation 16 in General Science. Reduction Factor = 40/48*(168-48)/128 = 0.78. This means the PEL reduced to 0.78 of 100 ppm."
    },
    {
        number: 47,
        description: "A workplace air sample is collected using a 25 mm filter with a graticule field area of 0.00785 mm\u00B2. After counting 200 fields, 16 asbestos fibers are observed, and the field blank contains 1 fiber. What is the airborne concentration of asbestos fibers in fibers per cubic centimeter (f/cc)? Assuming the total volume of air sampled is 1000 L.",
        choices: [
            "A. 0.04 fibers/ml",
            "B. 0.07 fibers/ml",
            "C. 0.09 fibers/ml",
            "D. 0.004 fibers/ml"
        ],
        answer: "D",
        explain: "Use Equation 17 in General Science. This equation is used to evaluate the concentration of asbestos fibers in the air through the use of phase-contrast microscopy (PCM). Cs = average number of fibers counted per graticule field in the sample, which is 16/200 = 0.08. Cb = Average number of fibers in the field blank, which is 1/200 = 0.005. Ac = effective area of filter, 385 mm\u00B2 for 25 mm filter. Af = Graticule field area, 0.00785 mm\u00B2. Vs = air volume sample in litters, 1000 liters.\n\n Casb = (0.08 - 0.005)*385/(1000*0.00785*1000) = 0.0037 fibers/ml.\n\n"
    },
    {
        number: 48,
        description: "If a workplace air sample of asbestos fibers is collected using a 25-mm filter with a graticule field area of 0.00785 mm\u00B2 and 1000 liters of air are sampled, what is the concentration of asbestos fibers? The fiber density on the filter is 100 fibers/mm\u00B2.",
        choices: [
            "A. 0.0385 fibers/mL",
            "B. 38.5 fibers/mL",
            "C. 0.385 fibers/mL",
            "D. 0.09 fibers/mL"
        ],
        answer: "A",
        explain: "Use Equation 18 in General Science. This equation is employed to evaluate the concentration of asbestos fibers in the air through the use of phase-contrast microscopy (PCM) with known fiber density. E = fiber density on filter, which is 100 fibers/mm\u00B2. Ac = effective area of filter, 385 mm\u00B2 for 25 mm filter.Vs = air volume sample in litters, 1000 liters. Casb = 100*385/(1000 * 1000) = 0.0385 fibers/ml."
    },
    {
        number: 49,
        description: "What is the fiber density if the filter contains 120 fibers and 150 fields were examined, assuming that the field blank has no fibers?",
        choices: [
            "A. 120 fibers/mm\u00B2",
            "B. 150 fibers/mm\u00B2",
            "C. 1 fibers/mm\u00B2",
            "D. 102 fibers/mm\u00B2"
        ],
        answer: "D",
        explain: "Use Equation 19 in General Science. f/Nf = average fiber count per graticule field. B/Nb = average fiber count per graticule field of the field blank. Af = graticule field area, commonly 0.00785 mm\u00B2. So E = (120/150 -0)/0.00785=102 fibers/mm\u00B2."
    },
    {
        number: 50,
        description: "What is the limit of resolution for a microscope with wavelength at 600 nm. Assume the numerical aperture is 0.5.",
        choices: [
            "A. 732 nm",
            "B. 600 nm",
            "C. 632 nm",
            "D. 32 nm"
        ],
        answer: "A",
        explain: "Use Equation 20 in General Science. d=limit of resolution in nm, λ = wavelength of imaging radiation in nm. n sin(θ) = numerical aperture (NA), which is a measure of the light-gathering ability of an optical system such as a microscope lens or an objective lens. So d = 0.61*600/0.5."
    },
    {
        number: 51,
        description: "Which of the following correctly matches the temperature conversion formulas between Celsius (°C), Fahrenheit (°F), Kelvin (K), and Rankine (R)?",
        choices: [
            "A. °F = (°C × 1.8) + 32, K = °C + 273.15, R = °F + 459.67",
            "B. °F = (°C × 1.8) + 32, K = °C + 273.15, R = K × 1.8",
            "C. °F = (°C × 1.8) + 32, K = °C + 273.15, R = (K × 9/5) - 32",
            "D. °F = (°C × 1.8) - 32, K = °C + 273.15, R = K + 273.15"
        ],
        answer: "A",
        explain: "The correct temperature conversion formulas are as follows:\n\n- **°F = (°C × 1.8) + 32**: This formula converts Celsius to Fahrenheit.\n- **K = °C + 273.15**: This formula converts Celsius to Kelvin.\n- **R = °F + 459.67**: This formula converts Fahrenheit to Rankine, which is another absolute temperature scale where 0 R = 0 K."
    },
    {
        number: 52,
        description: "What is the temperature and pressure under STP and NTP?",
        choices: [
            "A. Standard Temperature and Pressure: 0 °C (273K), 1 atm. Normal Temerature and Pressure: 20°C (293K), 1 atm/760 mmg",
            "B. Standard Temperature and Pressure: 20 °C (273K), 1 atm. Normal Temerature and Pressure: 20°C (293K), 1 atm/760 mmg",
            "C. Standard Temperature and Pressure: 0 °C (273K), 1 atm. Normal Temerature and Pressure: 0°C (293K), 1 atm/760 mmg",
            "D. Standard Temperature and Pressure: 0 °C (273K), 1 atm. Normal Temerature and Pressure: 20°C (293K), 1 atm/570 mmHg"
        ],
        answer: "A",
        explain: 'STP and NTP are two different sets of standard conditions often used in chemistry and physics to specify the temperature and pressure for comparing and reporting measurements. \n\n Remember this can be helpful: the molar volume is 22.4 L under STP and 24.04 L under NTP. \n\n Please note that STP is different from standard ambient temperature and pressure (SATP), which has temperature of 25 °C and 1 atm.'
    },
    {
        number: 53,
        description: "An oxygen cylinder is at a room temperature of 60 °F, and the pressure is 1000 psi, what will the pressure be if temperature raise to 80 °F?",
        choices: [
            "A. 1300 psi",
            "B. 1238 psi",
            "C. 1039 psi",
            "D. 800 psi"
        ],
        answer: "C",
        explain: 'Based on Equation 4 in General Science. Pressure is proportional to temperature. Pay attention that the Temperature needs to be converted to absolute temperature in Kevin. Use Equation 1 in Constant and Equations to convert Temperature from F to C. Then Use Equation 1 in Constant and Equations to convert C to K. Therefore initial temperature is : (60-32)*5/9 + 273 = 288.56. Final temperature = (80-32)*5/9 + 273 = 299.67.\n\n1000/(288.56) = P/(299.67) \n\n P = 1039 psi.'
    },
    {
        number: 54,
        description: "What is the primary role of a catalyst in a chemical reaction?",
        choices: [
            "A. To increase the energy of activation required for the reaction to occur",
            "B. To lower the energy of activation required for the reaction to occur",
            "C. To provide additional reactants to drive the reaction forward",
            "D. To change the stoichiometry of the reaction equation"
        ],
        answer: "B",
        explain: "A catalyst is a substance that facilitates a chemical reaction by lowering the energy of activation required for the reaction to occur. The energy of activation is the minimum energy input needed for the reactant molecules to undergo the necessary rearrangements and form the products. By lowering this energy barrier, a catalyst enables the reaction to proceed at a faster rate or under milder conditions.\n\nA catalyst itself is not consumed or permanently altered during the reaction.It interacts with the reactant molecules, providing an alternative reaction pathway with lower activation energy. This allows the reactant molecules to overcome the energy barrier more easily, resulting in an accelerated reaction."
    },
    {
        number: 55,
        description: "Which of the following is an example of an endothermic reaction?",
        choices: [
            "A. Combustion of gasoline",
            "B. Freezing of water",
            "C. Burning of wood",
            "D. Formation of rust"
        ],
        answer: "B",
        explain: "Endothermic reactions are reactions that absorb heat from the surroundings. Freezing of water is an endothermic reaction because it takes heat away from the surroundings to form ice. The other options are all exothermic reactions, which release heat to the surroundings."
    },
    {
        number: 56,
        description: "What is absolute temperature?",
        choices: [
            "A. Temperature measured on a scale where 0 represents the freezing point of water.",
            "B. Temperature measured on a scale where 0 represents the lowest possible temperature.",
            "C. Temperature measured on a scale where 0 represents the boiling point of water.",
            "D. Temperature measured on a scale where 0 represents room temperature."
        ],
        answer: "B",
        explain: "Absolute temperature is measured on a scale where 0 represents the lowest possible temperature, known as absolute zero. This is the theoretical temperature at which all molecular motion ceases. The Kelvin scale is an absolute temperature scale, where 0 Kelvin is equivalent to -273.15 degrees Celsius. Rankine scale is abosulte as well. Other temperature scales, like Celsius and Fahrenheit, are relative scales with arbitrary zero points."
    },
    {
        number: 57,
        description: "What is sound energy?",
        choices: [
            "A. The energy generated by vibrating objects.",
            "B. The energy carried by electromagnetic waves.",
            "C. The energy stored in sound-producing devices.",
            "D. The energy required to propagate sound waves."
        ],
        answer: "A",
        explain: "Sound energy is the energy that is generated by vibrating objects. When an object vibrates, it causes the air around it to vibrate as well. These vibrations travel through the air as sound waves. The energy of the sound waves is what we hear as sound.\n\nElectromagnetic waves are a different form of energy. They are not generated by vibrating objects, but by charged particles. Electromagnetic waves can travel through air, but they can also travel through other substances, such as water and glass.\n\nSound-producing devices, such as speakers and microphones, do not store sound energy. They convert other forms of energy, such as electrical energy, into sound energy.\n\nThe energy required to propagate sound waves is a measure of the amount of energy that is needed to keep the sound waves traveling. It is not the same as sound energy."
    },
    {
        number: 58,
        description: "What is the difference between DNA and RNA?",
        choices: [
            "A. DNA contains the sugar deoxyribose, while RNA contains ribose.",
            "B. DNA is double-stranded, while RNA is single-stranded.",
            "C. DNA uses thymine as one of its nitrogenous bases, while RNA uses uracil.",
            "D. All of the above."
        ],
        answer: "D",
        explain: "DNA and RNA are both nucleic acids, but they have some key differences. DNA is double-stranded, while RNA is single-stranded. DNA contains the sugar deoxyribose, while RNA contains ribose. DNA uses thymine as one of its nitrogenous bases, while RNA uses uracil."
    },
    {
        number: 59,
        description: "What defines a solution equilibrium in a chemical reaction?",
        choices: [
            "A. Equal concentrations of reactants and products.",
            "B. The complete consumption of reactants.",
            "C. A dynamic balance between the forward and reverse reactions.",
            "D. The absence of any chemical reaction."
        ],
        answer: "C",
        explain: "In a solution equilibrium, the reactants are continuously converting into products, while the products are simultaneously converting back into reactants. This dynamic equilibrium occurs when the rates of the forward and reverse reactions are equal, resulting in a stable concentration of the reactants and products."
    },
    {
        number: 60,
        description: "What is the unit used to measure electrical energy?",
        choices: [
            "A. Watts (W)",
            "B. Joules (J)",
            "C. Amperes (A)",
            "D. Volts (V)"
        ],
        answer: "B",
        explain: "Joules (J) is the unit of energy in the International System of Units (SI). It is named after the English physicist James Prescott Joule. One joule is the amount of energy required to move one kilogram of mass one meter per second squared. \n\nThe unit used to measure electrical energy is the joule (J). While watts (W) are used to measure power (the rate at which energy is transferred or converted), joules represent the total amount of energy. In electrical terms, one joule is equal to one watt-second, reflecting the energy consumption or transfer over time."
    },
    {
        number: 61,
        description: "Which statement accurately describes sound propagation in a vacuum?",
        choices: [
            "A. Sound travels at the same speed as light in a vacuum.",
            "B. Sound waves are unable to propagate in a vacuum.",
            "C. The speed of sound in a vacuum is infinite.",
            "D. Sound travels faster in a vacuum compared to other mediums."
        ],
        answer: "B",
        explain: "In a vacuum, there is an absence of particles or a medium for sound waves to travel through. Since sound waves require a medium to propagate, they are unable to travel in a vacuum. "
    },
    {
        number: 62,
        description: "Which subatomic particle carries a positive electric charge?",
        choices: [
            "A. Proton",
            "B. Neutron",
            "C. Electron",
            "D. Photon"
        ],
        answer: "A",
        explain: "Protons are subatomic particles that are found in the nucleus of an atom. They have a positive electric charge, which is equal to the negative charge of an electron. Photons, which are elementary particles of light, are electrically neutral, meaning they do not carry any electric charge."
    },
    {
        number: 63,
        description: "Which of the following components is present in all viruses?",
        choices: [
            "A. Capsid",
            "B. Envelope",
            "C. Spike proteins",
            "D. Genetic material"
        ],
        answer: "D",
        explain: "The genetic material is the component present in all viruses. It refers to the DNA or RNA that carries the instructions necessary for the virus to replicate and infect host cells. The genetic material can be single-stranded or double-stranded, depending on the specific virus. It encodes the necessary information for the production of viral proteins and replication of the viral genome. Without genetic material, a virus cannot carry out its life cycle and replicate itself. \n\nThe capsid, envelope, and spike proteins, on the other hand, are not universally present in all viruses. They can vary depending on the specific type of virus and its classification."
    },
    {
        number: 64,
        description: "Which of the following is a primary function of the pancreas?",
        choices: [
            "A. Production of bile",
            "B. Regulation of blood sugar levels",
            "C. Filtration of blood",
            "D. Secretion of gastric juices"
        ],
        answer: "B",
        explain: "The pancreas has two main functions: exocrine and endocrine. The exocrine function of the pancreas is to produce digestive enzymes that help break down food. The endocrine function of the pancreas is to produce hormones that regulate blood sugar levels. So the answer is (b).\n\nBile is produced by the liver, not the pancreas. Filtration of blood is the function of the kidneys, not the pancreas. Secretion of gastric juices is the function of the stomach, not the pancreas."
    },
    {
        number: 65,
        description: "According to Ideal Gas Law, what is the relationship between the volume and pressure of a gas at constant temperature?",
        choices: [
            "A. They are directly proportional.",
            "B. They are inversely proportional.",
            "C. They are not related.",
            "D. They fluctuate randomly."
        ],
        answer: "B",
        explain: "According to Ideal Gas Law, the volume and pressure of a gas at constant temperature are inversely proportional.\n\nIn other words, if the volume of a gas increases, the pressure will decrease, and vice versa. This is because the number of gas molecules in the container is constant, so if the volume increases, the molecules have more space to move around in, which reduces the pressure. Conversely, if the volume decreases, the molecules have less space to move around in, which increases the pressure."
    },
    {
        number: 66,
        description: "What is the Lower Explosive Limit (LEL)?",
        choices: [
            "A. The maximum concentration of a gas or vapor that can burn in air.",
            "B. The minimum concentration of a gas or vapor that can burn in air.",
            "C. The amount of oxygen required for a gas to burn.",
            "D. The temperature at which a gas ignites spontaneously."
        ],
        answer: "B",
        explain: "The Lower Explosive Limit (LEL) is the minimum concentration of a flammable gas or vapor in air that can support combustion. Below this concentration, the mixture is too lean to burn. LEL is important for safety in industries handling flammable substances, as concentrations above LEL can lead to explosions."
    }
    ,
    {
        number: 67,
        description: "Newton's First Law of Motion is also known as the law of:",
        choices: [
            "A. Acceleration",
            "B. Inertia",
            "C. Action and reaction",
            "D. Conservation of energy"
        ],
        answer: "B",
        explain: "Newton's First Law of Motion is often referred to as the law of inertia. It states that an object at rest or in uniform motion will continue to stay in that state unless acted upon by an external force."
    },
    {
        number: 68,
        description: "Newton's Second Law of Motion states that the force acting on an object is equal to:",
        choices: [
            "A. Its mass divided by acceleration",
            "B. Its velocity multiplied by displacement",
            "C. Its mass multiplied by acceleration",
            "D. Its weight divided by mass"
        ],
        answer: "C",
        explain: "Newton's Second Law of Motion states that the force acting on an object is equal to the mass of the object multiplied by its acceleration. In other words, the greater the mass of an object, the more force is needed to accelerate it. And the greater the acceleration of an object, the more force is needed.\n\nThe equation for Newton's Second Law of Motion is: F=ma. \n\nwhere F is the force, m is the mass, and a is the acceleration."
    },
    {
        number: 69,
        description: "According to the Law of Conservation of Mass, which of the following statements is true during a chemical reaction?",
        choices: [
            "A. The total mass of the reactants is always greater than the total mass of the products.",
            "B. The total mass of the reactants is always less than the total mass of the products.",
            "C. The total mass of the reactants is equal to the total mass of the products.",
            "D. The total mass of the reactants and products varies randomly."
        ],
        answer: "C",
        explain: "According to the Law of Conservation of Mass, the total mass of the reactants in a chemical reaction is equal to the total mass of the products. This means that matter is neither created nor destroyed; it only undergoes a rearrangement of atoms."
    },
    {
        number: 70,
        description: "What is the most abundant gas in the Earth's atmosphere?",
        choices: [
            "A. Oxygen",
            "B. Nitrogen",
            "C. Carbon dioxide",
            "D. Argon"
        ],
        answer: "B",
        explain: "Nitrogen is the most abundant gas in the Earth's atmosphere, making up about 78% of the air we breathe. Oxygen, while essential for life, is only about 21% of the atmosphere. Carbon dioxide and argon are present in much smaller amounts."
    }

]