import React, { useState, useEffect } from 'react';

const ConfettiComponent = () => {
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsActive(false);
        }, 5000); // 5000 milliseconds = 5 seconds

        return () => clearTimeout(timeoutId);
    }, []); // Empty dependency array ensures the effect runs once after the initial render

    return isActive ? (
        <div className="confetti active">
            <div class="confetti">
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
            </div>
        </div>
    ) : null;
};

export default ConfettiComponent