import React, { useState, useEffect } from 'react'
import Navigationbar from './Navigationbar'
import Air_sampling_video from '../VideosCollections/air_sampling_video.js'
import loading from "../assets/spinner.gif"
import cdcrad from "../assets/cdcrad.png"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ConfettiComponent from './ConfettiComponent'

function Videos({ currUser, handleLogout, updateCurrUser }) {
    let navigate = useNavigate();
    const clickeToLogIn = () => {
        navigate('/login')
    }
    const validAccess = (expire_date) => {
        let currDate = new Date();
        let date2 = new Date(expire_date);
        if (date2 >= currDate) {
            return true
        } else {
            return false
        }
    }
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);
    const [selectedCategory, setSelectedCategory] = useState("Analytical_Chemistry")
    const [isLoading, setisLoading] = useState(false);
    const [isConfet, setisConfet] = useState(false)
    const [categoryIndex, setCategoryIndex] = useState(0)
    const all_categories = [{ appearname: "Analytical_Chemistry", databasename: "Analytical_Chemistry" }, { appearname: "Biostatistics", databasename: "Biostatistics" }, { appearname: "Epidemiology", databasename: "Epidemiology" }, { appearname: "Ventilation_Part1", databasename: "Ventilation_Part1" }, { appearname: "Ventilation_Part2", databasename: "Ventilation_Part2" }, { appearname: "Noise", databasename: "Noise" }, { appearname: "Radiation_Part1", databasename: "Radiation_Part1" }, { appearname: "Radiation_Part2", databasename: "Radiation_Part2" }, { appearname: "Toxicology", databasename: "Toxicology" }, { appearname: "Heat Stress", databasename: "Heat_Stress" }, { appearname: "Ergonomics", databasename: "Ergonomics" }]
    const selectCategory = (name) => {
        setSelectedCategory(name)
        setCategoryIndex(all_categories.findIndex(category => category.databasename === name))
        setisConfet(false)
    }
    const video_content = {
        'Analytical_Chemistry': 'https://www.youtube.com/embed/eGfWymVxqTU',
        'Biostatistics': 'https://www.youtube.com/embed/v5-ZkOuTcpk',
        'Epidemiology': 'https://www.youtube.com/embed/4oaQUAnA6nY',
        'Ventilation_Part1': 'https://www.youtube.com/embed/MRRv1x40Bnw',
        'Ventilation_Part2': 'https://www.youtube.com/embed/3mTRN1ZYs0Q',
        'Noise': 'https://www.youtube.com/embed/Z-PNN9p2Vn8',
        'Radiation_Part1': 'https://www.youtube.com/embed/9HBBjIWKaLQ',
        'Radiation_Part2': 'https://www.youtube.com/embed/8sr0JwmlomA',
        'Toxicology': 'https://www.youtube.com/embed/u3iVX21o9H8',
        'Heat_Stress': 'https://www.youtube.com/embed/ktVZX7OCBCM',
        'Ergonomics': 'https://www.youtube.com/embed/u7ct9Bq4w4k',
    }
    const handleMark = () => {
        console.log("mark as complete")
        setisLoading(true)
        try {
            axios.post(`/markvideos`, { currUser, selectedCategory }).then(
                (response) => {
                    setisLoading(false)
                    updateCurrUser(response.data)
                    setisConfet(true)
                }
            )
        }
        catch (error) {
            console.log(error)
        }
    }
    const nextVideo = (name) => {
        const keys = Object.keys(video_content);
        var currentIndex = all_categories.findIndex(category => category.databasename === name)
        const nextIndex = (currentIndex + 1) % keys.length;
        setSelectedCategory(keys[nextIndex])
        setisConfet(false)
    }

    return (
        <div>
            <Navigationbar currUser={currUser} handleLogout={handleLogout} />
            <div className='marked_questions_container'>
                {width > 480 &&
                    <div className='all_marked_questions' style={{ height: '700px' }}>
                        {all_categories.map((category) => (
                            <div className='each_category'>
                                <div className='circle'>{currUser && currUser.watched_videos && currUser.watched_videos.length > 0 && currUser.watched_videos[0][category.databasename] ? <span className="checkmark">&#10003;</span> : null}</div>
                                <div key={category.appearname} className={(category.databasename === selectedCategory ? "selected-background" : 'category-marked')} onClick={() => { selectCategory(category.databasename) }}>{category.appearname}</div>
                            </div>
                        ))}
                    </div>
                }
                <div className='question-card-marked' style={{ height: '670px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div className='concept-content'>
                        {!currUser && (
                            <div className='video-container'>
                                <div className='notification-words' style={{ color: 'red' }}>Please log in to view all the training videos</div>
                                <div onClick={clickeToLogIn} className='sign-in-button'>Log in</div>
                            </div>
                        )}
                        {currUser && validAccess(currUser.expire_date) && selectedCategory == 'Epidemiology' && <div style={{ marginBottom: '-20px' }}>To understand the basics of epidemiology, check out this informative training from CDC.</div>}
                        {currUser && selectedCategory && (
                            <div className='explain-concept' style={{ maxHeight: '670px' }}>{selectedCategory !== 'Radiation_Part1' ? <Air_sampling_video videoLink={video_content[selectedCategory]} currUser={currUser} /> :
                                <div className='video-container'>{width > 920 && <a href="https://orau.gov/rsb/radbasics/" target="_blank" rel="noopener noreferrer">
                                    <img src={cdcrad} alt="CDC Radiation" width="920" height="515" />
                                </a>}
                                    {width <= 920 && <a href="https://orau.gov/rsb/radbasics/" target="_blank" rel="noopener noreferrer">
                                        <img src={cdcrad} alt="CDC Radiation" width="360" height="315" />
                                    </a>}
                                </div>}
                            </div>
                        )}
                    </div>
                    {width <= 480 && currUser && validAccess(currUser.expire_date) && <div className='mark-as-complete' onClick={() => nextVideo(selectedCategory)}>Next Video</div>}
                    {width > 480 && currUser && validAccess(currUser.expire_date) && currUser.watched_videos && currUser.watched_videos.length > 0 && !currUser.watched_videos[0][selectedCategory] ? (
                        <div className='mark-as-complete' onClick={() => handleMark('a category name')}>Mark As Complete</div>
                    ) : null}
                    {width > 480 && currUser && validAccess(currUser.expire_date) && currUser.watched_videos && currUser.watched_videos.length > 0 && currUser.watched_videos[0][selectedCategory] ? (
                        <>
                            <div className='mark-as-complete'>Keep going!</div>
                            {isConfet && <ConfettiComponent />}
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default Videos