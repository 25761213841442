export const biostats_epi_questions = [
    {
        number: 1,
        description: "Which of the following study designs is most appropriate for investigating the effectiveness of a new treatment or intervention?",
        choices: [
            "A. Cross-sectional study",
            "B. Case-control study",
            "C. Cohort study",
            "D. Randomized controlled trial"
        ],
        answer: "D",
        explain: "Randomized controlled trial (RCT) is the most appropriate study design for investigating the effectiveness of a new treatment or intervention. In an RCT, participants are randomly assigned to receive either the new treatment or a placebo or standard treatment, and the outcomes are compared between the two groups. This design helps to minimize bias and confounding factors and provides the strongest evidence for causal relationships."
    },
    {
        number: 2,
        description: "In statistical hypothesis testing, what is the p-value?",
        choices: ["A. The probability of observing a result as extreme as the one obtained, assuming the null hypothesis is true",
            "B. The probability of obtaining a result as extreme as the one obtained, assuming the alternative hypothesis is true",
            "C. The difference between the mean of two samples, divided by the standard error",
            "D. The ratio of the standard deviation to the mean"],
        answer: 'A',
        explain: "The p-value in statistical hypothesis testing is defined as the probability of observing a result as extreme as the one obtained, assuming the null hypothesis is true.\n\nWhen conducting a hypothesis test, the null hypothesis represents the default assumption or the belief that there is no significant difference or relationship between variables in the population. The alternative hypothesis, on the other hand, suggests that there is a significant difference or relationship.\n\nThe p-value quantifies the likelihood of obtaining a test statistic (or a more extreme one) if the null hypothesis is true. If the p-value is very small (below a pre-specified significance level, often denoted by α), it is typically interpreted as strong evidence against the null hypothesis, leading to its rejection in favor of the alternative hypothesis. Conversely, a larger p-value suggests weak evidence against the null hypothesis, which fails to provide enough support for rejecting it."
    },
    {
        number: 3,
        description: " What is the coefficient of variation for this sample dataset? {80, 85 ,90, 92, 95}",
        choices: [
            "A. 6.7%",
            "B. 6.0%",
            "C. 2.2%.",
            "D. 12%"
        ],
        answer: 'A',
        explain: 'Mean = (80 + 85 + 90 + 92 + 95) / 5= 88.4. Since it is a sample, we should use the formula for sample standard deviation. Sample Standard Deviation = sqrt(Σ(each value - mean)\u00B2 / (number of values - 1)) = 5.94, coefficient of variation = (standard deviation / mean) = 5.94/88.4 = 6.7%.\n\n Please check out your calculator. In some modern calculators, it is possible to calculate this in a much easier way. This can save you a lot of time in the exam!'
    },
    {
        number: 4,
        description: "During a lab experiment of 2 hours, a chemist collected five temperature readings in the first 30 minutes and obtained the following values: 25°C, 27°C, 28°C, 30°C, and 31°C. What is the estimated standard deviation of the temperatures during the whole experiment?",
        choices: [
            "A. 2.39",
            "B. 2.79",
            "C. 3.29",
            "D. 5.39"
        ],
        answer: "A",
        explain: "5 readings are just a sample of the population. Therefore, we should the the formular for sample standard deviation to estimate the population SD. Had we knew the temperature readings during the whole experience, we can just use the population SD formula. \n\nUse the 21th equation in General Science. Mean = (25 + 27 + 28 + 30 + 31) / 5 = 28.2°C \n \n Sum of Squares = [(25-28.2)\u00B2 + (27-28.2)\u00B2 + (28-28.2)\u00B2 + (30-28.2)\u00B2 + (31-28.2)\u00B2] =22.8\n \n Ss = sqrt(22.8/(5-1)) = 2.39."
    },
    {
        number: 5,
        description: "What is the geometric mean of the numbers 4, 8, 16, 32, and 64?",
        choices: [
            "A. 32",
            "B. 16",
            "C. 64",
            "D. 25"
        ],
        answer: "B",
        explain: "Use the 22th or the 23th equation in General Science. Geometric mean = (4 * 8 * 16 * 32 * 64)^(1/5) = 16. \n\nThe geometric mean is best used to calculate the average of a series of data where each item has some relationship to the others. Examples of this phenomenon include the interest rates that may be attached to any financial investments, or the statistical rates of human population growth.\n\n Please make sure you know how to use your calculator to do this."
    },
    {
        number: 6,
        description: "Suppose a set of bacterial colonies have a median diameter of 3 mm, and the diameter of 84.13% tile of the colonies is 6 mm. What is the geometric standard deviation of the colony diameter?",
        choices: [
            "A. 4",
            "B. 6",
            "C. 2",
            "D. 3"
        ],
        answer: "C",
        explain: "Use the 24th equation in General Science. GSD = 6/3 = 2."
    },
    {
        number: 7,
        description: "Suppose a set of bacterial colonies have a median diameter of 3 mm, and the diameter of 15.87% tile of the colonies is 1 mm. What is the geometric standard deviation of the colony size distribution?",
        choices: [
            "A. 0.33",
            "B. 9",
            "C. 6",
            "D. 3"
        ],
        answer: "D",
        explain: "Use the 25th equation in General Science. GSD = 3/1 = 3."
    },
    {
        number: 8,
        description: "An experiment is conducted to measure the concentration of a certain chemical in a solution. The coefficient of variation of the measurements is 8%. What are the sampling and analytical error?",
        choices: [
            "A. 0.23",
            "B. 0.13",
            "C. 0.43",
            "D. 0.33"
        ],
        answer: "B",
        explain: "Use the 26th equation in General Science. SAE = 1.645*0.08 = 0.13. "
    },
    {
        number: 9,
        description: "A laboratory technician measured the heights of 10 plants and obtained a mean height of 40 cm and a standard deviation of 4 cm. What is the coefficient of variation of the plant heights?",
        choices: [
            "A. 40",
            "B. 0.4",
            "C. 4",
            "D. 0.1"
        ],
        answer: "D",
        explain: "Use the 27th equation in General Science. CV = 4/40 = 0.1."
    },
    {
        number: 10,
        description: "What is the total error of air sampling when a pump with a precision of 2%, a sampling media with a precision of 5%, and an analytical laboratory with a precision of 8% are used?",
        choices: [
            "A. 0.02",
            "B. 0.05",
            "C. 0.096",
            "D. 0.08"
        ],
        answer: "C",
        explain: "Use the 28th equation in General Science. Total error = sqrt(0.02\u00B2  + 0.05\u00B2  + 0.08\u00B2 ) = 0.096."
    },
    {
        number: 11,
        description: "What is the t-value of a Student's t-test for two sets of data, each containing ten observations with averages of 7.2 and 8.4, and a pooled standard deviation of 2.6?",
        choices: [
            "A. -1.03",
            "B. -2.03",
            "C. -3.03",
            "D. -4.03"
        ],
        answer: "A",
        explain: "Use the 29th equation in General Science. t = (7.2 - 8.4) / (2.6 * sqrt(1/10 + 1/10)) = -1.03.\n\nThe Student's t-test can be used to determine if there is a significant difference between the means of two groups of data. It is commonly used when the sample sizes are small and the population variances are unknown.\n\nThe t-test works by calculating the t-value, which is a ratio of the difference between the means of the two groups and the variability within the groups. The t-value is compared to a critical value from the t-distribution, and the resulting p-value is used to determine if the difference between the means is statistically significant."
    },
    {
        number: 12,
        description: "What is the pooled standard deviation for two sets of data, each containing eight observations with standard deviations of 4.2 and 5.1?",
        choices: [
            "A. 4.67",
            "B. 8.42",
            "C. 6.32",
            "D. 10.91"
        ],
        answer: "A",
        explain: "Use the 30th equation in General Science.\n\nPooled standard deviation = sqrt(((8 - 1) * 4.2\u00B2  + (8 - 1) * 5.1\u00B2 ) / (8 + 8 - 2)) = 4.67."
    },
    {
        number: 13,
        description: "An employee's exposure to n-butanol with a PEL of 50 ppm is found to be 60 ppm for 3 hours and 40 ppm for the remaining 5 hours. The sampling and analytical error for the analytical method is 0.05. What is the lower confidence limit for this exposure?",
        choices: [
            "A. 0.4",
            "B. 1.1",
            "C. 0.9",
            "D. 5.9"
        ],
        answer: "C",
        explain: "Use the 31th equation in General Science. CA =time-weighted average concentration of consecutive samples = (60*3 + 40*5)/8 = 47.5 ppm. Pay attention that Tn (duration of sample) is in minutes.\n\nLCL = 47.5 / 50 - 0.05 sqrt(60\u00B2*180\u00B2  + 40\u00B2*300\u00B2 ) /(50*480) = 0.945. Since LCL (lower confidence level) <1, it does not violate the OSHA regulation."
    },
    {
        number: 14,
        description: "In biostatistics, why is the z-score an important measure when analyzing data?",
        choices: [
            "A. It indicates the mean of the data set.",
            "B. It represents the standard deviation of the data set.",
            "C. It measures the number of standard deviations a data point is from the mean.",
            "D. It calculates the variance of the data set."
        ],
        answer: "C",
        explain: "The z-score is important in biostatistics because it measures the number of standard deviations a data point is from the mean. For example, if a data point has a z-score of 2, it means the data point is 2 standard deviations above the mean. This allows for the comparison of data points from different distributions and helps in identifying outliers. Understanding the z-score is essential for performing standard normal distribution analyses and hypothesis testing."
    },
    {
        number: 15,
        description: "What is the purpose of linear regression analysis?",
        choices: [
            "A. To determine causation between variables.",
            "B. To estimate the effect size of an intervention.",
            "C. To model and predict the relationship between variables.",
            "D. To determine the statistical significance of a hypothesis."
        ],
        answer: "C",
        explain: "The purpose of linear regression analysis is to model and understand the relationship between a dependent variable and one or more independent variables. It aims to establish a linear equation that best fits the observed data, allowing for predictions or estimations of the dependent variable based on the values of the independent variables."
    },
    {
        number: 16,
        description: "What is the 90% two-sided confidence interval for a contaminant, given an average value of 8.2 ppm and a standard deviation of 1.9 ppm, based on 36 air samples collected by an industrial hygienist from a laboratory?",
        choices: [
            "A. 7.68 to 8.72",
            "B. 1.11 to 2.29",
            "C. 4.11 to 5.29",
            "D. 7.11 to 11.29"
        ],
        answer: "A",
        explain: "Use the 34th equation in General Science.\n\n90%Conf Interval = 8.2 ± 1.645 * 1.9/sqrt(36)\n\nA 90% confidence interval is a range of values that is likely to contain the true population parameter with a 90% level of confidence. In other words, if we take many samples from a population and construct a 90% confidence interval for each sample, approximately 90% of those intervals will contain the true population parameter. It is a statistical measure of the uncertainty in our estimate of the population parameter. A wider interval indicates more uncertainty, while a narrower interval indicates more precision in our estimate. "
    },
    {
        number: 17,
        description: "What is the one-sided 95% confidence upper interval for a contaminant, given an average value of 8.2 ppm and a standard deviation of 1.9 ppm, based on 36 air samples collected by an industrial hygienist from a laboratory?",
        choices: [
            "A. 8.2",
            "B. 8.39",
            "C. 8.72",
            "D. 9.29"
        ],
        answer: "C",
        explain: "Use the 35th equation in General Science. 95% Conf Limit = 8.2 + 1.645 * 1.9/sqrt(36) = 8.72."
    },
    {
        number: 18,
        description: "What is the most appropriate measure of central tendency to use when analyzing a dataset that includes a few extreme values? ",
        choices: [
            "A. Mean",
            "B. Mode",
            "C. Median",
            "D. All of the above"
        ],
        answer: "C",
        explain: "When a dataset includes a few extreme values (also known as outliers), the mean can be heavily influenced by these values and may not be a representative measure of the dataset as a whole. In such cases, the median can be a more appropriate measure of central tendency as it is less sensitive to outliers. The mode, on the other hand, is the value that appears most frequently in the dataset and may not be relevant in cases where there are no repeated values."
    },
    {
        number: 19,
        description: "Which of the following statements is true regarding a right-skewed distribution?",
        choices: [
            "A. The mean is greater than the median.",
            "B. The median is greater than the mean.",
            "C. The mode is greater than the mean.",
            "D. The mean, median, and mode are all equal."
        ],
        answer: "A",
        explain: "A right-skewed distribution, also known as a positively skewed distribution, is one in which the tail of the distribution extends to the right, indicating that there are a few extreme values on the high end of the data range. In a right-skewed distribution, the mean is pulled towards the tail, resulting in a value that is greater than the median. Therefore, option A is the correct answer.\n\nIn a left-skewed distribution, also known as a negatively skewed distribution, the tail of the distribution extends to the left, indicating that there are a few extreme values on the low end of the data range. In a left-skewed distribution, the mean is pulled towards the tail, resulting in a value that is less than the median."
    },
    {
        number: 20,
        description: "What is true about the mean, median, and mode of a symmetrical distribution?",
        choices: [
            "A. The mean is greater than the median, and the mode is less than the median.",
            "B. The mean is less than the median, and the mode is greater than the median.",
            "C. The mean, median, and mode are all equal.",
            "D. The mean and median are equal, but the mode is greater than both."
        ],
        answer: "C",
        explain: "In a symmetrical distribution, also known as a normal distribution, the data is evenly distributed around the center, with no skewness to the left or right. In such cases, the mean, median, and mode will all have the same value, which is at the center of the distribution. Therefore, option C is the correct answer.\n\nIt is important to note that not all datasets will follow a normal distribution, and the mean, median, and mode may have different values in such cases. In skewed distributions, the mean may be pulled towards the tail of the distribution, resulting in a value that is different from the median and mode."
    },
    {
        number: 21,
        description: "Which of the following measures of central tendency is most sensitive to outliers in a dataset?",
        choices: [
            "A. Mean",
            "B. Mode",
            "C. Median",
            "D. All of the above"
        ],
        answer: "A",
        explain: "The mean is calculated by summing up all values in a dataset and dividing by the total number of values. This measure of central tendency is sensitive to outliers because an extreme value in the dataset can have a large impact on the final result. In other words, outliers can 'skew' the mean towards their direction. Therefore, option A is the correct answer.\n\nThe mode, on the other hand, is the value that appears most frequently in a dataset and is not typically affected by outliers. The median, which is the middle value when the dataset is arranged in numerical order, can be a more appropriate measure of central tendency in datasets with extreme values as it is less sensitive to outliers than the mean."
    },
    {
        number: 22,
        description: "Which of the following statements is true regarding the difference between a statistic and a parameter?",
        choices: [
            "A. A statistic is a numerical measure that describes a population, while a parameter describes a sample.",
            "B. A statistic is a numerical measure that describes a sample, while a parameter describes a population.",
            "C. A statistic and a parameter both describe populations.",
            "D. A statistic and a parameter both describe samples."
        ],
        answer: "B",
        explain: "In statistics, a population is the entire group of individuals or items that the researcher is interested in studying, while a sample is a subset of the population that is selected for analysis. A parameter is a numerical measure that describes a characteristic of the population, such as the population mean or standard deviation. A statistic is a numerical measure that describes a characteristic of the sample, such as the sample mean or standard deviation."
    },
    {
        number: 23,
        description: "Which of the following statements is true regarding the null hypothesis in hypothesis testing?",
        choices: [
            "A. The null hypothesis is always true.",
            "B. The null hypothesis is always false.",
            "C. The null hypothesis represents the researcher's alternative hypothesis.",
            "D. The null hypothesis is a statement of no effect or no difference."
        ],
        answer: "D",
        explain: "In hypothesis testing, the null hypothesis is a statement that there is no significant difference between two groups or variables, or that there is no effect of an intervention or treatment. The alternative hypothesis, on the other hand, is the opposite of the null hypothesis, and represents the researcher's hypothesis that there is a significant difference or effect.\n\nThe null hypothesis is not always true or false, but rather serves as a starting point for the hypothesis testing process. The goal of hypothesis testing is to either reject or fail to reject the null hypothesis based on the results of the analysis. "
    },
    {
        number: 24,
        description: "Which of the following statements is true regarding Type I error in hypothesis testing?",
        choices: [
            "A. Type I error occurs when the null hypothesis is correctly rejected.",
            "B. Type I error occurs when the null hypothesis is incorrectly rejected.",
            "C. Type I error occurs when the alternative hypothesis is correctly accepted.",
            "D. Type I error occurs when the alternative hypothesis is incorrectly accepted."
        ],
        answer: "B",
        explain: "Type I error, also known as a false positive, occurs when the null hypothesis is incorrectly rejected, even though it is actually true. This means that the researcher has concluded that there is a significant difference or effect when there really isn't one."
    },
    {
        number: 25,
        description: "Which of the following statements best describes type II error in statistical hypothesis testing?",
        choices: [
            "A. Type II error occurs when a true null hypothesis is rejected.",
            "B. Type II error occurs when a false null hypothesis is accepted",
            "C. Type II error occurs when a true alternative hypothesis is accepted.",
            "D. Type II error occurs when a false alternative hypothesis is rejected"
        ],
        answer: "B",
        explain: "Type II error occurs when a false null hypothesis is accepted. meaning that you fail to conclude there is a significant difference or relationship between variables when, in reality, there is one."
    },
    {
        number: 26,
        description: "In the context of a normal distribution, what does the 68-95-99.7% rule describe?",
        choices: [
            "A. The percentage of data points that fall within one, two, and three standard deviations of the mean, respectively.",
            "B. The probability of events occurring in independent trials.",
            "C. The relationship between the mean, median, and mode in a data set.",
            "D. The distribution of data points in a skewed data set."
        ],
        answer: "A",
        explain: "The 68-95-99.7% rule, also known as the empirical rule, describes the percentage of data points that fall within one, two, and three standard deviations of the mean in a normal distribution. Specifically, approximately 68% of the data points lie within one standard deviation of the mean, 95% within two standard deviations, and 99.7% within three standard deviations. This rule helps in understanding the spread and variability of data in a normal distribution."
    },
    {
        number: 27,
        description: "Which of the following statements is true regarding precision and accuracy in industrial hygiene sampling?",
        choices: [
            "A. Precision and accuracy are the same thing and can be used interchangeably. ",
            "B. Precision refers to how close a set of measurements are to each other, while accuracy refers to how close a measurement is to the true value. ",
            "C. Accuracy refers to how close a set of measurements are to each other, while precision refers to how close a measurement is to the true value.",
            "D. Both precision and accuracy refer to how close a measurement is to the true value, but precision is more important than accuracy in industrial hygiene sampling."
        ],
        answer: "B",
        explain: "Precision refers to the degree of repeatability or reproducibility of a measurement. In other words, precision refers to how close a set of measurements are to each other when they are taken under the same conditions. A highly precise measurement is one where the measurements are clustered closely together.\n\nAccuracy, on the other hand, refers to how close a measurement is to the true or expected value. A highly accurate measurement is one where the measured value is very close to the true or expected value."
    },
    {
        number: 28,
        description: "What is the standard deviation of 90, 90, 30, 40, 80, 100, 101?",
        choices: [
            "A. 29",
            "B. 27",
            "C. 75",
            "D. 531"
        ],
        answer: "B",
        explain: "We have all the data in the 'population', therefore we should use the population standard deviation. Make sure you know how to do this in the calculator!"
    }
    ,
    {
        number: 29,
        description: "In industrial hygiene, when comparing the means of two independent samples, which of the following statements is true?",
        choices: [
            "A. A t-test is used when the population standard deviation is known, while a z-test is used when it is unknown.",
            "B. A z-test is used when the sample size is small, while a t-test is used when the sample size is large.",
            "C. A t-test is used when the population is normally distributed, while a z-test is used when it is not.",
            "D. A t-test is used when the sample size is small and the population standard deviation is unknown, while a z-test is used when the sample size is large and the population standard deviation is known. "
        ],
        answer: "D",
        explain: "The t-test and z-test are statistical hypothesis tests used to make inferences about population parameters based on sample data. However, they are used under different circumstances and have different assumptions. Here are the key differences between the t-test and z-test:\n\nPopulation Variance: The z-test assumes that the population variance is known, while the t-test is used when the population variance is unknown. If the population variance is unknown, the t-test uses the sample variance as an estimate.\n\nSample Size: The z-test is appropriate when the sample size is large (typically n > 30) because the Central Limit Theorem ensures that the sample mean distribution is approximately normally distributed. On the other hand, the t-test is more suitable for small sample sizes (typically n < 30)."
    },
    {
        number: 30,
        description: "In industrial hygiene, the chi-square test is commonly used to:",
        choices: [
            "A. Compare the means of two independent samples",
            "B. Test for differences between two or more groups with continuous data",
            "C. Test for differences between two or more groups with categorical data",
            "D. Determine if a sample is normally distributed. "
        ],
        answer: "C",
        explain: "The chi-square test is a statistical method used to examine the association between categorical variables. There are two main types: the chi-square test for independence and the chi-square goodness of fit test. The test for independence evaluates whether two categorical variables are related, such as gender and product preference. For example, if a researcher collects data on the product preferences of 40 males and 40 females, they would compare the observed preferences to the expected preferences if there were no association between gender and preference. By calculating the chi-square statistic and comparing it to a critical value from the chi-square distribution table, the researcher can determine if there is a significant association between the variables. \n\nThe chi-square goodness of fit test assesses whether a sample matches an expected distribution. For instance, if a dice is rolled 60 times, the test can determine if the outcomes are consistent with a fair dice, where each number (1 through 6) should appear 10 times. The observed frequencies are compared to the expected frequencies using the chi-square formula, and the resulting statistic is evaluated against a critical value based on the degrees of freedom. If the chi-square statistic is higher than the critical value, it suggests that the observed distribution deviates significantly from the expected distribution, indicating that the dice may not be fair."
    },
    {
        number: 31,
        description: "What is the primary purpose of conducting an ANOVA (Analysis of Variance) test in statistical analysis?",
        choices: [
            "A. To compare the means of two independent samples.",
            "B. To compare the means of three or more groups to determine if at least one group mean is significantly different from the others.",
            "C. To measure the strength and direction of a linear relationship between two variables.",
            "D. To estimate the population parameters based on sample data."
        ],
        answer: "B",
        explain: "The primary purpose of conducting an ANOVA (Analysis of Variance) test is to compare the means of three or more groups to determine if at least one group mean is significantly different from the others. For example, if a researcher wants to test the effectiveness of three different diets on weight loss, they can use ANOVA to determine if there is a significant difference in the average weight loss among the three diet groups. ANOVA helps identify whether the observed differences in sample means are likely due to true differences in population means or just random variation."
    },
    {
        number: 32,
        description: "Which of the following statements best describes the central limit theorem in industrial hygiene?",
        choices: [
            "A. The central limit theorem states that the mean of a sample will always be equal to the mean of the population.",
            "B. The central limit theorem states that as the sample size increases, the distribution of the sample means approaches a normal distribution.",
            "C. The central limit theorem states that as the sample size increases, the variance of the sample means increases.",
            "D. The central limit theorem states that as the sample size decreases, the standard error of the mean increases."
        ],
        answer: "B",
        explain: "The central limit theorem is a statistical concept that describes the behavior of sample means when drawn from a population with any distribution. It states that as the sample size increases, the distribution of sample means approaches a normal distribution, regardless of the underlying distribution of the population. This theorem is important because it enables the use of parametric statistical methods, such as t-tests and z-tests, which assume normal distribution, even when the population distribution is unknown or non-normal."
    },
    {
        number: 33,
        description: "Which of the following statistical tests should be used to determine the significance of the departure from an observed value in any positive or negative direction?",
        choices: [
            "A. Z-test",
            "B. T-test ",
            "C. Chi-square test",
            "D. Two tail test "
        ],
        answer: "D",
        explain: "A two-tailed test is a statistical hypothesis test where the critical area of a distribution is divided into two regions, one on each end, and the null hypothesis is rejected if the test statistic falls in either of these regions. It is used to test whether the true value of a population parameter is significantly different from a hypothesized value in any direction."
    },
    {
        number: 34,
        description: "A study has 100 workers exposed to a particular chemical and another 100 workers unexposed to the chemical. The study found that 20 exposed workers and 5 unexposed workers developed lung cancer over a 10-year period. What is the relative risk for lung cancer in the exposed group compared to the unexposed group?",
        choices: [
            "A. 0.25",
            "B. 4.75",
            "C. 2",
            "D. 4"
        ],
        answer: "D",
        explain: "To calculate the relative risk, you need to calculate the risk of lung cancer in the exposed group and the risk of lung cancer in the unexposed group, and then divide the two risks. The risk of developing lung cancer in the exposed group is 20/100. The risk of developing lung cancer in the unexposed group is 5/100.\n\nRelative risk = (20/100) / (5/100) = 4."
    },
    {
        number: 35,
        description: "What is the Healthy Worker Effect?",
        choices: [
            "A. A phenomenon where workers are consistently absent from work due to health-related issues.",
            "B. A bias that occurs when unhealthy individuals are systematically excluded from employment, leading to a falsely optimistic perception of the health of the workforce.",
            "C. A workplace wellness program designed to promote healthy habits among employees.",
            "D. The tendency for workers to be more health-conscious during working hours."
        ],
        answer: "B",
        explain: "The Healthy Worker Effect is a potential bias that arises when individuals with existing health conditions or who are more susceptible to illnesses are systematically excluded from certain job opportunities. This exclusion can lead to a distorted, overly positive perception of the overall health of the employed population, as it may not accurately represent the health of the general population."
    },
    {
        number: 36,
        description: "Which of the following best describes the Hawthorne Effect in industrial hygiene?",
        choices: [
            "A. A bias that occurs when study participants alter their behavior due to being observed or participating in a study.",
            "B. A bias that occurs when the control group is exposed to the same intervention as the experimental group.",
            "C. A bias that occurs when study participants drop out of the study before it is completed.",
            "D.A bias that occurs when study participants over-report their exposure to a potential hazard."
        ],
        answer: "A",
        explain: "The Hawthorne Effect is a type of bias that occurs when individuals alter their behavior or performance simply because they know they are being observed or participating in a study. This can affect the accuracy of the study's results and can be a significant concern in occupational health research."
    },
    {
        number: 37,
        description: "Which of the following best describes the difference between incidence and prevalence in industrial hygiene?",
        choices: [
            "A. Incidence refers to the number of new cases of a disease within a population during a specific time period, while prevalence refers to the total number of cases in the population at a specific time point.",
            "B. Incidence refers to the total number of cases of a disease in a population at a specific time point, while prevalence refers to the number of new cases during a specific time period.",
            "C. Incidence and prevalence refer to the same concept and can be used interchangeably in industrial hygiene.",
            "D. Incidence and prevalence both refer to the total number of cases of a disease in a population and are not useful in industrial hygiene."
        ],
        answer: "A",
        explain: "Incidence refers to the number of new cases of a disease within a population during a specific time period, while prevalence refers to the total number of cases in the population at a specific time point."
    },
    {
        number: 38,
        description: "Which of the following statements best describes the difference between prospective and retrospective cohort studies in industrial hygiene?",
        choices: [
            "A. Prospective cohort studies are conducted on individuals who have been exposed to a hazard in the past, while retrospective cohort studies are conducted on individuals who are currently exposed to a hazard.",
            "B. Prospective cohort studies are conducted on individuals who have already developed a disease, while retrospective cohort studies are conducted on individuals who have not yet developed the disease of interest.",
            "C. Prospective cohort studies are conducted by following individuals forward in time, while retrospective cohort studies are conducted by looking back in time to collect exposure and outcome data.",
            "D. Prospective and retrospective cohort studies are interchangeable terms that describe the same study design in industrial hygiene."
        ],
        answer: "C",
        explain: "Prospective cohort studies are conducted by identifying a group of individuals who are currently exposed to a particular hazard, and following them over time to assess whether they develop the outcome of interest. Retrospective cohort studies, on the other hand, involve identifying a group of individuals who were exposed to a hazard in the past, and looking back in time to collect exposure and outcome data."
    },
    {
        number: 39,
        description: "Which of the following best describes a case-control study in industrial hygiene?",
        choices: [
            "A. A study design that follows a group of individuals over time to identify new cases of a disease and determine their exposures.",
            "B. A study design that compares the prevalence of exposure to a hazard in a group of individuals with a specific disease to a group of individuals without the disease.",
            "C. A study design that randomizes participants to different exposure levels and measures the incidence of disease over time.",
            "D. A study design that interviews individuals with a specific disease and asks them to recall their past exposures to a hazard. "
        ],
        answer: "B",
        explain: "Case-control studies are observational studies that compare the prevalence of exposure to a hazard in a group of individuals with a specific disease to a group of individuals without the disease. They are often used to investigate the causes of rare diseases, when it would be impractical or unethical to conduct a randomized controlled trial.\n\nCase-control studies are retrospective, meaning that they look back in time to try to identify the cause of a disease. This is in contrast to cohort studies, which are prospective and follow a group of individuals over time to see who develops a disease."
    },
    {
        number: 40,
        description: "A study has 100 workers exposed to a particular chemical and another 100 workers unexposed to the chemical. The study found that 20 exposed workers and 5 unexposed workers developed lung cancer over a 10-year period. What is the odds ratio for lung cancer in the exposed group compared to the unexposed group?",
        choices: [
            "A. 0.25",
            "B. 4.75",
            "C. 2",
            "D. 4"
        ],
        answer: "B",
        explain: "To calculate the odds ratio, you need to calculate the odds of lung cancer in the exposed group and the odds of lung cancer in the unexposed group, and then divide the odds in the exposed group by the odds in the unexposed group. The odds of developing lung cancer in the exposed group is 20/80, or 0.25. The odds of developing lung cancer in the unexposed group is 5/95, or 0.053.\n\nThe odds ratio is calculated by dividing the odds of the exposed group by the odds of the unexposed group:\n\nOdds ratio = (20/80) / (5/95) = 4.75\n\nAn odds ratio (OR) greater than 1 indicates that there is a positive association between the exposure and outcome being studied. In other words, the odds of the outcome occurring are higher in the exposed group compared to the unexposed group. The larger the odds ratio, the stronger the association between exposure and outcome."
    },
    {
        number: 41,
        description: "Which of the following is true regarding mortality studies in occupational epidemiology?",
        choices: [
            "A. Mortality studies measure the incidence of diseases in a population over time.",
            "B. Mortality studies are useful for identifying specific exposures that cause disease.",
            "C. Mortality studies can only be conducted on living individuals. ",
            "D. Mortality studies compare the mortality rates of a population exposed to a hazard to a population that is not exposed."
        ],
        answer: "D",
        explain: "Mortality studies are a type of observational study that investigate the occurrence and patterns of death in a particular population. They are useful in occupational epidemiology to assess the impact of occupational exposures on mortality rates. In these studies, researchers typically compare the mortality rates of a population that is exposed to a particular hazard (e.g. a specific chemical) to the mortality rates of a similar population that is not exposed."
    },
    {
        number: 42,
        description: "Which of the following accurately describes the terms endemic, epidemic, and pandemic in the context of infectious diseases?",
        choices: [
            "A. Endemic refers to the constant presence of a disease in a specific geographic area, epidemic refers to a sudden unexpected increase in the number of cases of a disease within a community, and pandemic refers to a global outbreak of a disease affecting multiple countries or continents.",
            "B. Endemic refers to a global outbreak of a disease affecting multiple countries or continents, epidemic refers to the constant presence of a disease in a specific geographic area, and pandemic refers to a sudden increase in the number of cases of a disease within a community.",
            "C. Endemic refers to a sudden increase in the number of cases of a disease within a community, epidemic refers to a global outbreak of a disease affecting multiple countries or continents, and pandemic refers to the constant presence of a disease in a specific geographic area.",
            "D. Endemic, epidemic and pandemic are interchangeable terms."
        ],
        answer: "A",
        explain: "Endemic: An endemic disease refers to the constant or usual presence of a disease within a specific geographic area or population. The disease is consistently present at a relatively stable and expected level.\n\nEpidemic: An epidemic occurs when there is a sudden unexpected increase in the number of cases of a particular disease within a community or region. It represents a significant and widespread outbreak of the disease, exceeding what is typically observed.\n\nPandemic: A pandemic refers to a global outbreak of a disease that affects a large number of people across different countries or continents. Unlike an epidemic, which is localized, a pandemic involves the spread of the disease on a much broader scale, impacting multiple regions worldwide."
    },
    {
        number: 43,
        description: "What is the difference between morbidity and mortality?",
        choices: [
            "A. Morbidity refers to the number of deaths, while mortality refers to the incidence of disease.",
            "B. Morbidity refers to the incidence of disease, while mortality refers to the number of deaths.",
            "C. Morbidity refers to chronic diseases, while mortality refers to acute conditions.",
            "D. Morbidity and mortality both refer to the incidence of disease."
        ],
        answer: "B",
        explain: "In public health, morbidity refers to the incidence or prevalence of a disease or medical condition within a population, indicating how often the disease occurs. Mortality, on the other hand, refers to the number of deaths in a population due to a specific cause or disease. For example, if a population has a high morbidity rate for diabetes, it means many individuals are affected by diabetes. A high mortality rate for diabetes means many individuals are dying from complications related to diabetes."
    },
    {
        number: 44,
        description: "Which of the following statements about Legionella is accurate?",
        choices: [
            "A. Legionella is a virus that primarily affects plants.",
            "B. Legionella pneumophila is often associated with outbreaks of Legionnaires' disease.",
            "C. Legionella infections are only transmitted through direct person-to-person contact.",
            "D. Legionella is not associated with water systems."
        ],
        answer: "B",
        explain: "Legionella, a genus of bacteria, notably Legionella pneumophila, is associated with human infections like Legionnaires' disease and Pontiac fever. Contrary to option A, it's not a plant virus but thrives in water environments, particularly in man-made systems like cooling towers. Outbreaks occur when people inhale contaminated aerosolized droplets from these sources, highlighting the importance of water system maintenance.\n\nOption B is correct, noting that Legionella pneumophila is a common culprit in Legionnaires' disease outbreaks. This bacterium proliferates in water systems, forming biofilms that pose infection risks. Public health efforts must focus on prevention and monitoring of water systems to curb Legionella-related health risks."
    },
    {
        number: 45,
        description: "In epidemiology, what does the term 'cluster' refer to?",
        choices: [
            "A. The occurrence of a disease at higher levels than expected in a specific geographic area or population.",
            "B. The grouping of different diseases that share similar symptoms.",
            "C. The random distribution of cases across a population.",
            "D. The collection of data from multiple sources for analysis."
        ],
        answer: "A",
        explain: "In epidemiology, the term 'cluster' refers to the occurrence of a disease or health-related events at higher levels than expected within a specific geographic area or population over a particular period. For instance, if a small town experiences an unusually high number of cancer cases within a short time frame, this might be considered a cancer cluster. Identifying clusters helps epidemiologists investigate potential causes and risk factors associated with the disease, leading to better understanding and control measures."
    },
    {
        number: 46,
        description: "Which of the following statements about meningitis is correct?",
        choices: [
            "A. Meningitis is exclusively caused by viral infections.",
            "B. Bacterial meningitis is typically a self-limiting and mild illness.",
            "C. Neisseria meningitidis is a common bacterial cause of meningitis and is primarily transmitted through respiratory droplets.",
            "D. Meningitis primarily affects the bones and joints."
        ],
        answer: "C",
        explain: "Meningitis is a condition characterized by inflammation of the meninges, the protective membranes surrounding the brain and spinal cord. Contrary to option A, meningitis is not exclusively caused by viral infections; bacterial infections, such as Neisseria meningitidis, can also lead to this serious medical condition. Neisseria meningitidis is a common bacterial cause of meningitis, and its transmission occurs primarily through respiratory droplets. This mode of transmission highlights the importance of respiratory hygiene and preventive measures, especially in crowded settings or areas with close person-to-person contact.\n\nOption B is incorrect as bacterial meningitis is not typically self-limiting and mild; it is a severe and potentially life-threatening illness that requires prompt medical attention. Symptoms of bacterial meningitis often include high fever, severe headache, neck stiffness, and, in some cases, a characteristic rash. Meningitis does not primarily affect the bones and joints, as stated in option D; instead, its impact is centered on the central nervous system."
    },
    {
        number: 47,
        description: "In biostatistics, what does the significance level (alpha) represent in hypothesis testing?",
        choices: [
            "A. The probability of accepting the null hypothesis when it is true.",
            "B. The probability of rejecting the null hypothesis when it is true.",
            "C. The probability of making a Type II error.",
            "D. The probability of finding a significant result when there is none."
        ],
        answer: "B",
        explain: "In biostatistics, the significance level (alpha) represents the probability of rejecting the null hypothesis when it is true, also known as making a Type I error. For example, if the significance level is set at 0.05, there is a 5% chance of incorrectly rejecting the null hypothesis. This means that there is a 5% risk of concluding that there is a significant effect when, in fact, there is none. The significance level is crucial in determining the threshold for statistical significance in hypothesis testing."
    },
    {
        number: 48,
        description: "What is epidemiology, and what are its primary goals?",
        choices: [
            "A. The study of genetic mutations to understand their effects on human health, with the primary goal of developing new medications.",
            "B. The study of the distribution and determinants of health-related states or events in specific populations, with the primary goals of controlling health problems and improving public health.",
            "C. The study of nutritional impacts on human development, with the primary goal of creating dietary guidelines.",
            "D. The study of environmental factors affecting ecosystems, with the primary goal of preserving biodiversity."
        ],
        answer: "B",
        explain: "Epidemiology is the study of the distribution and determinants of health-related states or events in specific populations. Its primary goals are to control health problems and improve public health. Epidemiologists investigate the causes of diseases, identify risk factors, and develop strategies to prevent and control health issues. For example, during an outbreak, epidemiologists work to understand how the disease spreads, identify the source, and implement measures to contain and prevent further cases."
    },
    {
        number: 49,
        description: "Leachate refers to:",
        choices: [
            "A. Water that infiltrates through soil and dissolves pollutants, often found in contaminated groundwater.",
            "B. The byproduct of combustion processes, consisting of gases and particles emitted into the atmosphere.",
            "C. Solid waste materials generated from industrial processes that require special handling and disposal.",
            "D. The accumulation of dust and debris in confined spaces, posing health risks to workers."
        ],
        answer: "A",
        explain: "Leachate refers to the water that passes through or percolates through waste materials, such as landfill sites or contaminated soil, and picks up dissolved or suspended contaminants along the way. It is commonly found in landfill sites where rainwater or other liquids come into contact with the waste materials and extract various substances from them. Leachate can contain a variety of pollutants, including organic compounds, heavy metals, and other harmful substances. If not properly managed, leachate can pose a risk to the environment and human health by contaminating groundwater or surface water sources."
    },
    {
        number: 50,
        description: "What does the coefficient of variation (CV) indicate about a data set?",
        choices: [
            "A. The absolute difference between the highest and lowest values.",
            "B. The ratio of the standard deviation to the mean, expressed as a percentage.",
            "C. The average value of the data points.",
            "D. The correlation between two variables."
        ],
        answer: "B",
        explain: "The coefficient of variation (CV) indicates the ratio of the standard deviation to the mean, expressed as a percentage. It is a measure of relative variability and is useful for comparing the degree of variation between data sets with different units or different means. For example, if the standard deviation of a data set is 5 and the mean is 50, the CV is (5/50) * 100% = 10%. A lower CV indicates less relative variability, while a higher CV indicates more relative variability."
    },
    {
        number: 51,
        description: "Which of the following statements best describes the relationship between descriptive and inferential/analytic statistics? ",
        choices: [
            "A. Descriptive statistics is a subset of inferential statistics.",
            "B. Inferential statistics is a subset of descriptive statistics.",
            "C. Descriptive and inferential statistics are completely independent.",
            "D. Descriptive and inferential statistics are two distinct branches of statistics."
        ],
        answer: "D",
        explain: "Descriptive statistics and inferential statistics serve different purposes and have different objectives. Descriptive statistics focuses on summarizing and describing the characteristics of a data set, such as measures of central tendency (e.g., mean, median, mode), measures of variability (e.g., standard deviation, range), and graphical representations (e.g., histograms, bar charts). Descriptive statistics aim to provide a concise and meaningful summary of the data.\n\nOn the other hand, inferential or analytic statistics is concerned with making inferences, generalizations, or predictions about a population based on a sample of data. It involves techniques such as hypothesis testing, confidence intervals, and regression analysis. Inferential statistics allows researchers to draw conclusions beyond the observed data and make statements about the larger population from which the data were collected."
    },
    {
        number: 52,
        description: "What is the primary purpose of conducting a power analysis in biostatistics?",
        choices: [
            "A. To determine the statistical significance of study findings.",
            "B. To estimate the effectiveness a study.",
            "C. To assess the feasibility of a study and determine the necessary sample size.",
            "D. To calculate the p-value for a given hypothesis test."
        ],
        answer: "C",
        explain: "A power analysis is a statistical method used to determine the minimum sample size required to detect a statistically significant difference between two groups. It is important to conduct a power analysis before conducting a study, as it can help to ensure that the study is feasible and that the results are likely to be meaningful.\n\nThe power analysis can be conducted using a variety of statistical software packages. The results of the power analysis will provide the researcher with the minimum sample size required to detect a statistically significant difference between the two groups with a given level of confidence."
    },
    {
        number: 53,
        description: "What is the key difference between odds and odds ratio?",
        choices: [
            "A. Odds describe the probability of an event occurring, while odds ratio compares the odds between different groups.",
            "B. Odds ratio describes the probability of an event occurring, while odds compare the odds between different groups.",
            "C. Odds and odds ratio are interchangeable terms representing the same concept.",
            "D. Odds and odds ratio both measure the likelihood of an event occurring but in different contexts."
        ],
        answer: "A",
        explain: "Odds and odds ratio are distinct concepts with different interpretations. Odds represent the probability of an event occurring relative to the probability of its complement (not occurring). They are commonly used to describe the likelihood of an event in situations where there are two possible outcomes.\n\nOdds ratio, on the other hand, is a measure of association or effect size that compares the odds of an event between different groups or conditions. It quantifies the strength and direction of the relationship between two variables. The odds ratio compares the odds in one group to the odds in another group, providing insights into the relative change in odds between the groups."
    },
    {
        number: 54,
        description: "Which of the following study designs is best suited for assessing the temporal relationship between exposure and outcome?",
        choices: [
            "A. Case-control study ",
            "B. Cross-sectional study ",
            "C. Cohort study",
            "D. Experimental study"
        ],
        answer: "C",
        explain: "A cohort study is a type of observational study that follows a group of people over time to see if they develop a particular disease or outcome. This type of study is well-suited for assessing the temporal relationship between exposure and outcome, because it allows the researcher to see which came first, the exposure or the outcome."
    },
    {
        number: 55,
        description: "Which of the following is a common misconception about vaccines, and what is the scientific explanation that debunks it?",
        choices: [
            "A. Vaccines cause the disease they are meant to prevent; Vaccines contain inactivated or weakened pathogens that cannot cause the disease",
            "B. Vaccines are unnecessary because natural immunity is better; Vaccines provide immunity without the risk of severe illness or complications from the disease",
            "C. Vaccines overload the immune system; The immune system can handle multiple vaccines because it encounters numerous pathogens daily",
            "D. All of the above"
        ],
        answer: "D",
        explain: "Common misconceptions about vaccines include the belief that vaccines cause the disease they are meant to prevent, that natural immunity is superior, and that vaccines overload the immune system. Scientifically, vaccines contain inactivated or weakened pathogens that cannot cause the disease, provide immunity without the risk of severe illness, and the immune system can handle multiple vaccines as it encounters numerous pathogens daily."
    },
    {
        number: 56,
        description: "A diagnostic test has a PPV(Positive Predictive Value) of 80%. This means that:",
        choices: [
            "A. 80% of people who test positive for the disease actually have the disease.",
            "B. 20% of people who test positive for the disease have the disease.",
            "C. 80% of people who do not have the disease will test negative for the disease.",
            "D. 20% of people who do not have the disease will test positive for the disease."
        ],
        answer: "A",
        explain: "The positive predictive value (PPV) represents the proportion of individuals who test positive for a disease and truly have the disease. In this case, a PPV of 80% means that out of all the individuals who test positive for the disease, 80% of them actually have the disease. This indicates the likelihood of a positive test accurately indicating the presence of the disease."
    },
    {
        number: 57,
        description: "The PPV(Positive Predictive Value) of a diagnostic test is influenced by the following factors EXCEPT:",
        choices: [
            "A. Sensitivity",
            "B. Specificity",
            "C. Prevalence of the disease",
            "D. The number of people tested"
        ],
        answer: "D",
        explain: "The PPV is influenced by the prevalence of the disease in the population. If the prevalence of the disease is high, then the PPV will be higher. This is because there will be more true positives relative to false positives.\n\nThe PPV is also influenced by the sensitivity and specificity of the test. Sensitivity is the probability that a person with the disease will test positive. Specificity is the probability that a person without the disease will test negative.\n\nPPV = sensitivity * prevalence / (sensitivity * prevalence + (1 - specificity) * (1 - prevalence))."
    },
    {
        number: 58,
        description: "Which of the following is true about sensitivity and specificity?",
        choices: [
            "A. Sensitivity measures the ability of a test to correctly identify those without the disease.",
            "B. Specificity measures the ability of a test to correctly identify those with the disease.",
            "C. Sensitivity measures the ability of a test to correctly identify those with the disease.",
            "D. Specificity measures the ability of a test to incorrectly identify those without the disease."
        ],
        answer: "C",
        explain: "Sensitivity is a measure of the proportion of true positives correctly identified by a diagnostic test. It quantifies the ability of a test to correctly identify individuals who have the disease or condition being tested for. In other words, sensitivity measures the test's ability to accurately detect the presence of the disease.\n\nOn the other hand, specificity is a measure of the proportion of true negatives correctly identified by a diagnostic test. It quantifies the ability of a test to correctly identify individuals who do not have the disease or condition being tested for. Specificity measures the test's ability to accurately exclude individuals who do not have the disease."
    },
    {
        number: 59,
        description: "A study has a 95% confidence interval for the odds ratio that ranges from 0.8 to 1.2. What can be concluded about the association between the exposure and outcome?",
        choices: [
            "A. There is a significant positive association between the exposure and outcome.",
            "B. There is a significant negative association between the exposure and outcome. ",
            "C. There is no significant association between the exposure and outcome.",
            "D. There is a significant positive or negative association between the exposure and outcome."
        ],
        answer: "C",
        explain: "Since the confidence interval includes the value of 1, which represents no association or no effect, it suggests that the observed association between the exposure and outcome is not statistically significant. In other words, the study does not provide evidence of a significant positive or negative association between the exposure and outcome."
    },
    {
        number: 60,
        description: "Which of the following study designs is most suitable for investigating the causes of a rare disease?",
        choices: [
            "A. Case-control study",
            "B. Cross-sectional study",
            "C. Cohort study",
            "D. Ecological study"
        ],
        answer: "A",
        explain: "A case-control study is a type of observational study that compares people who have a particular disease (cases) to people who do not have the disease (controls). The cases and controls are matched on factors such as age, sex, and other potential confounders.\n\nCase-control studies are often used to investigate the causes of rare diseases because they can be conducted with a relatively small number of participants. Additionally, case-control studies can be used to investigate exposures that occurred in the past, which is often the case with rare diseases.\n\nThe other study designs listed are not as suitable for investigating the causes of a rare disease.\n\nCross-sectional studies are conducted at a single point in time and cannot be used to assess causality.\n\nCohort studies require a large number of participants and can be time-consuming to conduct."
    },
    {
        number: 61,
        description: "What is the purpose of single-blinding in a clinical trial?",
        choices: [
            "A. To ensure that participants are unaware of their treatment allocation.",
            "B. To prevent researchers from knowing the treatment allocation.",
            "C. To eliminate the need for a control group.",
            "D. To increase the sample size of the study."
        ],
        answer: "A",
        explain: "Blinding in a clinical trial is the process of preventing participants, researchers, and other study personnel from knowing which treatment group a participant has been assigned to. This is done to reduce the risk of bias in the study results.\n\nThere are two main types of blinding in clinical trials: single-blind and double-blind. In single-blind trials, only the participants are blinded to their treatment allocation. In double-blind trials, both the participants and the researchers are blinded to the treatment allocation.\n\nBlinding is important because it can help to ensure that the participants' responses to the treatment are not influenced by their knowledge of which treatment they are receiving. This is especially important in studies where the placebo effect is likely to be strong."
    },
    {
        number: 62,
        description: "Which of the following is an example of a vector-borne disease?",
        choices: [
            "A. Influenza",
            "B. Tuberculosis",
            "C. Malaria",
            "D. Diabetes"
        ],
        answer: "C",
        explain: "Vector-borne diseases are diseases that are transmitted by vectors, which are living organisms that carry the disease-causing agent from one host to another. Mosquitoes are the most common vector for vector-borne diseases, but ticks, fleas, and other insects can also transmit diseases.\n\nMalaria is a vector-borne disease that is caused by a parasite that is transmitted by mosquitoes. The parasite is spread when an infected mosquito bites a human. The parasite then enters the human's bloodstream and can cause a variety of symptoms, including fever, chills, headache, and vomiting."
    },
    {
        number: 63,
        description: "What is the primary purpose of stratified sampling in epidemiological studies?",
        choices: [
            "A. To ensure that the study population is representative of the target population.",
            "B. To increase sampling bias and reduce the precision of estimates.",
            "C. To assess the strength of association between exposure and outcome.",
            "D. To determine the incidence rate of a disease in a specific population."
        ],
        answer: "A",
        explain: "Stratified sampling is a type of probability sampling in which the population is divided into strata, or subgroups, and then a random sample is drawn from each stratum. This ensures that the study population is representative of the target population, which is important for making accurate inferences about the target population.\n\nStratified sampling can also help to reduce sampling bias, which is the systematic error that occurs when the sample is not representative of the target population. By ensuring that the study population is representative of the target population, stratified sampling can help to reduce this type of bias."
    },
    {
        number: 64,
        description: "What is the purpose of standardization in epidemiological studies?",
        choices: [
            "A. To control for confounding variables.",
            "B. The only purpose of standardization is to adjust for differences in age distribution between study groups.",
            "C. To calculate the odds ratio.",
            "D. To determine the prevalence of a disease."
        ],
        answer: "A",
        explain: "Standardization is a statistical method used to adjust for differences in the distribution of confounding variables between study groups. This is done by creating a standardized rate, which is a rate that has been adjusted for the distribution of the confounding variable.\n\nIn epidemiological studies, age is a common confounding variable. This is because the incidence and prevalence of many diseases vary with age. For example, the incidence of cancer increases with age. If a study does not adjust for age, then the results may be misleading.\n\nStandardization can help to ensure that the results of an epidemiological study are accurate and that they are not due to differences in the age distribution between the study groups.\n\n The purpose of standardization is not limited to adjusting for differences in age distribution; it can be used for various confounding variables, such as such as sex, socioeconomic status, or other demographic factors."
    },
    {
        number: 65,
        description: "Epidemiologists use a model for studying infectious disease and its spread that involves the microbe that causes the disease, the organism that harbors the disease, and the external factors that cause or allow disease transmission. This is known as:  ",
        choices: [
            "A. host, vector and transmission.",
            "B. transmission, host and environment.",
            "C. agent, host, and environment.",
            "D. organism, transmission, and environment."
        ],
        answer: "B",
        explain: 'the "transmission, host, and environment" model is a framework used to study the dynamics of infectious diseases. Here is a brief explanation of each component:\n\nTransmission: This refers to the mechanisms by which the infectious agent (microbe) is spread from one host to another. It includes modes such as direct person-to-person contact, airborne transmission, vector-borne transmission (e.g., through mosquitoes), and other routes.\n\nHost: The host is the organism that can be infected by the infectious agent. The characteristics of the host, including its susceptibility and immunity, play a crucial role in the transmission and outcome of the disease.\n\nEnvironment: Environmental factors encompass the external conditions that influence the transmission of the infectious agent. This can include physical factors (like climate and geography), social factors (such as population density and behavior), and other ecological elements.\n\nThis model helps epidemiologists analyze and understand how diseases spread within populations. By examining the interplay between the infectious agent, the host, and the environment, researchers can develop strategies for disease prevention, control, and intervention.'
    },
    {
        number: 66,
        description: "What type of study design involves manipulating variables to establish cause-and-effect relationships?",
        choices: [
            "A. Experimental Study",
            "B. Observational Study",
            "C. Analytical Study",
            "D. Descriptive Study"
        ],
        answer: "A",
        explain: "In an experimental study, researchers actively manipulate one or more variables (independent variables) and observe the changes in another variable (dependent variable). This controlled setting allows them to isolate the cause-and-effect relationship between the variables, making it the most reliable method for determining causality.\n\nB. Observational Study: This type of study involves observing and recording variables without manipulating them. While we can establish correlations between variables in observational studies, we cannot definitively confirm cause-and-effect relationships.\n\nC. Analytical Study: This type of study focuses on analyzing existing data, often from observational studies, to identify patterns and relationships. Like observational studies, they cannot conclusively establish cause-and-effect.\n\nD. Descriptive Study: This type of study primarily aims to describe the characteristics of a population or phenomenon. It does not necessarily involve hypothesis testing or establishing cause-and-effect relationships."
    },
    {
        number: 67,
        description: "What is the purpose of a Kaplan-Meier curve in survival analysis?",
        choices: [
            "A. To estimate the prevalence of a disease.",
            "B. To assess the association between variables.",
            "C. To visualize the probability of survival over time.",
            "D. To calculate odds ratios."
        ],
        answer: "C",
        explain: "A Kaplan-Meier curve is a graphical representation of the survival function. The survival function is the probability that a subject will survive up to a given time. The curve is constructed by plotting the survival function against time.\n\nKaplan-Meier curves are used in survival analysis to visualize the probability of survival over time. They can be used to compare the survival of different groups of subjects, or to track the survival of a single group of subjects over time."
    },
    {
        number: 68,
        description: "What is the purpose of a crossover design in clinical trials?",
        choices: [
            "A. To assess the association between variables.",
            "B. To evaluate the effectiveness of an intervention.",
            "C. To estimate the prevalence of a disease.",
            "D. To calculate odds ratios."
        ],
        answer: "B",
        explain: "A crossover design is a type of clinical trial in which each participant receives both the intervention and the control treatment. This is done in a sequential order, such that each participant serves as their own control.\n\nCrossover designs are used to evaluate the effectiveness of an intervention by comparing the participant's response to the intervention to their response to the control treatment. This allows for the control of confounding variables, such as the participant's underlying health status, which can affect the outcome of the trial."
    },
    {
        number: 69,
        description: "If the correlation coefficient between two variables is -0.85, it indicates:",
        choices: [
            "A. A strong positive relationship between the variables.",
            "B. A strong negative relationship between the variables.",
            "C. No relationship between the variables.",
            "D. Insufficient information to determine the relationship."
        ],
        answer: "B",
        explain: "A correlation coefficient is a measure of the strength and direction of the relationship between two variables. A correlation coefficient of -0.85 indicates a strong negative relationship, which means that as one variable increases, the other variable decreases."
    },
    {
        number: 70,
        description: "Which of the following statements best describes the difference between correlation and causation?",
        choices: [
            "A. Correlation refers to a cause-and-effect relationship, while causation refers to a statistical association between variables.",
            "B. Correlation refers to a statistical association between variables, while causation refers to a cause-and-effect relationship.",
            "C. Correlation and causation are interchangeable terms that describe the same relationship between variables.",
            "D. Correlation and causation are unrelated concepts in statistics."
        ],
        answer: "B",
        explain: "Correlation describes the statistical relationship between two variables, indicating how they tend to change together. It measures the extent to which changes in one variable are associated with changes in another variable. On the other hand, causation refers to a cause-and-effect relationship, where changes in one variable directly influence or cause changes in another variable."
    }

]