import './App.css';
import Home from './components/Home';
import Contact from './components/Contact';
import Login from './components/Login';
import AllCategories from './components/AllCategories';
import React, { useState, useEffect } from 'react';
import PurchaseSuccess from './components/PurchaseSuccess';
import RegisterSuccess from './components/RegisterSuccess';
import PurchaseError from './components/PurchaseError';
import Sitemap from './components/Sitemap';
import CIHExamBasics from './components/CIHExamBasics';
import PracticeExam from './components/PracticeExam';
import Videos from './components/Videos';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import axios from 'axios';
import MarkedQuestion from './components/MarkedQuestion';

function App() {
  function setCookie(name, value, daysToExpire) {
    console.log("setting cookies", name, value, daysToExpire)
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);

    // Add the Secure attribute if the site is served over HTTPS
    // var cookie = [name, '=', JSON.stringify(value), '; domain=.', window.location.host.toString(), '; path=/;'].join('');
    // document.cookie = cookie;
    var cookie = [
      name,
      '=',
      JSON.stringify(value),
      '; expires=',
      // Calculate expiration date in milliseconds
      new Date(Date.now() + (365 * 24 * 60 * 60 * 1000)).toUTCString(),
      '; domain=.',
      window.location.host.toString(),
      '; path=/'
    ].join('');

    document.cookie = cookie;
    console.log("Cookie set:", document.cookie); // Log to check if the cookie is set
  }

  function getCookie(name) {
    console.log("getting cookies for name", name)
    if (document.cookie) {
      console.log("cookie found", document.cookie)
      const cookies = document.cookie.split('; ');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        if (cookie[0] === name) {
          const encodedValue = cookie[1];
          const decodedValue = decodeURIComponent(encodedValue);
          console.log("Cookie path:", JSON.parse(decodedValue))
          return JSON.parse(decodedValue);
        }
      }
    }
    console.log("did not find any cookies for name", name)
    return null;
  }
  const [currUser, setCurrUser] = useState(getCookie('currUser'))
  useEffect(() => {
    if (currUser) {
      console.log("exsit user, will try to retrieve")
      const newRequest = { email: currUser.email, password: currUser.password }
      try {
        axios.post("/singleuser", newRequest).then(
          (response) => {
            if (response.data === 'Nouser') {
              return
            }
            if (response.data === 'wronginfo') {
              return
            }
            else {
              updateCurrUser(response.data)
            }
          }
        )
      }
      catch (error) {
        console.log(error)
      }
    } else {
      console.log("no user in cookies", getCookie('currUser'))
    }
  }, []);
  const updateCurrUser = (user) => {
    setCurrUser(user);
    console.log("user:", user)
    setCookie('currUser', { email: user.email, password: user.password, name: user.name, marked_question: user.marked_question, expire_date: user.expire_date, showdate: user.showdate, solved_question: user.solved_question, watched_videos: user.watched_videos }, 365)
    console.log("updated user, set cookies")
  }
  const handleLogout = () => {
    setCurrUser(null)
    setCookie('currUser', null, 90)
  }
  // remove this when deploy

  // if (window.location.protocol !== 'https:') {
  //   const httpsURL = 'https://' + window.location.hostname + window.location.pathname;
  //   window.location.replace(httpsURL);
  // }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/sitemap.xml" element={<Sitemap />} />
          <Route exact path="/" element={<Home currUser={currUser} handleLogout={handleLogout} />} />
          <Route path="/Industrial_Safety_Hub_Contact" element={<Contact currUser={currUser} handleLogout={handleLogout} />} />
          <Route path="/CIH_Traning_Course" element={<Videos currUser={currUser} handleLogout={handleLogout} updateCurrUser={updateCurrUser} />} />
          <Route path="/login" element={<Login updateCurrUser={updateCurrUser} />} currUser={currUser} handleLogout={handleLogout} />
          <Route path="/CIH_Sample_Questions" element={<AllCategories currUser={currUser} updateCurrUser={updateCurrUser} handleLogout={handleLogout} />} />
          <Route path="/purchasesuccess" element={<PurchaseSuccess currUser={currUser} updateCurrUser={updateCurrUser} />} />
          <Route path="/registersuccess" element={<RegisterSuccess currUser={currUser} updateCurrUser={updateCurrUser} />} />
          <Route path="/purchaseerror" element={<PurchaseError currUser={currUser} />} />
          <Route path="/CIH_Exam_Q&A" element={<CIHExamBasics currUser={currUser} handleLogout={handleLogout} />} />
          <Route path="/CIH_Practice_Exam" element={<PracticeExam currUser={currUser} handleLogout={handleLogout} />} />
          <Route path="/CIH_Sample_Questions_Marked" element={<MarkedQuestion currUser={currUser} handleLogout={handleLogout} updateCurrUser={updateCurrUser} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
