export const biohazards_questions = [
    {
        number: 1,
        description: "Which of the following is the primary mode of transmission for Legionella bacteria?",
        choices: [
            "A. Person-to-person contact",
            "B. Ingestion of contaminated food or water",
            "C. Direct contact with contaminated surfaces",
            "D. Inhalation of aerosolized droplets",
        ],
        answer: "D",
        explain: "Legionella bacteria are typically found in water systems, and they can be spread through the air in the form of a mist or aerosol. When people inhale these droplets, they can become infected with the bacteria. \n\nLegionnaires' disease is a serious type of pneumonia that can be fatal. The symptoms of Legionnaires' disease typically start 2-10 days after exposure to the bacteria and include fever, cough, shortness of breath, muscle aches, headache, and fatigue."
    },
    {
        number: 2,
        description: "What is the main principle of universal precautions in infection control?",
        choices: [
            "A. Treating all blood and bodily fluids as if they are infectious, regardless of the known health status of the patient.",
            "B. Using protective equipment only when a patient is known to be infected with a communicable disease.",
            "C. Isolating patients with infectious diseases in a separate area of the facility.",
            "D. Administering antibiotics to all patients as a preventive measure against infection."
        ],
        answer: "A",
        explain: "The main principle of universal precautions in infection control is to treat all blood and bodily fluids as potentially infectious, regardless of the patient's known health status. This approach is designed to prevent the transmission of infectious diseases in healthcare settings by consistently using protective measures, such as gloves, masks, and gowns, when handling blood, bodily fluids, or any materials that might be contaminated."
    },
    {
        number: 3,
        description: "Which of the following is the primary purpose of a biosafety cabinet (BSC)?",
        choices: [
            "A. To provide ventilation in the laboratory",
            "B. To protect the environment from laboratory contaminants",
            "C. To provide a sterile environment for cell culture work",
            "D. To provide containment and protection for laboratory workers"
        ],
        answer: "D",
        explain: "The main purpose of a biosafety cabinet is to create a controlled environment that minimizes the risk of exposure to biological agents or materials. It achieves this by using a combination of features, including airflow control, HEPA (High-Efficiency Particulate Air) filtration, and physical barriers.\n\nHere's how a biosafety cabinet fulfills its primary purpose:\n\nContainment: The cabinet is designed to contain any hazardous materials or biological agents being handled within it. It prevents the release of these materials into the laboratory or the surrounding environment, reducing the risk of exposure to both laboratory workers and the external environment.\n\nProtection for laboratory workers: Biosafety cabinets are equipped with HEPA filters that remove airborne particles and microorganisms, providing a barrier against potential contaminants. They also employ a directional airflow system that ensures the movement of air within the cabinet is from clean to contaminated areas. This helps to protect the laboratory workers by minimizing their exposure to harmful biological agents."
    },
    {
        number: 4,
        description: "Which of the following best describes the 'reservoir' in the chain of infection?",
        choices: [
            "A. The method by which an infectious agent is transported from one person to another",
            "B. The point at which an infectious agent enters the body",
            "C. The individual or object that harbors and spreads an infectious agent",
            "D. The process by which an infectious agent causes disease in the body"
        ],
        answer: "C",
        explain: "Reservoir: The reservoir refers to the source of an infectious agent, such as a person, animal, or object, that can harbor and spread the agent to others. The reservoir can be living or nonliving.\n\nExit: The exit is the method by which the infectious agent leaves the reservoir. For example, respiratory secretions from an infected person, or fecal matter from an infected animal, can serve as exits for infectious agents.\n\nTransmission: Transmission refers to the spread of an infectious agent from the reservoir to a susceptible host. Transmission can occur through a variety of means, such as direct contact, airborne particles, or contaminated food or water.\n\nEntry: The entry refers to the method by which the infectious agent enters a susceptible host, such as through the mouth, nose, or skin."
    },
    {
        number: 5,
        description: "What is the primary difference between cleaning and disinfecting surfaces in a healthcare or industrial setting?",
        choices: [
            "A. Cleaning removes visible dirt and debris, while disinfecting kills harmful microorganisms.",
            "B. Cleaning uses chemical agents, while disinfecting only requires water and soap.",
            "C. Cleaning is performed after disinfecting to remove residual chemicals.",
            "D. Cleaning and disinfecting are the same processes and can be used interchangeably."
        ],
        answer: "A",
        explain: "The primary difference between cleaning and disinfecting is that cleaning involves removing visible dirt, debris, typically using soap or detergent and water. Disinfecting, on the other hand, involves using chemical agents to kill harmful microorganisms on surfaces. While cleaning can help reduce the number of germs, disinfecting specifically targets and kills pathogens that can cause infections."
    },
    {
        number: 6,
        description: "What is the definition of sanitization in the context of preventing the spread of infectious agents?",
        choices: [
            "A. The complete elimination of all microorganisms from a surface or object",
            "B. The reduction of the number of microorganisms to a level that is not harmful to human health",
            "C. The process of killing or eliminating most, but not all, pathogenic microorganisms on a surface or object",
            "D. The process of preventing the spread of infectious agents by maintaining cleanliness and hygiene in the environment"
        ],
        answer: "B",
        explain: "In the context of preventing the spread of infectious agents, sanitization refers to the process of reducing the number of microorganisms on a surface or object to a level that is considered safe and not harmful to human health. Sanitization aims to lower the microbial load to a point where the risk of infection or disease transmission is significantly reduced."
    },
    {
        number: 7,
        description: "What is the definition of sterilization in the context of preventing the spread of infectious agents?",
        choices: [
            "A. The partial elimination of all microorganisms from a surface or object",
            "B. The reduction of the number of microorganisms to a level that is not harmful to human health",
            "C. The process of killing or eliminating most, but not all, pathogenic microorganisms on a surface or object",
            "D. The process of eliminating all forms of life, including microorganisms, from a surface or object"
        ],
        answer: "D",
        explain: "The process of eliminating all forms of life, including microorganisms, from a surface or object, is the definition of sterilization. This process typically involves the use of physical or chemical methods, such as heat, radiation, or ethylene oxide gas, to kill or eliminate all microorganisms on a surface or object, including spores and viruses. Sterilization is often used in medical and laboratory settings to ensure the complete elimination of pathogens and prevent the transmission of disease."
    },
    {
        number: 8,
        description: "Which of the following best describes Biosafety Level 1 (BSL-1)?",
        choices: [
            "A. A level of biosafety where work is performed with agents that pose moderate potential hazards to personnel and the environment.",
            "B. A level of biosafety where work is performed with agents that are not known to consistently cause disease",
            "C. A level of biosafety where work is performed with agents that require specialized facilities and equipment, and may cause serious or potentially lethal diseases as a result of exposure.",
            "D. A level of biosafety where work is performed with agents that require the highest level of containment and protection."
        ],
        answer: "B",
        explain: "BSL-1 is the lowest security level for handling biological material. This kind of material poses no or only a low risk to healthy adult humans and presents minimal potential hazard to laboratory personnel and the environment. BSL-1 laboratories do not have to be separated from the rest of a building. Laboratory workers can do their work on open bench tops, and there is no need to use special equipment."
    },
    {
        number: 9,
        description: "Which of the following best describes Biosafety Level 4 (BSL-4)?",
        choices: [
            "A. A level of biosafety where work is performed with agents that pose moderate potential hazards to personnel and the environment.",
            "B. A level of biosafety where work is performed with agents that are not known to consistently cause disease in healthy adults.",
            "C. A level of biosafety where work is performed with agents that require specialized facilities and equipment, and may cause serious or potentially lethal diseases as a result of exposure.",
            "D. A level of biosafety where work is performed with agents that require the highest level of containment and protection."
        ],
        answer: "D",
        explain: "Biosafety Level 4 (BSL-4) is the highest level of containment and is designed for work involving dangerous and exotic agents that pose a high risk of life-threatening disease, may be easily transmitted from person to person, or for which there is no known vaccine or treatment. Examples of agents that are handled in BSL-4 facilities include:\n\nEbola virus\n\nMarburg virus\n\nLassa fever virus\n\nNipah virus\n\nHendra virus\n\nCrimean-Congo hemorrhagic fever virus"
    },
    {
        number: 10,
        description: "Which of the following correctly distinguishes bacteria from fungi?",
        choices: [
            "A. Bacteria are single-celled prokaryotes, while fungi are eukaryotes that can be single-celled or multicellular.",
            "B. Bacteria can only reproduce asexually, while fungi only reproduce sexually.",
            "C. Bacteria are larger than fungi and have a more complex cellular structure.",
            "D. Bacteria and fungi are both photosynthetic organisms."
        ],
        answer: "A",
        explain: "Bacteria are single-celled organisms classified as prokaryotes, meaning they lack a true nucleus and other membrane-bound organelles. Fungi, on the other hand, are eukaryotic organisms that can be either single-celled (like yeasts) or multicellular (like molds and mushrooms)."
    },
    {
        number: 11,
        description: "Which of the following is NOT a type of bacteria?",
        choices: [
            "A. Legionella",
            "B. Mycobacterium tuberculosis",
            "C. Aspergillosis",
            "D. Escherichia coli (E. coli)"
        ],
        answer: "C",
        explain: "Aspergillosis is not a bacterium; it is a fungal infection caused by the Aspergillus species of fungi. The other options—Legionella, Mycobacterium tuberculosis, and Escherichia coli (E. coli)—are all types of bacteria. Legionella can cause Legionnaires' disease, Mycobacterium tuberculosis causes tuberculosis, and E. coli is commonly associated with foodborne illness."
    },
    {
        number: 12,
        description: "What is the danger zone for food, and why is it important to control it?",
        choices: [
            "A. The temperature range between 0°C and 5°C, where bacteria grow most rapidly and can cause foodborne illness.",
            "B. The temperature range between 40°F and 140°F, where bacteria grow most rapidly and can cause foodborne illness.",
            "C. The temperature range between 60°C and 70°C, where bacteria grow most rapidly and can cause foodborne illness.",
            "D. The temperature range between -20°C and -10°C, where bacteria grow most rapidly and can cause foodborne illness."
        ],
        answer: "B",
        explain: "It is important to control the danger zone because bacteria can grow rapidly within this temperature range, increasing the risk of foodborne illness. When food is held at temperatures within the danger zone for an extended period, bacteria can multiply to dangerous levels, leading to foodborne illnesses such as salmonella, E. coli, or listeria infections.\n\nIt is recommended to keep cold perishable foods below 40°F (4°C) and hot foods above 140°F (60°C) to minimize the risk of bacterial growth."
    },
    {
        number: 13,
        description: "What type of radiation is commonly used in the food industry to reduce the number of microorganisms in food products?",
        choices: [
            "A. Gamma radiation",
            "B. X-ray radiation",
            "C. Ultraviolet radiation ",
            "D. Infrared radiation "
        ],
        answer: "A",
        explain: "Gamma radiation is a type of ionizing radiation that is commonly used in the food industry for sterilization and preservation purposes. It is a form of high-energy electromagnetic radiation that is produced by the decay of radioactive isotopes. Gamma radiation is able to penetrate deep into materials, which makes it effective at killing microorganisms in food products without damaging the food itself.\n\nThe process of using gamma radiation to reduce the number of microorganisms in food products is known as irradiation. Irradiation has been approved by many countries as a safe and effective method for reducing the risk of foodborne illness, and it is often used to treat foods such as meat, poultry, seafood, and fresh produce. However, it is important to note that irradiation does not completely sterilize the food product, and other food safety measures such as good hygiene and proper storage are still necessary to prevent the growth of harmful bacteria."
    },
    {
        number: 14,
        description: "What is the primary use of hydrogen peroxide in industrial and healthcare settings?",
        choices: [
            "A. Disinfecting surfaces and equipment",
            "B. Treating dental cavities",
            "C. Removing stains from clothing",
            "D. Improving the taste of food products"
        ],
        answer: "A",
        explain: "Hydrogen peroxide is a powerful oxidizing agent that is commonly used as a disinfectant in industrial and healthcare settings. It is often used to clean surfaces and equipment in hospitals, clinics, and other healthcare facilities. Hydrogen peroxide can also be used to sterilize medical devices and instruments, and as a bleach for fabrics and other materials."
    },
    {
        number: 15,
        description: "What disease is caused by Legionella bacteria?",
        choices: [
            "A. Legionnaires' disease",
            "B. Lyme disease",
            "C. Malaria",
            "D. Tuberculosis "
        ],
        answer: "A",
        explain: "Legionnaires' disease is a severe form of pneumonia caused by the Legionella bacteria. The bacteria is commonly found in natural water sources, such as rivers and lakes, but can also be found in man-made water systems such as cooling towers, hot tubs, and plumbing systems. When the bacteria is aerosolized and inhaled, it can cause severe respiratory illness, with symptoms including fever, chills, cough, and shortness of breath. Legionnaires' disease can be particularly dangerous for people with weakened immune systems or underlying health conditions."
    },
    {
        number: 16,
        description: "Which of the following is a characteristic that distinguishes viruses from bacteria and fungi?",
        choices: [
            "A. Ability to reproduce independently ",
            "B. Presence of a cell membrane",
            "C. Composition of genetic material",
            "D. Dependence on a host cell for replication"
        ],
        answer: "D",
        explain: "The characteristic that distinguishes viruses from bacteria and fungi is their dependence on a host cell for replication. Viruses are obligate intracellular parasites, meaning they cannot reproduce or carry out their life cycle independently. Instead, they must infect a host cell and hijack its cellular machinery to replicate and produce new viral particles.\n\nIn contrast, bacteria and fungi are independent organisms that can reproduce and carry out their life processes autonomously."
    },
    {
        number: 17,
        description: "Which of the following is NOT a virus?",
        choices: [
            "A. Influenza",
            "B. Hepatitis B",
            "C. Streptococcus",
            "D. HIV (Human Immunodeficiency Virus)"
        ],
        answer: "C",
        explain: "Streptococcus is not a virus; it is a genus of bacteria that includes species responsible for conditions such as strep throat and pneumonia. The other options—Influenza, Hepatitis B, and HIV—are all viruses that cause infectious diseases. Influenza causes the flu, Hepatitis B affects the liver, and HIV is the virus responsible for AIDS."
    },
    {
        number: 18,
        description: "Which of the following is a common type of fungus that might be found in a workplace environment?",
        choices: [
            "A. Aspergillus",
            "B. Legionella",
            "C. Salmonella",
            "D. Norovirus"
        ],
        answer: "A",
        explain: "Aspergillus is a common type of fungus that can be found in workplace environments, particularly in areas with moisture and poor ventilation, such as HVAC systems and damp building materials. It can cause respiratory issues, especially in individuals with weakened immune systems. \n\nLegionella is a bacterium, Salmonella is a bacterium that causes foodborne illness, and Norovirus is a virus that causes gastrointestinal issues."
    },
    {
        number: 19,
        description: "Which of the following is the most common route of transmission of tuberculosis (TB)?",
        choices: [
            "A. Inhalation of airborne droplets containing Mycobacterium tuberculosis",
            "B. Contact with contaminated food or water",
            "C. Contact with contaminated surfaces or fomites",
            "D. Sexual contact with an infected individual"
        ],
        answer: "A",
        explain: "TB is primarily transmitted through the inhalation of airborne droplets containing Mycobacterium tuberculosis, which are released when an infected individual coughs, sneezes, or talks."
    },
    {
        number: 20,
        description: "Which of the following is a common route of transmission for tularemia?",
        choices: [
            "A. Direct contact with contaminated animals or their carcasses",
            "B. Ingestion of contaminated food or water",
            "C. Inhalation of airborne droplets containing Francisella tularensis",
            "D. All of the above"
        ],
        answer: "D",
        explain: "Tularemia is a bacterial infection caused by Francisella tularensis. It is a zoonotic disease, which means it can be spread from animals to humans but is not spread person to person. The bacteria can be found in a variety of animals, including rabbits, rodents, and ticks. Humans can become infected with tularemia through contact with infected animals, their tissues, or their fluids; through the bite of an infected tick or deerfly; or by inhaling contaminated dust or water.\n\nTularemia can cause a variety of symptoms, depending on how it is contracted. The most common symptoms include fever, chills, headache, muscle aches, and fatigue. Other symptoms may include skin ulcers, swollen lymph nodes, pneumonia, and meningitis.\n\nTularemia is treated with antibiotics. The type of antibiotic and the length of treatment will depend on the severity of the infection. In most cases, tularemia is curable with antibiotics. However, if left untreated, tularemia can be fatal."
    },
    {
        number: 21,
        description: "Which of the following is true about the Hepatitis B virus (HBV)?",
        choices: [
            "A. It is transmitted primarily through the fecal-oral route.",
            "B. It can survive outside the body for days to weeks.",
            "C. It does not have a vaccine available.",
            "D. It only causes acute symptoms that resolve within a few days."
        ],
        answer: "B",
        explain: "Hepatitis B is primarily transmitted through blood and other bodily fluids, but the virus can also survive outside the body for days to weeks on environmental surfaces, such as contaminated needles or medical equipment. There is a highly effective vaccine available for Hepatitis B, which is recommended for all healthcare workers and other individuals at risk for exposure. Hepatitis B can cause both acute and chronic infections, and symptoms can range from mild to severe."
    },
    {
        number: 22,
        description: "Which of the following is a source of psittacosis?",
        choices: [
            "A. Bites and beak-to-mouth contact.",
            "B. Direct contact with infected animals or their secretions",
            "C. Inhalation of airborne dust or particles",
            "D. All of the above"
        ],
        answer: "D",
        explain: "Psittacosis is a zoonotic disease caused by the bacterium Chlamydia psittaci, which is commonly found in birds, especially parrots and other psittacine species. \n\nThe bacteria can infect people exposed to infected birds. It is important to know that infected birds do not always show signs of disease or seem sick. Both sick birds and infected birds without signs of illness shed the bacteria in their droppings and respiratory secretions. When the droppings and secretions dry, small dust particles (that include the bacteria) can get into the air. The most common way someone gets infected is by breathing in the dust from these dried secretions. Less commonly, birds infect people through bites and beak-to-mouth contact."
    },
    {
        number: 23,
        description: "Which of the following types of pesticides is known to have the longest environmental persistence and can accumulate in the food chain?",
        choices: [
            "A. Organophosphates",
            "B. Carbamates",
            "C. Organochlorines",
            "D. All of the above"
        ],
        answer: "C",
        explain: "Organochlorines are a group of pesticides that includes the well-known pesticide DDT. They are known for their long environmental persistence, meaning they can remain in the environment for years to decades without breaking down. They can also accumulate in the food chain, meaning that organisms at higher levels of the food chain, such as predators, can have higher concentrations of these pesticides in their tissues."
    },
    {
        number: 24,
        description: "Which of the following industries or job roles is most likely to involve potential occupational exposure to parathion?",
        choices: [
            "A. Information technology",
            "B. Healthcare administration",
            "C. Agricultural farming and pesticide application",
            "D. Accounting and finance"
        ],
        answer: "C",
        explain: "Agricultural farming and pesticide application are industries and job roles that are most likely to involve potential occupational exposure to parathion. Parathion is primarily used as an insecticide in agriculture to control pests on crops. Workers involved in the application of pesticides, such as farmers, agricultural workers, or pesticide applicators, may come into contact with parathion during their work activities. \n\nParathion is an organophosphate insecticide. It is a type of pesticide that acts on the nervous system of insects, disrupting their normal functioning. Organophosphates, including parathion, are often used in agriculture to control pests on crops. However, they can also pose risks to human health and the environment and need to be used with caution. Its use has been restricted or banned in many countries due to its potential health and environmental hazards."
    },
    {
        number: 25,
        description: "Which of the following statements about Anthrax is correct?",
        choices: [
            "A. Anthrax is caused by a virus and primarily affects the respiratory system.",
            "B. Anthrax is a bacterial infection that can be contracted through skin contact, inhalation, or ingestion.",
            "C. Anthrax is a fungal infection commonly found in damp environments.",
            "D. Anthrax is spread primarily through human-to-human contact."
        ],
        answer: "B",
        explain: "Anthrax is a bacterial infection caused by *Bacillus anthracis*. It can be contracted through skin contact (cutaneous anthrax), inhalation (inhalation anthrax), or ingestion (gastrointestinal anthrax). It is not caused by a virus or fungus and is not spread through human-to-human contact but rather through contact with infected animals or contaminated animal products. Inhalation anthrax is particularly dangerous and affects the respiratory system, while cutaneous anthrax is the most common form."
    },
    {
        number: 26,
        description: "Which of the following is not a route of entry for the bacteria that causes tetanus?",
        choices: [
            "A. Inhalation of airborne particles",
            "B. Wounds or cuts on the skin that come into contact with contaminated soil or dust",
            "C. Broken skin contact with contaminated surfaces or equipment",
            "D. Injection from contaminated needlesticks"
        ],
        answer: "A",
        explain: "The bacteria that causes tetanus, Clostridium tetani, is found in soil and dust. It can enter the body through a break in the skin, such as a cut, puncture wound, or burn. However, it is not easily spread through the air."
    },
    {
        number: 27,
        description: "Which of the following types of mold is commonly referred to as 'black mold' and can cause health effects in humans?",
        choices: [
            "A. Penicillium",
            "B. Aspergillus",
            "C. Stachybotrys",
            "D. Alternaria"
        ],
        answer: "C",
        explain: "Stachybotrys is a type of mold commonly referred to as 'black mold' due to its dark color and texture. It is known to produce mycotoxins, which can cause health effects in humans, such as respiratory problems, headaches, and allergic reactions. Penicillium, Aspergillus, and Alternaria are other types of mold that can be found in indoor environments and can also cause health effects in humans, but they are not typically referred to as 'black mold'."
    },
    {
        number: 28,
        description: "Which of the following is NOT a recommended method for controlling mold growth in indoor environments?",
        choices: [
            "A. Proper ventilation",
            "B. Regular cleaning of surfaces and materials",
            "C. Removing sources of moisture",
            "D. Increasing the humidity levels"
        ],
        answer: "D",
        explain: "Mold growth requires moisture, so controlling moisture levels in indoor environments is key to preventing and controlling mold growth. Proper ventilation and regular cleaning of surfaces and materials can help to reduce moisture levels and minimize mold growth. Removing sources of moisture, such as fixing leaks or improving ventilation in areas prone to high humidity, is also important. However, increasing humidity levels can promote mold growth and is not a recommended method for controlling mold. In fact, indoor humidity levels should be maintained below 60 percent to prevent mold growth. "
    },
    {
        number: 29,
        description: "Which of the following statements best describes histoplasmosis?",
        choices: [
            "A. A bacterial infection transmitted through contaminated water",
            "B. A viral infection spread by contact with infected animals",
            "C. A fungal infection caused by inhaling spores from bird or bat droppings",
            "D. A parasitic infection transmitted by insect bites"
        ],
        answer: "C",
        explain: "Histoplasmosis is a fungal infection caused by inhaling spores of the fungus *Histoplasma capsulatum*, which is commonly found in soil contaminated by bird or bat droppings. The infection primarily affects the lungs, and symptoms can range from mild flu-like symptoms to severe respiratory issues, particularly in individuals with weakened immune systems."
    },
    {
        number: 30,
        description: "Which of the following is NOT a role of fungi in nature?",
        choices: [
            "A. Decomposition of dead organic matter",
            "B. Symbiotic relationships with plants",
            "C. Production of antibiotics",
            "D. Formation of ozone in the atmosphere"
        ],
        answer: "D",
        explain: "Fungi play important roles in ecosystems, including the decomposition of dead organic matter, forming symbiotic relationships with plants, and production of antibiotics. However, the formation of ozone in the atmosphere is not a role of fungi. Ozone is formed through chemical reactions between oxygen and other substances in the atmosphere, such as nitrogen oxides and volatile organic compounds, and is not directly influenced by fungi. "
    },
    {
        number: 31,
        description: "Which of the following is a common source of E. coli contamination in food?",
        choices: [
            "A. Properly cooked meat",
            "B. Fresh fruits and vegetables",
            "C. Pasteurized dairy products",
            "D. Canned seafood"
        ],
        answer: "B",
        explain: "E. coli is a type of bacteria that is commonly found in the intestines of humans and animals. While most strains of E. coli are harmless, some can cause illness, particularly if contaminated food or water is consumed. Fresh fruits and vegetables are a common source of E. coli contamination, as they may come into contact with contaminated soil, water, or animal feces during production, handling, or storage. \n\nProperly cooked meat and pasteurized dairy products are generally considered safe, as the high temperatures involved in cooking and pasteurization can kill harmful bacteria. Canned seafood is also typically safe, as the canning process involves high temperatures that can kill bacteria. "
    },
    {
        number: 32,
        description: "Which of the following is an effective method for controlling the spread of Methicillin-resistant Staphylococcus aureus (MRSA) in healthcare settings?",
        choices: [
            "A. Routine use of antibiotics",
            "B. Regular cleaning and disinfection of surfaces and equipment",
            "C. Handwashing with plain soap and water",
            "D. Using communal towels and clothing"
        ],
        answer: "B",
        explain: "Methicillin-resistant Staphylococcus aureus (MRSA) is a type of bacteria that is resistant to many antibiotics, making it difficult to treat infections. Healthcare settings, such as hospitals and long-term care facilities, are particularly at risk for MRSA outbreaks. To control the spread of MRSA in these settings, regular cleaning and disinfection of surfaces and equipment is an effective method. This includes using appropriate cleaning agents and disinfectants, as well as following proper cleaning procedures. \n\nRoutine use of antibiotics is not recommended for controlling MRSA, as this can contribute to antibiotic resistance. Handwashing with plain soap and water is also important, but may not be sufficient on its own to control the spread of MRSA. Finally, communal towels and clothing can contribute to the spread of MRSA and should be avoided."
    },
    {
        number: 33,
        description: "Which of the following is NOT a common symptom of staphylococcal food poisoning?",
        choices: [
            "A. Diarrhea",
            "B. Coughing",
            "C. Abdominal pain and cramping",
            "D. Nausea and vomiting"
        ],
        answer: "B",
        explain: "Staphylococcal food poisoning is a type of food poisoning caused by toxins produced by the bacterium Staphylococcus aureus. The toxins are produced when the bacteria grow in food that has been improperly handled or stored. Symptoms of staphylococcal food poisoning usually begin within 30 minutes to 8 hours after eating contaminated food. The most common symptoms are nausea, vomiting, and stomach cramps. Other symptoms may include diarrhea, headache, and fever.\n\nStaphylococcal food poisoning is usually mild and most people recover without treatment. However, in severe cases, it can lead to dehydration, electrolyte imbalance, and shock."
    },
    {
        number: 34,
        description: "What is the primary route of transmission for Hantavirus Pulmonary Syndrome (HPS)?",
        choices: [
            "A. Person-to-person contact",
            "B. Contaminated water or food",
            "C. Inhalation of virus-contaminated rodent droppings, urine, or saliva",
            "D. Bites from infected ticks or mosquitoes"
        ],
        answer: "C",
        explain: "Hantavirus Pulmonary Syndrome (HPS) is a rare but potentially deadly viral infection that is transmitted to humans by exposure to infected rodents or their excreta. The primary route of transmission is through inhalation of virus-contaminated airborne particles from rodent droppings, urine, or saliva. People who come into contact with rodent-infested areas, such as cabins or buildings that have been closed up for a long time, may be at risk of contracting HPS if they inhale the virus. "
    },
    {
        number: 35,
        description: "Who was 'Typhoid Mary' and why was she significant in the field of public health?",
        choices: [
            "A. A scientist who discovered the cause of typhoid fever",
            "B. A nurse who contracted and survived typhoid fever",
            "C. A cook who was an asymptomatic carrier of typhoid fever and transmitted the disease to others",
            "D. A patient who was successfully treated for typhoid fever and became an advocate for public health measures"
        ],
        answer: "C",
        explain: "’Typhoid Mary’ was the nickname given to Mary Mallon, an Irish immigrant who worked as a cook in the early 1900s in the New York City area. She was an asymptomatic carrier of typhoid fever, meaning she carried the bacteria that caused the disease but showed no symptoms herself. Mallon unknowingly infected numerous people with typhoid fever, including several who died. \n\nMallon was eventually identified as a carrier and forcibly isolated for several years, but she remained uncooperative and continued to work as a cook under different names, transmitting the disease to others. Mallon's case was significant in the field of public health because it demonstrated the importance of identifying carriers of infectious diseases, even if they show no symptoms, and implementing measures to prevent the spread of disease."
    },
    {
        number: 36,
        description: "Leptospirosis is a bacterial infection that is most commonly transmitted to humans through:",
        choices: [
            "A. Mosquito bites",
            "B. Contaminated food and water",
            "C. Direct contact with the urine of infected animals",
            "D. Inhalation of airborne bacteria"
        ],
        answer: "C",
        explain: "Leptospirosis can be life-threatening and is caused by a type of bacteria called Leptospira, which is commonly found in the urine of infected animals such as rats, mice, cows, pigs, and dogs. Humans can become infected with Leptospira through direct contact with the urine of infected animals, such as by swimming or wading in contaminated water, handling infected animal tissues or fluids, or through occupational exposure in certain high-risk professions such as agriculture, veterinary medicine, and waste management."
    },
    {
        number: 37,
        description: "Lyme disease is transmitted to humans by which of the following?",
        choices: [
            "A. Mosquito bites",
            "B. Tick bites",
            "C. Inhalation of airborne bacteria",
            "D. Contact with contaminated food or water"
        ],
        answer: "B",
        explain: "Lyme disease is caused by the bacterium Borrelia burgdorferi, which is transmitted to humans through the bites of infected blacklegged ticks. These ticks are found in wooded and grassy areas in the northeastern, mid-Atlantic, and north-central United States.\n\nLyme disease is the most common vector-borne disease in the United States. According to the Centers for Disease Control and Prevention (CDC), an estimated 476,000 people are diagnosed with Lyme disease in the United States each year.\n\nSymptoms of Lyme disease usually begin within 3 to 30 days after a tick bite. The most common symptom is a rash that looks like a bull's-eye. Other symptoms may include fever, headache, fatigue, and muscle and joint aches. If left untreated, Lyme disease can cause serious health problems, such as arthritis, meningitis, and heart problems.\n\nThere is no vaccine to prevent Lyme disease. The best way to prevent Lyme disease is to avoid tick bites. You can do this by wearing long pants and long-sleeved shirts when you are in wooded or grassy areas, using insect repellent, and checking your body for ticks after you have been outdoors."
    },
    {
        number: 38,
        description: "The Black Death, a devastating epidemic that killed an estimated 75-200 million people in mid-1300, was primarily caused by:",
        choices: [
            "A. A virus",
            "B. A bacterium",
            "C. A fungus",
            "D. A parasite"
        ],
        answer: "B",
        explain: "The Black Death, also known as the Bubonic Plague, was a devastating epidemic that occurred in the mid-14th century in Europe, Asia, and Africa. It is estimated that the epidemic killed between 75 and 200 million people, which was approximately 30-60% of the total population at the time. \n\nThe Black Death was primarily caused by the bacterium Yersinia pestis, which is typically spread to humans through the bite of infected fleas that live on rodents such as rats. The bacteria can also be transmitted through direct contact with infected bodily fluids or tissues. While some other infectious diseases may be caused by viruses, fungi, or parasites, the Black Death was primarily caused by a bacterium."
    },
    {
        number: 39,
        description: "Within what timeframe after water damage is it most crucial to initiate drying efforts to prevent mold growth?",
        choices: [
            "A. Within the first 24 hours.",
            "B. Within the first 48-72 hours.",
            "C. Within the first week.",
            "D. Mold can only grow after several weeks of water damage."
        ],
        answer: "A",
        explain: "The first 24-48 hours following water damage are critical for preventing mold growth. Mold spores are prevalent and can rapidly colonize damp areas. Quick drying removes the moisture necessary for mold development, safeguarding against potential health hazards and structural damage."
    },
    {
        number: 40,
        description: "Which of the following is not a type of immunity?",
        choices: [
            "A. Innate immunity",
            "B. Adaptive immunity",
            "C. Passive immunity",
            "D. Reactive immunity"
        ],
        answer: "D",
        explain: "Reactive immunity. Reactive immunity is not a recognized type of immunity. \n\nA) Innate immunity: This is the natural defense mechanism that humans are born with, which protects them from infections and diseases. It includes physical barriers such as the skin and mucous membranes, as well as immune cells like phagocytes and natural killer cells. Innate immunity provides a rapid response to any foreign invader, but it is not specific to particular pathogens.\n\nB) Adaptive immunity: This is a type of immunity that is acquired over time as the body is exposed to different pathogens. Adaptive immunity is specific to particular pathogens and involves the production of antibodies and the activation of T cells. Once the body has been exposed to a pathogen, it will 'remember' it and be able to mount a faster and stronger response if it is encountered again in the future.\n\nC) Passive immunity: This type of immunity is acquired through the transfer of antibodies from one individual to another. It can occur naturally, such as from a mother to her baby during breastfeeding, or artificially, such as through the administration of antibodies to treat or prevent a particular infection. Passive immunity provides immediate protection but is temporary, as the transferred antibodies eventually break down and are eliminated from the body."
    },
    {
        number: 41,
        description: "Which of the following foods is most commonly associated with Vibrio parahaemolyticus infection?",
        choices: [
            "A. Undercooked beef",
            "B. Raw eggs",
            "C. Raw oysters",
            "D. Unpasteurized milk"
        ],
        answer: "C",
        explain: "Vibrio parahaemolyticus is a bacterium commonly found in warm seawater and can cause gastroenteritis in humans. Consumption of raw or undercooked shellfish, especially raw oysters, is the most common cause of Vibrio parahaemolyticus infection. Vibrio parahaemolyticus can cause gastroenteritis, an infection of the digestive system, in people who consume contaminated seafood or water. Symptoms may include diarrhea, vomiting, and abdominal pain."
    },
    {
        number: 42,
        description: "Which of the following best describes the processes of biomagnification and bioaccumulation in environmental systems?",
        choices: [
            "A. Biomagnification refers to the buildup of pollutants in the tissues of organisms over time, while bioaccumulation is the transfer of pollutants between different trophic levels in a food chain.",
            "B. Biomagnification is the transfer of pollutants between different trophic levels in a food chain, while bioaccumulation refers to the buildup of pollutants in the tissues of organisms over time.",
            "C. Biomagnification and bioaccumulation are two different terms that refer to the same process of pollutant accumulation in the environment.",
            "D. Biomagnification and bioaccumulation are terms used to describe the natural cycling of nutrients in ecosystems, without any relation to pollutant accumulation."
        ],
        answer: "B",
        explain: "Biomagnification refers to the increase in the concentration of pollutants as they move up the food chain. When organisms consume other organisms, the pollutants they contain are transferred and accumulated in higher trophic levels. This process leads to higher concentrations of pollutants in top predators. \n\nBioaccumulation, on the other hand, is the accumulation of pollutants in the tissues of organisms over time. As organisms are exposed to pollutants in their environment, they absorb and store these substances in their tissues, which can result in higher concentrations over their lifespan. Both biomagnification and bioaccumulation are significant processes in the environmental fate and impact of pollutants."
    },
    {
        number: 43,
        description: "What is the primary route of transmission for bloodborne pathogens?",
        choices: [
            "A. Inhalation",
            "B. Ingestion",
            "C. Direct contact with infected blood or bodily fluids",
            "D. Indirect contact through contaminated surfaces"
        ],
        answer: "C",
        explain: "The primary route of transmission for bloodborne pathogens, such as HIV, Hepatitis B, and Hepatitis C, is through direct contact with infected blood or bodily fluids. This can occur through activities such as needlestick injuries, contact with open wounds or mucous membranes, and exposure to contaminated medical equipment or surfaces."
    },
    {
        number: 44,
        description: "Which of the following statements is true about Hepatitis B and Hepatitis C testing?",
        choices: [
            "A. Hepatitis B and Hepatitis C can be diagnosed based on symptoms alone.",
            "B. Routine screening for Hepatitis B and Hepatitis C is recommended for all individuals.",
            "C. Hepatitis B and Hepatitis C can be diagnosed through a simple blood test.",
            "D. Testing for Hepatitis B and Hepatitis C is not necessary for healthcare workers."
        ],
        answer: "C",
        explain: "Hepatitis B and Hepatitis C infections can be diagnosed through a simple blood test. These tests detect the presence of specific antibodies or viral genetic material in the blood, which indicate whether a person has been infected with Hepatitis B virus (HBV) or Hepatitis C virus (HCV). \n\nIt is important to note that many individuals with Hepatitis B or Hepatitis C may not experience noticeable symptoms, or symptoms may be mild and easily overlooked. Therefore, relying on symptoms alone is not sufficient for diagnosis. Routine screening for Hepatitis B and Hepatitis C is recommended for individuals at higher risk, such as those with a history of injection drug use, unprotected sexual contact with an infected individual, or exposure to contaminated blood products. \n\nHowever, healthcare professionals may also recommend testing for other individuals based on specific circumstances and risk factors."
    },
    {
        number: 45,
        description: "Which of the following items is typically NOT considered biohazardous waste?",
        choices: [
            "A. Blood-soaked bandages",
            "B. Used needles and sharps",
            "C. Contaminated culture media",
            "D. Disposable gloves used during a routine examination"
        ],
        answer: "D",
        explain: "Disposable gloves used during routine examinations are not typically considered biohazardous waste. While they may come into contact with patients and potentially carry some level of contamination, they are considered regular medical waste and can be disposed of in accordance with general waste management practices.\n\nHowever, blood-soaked bandages, used needles and sharps, and contaminated culture media are all examples of biohazardous waste that require special handling and disposal procedures due to the potential for transmitting infectious agents."
    },
    {
        number: 46,
        description: "What is the primary objective of the sterilization phase in biohazard waste autoclaving?",
        choices: [
            "A. To remove all visible signs of contamination from the waste.",
            "B. To reduce the volume of the biohazard waste for disposal.",
            "C. To kill microorganisms and render the waste safe for disposal.",
            "D. To minimize the environmental impact of biohazard waste."
        ],
        answer: "C",
        explain: "The primary objective of the sterilization phase in biohazard waste autoclaving is to kill microorganisms and render the waste safe for disposal. Autoclaving uses steam under pressure to kill microorganisms, including bacteria, viruses, and parasites. The steam causes the proteins in the microorganisms to denature, which means that they lose their shape and function. This renders the microorganisms unable to replicate or cause infection."
    },
    {
        number: 47,
        description: "What is the primary purpose of containment and isolation in a biohazard spill response plan?",
        choices: [
            "A. To identify and assess the nature of the spill.",
            "B. To clean up the spilled material and decontaminate the area.",
            "C. To prevent further spread and exposure of the biohazard.",
            "D. To report and document the spill incident."
        ],
        answer: "C",
        explain: "The primary purpose of containment and isolation in a biohazard spill response plan is to prevent further spread and exposure of the biohazardous material. When a spill occurs, it is crucial to establish barriers and measures that effectively contain the spill within a defined area. This containment helps to restrict the movement of the biohazardous material and prevents it from spreading to other areas or potentially coming into contact with individuals."
    },
    {
        number: 48,
        description: "Which of the following statements is true regarding the potential biohazard risks associated with genetically modified organisms (GMOs)?",
        choices: [
            "A. GMOs pose no biohazard risks as they undergo rigorous safety assessments before release.",
            "B. GMOs can have unintended effects on ecosystems and non-target organisms.",
            "C. All GMOs are classified as biohazards and require strict containment measures.",
            "D. The biohazard risks of GMOs are limited to specific applications in the medical field."
        ],
        answer: "B",
        explain: "While GMOs undergo rigorous safety assessments before release, there is scientific evidence that shows GMOs can have unintended effects on ecosystems and non-target organisms. These effects can include changes in biodiversity, disruption of ecological balance, and potential harm to beneficial insects or other wildlife. "
    },
    {
        number: 49,
        description: "What is the primary cause of Mad Cow Disease?",
        choices: [
            "A. A bacterial infection",
            "B. A fungal infection",
            "C. Genetic mutation",
            "D. Consumption of contaminated feed"
        ],
        answer: "D",
        explain: "Mad Cow Disease, or Bovine Spongiform Encephalopathy (BSE), is primarily caused by the consumption of contaminated feed, often containing meat and bone meal from infected animals. This leads to the accumulation of abnormal proteins in the brain, causing the disease's devastating effects. \n\nBSE is caused by a prion, an infectious protein. Prions are misfolded forms of proteins normally found in the bodies of animals and people."
    },
    {
        number: 50,
        description: "Which of the animals below possesses venom?",
        choices: [
            "A. Dolphin",
            "B. Ladybug",
            "C. Cobra",
            "D. Squirrel"
        ],
        answer: "C",
        explain: "Cobras are venomous snakes that are found in various parts of the world, known for their potent venom and iconic hood display. They belong to the family Elapidae and are known for their ability to inject venom through their fangs when biting. Cobras pose a significant threat to humans and other animals due to their venomous nature. It is important to exercise caution and avoid interactions with venomous animals like cobras to prevent potential harm."
    },
    {
        number: 51,
        description: "What is the difference between an antigen and an allergen?",
        choices: [
            "A. There is no difference between the two.",
            "B. An antigen is any substance that triggers an immune response, while an allergen is a specific type of antigen that causes an allergic reaction.",
            "C. An allergen is any substance that triggers an immune response, while an antigen is a specific type of allergen that causes an allergic reaction.",
            "D. Antigens are found in the environment, while allergens are produced by the body."
        ],
        answer: "B",
        explain: "An antigen is any substance that can trigger an immune response. This includes a wide range of things like bacteria, viruses, and pollen. An allergen is a specific type of antigen that causes an allergic reaction in sensitive individuals. The immune system overreacts to the allergen, leading to symptoms like sneezing, itching, and inflammation."
    },
    {
        number: 52,
        description: "What is a common symptom of latex allergy?",
        choices: [
            "A. Headache",
            "B. Skin rash",
            "C. Loss of appetite",
            "D. Fever"
        ],
        answer: "B",
        explain: "A common symptom of latex allergy is a skin rash. This can occur after direct contact with latex products and may include itching, redness, and swelling. More severe latex allergies can cause difficulty breathing, wheezing, and even anaphylaxis."
    },
    {
        number: 53,
        description: "Which of the following is the correct term for the process of a virus changing its genetic material, potentially leading to the emergence of new variants?",
        choices: [
            "A. Mutation",
            "B. Replication",
            "C. Recombination",
            "D. Adaptation"
        ],
        answer: "A",
        explain: "Mutation is a change in the genetic material of an organism. Mutations can be caused by a variety of factors, including exposure to radiation, chemicals, or viruses.\n\nReplication is the process by which a virus makes copies of itself. Replication occurs when a virus enters a host cell and hijacks the cell's machinery to make new copies of the virus.\n\nRecombination is the process by which two different viruses exchange genetic material. Recombination can occur when two viruses infect the same cell or when two viruses are mixed together.\n\nAdaptation is the process by which an organism becomes better suited to its environment. Adaptation can occur through mutation, recombination, or other mechanisms."
    },
    {
        number: 54,
        description: "Which of the following is a common symptom of Brucellosis infection in humans?",
        choices: [
            "A. Rash and skin lesions",
            "B. Severe respiratory distress",
            "C. Joint pain and swelling",
            "D. Neurological impairment"
        ],
        answer: "C",
        explain: "Brucellosis is a bacterial infection that can affect humans and animals. It is caused by bacteria of the genus Brucella, which are found in the bloodstream of infected animals. Brucellosis can be transmitted to humans through contact with infected animals, their products, or their tissues. Commonly affected animals include cattle, goats, sheep, pigs, and dogs.\n\n Brucellosis can cause a variety of symptoms, including fever, fatigue, headache, muscle pain, and joint pain."
    },
    {
        number: 55,
        description: "Which influenza pandemic, occurring between 1918 and 1919, is considered one of the deadliest in human history?",
        choices: [
            "A. H1N1 influenza pandemic",
            "B. H2N2 influenza pandemic",
            "C. H3N2 influenza pandemic",
            "D. H5N1 influenza pandemic"
        ],
        answer: "A",
        explain: "The H1N1 influenza pandemic of 1918–1919, also known as the Spanish flu, was one of the deadliest pandemics in human history. It is estimated that the pandemic killed between 50 and 100 million people worldwide, or 5% of the world's population at the time. The pandemic was particularly deadly for young adults, who were more likely to die from the infection than older adults or children.\n\nThe H1N1 influenza virus that caused the pandemic was a new strain of the virus that had not been seen before. The virus was highly contagious and caused a severe respiratory illness. The symptoms of the illness included fever, cough, shortness of breath, and pneumonia.\n\nThe pandemic spread rapidly around the world, helped by the movement of troops during World War I. The pandemic peaked in the fall of 1918 and died out in the spring of 1919."
    },
    {
        number: 56,
        description: "Which of the following procedures could generate aerosols?",
        choices: [
            "A. Cell sorters",
            "B. Pipetting",
            "C. Sonicating tissue culture cells",
            "D. All of the above"
        ],
        answer: "D",
        explain: "Aerosols are airborne particles that can be generated by a variety of procedures, including cell sorters, pipetting, and sonication of tissue culture cells.\n\nCell sorters: Cell sorters use a high-pressure stream of air or liquid to separate cells based on their size, shape, or other properties. This process can generate aerosols that contain cells, cell fragments, and other debris.\n\nPipetting: Pipettes are used to transfer liquids from one container to another. When liquids are pipetted, they can be splashed or aerosolized, creating droplets that can contain harmful substances.\n\nSonication: Sonication is a process that uses sound waves to break cells apart. This process can also generate aerosols that contain cell fragments and other debris.\n\nAerosols can be harmful because they can contain harmful substances that can be inhaled or ingested. They can also cause eye irritation and skin rashes."
    },
    {
        number: 57,
        description: "The D-value is commonly used to:",
        choices: [
            "A. Determine the size of microbial populations",
            "B. Assess the cost-effectiveness of control agents",
            "C. Measure the effectiveness of sterilization or disinfection processes",
            "D. Determine the resistance of microorganisms to control agents"
        ],
        answer: "C",
        explain: "The D-value is a measure of the effectiveness of a sterilization or disinfection process. It represents the time it takes for the population of microorganisms to be reduced by 90%. For example, if the D-value for a particular control agent is 10 minutes, it means that it takes 10 minutes to kill 90% of the microorganisms present."
    },
    {
        number: 58,
        description: "What does it mean when a disinfectant product is included on EPA List N?",
        choices: [
            "A. It is a guarantee of the product's safety for use in any setting",
            "B. It is expected to be effective against SARS-CoV-2 because they demonstrate efficacy against SARS-CoV-2 or a pathogen that is harder to kill than SARS-CoV-2 or a different human coronavirus similar to SARS-CoV-2",
            "C. It is recommended exclusively for use on porous surfaces",
            "D. It requires a separate approval from the Centers for Disease Control and Prevention (CDC)"
        ],
        answer: "B",
        explain: "List N: Disinfectants for Coronavirus (COVID-19) is a list compiled by the United States Environmental Protection Agency (EPA) that includes registered disinfectant products that have demonstrated efficacy against SARS-CoV-2, the virus that causes COVID-19 or a pathogen that is harder to kill than SARS-CoV-2 or a different human coronavirus similar to SARS-CoV-2. The list serves as a resource for individuals, businesses, and organizations to identify and choose appropriate disinfectant products for use in their facilities or households to help prevent the spread of the virus.\n\nThe EPA maintains List N to provide transparency and help consumers make informed decisions when selecting disinfectants. These disinfectants are intended for use on surfaces, objects, or inanimate items and are not meant for direct application to the human body."
    },
    {
        number: 59,
        description: "The CDC and American Biological Safety Association does not support the installation of what biosafety cabinet element?",
        choices: [
            "A. Panorama Side Windows",
            "B. Angled Front Sash",
            "C. Ultraviolet Lamps",
            "D. HEPA filters"
        ],
        answer: "C",
        explain: "UV lamps have germicidal properties and can effectively kill or inactivate microorganisms, including bacteria, viruses, and fungi. However, the CDC and ABSA do not recommend or endorse the use of UV lamps as the primary means of disinfection within biosafety cabinets. Here's why:\n\nIneffectiveness in Shadowed Areas: UV lamps may not reach or adequately disinfect areas within the biosafety cabinet that are shadowed or shielded from direct exposure to UV light. This can result in incomplete decontamination of surfaces and potential risks of cross-contamination.\n\nPotential Hazards: UV lamps emit ultraviolet radiation, which can be harmful to the skin and eyes if exposed directly. The use of UV lamps in biosafety cabinets could pose risks to personnel if not handled properly or if safety precautions are not followed.\n\nAirflow Interference: UV lamps can interfere with the laminar airflow pattern of the biosafety cabinet, affecting the cabinet's containment and protection capabilities."
    },
    {
        number: 60,
        description: "What is the major advantage of steam sterilization over dry heat sterilization?",
        choices: [
            "A. It can be used for the sterilization of anhydrous materials",
            "B. It kills a greater spectrum of organisms.",
            "C. It is not as destructive to materials.",
            "D. It sterilizes in less time"
        ],
        answer: "B",
        explain: "Steam sterilization, also known as autoclaving, utilizes high-pressure saturated steam to achieve effective sterilization. Steam has the ability to penetrate materials and reach areas that may be difficult for dry heat to access. As a result, steam sterilization is effective against a wide range of microorganisms, including bacteria, fungi, viruses, and spores.\n\nOn the other hand, dry heat sterilization relies on hot air or an open flame to achieve sterilization. While it can be useful for certain materials and instruments that are sensitive to moisture, dry heat is generally less effective against spores and some heat-resistant microorganisms compared to steam sterilization."
    }
]