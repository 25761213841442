export const non_engineering_questions = [
    {
        number: 1,
        description: "Which of the following represents the most effective control measure in the safety hierarchy of controls?",
        choices: [
            "A. Personal Protective Equipment (PPE)",
            "B. Engineering controls",
            "C. Administrative controls",
            "D. Elimination of the hazard"
        ],
        answer: "D",
        explain: "The most effective control measure in the safety hierarchy of controls is the elimination of the hazard. This approach involves completely removing the source of danger, which effectively eliminates the risk of exposure. The hierarchy of controls ranks control measures from most to least effective: elimination, substitution, engineering controls, administrative controls, and finally, personal protective equipment (PPE). While PPE is important, it is considered the least effective control because it relies on proper use and does not eliminate the hazard itself."
    },
    {
        number: 2,
        description: "Which of the following best describes an administrative control measure in occupational health and safety?",
        choices: [
            "A. Installing machine guards to prevent contact with hazardous moving parts",
            "B. Providing personal protective equipment (PPE) to workers",
            "C. Conducting regular training sessions on safe work practices",
            "D. Ventilating a workspace to remove airborne contaminants"
        ],
        answer: "C",
        explain: "An administrative control measure in occupational health and safety involves conducting regular training sessions on safe work practices to educate and empower workers to prevent accidents and injuries by following proper procedures and utilizing their knowledge of safe practices."
    },
    {
        number: 3,
        description: "Which of the following is **not** an example of an engineering control?",
        choices: [
            "A. Installing local exhaust ventilation to remove hazardous fumes.",
            "B. Using machine guards to prevent contact with moving parts.",
            "C. Implementing a rotating shift schedule to reduce worker fatigue.",
            "D. Adding soundproofing materials to reduce noise levels."
        ],
        answer: "C",
        explain: "Implementing a rotating shift schedule to reduce worker fatigue is an example of an administrative control, not an engineering control. Engineering controls involve physical modifications to the workplace or equipment that reduce or eliminate hazards, such as installing ventilation systems, using machine guards, or adding soundproofing materials. Administrative controls, on the other hand, involve changes to work practices, policies, or schedules to minimize risk."
    },
    {
        number: 4,
        description: "What is the proper way to handle contaminated sharps in the workplace?",
        choices: [
            "A. Dispose of sharps directly into a trash can or waste container",
            "B. Place sharps in a designated sharps container immediately after use",
            "C. Recap needles and dispose of them in a regular waste container",
            "D. Rinse sharps with water before disposal to remove any contamination"
        ],
        answer: "B",
        explain: 'Contaminated sharps, such as needles, syringes, and lancets, can pose a significant risk of injury and infection if not handled properly. The Occupational Safety and Health Administration (OSHA) requires that all contaminated sharps be disposed of in puncture-resistant sharps containers that are labeled with the biohazard symbol.'
    },
    {
        number: 5,
        description: "Which of the following is the primary purpose of wetting asbestos during asbestos-related work?",
        choices: [
            "A. To increase the ease of cutting and shaping asbestos-containing materials.",
            "B. To reduce the weight of asbestos-containing materials for easier transport.",
            "C. To minimize the potential release of harmful airborne asbestos fibers.",
            "D. To enhance the insulation properties of asbestos-containing materials."
        ],
        answer: "C",
        explain: "The primary purpose of wetting asbestos during asbestos-related work is to reduce the risk of fiber release into the air. Wetting asbestos-containing materials helps to suppress dust and prevent the airborne release of asbestos fibers, which can be hazardous to health when inhaled. This safety measure is crucial in minimizing the exposure of workers and the surrounding environment to asbestos fibers during handling, removal, or any other activities involving asbestos-containing materials."
    },
    {
        number: 6,
        description: "Which of the following is **not** an example of an air-purifying respirator?",
        choices: [
            "A. N95 respirator",
            "B. Powered Air-Purifying Respirator (PAPR)",
            "C. Self-Contained Breathing Apparatus (SCBA)",
            "D. Half-face particulate respirator"
        ],
        answer: "C",
        explain: "A Self-Contained Breathing Apparatus (SCBA) is not an air-purifying respirator. It provides clean air from a compressed air tank, allowing the user to breathe independently of the surrounding atmosphere, which is essential in environments with insufficient oxygen or where the air is highly contaminated. In contrast, air-purifying respirators like N95 masks, PAPRs, and half-face particulate respirators filter contaminants from the air before it is inhaled."
    },
    {
        number: 7,
        description: "Which of the following best describes the concept of Assigned Protection Factor (APF) in respiratory protection?",
        choices: [
            "A. APF refers to the maximum concentration of a contaminant that a respirator can filter.",
            "B. APF indicates the level of protection a respirator provides by reducing the user's exposure to airborne contaminants.",
            "C. APF is the rating that determines how comfortable a respirator is to wear for long periods.",
            "D. APF measures the time it takes for a respirator to become ineffective."
        ],
        answer: "B",
        explain: "Assigned Protection Factor (APF) is a numerical rating that indicates the level of protection a respirator provides by reducing the user's exposure to airborne contaminants. For example, an APF of 10 means that the respirator can reduce the exposure to contaminants by a factor of 10. APF helps determine the appropriate type of respirator needed based on the concentration of hazardous substances in the work environment."
    },
    {
        number: 8,
        description: "A half-mask, air purifying respirator has a Protection Factor of 10. The PEL for asbestos is 0.1 f/cc. What is the Maximum Use Concentration(MUC) for this respirator when it is used for asbestos?",
        choices: [
            "A. 1",
            "B. 10",
            "C. 100",
            "D. 0.1"
        ],
        answer: "A",
        explain: "A protection factor of 10 means for every 10 fibers outside the respirator, 1 fiber can leak in. MUC = Protection Factor * PEL = 1 f/cc. This means if the centration of asbestos is more than 1 f/cc, you need to use a full-face respirator (protection factor of 50) or implement other controls. "
    },
    {
        number: 9,
        description: "What does the blue diamond on the NFPA diamond represent?",
        choices: [
            "A. Flammability",
            "B. Health Hazard",
            "C. Reactivity",
            "D. Special Hazard"
        ],
        answer: "B",
        explain: 'The NFPA diamond is a standardized system of labeling for identifying hazards associated with materials. It consists of a four-color diamond-shaped sign with each quadrant representing a different hazard. The top quadrant is red and indicates the fire hazard, the left is blue and indicates health hazards, the right is yellow and indicates reactivity hazards, and the bottom is white and indicates any specific hazard such as water-reactivity or oxidizers. Each quadrant is assigned a numerical rating indicating the severity of the hazard (0 least dangerous to 4 most danagerous). \n\nNFPA stands for the National Fire Protection Association. It is a nonprofit organization based in the United States that develops and publishes codes and standards related to fire safety and other hazards.'
    },
    {
        number: 10,
        description: "Which type of gloves is not recommended for chemical protection?",
        choices: [
            "A. Latex gloves",
            "B. Nitrile gloves",
            "C. Neoprene gloves",
            "D. PVC gloves"
        ],
        answer: "A",
        explain: "Latex gloves are not suitable for protection against many chemicals, as they are more permeable to a wide range of substances. For chemical protection, it's usually recommended to use gloves made from materials like nitrile, neoprene, or PVC, which offer better resistance to various chemicals."
    },
    {
        number: 11,
        description: "Which type of respirator is recommended for protection against strong acids?",
        choices: [
            "A. N95 respirator",
            "B. P100 respirator",
            "C. Respirator with acid gas cartridge",
            "D. Powered air-purifying respirator (PAPR)"
        ],
        answer: "C",
        explain: "When it comes to protection against strong acids, a respirator with acid gas cartridge is recommended. Acid gas cartridges are specifically designed to filter out and provide protection against acidic vapors, gases, and particulates generated by strong acids."
    },
    {
        number: 12,
        description: "Which of the following respirator filter designations indicates that the filter is not resistant to oil-based particles?",
        choices: [
            "A. N95",
            "B. R95",
            "C. P95",
            "D. A95"
        ],
        answer: "A",
        explain: "R95: The R95 designation indicates that the respirator filter is resistant to oil-based particles to some extent. It can filter out both oil-based and non-oil-based particles, but it may not provide as high of a level of protection against oil-based particles as a P-series filter.\n\nP95: The P95 designation indicates that the respirator filter is oil-proof and can filter out both oil-based and non-oil-based particles effectively. It provides a higher level of protection against oil-based particles compared to N-series or R-series filters.\n\nA95: Does not exist."
    },
    {
        number: 13,
        description: "What are some advantages of using a Powered Air Purifying Respirator (PAPR) over other types of respirators?",
        choices: [
            "A. Higher level of respiratory protection",
            "B. Compatibility with individuals with facial hair or specific facial characteristics",
            "C. Greater range of mobility and freedom of movement",
            "D. All of the above"
        ],
        answer: "D",
        explain: "Powered Air Purifying Respirators (PAPRs) offer a number of advantages over other types of respirators, including:\n\nHigher level of respiratory protection: PAPRs provide a higher level of respiratory protection than other types of respirators because they use a blower to force filtered air into the wearer's face. This means that PAPRs are more effective at filtering out harmful substances, such as dust, mist, and fumes.\n\nGreater comfort: PAPRs are typically more comfortable to wear than other types of respirators because they do not require a tight seal around the face. This is because the blower in a PAPR forces filtered air into the wearer's face, which creates a positive pressure inside the mask. This positive pressure helps to prevent harmful substances from entering the mask.\n\nCompatibility with facial hair: PAPRs are more compatible with facial hair than other types of respirators. This is because PAPRs do not require a tight seal around the face.\n\nGreater range of mobility and freedom of movement: PAPRs offer a greater range of mobility and freedom of movement than other types of respirators. This is because PAPRs do not require a tight seal around the face, which allows the wearer to move their head and neck more freely."
    },
    {
        number: 14,
        description: "Why is it important to consider latex allergies or sensitivities when selecting gloves for hand protection?",
        choices: [
            "A. Latex allergies can cause adverse reactions and potentially serious health consequences.",
            "B. Latex gloves are less effective in providing adequate protection compared to other materials.",
            "C. Latex allergies are extremely rare, so there is no need to be concerned about them.",
            "D. Latex allergies only affect individuals with pre-existing respiratory conditions."
        ],
        answer: "A",
        explain: "Latex allergies are not rare. In fact, according to the American Latex Allergy Association, about 1% of the population in the United States is allergic to latex. Latex allergies can cause a variety of symptoms, including hives, itching, swelling, and difficulty breathing. In severe cases, latex allergies can even be fatal.\n\nWhen selecting gloves for hand protection, it is important to consider the risk of latex allergies or sensitivities. If you or someone you know has a latex allergy, it is important to choose gloves that are made from a non-latex material, such as nitrile or vinyl."
    },
    {
        number: 15,
        description: "Which of the following glove materials is commonly used for cut-resistant gloves?",
        choices: [
            "A. Latex",
            "B. Leather",
            "C. Cotton",
            "D. Kevlar"
        ],
        answer: "D",
        explain: "Kevlar is a commonly used material for cut-resistant gloves. Kevlar is a high-strength synthetic fiber known for its exceptional cut resistance properties. It provides excellent protection against sharp objects and blades, reducing the risk of cuts and lacerations. Kevlar gloves are widely used in industries where workers are exposed to potential hand injuries from sharp tools, glass, or metal edges."
    },
    {
        number: 16,
        description: "What is the purpose of Lock Out Tag Out (LOTO)?",
        choices: [
            "A. To prevent workers from entering an area with high noise levels",
            "B. To prevent workers from accidentally starting machinery during maintenance or servicing",
            "C. To prevent workers from smoking in areas where flammable materials are present",
            "D. To prevent workers from using electrical equipment without proper training and certification"
        ],
        answer: "B",
        explain: 'LOTO procedures are designed to protect workers from the sudden or unexpected release of energy from machines or equipment while they are being serviced or maintained. By locking and tagging the equipment, workers are prevented from accidentally starting the machine and putting themselves and others in danger.'
    },
    {
        number: 17,
        description: "How is radioactive waste typically disposed of?",
        choices: [
            "A. Incineration at specialized waste treatment facilities",
            "B. Burial in a designated landfill for hazardous materials",
            "C. Release into the atmosphere under controlled conditions",
            "D. Encapsulation and burial in a deep geological repository"
        ],
        answer: "D",
        explain: "The typical method of disposing radioactive waste is through encapsulation and burial in a deep geological repository. This involves isolating the waste in specially designed containers, such as metal or concrete drums, and placing them in a deep underground facility, often in stable geological formations like deep rock formations or salt deposits. \n\nThe goal is to ensure long-term containment and isolation of the waste from the environment, minimizing the potential for release and exposure to humans and the environment. The selection of suitable geological repositories involves careful consideration of factors such as geological stability, absence of water flow, and low potential for future human intrusion. This disposal method aims to prevent the migration and release of radioactive materials into the biosphere and protect public health and the environment."
    },
    {
        number: 18,
        description: "Which type of fire extinguisher is specifically designed for use on electrical fires?",
        choices: [
            "A. Water extinguisher",
            "B. Foam extinguisher",
            "C. Carbon dioxide (CO\u2082) extinguisher",
            "D. Class K extinguisher"
        ],
        answer: "C",
        explain: "A carbon dioxide (CO\u2082) extinguisher is specifically designed for use on electrical fires. Electrical fires involve energized electrical equipment, such as electrical panels, motors, or appliances. CO\u2082 extinguishers are effective for these fires because they do not conduct electricity and leave no residue, making them safe to use on live electrical equipment.\n\nCO\u2082 extinguishers work by displacing oxygen and reducing the oxygen concentration around the fire. By reducing the oxygen level, the CO\u2082 extinguisher helps to smother and extinguish the fire. It is important to note that CO\u2082 extinguishers are not suitable for all types of fires, such as fires involving solid combustible materials because CO₂ doesn't cool the burning material, so it won't effectively extinguish fires involving wood, paper, textiles, etc.\n\nWater extinguishers and foam extinguishers are not recommended for electrical fires as they can conduct electricity and may cause further harm. Class K extinguishers are designed for kitchen fires involving cooking oils and fats and are not suitable for electrical fires."
    },
    {
        number: 19,
        description: "Which type of fire extinguisher is specifically designed for use on metal fires?",
        choices: [
            "A. Water extinguisher",
            "B. Foam extinguisher",
            "C. Carbon dioxide (CO\u2082) extinguisher",
            "D. Class D extinguisher"
        ],
        answer: "D",
        explain: "A Class D fire extinguisher is specifically designed for use on metal fires, such as fires involving combustible metals like magnesium, sodium, titanium, and potassium. Class D extinguishers typically contain a dry powder agent, like sodium chloride or graphite, which is effective in absorbing heat and suppressing metal fires. Using water or foam extinguishers on metal fires can be dangerous, as these substances may react with certain metals, potentially exacerbating the fire or causing explosions."
    },
    {
        number: 20,
        description: "Which of the following is a key difference between safety glasses and safety goggles?",
        choices: [
            "A. Safety glasses provide better peripheral vision than safety goggles.",
            "B. Safety goggles offer better protection against chemical splashes than safety glasses.",
            "C. Safety glasses have adjustable straps for a secure fit, while safety goggles do not.",
            "D. Safety goggles are more comfortable to wear for extended periods compared to safety glasses."
        ],
        answer: "B",
        explain: "One key difference between safety glasses and safety goggles is that safety goggles are designed to provide better protection against chemical splashes compared to safety glasses. Safety goggles typically have a tighter and more secure seal around the eyes. Safety goggles include a full seal, prevents liquids from reaching the eyes from various angles.\n\nWhile safety glasses provide eye protection against impact hazards, such as flying particles or debris, they may not provide the same level of protection against chemical splashes. Safety glasses typically have lenses that are held in place by frames, offering coverage to the front and sides of the eyes. However, they may not have the same level of sealing and close-fitting design as safety goggles."
    },
    {
        number: 21,
        description: "Which of the following features is essential for safety shoes in environments with electrical hazards?",
        choices: [
            "A. Slip-resistant soles",
            "B. Steel toe caps",
            "C. Antistatic properties",
            "D. Waterproof materials"
        ],
        answer: "C",
        explain: "In environments with electrical hazards, safety shoes with antistatic properties are essential. Antistatic safety shoes are designed to dissipate static electricity and prevent the buildup of static charges. These shoes help protect individuals working near sensitive electronic equipment or in areas where flammable substances may be present. By reducing the risk of static electricity discharge, antistatic safety shoes help minimize the potential for electric shock or ignition of flammable materials."
    },
    {
        number: 22,
        description: "Which of the following is a key safety practice when managing compressed gas cylinders in the workplace?",
        choices: [
            "A. Storing cylinders in a horizontal position to prevent them from rolling",
            "B. Keeping cylinders near heat sources to maintain gas pressure",
            "C. Ensuring cylinders are secured upright and away from heat sources",
            "D. Using any regulator as long as it fits the cylinder"
        ],
        answer: "C",
        explain: "A key safety practice in managing compressed gas cylinders is to ensure they are secured upright and stored away from heat sources. Storing cylinders upright prevents them from rolling and minimizes the risk of the valve being damaged, which could lead to a dangerous release of gas. Additionally, keeping cylinders away from heat sources is essential to prevent pressure build-up, which could lead to an explosion. Only use the correct regulator designed for the specific type of gas."
    },
    {
        number: 23,
        description: "Which of the following qualitative fit testing substances is known for having a bitter taste?",
        choices: [
            "A. Saccharin",
            "B. Banana oil (Isoamyl acetate)",
            "C. Bitrex",
            "D. Stannic chloride (irritant smoke)"
        ],
        answer: "C",
        explain: "Bitrex creates a distinct bitter taste that indicates break through in the respirator fit. Saccharin, another fit testing substance, has a sweet taste, while banana oil (isoamyl acetate) has a distinctive banana-like odor. Stannic chloride irritant smoke produces a dense, white smoke when exposed to moisture. This smoke acts as a mild irritant, causing coughing or discomfort in the respiratory tract if the respirator does not fit properly."
    },
    {
        number: 24,
        description: "Which of the following statements about quantitative respirator fit testing is **incorrect**?",
        choices: [
            "A. Quantitative fit tests use instruments like the PortaCount to measure the amount of leakage into the respirator.",
            "B. Quantitative fit tests can only be performed in environments with enough background particles.",
            "C. Quantitative fit tests are required for both loose-fitting and tight-fitting respirators",
            "D. Quantitative fit tests provide a numerical value that indicates the effectiveness of the respirator's seal."
        ],
        answer: "C",
        explain: "Quantitative respirator fit testing measures the actual amount of leakage into a respirator by using specialized equipment such as PortaCount, that counts particles inside and outside the mask. This method provides a numerical result, known as a fit factor, which indicates the effectiveness of the respirator's seal. \n\nIt is only required for tight-fitting respirators such as N95, elastomeric half and full facepiece respirators, because these rely on a secure seal against the face to prevent "
    },
    {
        number: 25,
        description: "Why are 0.3 micrometer (µm) particles commonly used as test particles for evaluating the effectiveness of filters in respirators?",
        choices: [
            "A. Because they are the smallest particles found in the air.",
            "B. Because 0.3 µm particles are the most difficult size for filters to capture.",
            "C. Because they represent the average size of airborne contaminants.",
            "D. Because they are large enough to be easily measured by instruments."
        ],
        answer: "B",
        explain: "0.3 micrometer (µm) particles are commonly used as test particles for evaluating the effectiveness of filters in respirators because they are the most difficult size for filters to capture. This size represents the point of 'most penetrating particle size' (MPPS) where particles are neither too large to be caught by inertial impaction nor too small to be trapped by diffusion. By testing with 0.3 µm particles, the performance of the filter is assessed under challenging conditions, ensuring it can capture a wide range of particle sizes effectively."
    },
    {
        number: 26,
        description: "A painter is working with oil-based paints in a poorly ventilated area. Which type of respirator cartridge should they be using?",
        choices: [
            "A. Acid gas",
            "B. N95",
            "C. Organic vapor",
            "D. PAPR"
        ],
        answer: "C",
        explain: "The painter should be using an organic vapor cartridge. This type of cartridge is specifically designed to protect against harmful fumes and vapors from solvents found in oil-based paints. A N95 respirator only protect against particulates and not vapors and they are not resistant to oil. \n\nPowered Air-Purifying Respirators (PAPRs) are primarily designed for filtering out particulates, such as dust, fumes, and mists. However, there are different types of PAPRs with various cartridges and filters that can be used for vapors and gases."
    },
    {
        number: 27,
        description: "Which of the following correctly describes the purpose of the hot zone, warm zone, and cold zone in a hazardous materials (hazmat) cleanup operation?",
        choices: [
            "A. The hot zone is the area where contaminated materials are handled and requires the highest level of protection.",
            "B. The warm zone is where decontamination of personnel and equipment takes place.",
            "C. The cold zone (support zone) is the area designated for administrative support and command, away from contamination risks.",
            "D. All of the above"
        ],
        answer: "D",
        explain: "In hazmat cleanup operations:\n\n- The hot zone is the most hazardous area, where the actual contamination is present, requiring the highest level of personal protective equipment (PPE).\n- The warm zone, or contamination reduction zone, is where decontamination occurs. Personnel and equipment are cleaned in this area to prevent the spread of hazardous materials to clean areas.\n- The cold zone, or support zone, is a safe area away from the contaminated zones. It serves as the command and support area, with minimal PPE requirements."
    },
    {
        number: 28,
        description: "What does 'fully encapsulating' mean in the context of Hazmat protective suits?",
        choices: [
            "A. The suit is resistant to cuts and abrasions",
            "B. The suit is designed to be gas-tight and protect against unknown hazardous substances",
            "C. The suit provides flame resistance",
            "D. The suit allows for ventilation to prevent overheating"
        ],
        answer: "B",
        explain: "'Fully encapsulating' in the context of Hazmat protective suits means that the suit is designed to be gas-tight, offering complete protection from hazardous substances, including gases, vapors, and liquids. This level of protection is crucial in situations where the nature of the hazard is unknown, ensuring the wearer is shielded from potential exposure to dangerous chemicals, biological agents, or radiological materials."
    },
    {
        number: 29,
        description: "Which of the following best describes the composition of the air supplied in a Self-Contained Breathing Apparatus (SCBA) used in Hazmat situations?",
        choices: [
            "A. Pure oxygen",
            "B. Compressed ambient air, similar to the natural atmosphere",
            "C. A mix of oxygen and nitrogen",
            "D. Filtered air from the immediate environment"
        ],
        answer: "B",
        explain: "The air supplied in a Self-Contained Breathing Apparatus (SCBA) is typically compressed ambient air, which closely resembles the composition of natural atmospheric air (approximately 21% oxygen and 78% nitrogen, with trace amounts of other gases). This ensures that the wearer has a safe and breathable air supply in environments where the atmosphere may be hazardous or oxygen-deficient. SCBAs do not use pure oxygen or air filtered from the immediate environment."
    },
    {
        number: 30,
        description: "Which of the following best describes the purpose of Failure Mode & Effects Analysis (FMEA)?",
        choices: [
            "A. To identify potential failure modes and their effects on system performance",
            "B. To determine the root cause of a failure after it has occurred",
            "C. To determine the optimal maintenance schedule for a system",
            "D. To assess the financial impact of a system failure"
        ],
        answer: "A",
        explain: 'FMEA is a proactive risk assessment tool that helps to identify potential failure modes and their effects on a system or process before they occur, so that appropriate measures can be taken to prevent or mitigate them. Option B is incorrect because FMEA is not a reactive tool that is used after a failure has occurred, but rather a proactive tool used to prevent failures.'
    },
    {
        number: 31,
        description: "The primary purpose of constructing a fault tree in Fault Tree Analysis (FTA) is to:",
        choices: [
            "A. Determine the sequence of events leading to an accident.",
            "B. Quantify the probability of a hazardous event occurring.",
            "C. Identify the contributing causes and failures leading to a specific event.",
            "D. Assess the potential consequences of a hazardous event."
        ],
        answer: "C",
        explain: 'Fault Tree Analysis (FTA) is a top-down, graphical, and logical approach to identify the sequence of events that lead to an undesired event or top event. It is a systematic way of identifying the root causes of an accident or system failure.\n\nThe top event is the undesired event that is being analyzed. The lower events are the events that could contribute to the top event. The events are connected by logic gates, which represent the logical relationships between the events.'
    },
    {
        number: 32,
        description: "Which of the following is an essential component of an effective OEHS program?",
        choices: ["A. Worker participation", "B. Cost minimization", "C. Minimal regulatory compliance", "D. Focus on productivity over safety"],
        answer: "A",
        explain: "An effective OEHS program must have the active participation of workers. Workers are the ones who are most familiar with the hazards in their workplace, and they are the ones who can make the most significant changes to reduce those hazards. When workers are involved in the development and implementation of an OEHS program, they are more likely to follow the safety procedures and to report hazards."
    },
    {
        number: 33,
        description: "Which of the following is a critical safety practice when handling pyrophoric materials, such as white phosphorus, in the workplace?",
        choices: [
            "A. Storing materials in a well-ventilated area with high humidity",
            "B. Handling materials in a dry and air-free environment, such as under an inert gas",
            "C. Storing materials in clear, glass containers for easy monitoring",
            "D. Handling materials with bare hands to detect heat changes quickly"
        ],
        answer: "B",
        explain: "Pyrophoric materials, like white phosphorus, are substances that can spontaneously ignite upon exposure to air. A critical safety practice when handling these materials is to work with them in a dry, air-free environment, typically under an inert gas such as nitrogen or argon. This prevents the materials from coming into contact with oxygen, which could cause them to ignite. Pyrophoric materials should never be stored in clear glass containers or handled with bare hands, as both practices could lead to hazardous situations."
    },
    {
        number: 34,
        description: "Which of the following statements accurately describes EPA Universal Waste?",
        choices: [
            "A. EPA Universal Waste refers to common household hazardous waste that is managed separately from other types of waste.",
            "B. EPA Universal Waste includes radioactive materials used in medical and industrial applications.",
            "C. EPA Universal Waste consists of certain widely generated hazardous wastes, such as batteries, lamps, and pesticides, that are subject to streamlined regulations for collection and disposal.",
            "D. EPA Universal Waste pertains to the management of solid waste in landfills to prevent environmental contamination."
        ],
        answer: "C",
        explain: "EPA Universal Waste refers to a specific category of hazardous wastes that are commonly generated by a wide range of entities, including households, businesses, and institutions. These wastes, such as batteries, fluorescent lamps, pesticides, and certain electronic devices, are considered universal wastes because they are widespread and pose environmental risks if not managed properly.\n\nThe EPA has established streamlined regulations for the management of universal wastes to encourage their proper collection, recycling, and disposal. These regulations simplify the requirements for generators, transporters, and facilities that handle universal wastes, making it easier to manage and dispose of these wastes in an environmentally sound manner.\n\nThe goal of the EPA Universal Waste program is to promote responsible management of these commonly generated hazardous wastes and reduce the likelihood of improper disposal, which could lead to environmental contamination. By implementing streamlined regulations, the EPA aims to facilitate the safe and efficient management of universal wastes while minimizing the regulatory burden on businesses and other waste generators."
    },
    {
        number: 35,
        description: "What is the primary purpose of an emergency response plan in a workplace?",
        choices: [
            "A. To ensure compliance with safety regulations.",
            "B. To provide a detailed inventory of workplace equipment.",
            "C. To establish procedures for protecting workers and minimizing harm during emergencies.",
            "D. To outline daily operational tasks for employees."
        ],
        answer: "C",
        explain: "The primary purpose of an emergency response plan in a workplace is to establish procedures for protecting workers and minimizing harm during emergencies. This includes preparing for various scenarios such as fires, chemical spills, natural disasters, or other emergencies, and ensuring that all employees know what actions to take to ensure their safety and the safety of others."
    },
    {
        number: 36,
        description: "What is the range of AQI values?",
        choices: [
            "A. 0 to 500",
            "B. 0 to 100",
            "C. 0 to 200",
            "D. 0 to 300"
        ],
        answer: "A",
        explain: "Air Quality Index (AQI) is a measure of how polluted the air is. It is calculated by the Environmental Protection Agency (EPA) and is based on six common pollutants: ground-level ozone, particle pollution, carbon monoxide, sulfur dioxide, nitrogen dioxide, and lead.\n\nThe AQI ranges from 0 to 500. AQI values below 100 are considered good, values between 101 and 150 are considered moderate, values between 151 and 200 are considered unhealthy for sensitive groups, values between 201 and 300 are considered unhealthy, values between 301 and 400 are considered very unhealthy, and values above 400 are considered hazardous.\n\nThe EPA provides a color-coded scale to help people understand the AQI. Green is used for AQI values below 50, yellow is used for AQI values between 51 and 100, orange is used for AQI values between 101 and 150, red is used for AQI values between 151 and 200, purple is used for AQI values above 200, and maroon is used for AQI values above 300."
    },
    {
        number: 37,
        description: "Which of the following is NOT a benefit of using a Gantt chart?",
        choices: [
            "A. It helps to visualize the project schedule.",
            "B. It helps to identify potential scheduling conflicts.",
            "C. It helps to track project progress.",
            "D. It helps to communicate the occupational hazards in the projects"
        ],
        answer: "D",
        explain: "Gantt charts are a helpful tool for visualizing the project schedule, identifying potential scheduling conflicts, and tracking project progress. However, they are not specifically designed to communicate occupational hazards in projects."
    },
    {
        number: 38,
        description: "Which of the following is a common mistake made when resolving conflict? ",
        choices: [
            "A. Assuming that the other person is wrong.",
            "B. Trying to win at all costs",
            "C. Personalizing the conflict.",
            "D. All of the above"
        ],
        answer: "D",
        explain: "All of the above are common mistakes made when resolving conflict. Assuming that the other person is wrong can lead to defensiveness and a lack of cooperation. Trying to win at all costs can damage relationships and make it difficult to resolve the conflict in the long run. Personalizing the conflict can make it difficult to see the other person's point of view and can lead to emotional outbursts."
    },
    {
        number: 39,
        description: "Which of the following is a good way to encourage employees to participate in safety training?",
        choices: [
            "A. By making the training mandatory",
            "B. By providing employees with incentives for participating in training",
            "C. By creating a positive and supportive training environment ",
            "D. All of the above"
        ],
        answer: "D",
        explain: "Making the training mandatory can help to ensure that all employees receive the training they need. Providing employees with incentives for participating in training can help to make the training more appealing. Creating a positive and supportive training environment can help to make the training more enjoyable and less stressful."
    },
    {
        number: 40,
        description: "Which of the following is an essential component of an effective IIPP (Injury and Illness Prevention Program)?",
        choices: [
            "A. Employee break schedules",
            "B. Performance appraisal procedures",
            "C. Employee assistance programs",
            "D. Management commitment and employee involvement"
        ],
        answer: "D",
        explain: "Management commitment and employee involvement are essential components of an effective IIPP. Management commitment ensures that workplace safety is a top priority, with resources allocated and policies established. \n\nEmployee involvement encourages active participation, leveraging their knowledge and fostering a sense of ownership in maintaining a safe work environment. Together, they create a collaborative culture that promotes accountability, teamwork, and continuous improvement in safety practices."
    },
    {
        number: 41,
        description: "Which of the following statements is true about experience modification rate (EMR)?",
        choices: [
            "A. EMR is calculated based on the total number of employees in a company.",
            "B. A low EMR indicates a high rate of workplace injuries and illnesses.",
            "C. EMR is used to adjust workers' compensation insurance premiums based on a company's past injury and illness history.",
            "D. EMR is only used by large companies with more than 100 employees."
        ],
        answer: "C",
        explain: "EMR is a factor used to adjust workers' compensation insurance premiums based on a company's past injury and illness history, and a lower EMR indicates a better safety record and can result in lower insurance premiums. It is calculated based on a formula that compares a company's actual losses to expected losses in its industry. EMR is used by all employers, regardless of their size.\n\n An EMR of less than 1.0 means that the company has a better-than-average safety performance, while an EMR of greater than 1.0 means that the company has a worse-than-average safety performance."
    },
    {
        number: 42,
        description: "According to Douglas McGregor's management theories, which of the following describes Theory X managers?",
        choices: [
            "A. They believe employees are self-motivated and require little supervision.",
            "B. They believe employees dislike work and need to be coerced and controlled.",
            "C. They believe in empowering employees and delegating authority.",
            "D. They believe in using positive reinforcement to motivate employees."
        ],
        answer: "B",
        explain: 'Douglas McGregor proposed two management theories in his book "The Human Side of Enterprise" published in 1960. \n\nTheory X assumes that employees are inherently lazy and require strict supervision and punishment to be motivated to work. \n\nTheory Y assumes that employees are inherently motivated and creative, and that management should provide opportunities for them to develop and use their potential. \n\nMcGregor later added a third theory, Theory Z, which focuses on employee involvement and teamwork. Theory Z combines aspects of both Theory X and Theory Y, but emphasizes long-term employment, collective decision-making, and employee loyalty to the organization.'
    },
    {
        number: 43,
        description: "What information should be included on a safety data sheet (SDS)?",
        choices: [
            "A. Physical and chemical properties of the hazardous substance.",
            "B. Emergency response procedures in case of spills or exposures.",
            "C. Health hazards associated with the substance and precautionary measures.",
            "D. All of the above."
        ],
        answer: "D",
        explain: "Safety Data Sheets (SDS) are comprehensive documents that provide detailed information about hazardous substances. They typically include information on the physical and chemical properties of the substance, emergency response procedures in case of spills or exposures, as well as health hazards associated with the substance and precautionary measures to ensure safe handling, storage, and use. The SDS serves as a valuable resource for employees, providing them with critical information to understand and mitigate the risks associated with hazardous substances in the workplace."
    },
    {
        number: 44,
        description: "Which of the following is an example of a safety leading indicator in a workplace?",
        choices: [
            "A. Number of lost-time injuries",
            "B. Frequency of safety meetings",
            "C. Total recordable incident rate (TRIR)",
            "D. Severity of first aid cases"
        ],
        answer: "B",
        explain: "The frequency of safety meetings is a leading indicator because it shows the level of commitment and engagement of the organization in promoting a safe work environment. More frequent safety meetings indicate a higher level of attention to safety and a proactive approach to identifying and addressing potential risks. It helps to foster a culture of continuous improvement and empowers employees to actively participate in maintaining a safe workplace.\n\nIn contrast, the other options listed in the question are lagging indicators. The number of lost-time injuries (Option A) and severity of first aid cases (Option D) reflect past incidents and their associated injuries. The total recordable incident rate (TRIR) (Option C) is a measure of all injuries and illnesses that result in medical treatment, restricted work, or job transfer, which is also a retrospective indicator."
    },
    {
        number: 45,
        description: "Which of the following best describes the purpose of the PDCA (Plan-Do-Check-Act) cycle in continuous improvement?",
        choices: [
            "A. It is a linear process used to complete one-time projects.",
            "B. It is a cycle designed to implement changes, monitor their effectiveness, and ensure ongoing improvements.",
            "C. It is a financial planning tool used for budget management.",
            "D. It is a communication framework for resolving workplace conflicts."
        ],
        answer: "B",
        explain: "The PDCA (Plan-Do-Check-Act) cycle is a continuous improvement process used to implement changes, monitor their effectiveness, and ensure ongoing improvements. It involves planning a change, implementing the change (Do), checking the results, and acting on what has been learned to standardize the improvement or adjust as necessary. This cycle promotes iterative progress and can be applied to various processes and systems to enhance quality and efficiency."
    },
    {
        number: 46,
        description: "Which of the following is the most common method for measuring the severity of a work-related injury?",
        choices: [
            "A. Number of lost workdays",
            "B. Days away from work",
            "C. Medical costs",
            "D. Lost productivity"
        ],
        answer: "A",
        explain: "This is the most straightforward way to measure the severity of an injury, as it simply counts the number of days that an employee is unable to work due to the injury."
    },
    {
        number: 47,
        description: "What were some of the factors that contributed to the high casualty rate in the Triangle Shirtwaist Factory fire?",
        choices: [
            "A. Inadequate fire safety measures and locked exit doors.",
            "B. Lack of proper ventilation and inadequate lighting.",
            "C. Insufficient wages and long working hours.",
            "D. Excessive heat and poor building construction."
        ],
        answer: "A",
        explain: "The Triangle Shirtwaist Factory fire occurred on March 25, 1911, in New York City. The factory was located on the eighth, ninth, and tenth floors of the Asch Building in New York City. At the time of the fire, the building had inadequate fire safety measures in place. There were no sprinkler systems installed, which could have helped contain or extinguish the fire. Additionally, there were insufficient fire extinguishers available to fight the flames.\n\nMoreover, the exit doors in the factory were locked or blocked, allegedly to prevent theft and unauthorized breaks. This meant that when the fire broke out, many workers were unable to escape through the locked exit doors, trapping them inside the burning building. Furthermore, the one working fire escape on the side of the building collapsed under the weight of people trying to escape, further limiting the means of egress.\n\nMany workers had to resort to desperate measures, such as jumping out of windows, to try to escape the fire."
    },
    {
        number: 48,
        description: "What were some of the contributing factors to the Bhopal disaster?",
        choices: [
            "A. Inadequate safety systems and poor maintenance practices",
            "B. Natural disaster and seismic activity",
            "C. Sabotage and terrorist attack",
            "D. Inefficient waste disposal practices"
        ],
        answer: "A",
        explain: "One of the most famous occupational health incidents in the world is the Bhopal disaster, which occurred on December 2-3, 1984, in Bhopal, India. The incident involved a massive chemical leak from a pesticide plant owned by the Union Carbide Corporation (UCC), now a subsidiary of Dow Chemical Company.\n\nDuring the night of the tragedy, a toxic gas called methyl isocyanate (MIC) was released into the atmosphere from a storage tank at the Union Carbide India Limited (UCIL) pesticide plant. The gas, which is highly toxic and reacts violently with water, spread over the surrounding residential areas, exposing thousands of people to its harmful effects.\n\nThe immediate consequences of the disaster were devastating. Thousands of people died within the first few days, and estimates of the total death toll range from several thousand to tens of thousands. The gas leak also caused injuries to countless others, leading to long-term health complications for many survivors.\n\nThe Bhopal disaster shed light on numerous issues related to occupational health and safety, industrial negligence, and corporate responsibility. Investigations into the incident revealed a range of contributing factors, including inadequate safety systems, poor maintenance practices, and insufficient training of plant workers. Furthermore, the plant lacked proper emergency preparedness measures, such as effective gas leak alarms and evacuation plans."
    },
    {
        number: 49,
        description: "Which of the following is NOT a step in the root cause analysis process?",
        choices: [
            "A. Identify the problem or incident",
            "B. Develop and implement corrective actions",
            "C. Determine the root causes of the problem or incident",
            "D. Assign blame and disciplinary action"
        ],
        answer: "D",
        explain: 'Assigning blame and disciplinary action is not a step in the root cause analysis process. Root cause analysis is a systematic process used to identify the underlying factors that contribute to problems or incidents, with the goal of addressing those factors to prevent future occurrences. The focus is on understanding the systemic issues rather than placing blame on individuals. The correct steps in the root cause analysis process typically include identifying the problem or incident, determining the root causes, and developing and implementing corrective actions to prevent similar issues in the future.'
    },
    {
        number: 50,
        description: "Which of the following is an input required for conducting a Failure Mode and Effects Analysis (FMEA)?",
        choices: [
            "A. Process flowchart",
            "B. Customer feedback",
            "C. Marketing strategy",
            "D. Quality control checklist"
        ],
        answer: "A",
        explain: "The correct answer is A. Process flowchart. In Failure Mode and Effects Analysis (FMEA), a process flowchart is an essential input that provides a visual representation of the steps and components involved in a process or system. It helps in identifying potential failure modes and understanding the sequence of events, inputs, and outputs within the process. The process flowchart serves as a foundation for conducting a thorough analysis and assessment of potential failures and their effects."
    },
    {
        number: 51,
        description: "Which of the following is a critical aspect of safety management when working with hydrofluoric acid (HF)?",
        choices: [
            "A. Ensuring the availability of calcium gluconate gel in case of skin exposure.",
            "B. Using standard latex gloves for hand protection.",
            "C. Storing hydrofluoric acid in plastic containers at room temperature.",
            "D. Ventilating the area with general exhaust ventilation."
        ],
        answer: "A",
        explain: "A critical aspect of safety management when working with hydrofluoric acid (HF) is ensuring the availability of calcium gluconate gel in case of skin exposure. HF is highly corrosive and can cause severe tissue damage, including deep burns and systemic toxicity. Calcium gluconate gel is used as a first aid treatment to neutralize the acid and prevent further damage. Standard latex gloves are not sufficient for protection; specialized gloves are required. HF should be stored in appropriate containers, and local exhaust ventilation, rather than general ventilation, is needed to control exposure."
    },
    {
        number: 52,
        description: "Which of the following is a key requirement for eyewash stations and emergency showers in workplaces handling hazardous chemicals?",
        choices: [
            "A. They must be located within 100 feet of hazardous work areas.",
            "B. They should be capable of delivering tepid water continuously for at least 15 minutes.",
            "C. They can be used for general cleaning purposes in addition to emergency use.",
            "D. They should be activated only by trained personnel."
        ],
        answer: "B",
        explain: "A key requirement for eyewash stations and emergency showers is that they must be capable of delivering tepid water (60-100°F or 16-38°C) continuously for at least 15 minutes to effectively flush out hazardous substances from the body or eyes. This is crucial for minimizing injury and preventing further harm following exposure to hazardous chemicals. Eyewash stations and emergency showers should be easily accessible, located within 10 seconds of hazardous work areas, and used exclusively for emergency purposes. They must be activated immediately by any worker in need, not just trained personnel."
    },
    {
        number: 53,
        description: "A CIH is asked to conduct an industrial hygiene assessment at a new manufacturing facility. The company's management expresses a strong preference for the results to show minimal health risks. How should the CIH proceed to maintain ethical integrity in this situation?",
        choices: [
            "A. Conduct the assessment with a focus on finding only minor health risks to meet the company's expectations.",
            "B. Present the findings honestly, regardless of the company's preferences, and explain the potential consequences of ignoring any significant risks.",
            "C. Refuse to conduct the assessment if the company insists on manipulating the results.",
            "D. Conduct the assessment but only report the findings to the company's management, not to the workers."
        ],
        answer: "B",
        explain: "A CIH's primary ethical obligation is to protect the health and safety of workers. Even if a company expresses a preference for favorable results, the CIH must conduct the assessment objectively and report the findings honestly. The CIH should explain the potential consequences of ignoring any significant health risks to the company's management, emphasizing the importance of taking corrective actions to protect workers' well-being."
    },
    {
        number: 54,
        description: "You are an outside consultant conducting a noise assessment at a manufacturing facility. How should you respond if a worker ask you the results of your assessment?",
        choices: [
            "A. \"It's pretty loud.\"",
            "B. \"I'll send you the results of the assessment.\"",
            "C. \"The noise levels are being measured and will be reported to management.\"",
            "D. \"I can't provide any information about the noise levels.\""
        ],
        answer: "C",
        explain: "As an outside consultant, you should maintain confidentiality with the company. While you can't provide specific details about the noise levels to individual employees, you can reassure them that the findings will be reported to management, who will take appropriate actions to address any concerns."
    },
    {
        number: 55,
        description: "You are asked to conduct an asbestos survey in a building where you have no prior experience with asbestos sampling techniques. Which of the following is the best course of action?",
        choices: [
            "A. Attempt the survey using your general knowledge of industrial hygiene.",
            "B. Learn how to do the survey online",
            "C. Decline the survey, citing lack of experience.",
            "D. Perform the survey but inform the client that the results may not be accurate."
        ],
        answer: "C",
        explain: "As a professional, it is important to acknowledge your limitations and avoid performing tasks that you are not qualified to do. Conducting an asbestos survey requires specialized knowledge and training. By declining the survey, you are protecting your professional reputation and ensuring the accuracy of the results."
    },
    {
        number: 56,
        description: "Which of the following scenarios represents the highest risk?",
        choices: [
            "A. A low-probability event with catastrophic harm.",
            "B. A high-probability event with minor harm.",
            "C. A medium-probability event with moderate harm.",
            "D. A high-probability event with catastrophic harm."
        ],
        answer: "D",
        explain: "Risk is determined by multiplying the probability of an event occurring by the magnitude of harm it could cause. A high-probability event with catastrophic harm represents the highest risk."
    },
    {
        number: 57,
        description: "Which of the following statements about a Similar Exposure Group (SEG) is **incorrect**?",
        choices: [
            "A. A SEG consists of workers who have similar exposures to hazards during their work tasks.",
            "B. SEGs are used to assess the effectiveness of control measures for specific hazards.",
            "C. SEGs can include workers with widely different job tasks as long as they are in the same department.",
            "D. Grouping workers into SEGs allows for more efficient and accurate exposure monitoring."
        ],
        answer: "C",
        explain: "SEGs are defined by workers who perform similar tasks and are exposed to similar hazards under similar conditions, regardless of their department. This grouping helps in accurately assessing exposures and implementing control measures. Grouping workers with different tasks and exposures would lead to inaccurate exposure assessments."
    },
    {
        number: 58,
        description: "What is the primary purpose of the Globally Harmonized System of Classification and Labeling of Chemicals (GHS)?",
        choices: [
            "A. To regulate the production of hazardous chemicals worldwide.",
            "B. To standardize the classification and labeling of chemicals to improve safety and communication across countries.",
            "C. To create a universal standard for chemical manufacturing processes.",
            "D. To establish guidelines for the transportation of chemicals."
        ],
        answer: "B",
        explain: "The primary purpose of the Globally Harmonized System of Classification and Labeling of Chemicals (GHS) is to standardize the classification and labeling of chemicals to improve safety and communication across countries. GHS provides a consistent approach to identifying chemical hazards and communicating those hazards through labels and safety data sheets (SDS). This helps ensure that workers and consumers worldwide can understand the risks associated with chemicals, regardless of where they are used or produced."
    },
    {
        number: 59,
        description: "Which of the following ISO standards is correctly matched with its primary focus?",
        choices: [
            "A. ISO 9001 - Environmental Management Systems",
            "B. ISO 14001 - Quality Management Systems",
            "C. ISO 45001 - Occupational Health and Safety Management Systems",
            "D. ISO 27001 - Food Safety Management Systems"
        ],
        answer: "C",
        explain: "ISO 45001 is the correct standard for Occupational Health and Safety Management Systems. It provides a framework to improve employee safety, reduce workplace risks, and create better, safer working conditions. ISO 9001 is related to Quality Management Systems, ISO 14001 focuses on Environmental Management Systems, and ISO 27001 is for Information Security Management Systems.\n\nThe International Organization for Standardization is an independent, non-governmental, international standard development organization composed of representatives from the national standards organizations of member countries."
    },
    {
        number: 60,
        description: "What is the primary purpose of the ACGIH Biological Exposure Indices (BEIs)?",
        choices: [
            "A. To establish maximum permissible levels of chemical exposure in the air.",
            "B. To provide guidelines for acceptable levels of chemical substances in biological samples like blood or urine.",
            "C. To define safety procedures for handling hazardous chemicals.",
            "D. To set environmental limits for chemical emissions from industrial facilities."
        ],
        answer: "B",
        explain: "The primary purpose of the ACGIH Biological Exposure Indices (BEIs) is to provide guidelines for acceptable levels of chemical substances in biological samples, such as blood or urine. BEIs are reference values intended to assess exposure by measuring the concentration of chemicals, or their metabolites, in biological specimens. These indices help in evaluating whether a worker has been exposed to a chemical at a level that is within safe limits, complementing air exposure limits like TLVs (Threshold Limit Values).\n\nThe American Conference of Governmental Industrial Hygienists (ACGIH) is a professional association of industrial hygienists and practitioners of related professions, with headquarters in Cincinnati, Ohio. One of its goals is to advance worker protection by providing timely, objective, scientific information to occupational and environmental health professionals."
    },
    {
        number: 61,
        description: "Which of the following statements is **incorrect** regarding the Time-Weighted Average (TWA), Ceiling limit, and Short-Term Exposure Limit (STEL) as recommended by ACGIH?",
        choices: [
            "A. TWA represents the average concentration of a contaminant over an 8-hour workday and should not be exceeded during a 40-hour workweek.",
            "B. The Ceiling limit is the concentration of a contaminant that should not be exceeded at any time during the workday.",
            "C. STEL is a TWA concentration over 15 minutes and can be exceeded if the 8-hour TWA is within acceptable limits.",
            "D. TWA, Ceiling value, and STEL are used to manage exposure to hazardous substances in the workplace."
        ],
        answer: "C",
        explain: "C incorrect because the STEL is a short-term exposure limit that ACGIH recommends should not be exceeded, even if the 8-hour TWA is within standards. STEL is used to protect workers from the health effects of short-term exposure to high levels of hazardous substances."
    },
    {
        number: 62,
        description: "Which of the following statements correctly describes the guidelines for exposures above the TLV–TWA up to the TLV–STEL (15-min TWA) according to ACGIH?",
        choices: [
            "A. Exposures above the TLV–TWA up to the TLV–STEL can last up to 30 minutes and should occur no more than six times per day.",
            "B. Exposures above the TLV–TWA up to the TLV–STEL should be less than 15 minutes, should occur no more than four times per day, and there should be at least 60 minutes between successive exposures in this range.",
            "C. Exposures above the TLV–TWA up to the TLV–STEL can occur continuously as long as the 8-hour TWA is not exceeded.",
            "D. Exposures above the TLV–TWA up to the TLV–STEL are only allowed if no ceiling value is specified for the substance."
        ],
        answer: "B",
        explain: "Exposures above the TLV–TWA up to the TLV–STEL should be less than 15 minutes, should occur no more than four times per day, and there should be at least 60 minutes between successive exposures in this range. These guidelines are set by ACGIH to ensure that workers are not overexposed to hazardous substances, particularly during short-term exposure periods that could lead to acute health effects."
    },
    {
        number: 63,
        description: "Which of the following statements is true regarding default short-term exposure limits for TLV–TWAs that do not have a specified TLV–STEL?",
        choices: [
            "A. Workers' exposure levels may exceed 3 times the TLV–TWA value for no more than 30 minutes at a time, up to 6 times per day.",
            "B. Exposure levels may exceed 5 times the TLV–TWA level when measured as a 15-minute TWA, provided the 8-hour TWA is not exceeded.",
            "C. Transient increases in exposure may exceed 3 times the TLV–TWA level for no more than 15 minutes at a time, on no more than 4 occasions spaced 1 hour apart, and should never exceed 5 times the TLV–TWA level.",
            "D. The 8-hour TWA can be exceeded as long as short-term exposure limits are not surpassed."
        ],
        answer: "C",
        explain: "Transient increases in workers’ exposure levels may exceed 3 times the value of the TLV–TWA level for no more than 15 minutes at a time, on no more than 4 occasions spaced 1 hour apart, and under no circumstances should they exceed 5 times the value of the TLV–TWA level when measured as a 15-minute TWA. Additionally, the 8-hour TWA should not be exceeded for an 8-hour work period. These guidelines ensure that short-term exposures do not pose an undue risk to workers, even in the absence of a specified TLV–STEL."
    },
    {
        number: 64,
        description: "What is the 8-hour time-weighted average (TWA) exposure when the worker is exposed to a hazardous chemcials for 4 hours at 1 ppm, 2 hours at 4 ppm, and 2 hours at 5 ppm?",
        choices: [
            "A. 2.50",
            "B. 3.00",
            "C. 2.75",
            "D. 3.50"
        ],
        answer: "C",
        explain: "To calculate the 8-hour TWA, you multiply each concentration by the number of hours it was sustained, sum these values, and then divide by the total number of hours (8 hours in this case). The calculation is as follows: \n\nTWA = [(1 ppm × 4 hours) + (4 ppm × 2 hours) + (5 ppm × 2 hours)] / 8 hours = [4 + 8 + 10] / 8 = 22 / 8 = 2.75 ppm."
    },
    {
        number: 65,
        description: "A worker is exposed to the following chemicals during their shift: Chemical A, at 20 ppm (TWA = 40 ppm);  Chemical B, at 10 ppm (TWA = 50 ppm); Chemical C, at 30 ppm (TWA = 90 ppm). Has the Threshold Limit Value (TLV) been exceeded for this worker? Assume A, B, C are similiar chemicals.",
        choices: [
            "A. Yes, the TLV has been exceeded",
            "B. No, the TLV has not been exceeded",
            "C. More information is needed to determine",
            "D. It depends on the cumulative exposure over the entire shift"
        ],
        answer: "A",
        explain: "To determine whether the total TLV has been exceeded, use equation 13 under General Science. \n\nChemical A: 20 ppm / 40 ppm = 0.5. Chemical B: 10 ppm / 50 ppm = 0.2. Chemical C: 30 ppm / 90 ppm = 0.33.\n\nTotal equivalent exposure = 0.5 + 0.2 + 0.33 = 1.03. Since the total equivalent exposure is more than 1, the TLV has been exceeded."
    },
    {
        number: 66,
        description: "What is the Threshold Limit Value (TLV) for a mixed liquid composed of 40% Chemical A (TLV = 100 mg/m³) and 60% Chemical B (TLV = 20 mg/m³)?",
        choices: [
            "A. 29 mg/m³",
            "B. 40 mg/m³",
            "C. 50 mg/m³",
            "D. 60 mg/m³"
        ],
        answer: "A",
        explain: "Use the 14th Equeation under General Science to calculate TLV for a mixture: \n\n1/TLV_mixture = (fraction_A/TLV_A) + (fraction_B/TLV_B)\n\nFor this mixture: \n\n1/TLV_mixture = 0.4/100 + 0.6/20 = 0.034\n\nTLV_mixture = 1/0.034 ≈ 29.41 mg/m³."
    },
    {
        number: 67,
        description: "A chemical has a Threshold Limit Value (TLV) of 50 mg/m³ for an 8-hour workday. If a worker's shift is extended to 10 hours, what would be the adjusted TLV?",
        choices: [
            "A. 40 mg/m³",
            "B. 35 mg/m³",
            "C. 48 mg/m³",
            "D. 52 mg/m³"
        ],
        answer: "B",
        explain: "Use the 15th Equation under General Science to calculate the reduction factor. \n\nRF = 0.8*(24-10)/16 = 0.7. \n\nTLV_adjusted = 50 mg/m³ × RF = 35 mg/m³.\n\n Regular TLV is based on 8-hour work day and 40 hours a week."
    },
    {
        number: 68,
        description: "Calculate the reduction factor for a 50-hour workweek?",
        choices: [
            "A. 0.74",
            "B. 0.7",
            "C. 0.6",
            "D. 0.9"
        ],
        answer: "A",
        explain: "Use the 16th Equation under General Science to calculate reduction factor when you have a workweek more than 40 hours. \n\n RF = (40/50) * ((168-50)/128) = 0.74."
    },
    {
        number: 69,
        description: "What is the primary purpose of monitoring stack emissions in an industrial setting?",
        choices: [
            "A. To measure the efficiency of production processes",
            "B. To ensure compliance with environmental regulations",
            "C. To calculate the energy consumption of machinery",
            "D. To monitor indoor air quality for worker safety"
        ],
        answer: "B",
        explain: "Stack emission is basically gases released into the air from boiler stack, chimneys or DG set stack etc. from various industries after incineration process i.e. method to remove or burn the harmful gases before releasing to environment.\n\nThe primary purpose of monitoring stack emissions is to ensure that industrial facilities comply with environmental regulations that limit the amount and types of pollutants released into the atmosphere. This helps to protect public health and the environment by minimizing air pollution."
    },
    {
        number: 70,
        description: "Which of the following statements correctly describes the consequences of performing isokinetic sampling with a velocity that is either higher or lower than the actual gas stream velocity?",
        choices: [
            "A. If the sampling velocity is higher than the gas stream velocity, larger particles may be underrepresented in the sample.",
            "B. If the sampling velocity is lower than the gas stream velocity, larger particles may be overrepresented in the sample.",
            "C. Both A and B are correct.",
            "D. Neither A nor B is correct; velocity differences do not affect particle representation in isokinetic sampling."
        ],
        answer: "C",
        explain: "In isokinetic sampling, the sampling velocity must match the gas stream velocity to ensure that the sampled particles are representative of the actual particle distribution in the gas stream. If the sampling velocity is higher, larger particles may not enter the sampling probe effectively, leading to underrepresentation (resulting concentration less than actual). Conversely, if the sampling velocity is lower, larger particles may enter the probe more easily, leading to overrepresentation (resulting concentration more than actuall because largers particles are heavier)."
    },
    {
        number: 71,
        description: "Which of the following statements best describes the key difference between occupational exposure and community exposure to hazardous substances?",
        choices: [
            "A. Occupational exposure typically occurs over a longer period of time than community exposure.",
            "B. Community exposure usually involves higher concentrations of hazardous substances than occupational exposure.",
            "C. Occupational exposure is often more controlled and regulated, with specific limits and protective measures, while community exposure can vary widely and may affect sensitive populations such as children and the elderly.",
            "D. Community exposure is more likely to occur indoors, whereas occupational exposure is generally confined to outdoor environments."
        ],
        answer: "C",
        explain: "Occupational exposure is often more controlled and regulated, with specific limits and protective measures in place. In contrast, community exposure can vary widely and may involve vulnerable populations such as children, the elderly, or individuals with pre-existing health conditions. Occupational exposures typically occur in work settings, whereas community exposures can happen in a variety of environments, including homes, schools, and public spaces."
    },
    {
        number: 72,
        description: "Which of the following statements correctly describes the principle of electrostatic precipitation used in air filters?",
        choices: [
            "A. Electrostatic precipitation uses high-pressure airflow to mechanically trap particles in a filter medium.",
            "B. Electrostatic precipitation relies on a chemical reaction to neutralize airborne contaminants.",
            "C. Electrostatic precipitation charges particles in the air, causing them to be attracted to and collected on oppositely charged plates or surfaces.",
            "D. Electrostatic precipitation is primarily used to filter out gaseous pollutants from industrial emissions."
        ],
        answer: "C",
        explain: "Electrostatic precipitation works by charging particles in the air with an electric charge, which causes them to be attracted to and collected on oppositely charged plates or surfaces within the filter. This method is effective for removing fine particles, such as dust and smoke, from the air."
    },
    {
        number: 73,
        description: "How does the electrostatic charge in an N95 respirator contribute to its filtration efficiency?",
        choices: [
            "A. The filter fibers in an N95 respirator are only positively charged to repel particles from the surface.",
            "B. The filter fibers in an N95 respirator are negatively charged to neutralize harmful gases before they enter the filter.",
            "C. The filter fibers in an N95 respirator carry both positive and negative charges, attracting and capturing charged particles, and even neutral particles, through electrostatic attraction.",
            "D. The electrostatic charge in an N95 respirator allows it to self-clean by dislodging trapped particles after use."
        ],
        answer: "C",
        explain: "The filter fibers in an N95 respirator carry both positive and negative electrostatic charges. These charged fibers attract and capture airborne particles that have an opposite charge or are neutral through a process called electrostatic attraction. This mechanism enhances the respirator's ability to filter out a wide range of particle sizes, including very fine particles as small as 0.3 microns, making the N95 respirator highly effective in protecting the wearer."
    },
    {
        number: 74,
        description: "Which of the following chemical treatments is commonly used to control Legionella bacteria in cooling towers?",
        choices: [
            "A. Chlorine-based biocides",
            "B. Hydrogen peroxide",
            "C. Ultraviolet (UV) radiation",
            "D. Activated carbon filters"
        ],
        answer: "A",
        explain: "Chlorine-based biocides are commonly used to control Legionella bacteria in cooling towers. These biocides effectively kill or inhibit the growth of Legionella and other microorganisms in the water system."
    },
    {
        number: 75,
        description: "Which of the following parameters is essential for maintaining proper water quality in public swimming pools?",
        choices: [
            "A. pH level and chlorine concentration",
            "B. Dissolved oxygen levels",
            "C. Nitrogen concentration",
            "D. Turbidity and salinity"
        ],
        answer: "A",
        explain: "The correct answer is A: Maintaining proper pH level and chlorine concentration is essential for ensuring water quality in public swimming pools. The pH level needs to be kept within a specific range (usually 7.2 to 7.8) to ensure effective disinfection and swimmer comfort, while chlorine concentration must be maintained to kill harmful bacteria and pathogens. Dissolved oxygen levels, nitrogen concentration, turbidity, and salinity are less critical in the context of routine public pool maintenance, although they may be monitored in other water treatment scenarios."
    }
]