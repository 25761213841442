import React from 'react'
import Navigationbar from './Navigationbar'

function CIHExamBasics({ currUser, handleLogout }) {
    return (
        <>
            <Navigationbar currUser={currUser} handleLogout={handleLogout} />
            <h1>CIH Exam Frequently Asked Questions</h1>
            <h2 className='qanda'>What is the CIH exam?</h2>
            <p className='cihanswer'>The Certified Industrial Hygienist (CIH) credential is the global standard for certification in protecting the health and safety of workers and the public by anticipating, recognizing, evaluating, and controlling chemical, physical, ergonomic, or biological hazards, including COVID-19.</p>
            <p className='cihanswer'>A CIH must meet the minimum requirements for education and experience, and through examination, demonstrate a minimum level of knowledge and skills in the following areas: Air Sampling & Instrumentation, Analytical Chemistry, Basic Science, Biohazards, Biostatistics & Epidemiology, Community Exposure, Engineering Controls/Ventilation, Ergonomics, Health Risk Analysis & Hazard Communication, Industrial Hygiene/OEHS Program Management, Noise, Non-Engineering Controls, Radiation – Ionizing and Non-ionizing, Thermal Stressors, Toxicology, Work Environments & Industrial Processes.</p>
            <hr></hr>
            <h2 className='qanda'>How hard is the CIH exam?</h2>
            <p className='cihanswer'>The difficulty of the CIH exam can vary depending on an individual's level of experience, education, and preparation. Generally, it is considered quite challenging, compared with other safety and health certifications. Pass rates typically hover around 50%-60%.</p>
            <hr></hr>
            <h2 className='qanda'>What is the CIH passing score?</h2>
            <p className='cihanswer'>Your passing score is based on the total number of questions that you answer correctly in the entire examination. To pass you must receive a score of 70% (126 questions out of 180). It will be to your advantage to answer all questions.</p>
            <ul>
                <li style={{ textAlign: 'left', marginLeft: '20px' }}>All questions have the same point value.</li>
                <li style={{ textAlign: 'left', marginLeft: '20px' }}>There is no penalty for incorrect answers.</li>
                <li style={{ textAlign: 'left', marginLeft: '20px' }}>A “passing” score is not required in each of the individual subject areas.</li>
            </ul>
            <hr></hr>
            <h2 className='qanda'>How long does it take to get CIH certification?</h2>
            <p className='cihanswer'>The overall timeframe to get CIH certified can vary depending on your academic background and existing experience in industrial hygiene. Here's a breakdown of the time contributors:</p>
            <ul>
                <li style={{ textAlign: 'left', marginLeft: '20px' }}><strong>Education:</strong> You'll need a minimum of a 4-year bachelor's degree in a relevant field like science, engineering, or safety, and 60 credit hours of coursework in science, math, engineering, or science-based technology. Completing a degree typically takes 4 years.</li>
                <li style={{ textAlign: 'left', marginLeft: '20px' }}><strong>Industrial Hygiene Coursework:</strong> On top of the general science background, you'll need to complete 180 academic hours or 240 contact hours of specific industrial hygiene courses. These courses can be taken full-time or part-time.</li>
                <li style={{ textAlign: 'left', marginLeft: '20px' }}><strong>Experience:</strong> The minimum requirement is 4 years of professional-level industrial hygiene experience.</li>
            </ul>
            <p className='cihanswer'>Considering these factors, it can take anywhere from 5 to 8 years or even longer to get CIH certified, especially if you need to gain the required work experience.</p>
            <hr></hr>
            <h2 className='qanda'>How many times can you take the CIH exam?</h2>
            <p className='cihanswer'>There is no limit to the number of times you can take the CIH exam. However, you must wait at least three months between exam attempts.</p>
            <hr />
            <h2 className='qanda'>How to study for the CIH exam?</h2>
            <p className='cihanswer'>Studying for the CIH exam involves reviewing relevant textbooks, reference materials, and practice exams. The best way to get ready for the CIH exam is to take a course, study one topic at a time, and do lots of practice questions. Here are more tips from us: </p>
            <ul>
                <li style={{ textAlign: 'left', marginLeft: '20px' }}>Study for all the categories covered in the exam</li>
                <li style={{ textAlign: 'left', marginLeft: '20px' }}>Be familiar with Equation Sheet and calculator</li>
                <li style={{ textAlign: 'left', marginLeft: '20px' }}>Practice, Practice and Practice</li>
                <li style={{ textAlign: 'left', marginLeft: '20px' }}>Find harmony between your personal life, work, and studies.</li>
                <li style={{ textAlign: 'left', marginLeft: '20px' }}>Maintain a positive mindset and stay motivated</li>
            </ul>
            <hr />
            <h2 className='qanda'>Is the CIH exam multiple-choice?</h2>
            <p className='cihanswer'>Yes, the CIH exam only consists of multiple-choice questions.</p>
            <hr />
            <h2 className='qanda'>How long is the CIH exam?</h2>
            <p className='cihanswer'>The examination is conducted in two sections. You may go back and review questions at any time during a section. The test is 2.5 hours for the first section, with an optional thirty-minute break, and then 2.5 hours for the second section. There is a short tutorial prior to the examination and a short survey following the exam.</p>
            <p className='cihanswer'>If you choose to take a thirty-minute break, you will have 30 minutes and no more. In other words, the second section of the exam will begin after 30 minutes, regardless of when you return from your break.</p>
            <hr />
            <h2 className='qanda'>How many certified industrial hygienists are there in the US?</h2>
            <p className='cihanswer'>Estimates are that there are approximately 15,000 industrial hygienists in the United States; half of them are certified industrial hygienist. Annually, the number of new Certified Industrial Hygienists (CIHs) worldwide ranges from 100 to 200.</p>
            <hr />
        </>
    )
}

export default CIHExamBasics