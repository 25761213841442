export const ventilation_questions = [
    {
        number: 1,
        description: "A laboratory room is 20 feet long, 15 feet wide, and 10 feet high. The air exchange rate required is 6 air changes per hour. What is the supply air flow rate required for the room in CFM?",
        choices: [
            "A. 18000 CFM",
            "B. 300 CFM",
            "C. 600 CFM",
            "D. 1000 CFM"
        ],
        answer: "B",
        explain: "Step 1: Calculate the volume of the room. V = 20 * 15 * 10 = 3,000 cubic feet.\n\nStep 2: Calculate the total volume of air required per hour. Total air required per hour = Volume of the room * Air exchange rate = 3,000 * 6 = 18,000 cubic feet. \n\nStep 3: Convert the total air volume required per hour to per minute. Total air flow rate required in CFM (cubit feet per minute) = Total air volume required per hour / 60 = 18,000 / 60= 300 CFM."
    },
    {
        number: 2,
        description: "What is the relationship between the hood entry loss factor (Fh) and the hood entry coefficient (Ce) in ventilation systems?",
        choices: [
            "A. Fh is the inverse of Ce",
            "B. Fh equals Ce multiplied by the air velocity",
            "C. Fh equals Ce divided by the air velocity",
            "D. Fh is the same as Ce"
        ],
        answer: "A",
        explain: "In ventilation systems, the hood entry loss factor (Fh) and the hood entry coefficient (Ce) are related such that Fh is the inverse of Ce. The hood entry coefficient (Ce) is a dimensionless number that represents the efficiency of airflow into the hood. A higher Ce indicates a more efficient hood design with less resistance to airflow. The hood entry loss factor (Fh) quantifies the loss of pressure due to air entering the hood. A lower Fh indicates less pressure loss, which corresponds to a higher Ce."
    }
    ,
    {
        number: 3,
        description: "Calculate the volumetric flow rate of an 6-inch diameter duct with a 10 feet per minute duct velocity?",
        choices: [
            "A. 1.96 cfm",
            "B. 60 cfm",
            "C. 16 cfm",
            "D. 282 cfm"
        ],
        answer: "A",
        explain: "Use Formula 1 in Ventilation. Q = VA, A is sectional area of the duct, which equals Area = π * (diameter/2)\u00B2. V is duct velocity. Remember to convert 6 inches to 0.5 ft.\n\n Q = (0.5/2)\u00B2π * 10= 1.96 cfm.",
    },
    {
        number: 4,
        description: "What is the hood entry loss for a plain hood within a duct with a velocity pressure of 0.5 in.wc?",
        choices: [
            "A. 0.47 in.wc",
            "B. 1 in.wc",
            "C. 0.5 in.wc",
            "D. 0.25 in.wc"],
        answer: 'A',
        explain: 'Refer to the Hood Entry Loss Factor table in the equation sheet. Plain duct hood has the least hood entry loss factor Fh = 0.93. Use the 10th formula in the equation sheet. Hood entry loss = Fh * VPd = 0.93*0.5=0.47 in.wc.'
    },
    {
        number: 5,
        description: "What is the total pressure for the inlet of a fan if the static pressure is -5 in.wc. and velocity pressure is 2 in.wc? ",
        choices: [
            "A. -3 in.wc",
            "B. 3 in.wc",
            "C. -7 in.wc",
            "D. 7 in.wc"
        ],
        answer: "A",
        explain: "Inlet of the fan (pre fan), the total pressure and static pressure are always negative since air is comming in. Using Formula 3: TP = SP + VP = -5 + 2 = -3 in.wc."
    },
    {
        number: 6,
        description: "Which of the following hood shapes is generally considered to be the most efficient for minimizing hood entry loss?",
        choices: [
            "A. Rectangular",
            "B. Square",
            "C. Bell-shaped",
            "D. Conical"
        ],
        answer: 'C',
        explain: 'Refer to the Hood Entry Loss Factor table in the equation sheet. Bell-shaped hood has the least  hood entry loss factor Fh = 0.04.'
    },
    {
        number: 7,
        description: "Assuming a density factor of 1, what is the velocity in a duct if the velocity pressure is 900 pa?",
        choices: [
            "A. 38.7 fpm",
            "B. 38.7 m/sec",
            "C. 60 fpm",
            "D. 80 m/sec"
        ],
        answer: 'B',
        explain: 'Use Formula 7 in Ventilation. VP = 900 pa, df = 1. Therefore V = 1.29*sqrt(900) = 38.7 m/sec. Pay attention that the velocity of the airflow in this formula is measured in m/sec and the velocity pressure is in Pa.'
    },
    {
        number: 8,
        description: 'Assuming a density factor of 1, what is the velocity in a duct if the velocity pressure is 4 in.wc?',
        choices: [
            "A. 8010 fpm",
            "B. 16020 fpm",
            "C. 4005 fpm",
            "D. 3000 fpm"
        ],
        answer: 'A',
        explain: "Use Formula 6 in Ventilation. VP = 4 in.wc, df = 1, therefore V = 4005*sqrt(4) = 8010 fpm. Pay attention that the velocity of the airflow in this formula is measured in fpm and the velocity pressure is in in.wc."
    },
    {
        number: 9,
        description: "If the velocity pressure (VPd) in a duct is 2 in.wc and the hood entry loss factor (Fh) is 0.6, what is the static pressure of the hood?",
        choices: [
            "A. -3.2 in.wc",
            "B. 3.2 in.wc",
            "C. 2.6 in.wc",
            "D. -1.4 in.wc"
        ],
        answer: 'A',
        explain: 'Use Formula 5 in Ventilation. 𝐒𝐏𝐡 = -(𝐅𝐡 + 𝟏)𝐕𝐏𝐝 = -(0.6+1)*2 = -3.2 in.wc. The static pressure of the hood is always negative due to the air being drawn in.'
    },
    {
        number: 10,
        description: 'A fluid flows through a 1000-meter-long pipeline with a diameter of 50 cm. The total pressure at the inlet of the pipeline is -8 in.wc, and the total pressure at the outlet is 5 in.wc. What is the head loss (energy loss) across the pipeline?',
        choices: [
            "A. 13 in.wc",
            "B. 3 in.wc",
            "C. 40 in.wc",
            "D. 2 in.wc"
        ],
        answer: "B",
        explain: 'Use Formua 4 in Ventilation. SP + VP = Total Pressure(TP). Therefore, TP\u2081 = TP\u2082 +∑ 𝒍𝒐𝒔𝒔𝒆𝒔 . ∑ 𝒍𝒐𝒔𝒔𝒆𝒔 = TP\u2081 - TP\u2082 = 8 - 5 = 3 in.wc. \n\n In this formula, TP\u2081 and TP\u2082 mean absolute value. The inlet total pressure is -8 in.wc, if there is no loss, the outlet total pressure should be + 8 in.wc. But it is now 5 in.wc. Therefore, the loss is 3 in.wc.'
    },
    {
        number: 11,
        description: "If a 6-inch diameter duct has a velocity of 200 feet per minute, what would be the velocity in a 12-inch diameter duct with the same airflow rate?",
        choices: [
            "A. 25 fpm",
            "B. 50 fpm",
            "C. 75 fpm",
            "D. 100 fpm"
        ],
        answer: "B",
        explain: 'Use the equation of continuity (A\u2081V\u2081 = A\u2082V\u2082, Formula 2 in Ventilation), which states that the product of the area and velocity of a fluid at one point in a pipe must be equal to the product of the area and velocity at another point in the pipe. If the diameter is doubled, the area will be four times more, therefore the velocity should be four times less.'
    },
    {
        number: 12,
        description: "A laboratory has a ventilation system that is designed to maintain a total pressure (TP) of 0.5 in.wc. The velocity pressure (VP) in the ductwork is measured to be 0.1 in.wc. What is the static pressure (SP) in the ductwork?",
        choices: [
            "A. 0.6 in.wc",
            "B. 0.5 in.wc",
            "C. 0.4 in.wc",
            "D. 0.3 in.wc"
        ],
        answer: "C",
        explain: "Use Formula 3 in Equation 3 in Ventilation. TP = VP + SP. we can rearrange it to solve for SP = TP - VP. Substituting the given values, we get: SP = 0.5 - 0.1 = 0.4 in.wc."
    },
    {
        number: 13,
        description: "If the density factor is 1, what is the velocity pressure when the velocity inside a duct is 4005 fpm?",
        choices: [
            "A. 1 in.wc.",
            "B. 2 in.wc.",
            "C. 1 pa",
            "D. 2 pa"
        ],
        answer: "A",
        explain: "Use Equation 8 in Ventilation. V = velocity of air flow in fpm =4005. df = 1. 4005 = 4005 sqrt(VP/1). Therefore, VP = 1 in.wc. Pay attention to the unit."
    },
    {
        number: 14,
        description: "At standard temperature and pressure, what is the velocity pressure when the velocity inside a duct is 12.9 m/sec?",
        choices: [
            "A. 100 in.wc",
            "B. 10 in.wc",
            "C. 10 pa",
            "D. 100 pa"
        ],
        answer: "D",
        explain: "Use Formula 9 in Ventilation. V = 12.9 m/sec. df = 1 at standard temperature and pressure. Pay attention to the unit. V = velocity of air flow in m/sec, VP = velocity pressure in Pascals. VP=(12.9/1.29)\u00B2 *1 = 100 pa."
    },
    {
        number: 15,
        description: "What is the coefficient of entry for a hood located within a duct that has a hood static pressure of 0.8 in.wc. and a velocity pressure of 0.4 in.wc?",
        choices: [
            "A. 0.5",
            "B. 0.7",
            "C. 1",
            "D. 1.9"
        ],
        answer: "B",
        explain: "Use Formula 11 in Ventilation. VP= velocity pressure, in.wc. SPh = value of hood static pressure, in in.wc. Ce=hood entry coefficient = sqrt(0.4/0.8) = 0.7."
    },
    {
        number: 16,
        description: "If two flow ducts, one with a volumetric flow rate of 200 ft\u00B3/min and a velocity pressure of 1.5 in. w.c, and the other with a volumetric flow rate of 300 ft\u00B3/min and a velocity pressure of 2.0 in.wc, are merged, what will be the resulting velocity pressure in the combined duct?",
        choices: [
            "A. 1.8 in.wc",
            "B. 1.5 in.wc",
            "C. 2.0 in.wc",
            "D. 2.8 in.wc"
        ],
        answer: "A",
        explain: "Use Formula 12 in Ventilation. Q\u2083 = volumetric flow rate of the merged branches = 200 + 300 = 500 cfm. Q\u2081 = 200 cfm; Q\u2082 = 300 cfm, VP\u2081= 1.5 in.wc. VP\u2082= 2 in.wc. VPr = resulting velocity pressure of the merged flows = (200/500)*1.5 + (300/500)*2 = 1.8 in.wc."
    },
    {
        number: 17,
        description: "Assuming a hood entry coefficient of 0.98 (bell-shaped), a hood static pressure of 4 in. wc, and a density factor of 1, what is the volumetric flow rate of a duct with a cross-sectional area of 0.196 ft\u00B2, connected to the hood?",
        choices: [
            "A. 539 cfm",
            "B. 1539 cfm",
            "C. 1009 cfm",
            "D. 4005 cfm"
        ],
        answer: "B",
        explain: "Use Formula 13 in Ventilation. Ce= hood entry coefficient=0.98, SPh= hood static pressure = 4 in.wc. df=air density correction factor = 1. Q=volumetric flow rate = 4005*0.98*2*0.196 = 1539 cfm."
    },
    {
        number: 18,
        description: "Assuming a hood entry coefficient of 0.98 (bell-shaped), a hood static pressure of 3600 pa, and a density factor of 1, what is the volumetric flow rate of a duct with a cross-sectional area of 2 m\u00B2, connected to the hood?",
        choices: [
            "A. 1.52 m\u00B3/sec",
            "B. 1.25 cfm",
            "C. 15.2 cfm",
            "D. 152 m\u00B3/sec"
        ],
        answer: "D",
        explain: "Use Equation 14 in Ventilation. Pay attention to the unit. Ce= hood entry coefficient=0.98, SPh= value of hood static pressure = 3600 pa. df=air density correction factor = 1. Q=volumetric flow rate = 1.29*0.98*60*2 = 152 m\u00B3/sec."
    },
    {
        number: 19,
        description: "Assuming a main duct with a static pressure of 4 in. wc, what is the corrected flow rate for a ventilation branch that converges with the main duct, given that the ventilation branch has a volumetric flow rate of 1500 cfm and a static pressure of 1 in.wc?",
        choices: [
            "A. 3000 cfm",
            "B. 4000 cfm",
            "C. 5000 cfm",
            "D. 1500 cfm"
        ],
        answer: "A",
        explain: "Use Formula 16 in Ventilation. Qcorr = corrected flow rate in cfm. Qlower=existing flow rate. SPgov = governing static pressure in.wc. SPlower = new duct static pressure in.wc. Qcorr = 1500 sqrt(4/1) = 3000 cfm."
    },
    {
        number: 20,
        description: "Assuming a mixing factor of 5, what is the effective ventilation rate in a room with a ventilation rate of 100 cfm?",
        choices: [
            "A. 500",
            "B. 100",
            "C. 5",
            "D. 20"
        ],
        answer: "D",
        explain: "Use Formula 17 in Ventilation. Q’ = effective rate of ventilation. Q=actual(original) airflow. mi = mixing factor. Q’ = 100/5=20. Effective ventilation rate refers to the actual amount of outdoor air that is entering a room or space and diluting indoor air pollutants. It takes into account the ventilation rate and the degree of mixing of the outdoor air with the indoor air. The effective ventilation rate is typically lower than the actual ventilation rate due to factors such as air distribution, recirculation, and the degree of mixing of the air."
    },
    {
        number: 21,
        description: "A spill of toluene in a room with a volume of 30,000 cubic feet results in an initial concentration of 50 ppm. After the use of toluene is stopped, the effective rate of ventilation is measured at 6,000 cubic feet of dilution air per minute. How long will it take for the concentration of toluene to decrease to the permissible exposure limit (PEL) of 10 ppm?",
        choices: [
            "A. 8 min",
            "B. 16 min",
            "C. 50 min",
            "D. 2 min"
        ],
        answer: "A",
        explain: "Use Formula 18 in Ventilation. t\u2082=final time in min; t\u2081 = initial concentral; Cg\u2082= final concentration; Q’=effective rate of ventilation in cfm. t\u2082-t\u2081=-30000/6000*ln(10/50)= 8 min."
    },
    {
        number: 22,
        description: "In a room with a volume of 20,000 cubic feet, toluene is evaporating at a rate of 10 cubic feet per minute. The initial concentration of toluene in the room is measured at 10 ppm. If 5,000 cubic feet per minute of dilution air is introduced into the room, what will the concentration of toluene be after 60 minutes?",
        choices: [
            "A. 1000 ppm",
            "B. 2000 ppm",
            "C. 300 ppm",
            "D. 0 ppm"
        ],
        answer: "B",
        explain: "Use Formula 19 in Ventilation. G = rate of generation of contaminant in cfm. t\u2082=final time, min; t\u2081=initial time, min; Vr= volume of enclosure in cubic feet; Cg\u2081 = initial concentral; Cg\u2082= final concentration; Q’=effective rate of ventilation in cfm.\n\nln(10-5000Cg\u2082)/(10-5000*0.00001) = - 5000/20000*60. Cg\u2082 = 2000 ppm."
    },
    {
        number: 23,
        description: "An open bottle on a laboratory table is evaporating benzene at a rate of 0.5 pints per minute. To keep the concentration of benzene in the room below 1 ppm, what is the required flow rate of dilution air in cubic feet per minute (cfm)? Assume the ventilation safety factor is 2 and the molecular weight of benzene is 78 g/mol and the specific gravity is 0.876.",
        choices: [
            "A. 9,019,625 cfm",
            "B. 4,526,000 cfm",
            "C. 19,625 cfm",
            "D. 2,519,625 cfm"
        ],
        answer: "B",
        explain: 'Use Formula 20 in Ventilation. Q=volumetric flow required to limit contaminant concentration in cfm. SG=specific gravacity. ER = evaporation rate in pints/min. Mi = mixing ventilation (dilution) safety factor. MW=molecular weight. Cg = contaminant concentration in ppm. \n\n Q = 403*0.876*0.5*2*10\u2076/(78*1) = 4,526,000 cfm.'
    },
    {
        number: 24,
        description: "An open bottle on a laboratory table is evaporating benzene at a rate of 0.002 liters per second. To keep the concentration of benzene in the room below 1 ppm, what is the required flow rate of dilution air in cubic feet per second)? Assume the ventilation safety factor is 2 and the molecular weight of benzene is 78 g/mol and the specific gravity is 0.876.",
        choices: [
            "A. 7011 cfm",
            "B. 7011 m\u00B3/sec",
            "C. 1078 m\u00B3/sec",
            "D. 1078 cfm"
        ],
        answer: "C",
        explain: 'Use Formula 21 in Ventilation. Pay attention to the units. Q=volumetric flow required to limit contaminant concentration in cubic meters/second. SG=specific gravity. ER = evaporation rate in liters/second. Mi = mixing ventilation (dilution) safety factor. MW=molecular weight. Cg = contaminant concentration in ppm. \n\n Q = 24*0.876*0.002*2*10\u2076/(78*1) = 1078 m\u00B3/sec.'
    },
    {
        number: 25,
        description: "If a room has a volume of 20,000 cubic feet and a ventilation rate of 5,000 cfm, how many air exchanges per hour (ACH) are achieved?",
        choices: [
            "A. 5",
            "B. 25",
            "C. 10",
            "D. 15"
        ],
        answer: "D",
        explain: 'Use Formula 22 in Ventilation. Q = room ventilation in cfm. Vr = room volume. Number of changes = 60*5,000/20,000 = 15. \n\nAs a general guideline, the American Society of Heating, Refrigerating, and Air-Conditioning Engineers (ASHRAE) recommends a minimum of 4-6 air changes per hour (ACH) for office spaces. Additionally, during the COVID-19 pandemic, ASHRAE also recommends increasing ventilation rates to reduce the risk of airborne transmission of the virus.'
    },
    {
        number: 26,
        description: "What will be the concentration of benzene in a room with a volume of 20,000 cubic feet and a ventilation rate of 8,000 cfm, if an open bottle of benzene left on a lab table evaporating at a rate of 0.3 cfm for 15 minutes?",
        choices: [
            "A. 20 ppm",
            "B. 37 ppm",
            "C. 40 ppm",
            "D. 50 ppm"
        ],
        answer: "B",
        explain: 'Use Formula 23 in Ventilation. Cg2 = final concentration of gas or vapor in ppm. G = rate of generation of contaminant in cfm = 0.3; Δt= amount of time in mins = 15. Q’= effective room ventilation rate in cfm = 8000. Vr = room volume in cubit feet = 20,000.'
    },
    {
        number: 27,
        description: "After 30 minutes, what will be the concentration of benzene in a room with a volume of 20,000 cubic feet and a ventilation rate of 5,000 cfm, if the initial concentration of benzene is 80 ppm and there is no more benzene being generate?",
        choices: [
            "A. 0.044 ppm",
            "B. 0.055 ppm",
            "C. 0.6 ppm",
            "D. 4 ppm"
        ],
        answer: "A",
        explain: 'Use Formula 24 in Ventilation. Cg2 = final concentration of contaminant in ppm. Cg1 = initial concentration of contaminant in ppm = 80. Δt= amount of time in mins = 30. Q’= effective room ventilation rate in cfm = 5000. Vr = room volume in cubit feet = 20,000.'
    },
    {
        number: 28,
        description: "What is the new flow rate of a local exhaust ventilation system if its fan, with a diameter that remains constant, is replaced by one turning at 3000 rpm when the original fan had a flow rate of 400 cfm and was turning at 1000 rpm?",
        choices: [
            "A. 1200 cfm",
            "B. 900 cfm",
            "C. 2400 cfm",
            "D. 400 cfm"
        ],
        answer: "A",
        explain: 'Use Formula 25 in Ventilation. RPM\u2082 = new fan speed. RPM\u2081 = original fan speed. d\u2082=d\u2081. Q\u2081= initial flow rate. \n\nQ\u2082 = new flow rate = 400*(1/1)\u00B3*(3000/1000) = 1200 cfm.'
    },
    {
        number: 29,
        description: "What is the new fan pressure of a local exhaust ventilation system if its fan, with a diameter that remains constant, is replaced by one turning at 3000 rpm when the original fan had a pressure of 5 in.wc and was turning at 1000 rpm?",
        choices: [
            "A. 5 in.wc",
            "B. 45 in.wc",
            "C. 10 in.wc",
            "D. 20 in.wc"
        ],
        answer: "B",
        explain: 'Use Formula 26 in Ventilation. RPM\u2082 = new fan speed. RPM\u2081 = original fan speed. d\u2082=d\u2081. P\u2081= initial system pressure. \n\nP\u2082 = new system pressure = 5*(1/1)\u00B2(3000/1000)\u00B2 = 45 in. wc.'
    },
    {
        number: 30,
        description: "What is the new system power of a local exhaust ventilation system if the fan RPM is doubled and the diameter is halved? Original system power is 20 hp.",
        choices: [
            "A. 20 hp",
            "B. 1600 hp",
            "C. 5 hp",
            "D. 6400 hp"
        ],
        answer: "C",
        explain: 'Use Formula 27 in Ventilation. d\u2082 = 0.5d\u2081. RPM\u2082 = 2RPM\u2081. Therefore, the new power = 20 * (1/2)\u2075 2\u00B3 = 5 hp.'
    },
    {
        number: 31,
        description: "What is the static pressure of a fan given a static pressure of -5 in.wc at the inlet, a static pressure of 10 in.wc at the outlet, and a velocity pressure of 1 in.wc on the inlet side of the fan?",
        choices: [
            "A. 10 in.wc",
            "B. 24 in.wc",
            "C. 14 in.wc",
            "D. 8 in.wc"
        ],
        answer: "C",
        explain: 'Use Formula 28 in Ventilation. FSP = fan static pressure. SPout = static pressure measured on the outlet side of the fan. SPin =  static pressure measured on the inlet side of the fan. VPin = velocity pressure on the inlet side of the fan.\n\nTherefore, FSP = 10 - (-5) - 1 = 14 in.wc. Pay attention that the inlet Static Pressure will always be counted as negative, and the outlet Static Pressure is always positive. The Velocity Pressure is always positive at both ends.'
    },
    {
        number: 32,
        description: "What is the total pressure of a fan if the total pressure at inlet is -5 in.wc and the total pressure at the outlet is 8 in.wc?",
        choices: [
            "A. 5 in.wc",
            "B. 8 in.wc",
            "C. 13 in.wc",
            "D. 2 in.wc"
        ],
        answer: "C",
        explain: 'Use Formula 29 in Ventilation. FTP = Fan total pressure. TPout = total pressure measured on the outlet side of the fan. TPin = total pressure measured on the inlet side of the fan. Pay attention that the inlet total pressure is always negative, and the outlet total pressure is always positive. FTP = 8-(-5) = 13 in. wc.'
    },
    {
        number: 33,
        description: "What would be the capture velocity of a flangeless slot hood, which has an opening height of 2 inches and is 12 inches long, and the flow rate is 600 cfm, when measured at a distance of 1 foot from the hood?",
        choices: [
            "A. 62 fpm",
            "B. 162 fpm",
            "C. 82 fpm",
            "D. 1620 fpm"
        ],
        answer: "B",
        explain: 'Use Formula 1 in Hood Airflow Equations. First verify the aspect ratio = 2/12<0.2. X= distance outward along the axis in feet = 1 foot. Q= volumetric flow rate in cfm. L = hood height in feet = 1 foot. Therefore, 600 = 3.7*1*Vx*1. Vx = 162 fpm.'
    },
    {
        number: 34,
        description: "What would be the capture velocity of a flanged slot hood, which has an opening height of 2 inches and is 12 inches long, and the flow rate is 600 cfm, when measured at a distance of 1 foot from the hood?",
        choices: [
            "A. 231 fpm",
            "B. 162 fpm",
            "C. 192 fpm",
            "D. 362 fpm"
        ],
        answer: "A",
        explain: 'Use Formula 2 in Hood Airflow Equations. First verify the aspect ratio = 2/12<0.2. X= distance outward along the axis in feet = 1 foot. Q= volumetric flow rate in cfm. L = hood height in feet = 1 foot. Therefore, 600 = 2.6*1*Vx*1. Vx = 231 fpm. Compared with the previous question, you see the flanged hood has higher velocity.'
    },
    {
        number: 35,
        description: "What would be the capture velocity of a plain opening slot hood, which has an opening height of 6 inches and is 6 inches long, and the flow rate is 600 cfm, when measured at a distance of 1 foot from the hood?",
        choices: [
            "A. 49 fpm",
            "B. 52 fpm",
            "C. 54 fpm",
            "D. 59 fpm"
        ],
        answer: "D",
        explain: 'Use Formula 3 in Hood Airflow Equations. First verify the aspect ratio = 2/2=1. X= distance outward along the axis in feet = 1 foot. Q= volumetric flow rate in cfm. L = hood height in feet = 1 foot. Af= area of hood face opening in square feet. \n\nTherefore, 600 = Vx(10*1\u00B2 + 0.5*0.5). Vx = 59 fpm.'
    },
    {
        number: 36,
        description: "What would be the capture velocity of a flanged opening slot hood, which has an opening height of 6 inches and is 6 inches long, and the flow rate is 600 cfm, when measured at a distance of 1 foot from the hood?",
        choices: [
            "A. 78 fpm",
            "B. 68 fpm",
            "C. 58 fpm",
            "D. 48 fpm"
        ],
        answer: "A",
        explain: 'Use Formula 4 in Hood Airflow Equations. First verify the aspect ratio = 2/2=1. X= distance outward along the axis in feet = 1 foot. Q= volumetric flow rate in cfm. L = hood height in feet = 1 foot. Af= area of hood face opening in square feet. Therefore, 600 = 0.75Vx(10*1\u00B2 + 0.5*0.5). Vx = 78 fpm. Compared with the previous question, you see the flanged hood has higher velocity.'
    },
    {
        number: 37,
        description: "If a local booth has a square hood with a side length of 3 feet and a velocity of 200 fpm, what is its volumetric flow rate?",
        choices: [
            "A. 900 cfm",
            "B. 1800 cfm",
            "C. 400 cfm",
            "D. 700 cfm"
        ],
        answer: "B",
        explain: 'Use Formula 5 in Hood Airflow Equations. Q=200*3*3=1800 cfm.'
    },
    {
        number: 38,
        description: "If a canopy has a tank with dimensions 5 ft by 3 ft, and is placed at a height of 1 foot above the work surface, what is its volumetric flow rate given that the centerline velocity is 200 fpm?",
        choices: [
            "A. 2480 cfm",
            "B. 4480 cfm",
            "C. 6480 cfm",
            "D. 1480 cfm"
        ],
        answer: "B",
        explain: 'Use Formula 6 in Hood Airflow Equations. P = perimeter of work = 5+5+3+3 = 16 feet. V= centerline velocity in fpm. X = height above work. Therefore, Q = 1.4*16*200*1 = 4480 cfm.'
    },
    {
        number: 39,
        description: "If a square plain hood with multiple slots has a flow rate of 400 cfm and a total opening area of 4 square feet, what is the capture velocity of the hood at a distance of 2 feet from the hood?",
        choices: [
            "A. 9.1 fpm",
            "B. 12.1 fpm",
            "C. 3.1 fpm",
            "D. 4.1 fpm"
        ],
        answer: "A",
        explain: 'Use Formula 7 in Hood Airflow Equations. First verify the aspect ratio, since it is a square, the ratio = 1. As = total areas of slots openings. Therefore, 400 = Vx(10*2\u00B2 + 4).  Vx = 9.1 fpm.'
    },
    {
        number: 40,
        description: "If a flanged hood with multiple square slots has a flow rate of 400 cfm and a total opening area of 4 square feet, what is the capture velocity of the hood at a distance of 2 feet from the hood?",
        choices: [
            "A. 2.1 fpm",
            "B. 13  fpm",
            "C. 12.1 fpm",
            "D. 9.1 fpm"
        ],
        answer: "C",
        explain: 'Use Formula 8 in Hood Airflow Equations. First verify the aspect ratio, since it is a square, the ratio = 1. As = total areas of slots openings. Therefore, 400 = 0.75*Vx(10*2\u00B2 + 4). Vx=12.1 fpm.'
    },
    {
        number: 41,
        description: "Which of the following statements is true regarding local exhaust ventilation (LEV) and dilution ventilation?",
        choices: [
            "A. LEV is more effective than dilution ventilation in controlling airborne contaminants.",
            "B. Dilution ventilation is more effective than LEV in controlling airborne contaminants.",
            "C. LEV and dilution ventilation are equally effective in controlling airborne contaminants.",
            "D. The effectiveness of LEV and dilution ventilation depends on the type of contaminant and the ventilation system design."
        ],
        answer: "D",
        explain: 'LEV is a ventilation system that captures and removes airborne contaminants at or near their source using hoods, ducts, and fans. This type of system is especially useful for controlling high concentrations of contaminants in areas where workers perform tasks that generate high levels of contaminants, such as welding or spray painting. LEV is effective in removing a large percentage of the contaminants from the air in the immediate vicinity of the source, and can be a very effective control measure for some types of contaminants.\n\nDilution Ventilation, on the other hand, is a type of ventilation system that involves introducing fresh air into a work area to dilute and reduce the concentration of contaminants in the air. This type of system is useful for controlling low to moderate concentrations of contaminants in large areas where the contaminants are more diffuse and evenly distributed. Dilution ventilation relies on air movement to mix the fresh air with the contaminated air, and to remove the contaminated air from the work area.'
    },
    {
        number: 42,
        description: "What is the primary difference between a capture hood and an enclosing hood in ventilation systems?",
        choices: [
            "A. A capture hood surrounds the contaminant source completely, while an enclosing hood draws air from the immediate vicinity of the contaminant release",
            "B. A capture hood draws air from the immediate vicinity of the contaminant release, while an enclosing hood surrounds the contaminant source completely",
            "C. A capture hood is used for large areas, while an enclosing hood is used for small, confined spaces",
            "D. A capture hood uses a downdraft system, while an enclosing hood uses an updraft system"
        ],
        answer: "B",
        explain: "The primary difference between a capture hood and an enclosing hood in ventilation systems is that a capture hood draws air from the immediate vicinity of the contaminant release, effectively capturing contaminants at their source. In contrast, an enclosing hood surrounds the contaminant source completely, providing a more controlled environment to contain and capture emissions. Capture hoods are suitable for localized sources where the hood can be placed close to the emission point, while enclosing hoods are ideal for containing contaminants within a defined area."
    },
    {
        number: 43,
        description: "What is a pitot tube primarily used for in HVAC?",
        choices: [
            "A. Measuring the velocity of air in ductwork",
            "B. Measuring the concentration of airborne contaminants",
            "C. Measuring the temperature of air in ductwork",
            "D. Measuring the static pressure of air in ductwork"
        ],
        answer: "A",
        explain: 'In HVAC (Heating, Ventilation, and Air Conditioning) systems, a pitot tube is primarily used to measure air velocity within ducts. The primary purpose is to assess the airflow and ensure that it meets the design specifications for efficient heating, cooling, and ventilation.\n\nThe pitot tube is inserted into the airflow within a duct. As air flows over the tube, it creates a pressure difference between the impact pressure (total pressure) and the static pressure. This pressure difference (velocity pressure) is used to calculate the air velocity.'
    },
    {
        number: 44,
        description: "What is the partial pressure of carbon monoxide in the air, if the measured concentration of carbon monoxide is 10 ppm? The atmospheric pressure is 780 mmHg.",
        choices: [
            "A. 0.0078 mmHg",
            "B. 0.078 mmHg",
            "C. 0.78 mmHg",
            "D. 0.00078 mmHg"
        ],
        answer: "A",
        explain: 'Partial pressure is the pressure exerted by a single component of a mixture of gases. It is proportional to the concentration of that gas in the mixture. In this case, the partial pressure of CO = 10/10\u2076 * 780 mmHg = 0.0078 mmHg.'
    },
    {
        number: 45,
        description: "What is the primary difference between static pressure and velocity pressure in a fluid flow system?",
        choices: [
            "A. Static pressure measures the fluid's motion, while velocity pressure measures the fluid at rest",
            "B. Static pressure is the sum of velocity pressure and total pressure",
            "C. Static pressure measures the pressure exerted by the fluid at rest, while velocity pressure measures the pressure due to the fluid's motion",
            "D. Velocity pressure is the sum of static pressure and total pressure"
        ],
        answer: "C",
        explain: "The primary difference between static pressure and velocity pressure in a fluid flow system is that static pressure measures the pressure exerted by the fluid at rest, while velocity pressure measures the pressure due to the fluid's motion. Static pressure represents the potential energy of the fluid, and it can be positive or negative depending on the reference point and direction of measurement. Velocity pressure represents the kinetic energy of the moving fluid and is always positive as it depends on the fluid's velocity."
    },
    {
        number: 46,
        description: "What is the recommended indoor CO\u2082  level, according to ASHRAE, in relation to outdoor air levels?",
        choices: [
            "A. Indoor CO\u2082  levels should be no higher than outdoor air levels.",
            "B. Indoor CO\u2082  levels should be no higher than 500 ppm above outdoor air levels.",
            "C. Indoor CO\u2082  levels should be no higher than 700 ppm above outdoor air levels.",
            "D. Indoor CO\u2082  levels should be no higher than 1000 ppm above outdoor air levels."
        ],
        answer: "C",
        explain: 'ASHRAE, the American Society of Heating, Refrigerating and Air-Conditioning Engineers, is a professional organization that provides guidelines for indoor air quality. According to ASHRAE, the recommended CO2 level in buildings should be no more than 700 parts per million (ppm) above outdoor air. Since outdoor air is approximately 400ppm, indoor CO2 levels should be no more than 1,100 ppm. This guideline is based on the fact that elevated levels of CO\u2082  can cause headaches, fatigue, and decreased concentration, among other symptoms, and can also indicate poor ventilation in a building.'
    },
    {
        number: 47,
        description: "What type of exhaust system is commonly built into portable hand tools and used to control dust from sanding, grinding, and drilling?",
        choices: [
            "A. High volume low velocity exhaust system",
            "B. Balanced volume and velocity exhaust system",
            "C. Low volume high velocity exhaust system",
            "D. Variable volume and velocity exhaust system"
        ],
        answer: "C",
        explain: "A low volume high velocity exhaust system moves smaller volumes of air at higher speeds, making it effective for capturing contaminants and ensuring rapid extraction from specific areas. The low volume and high velocity design help minimize energy consumption while maintaining effective extraction, making it suitable for localized exhaust applications."
    },
    {
        number: 48,
        description: "What is the recommended range of face velocity for a fume hood, and why should the face velocity not be too high or too low?",
        choices: [
            "A. 50-80 fpm; Low face velocities can cause inadequate containment of hazardous fumes",
            "B. 80-120 fpm; High face velocities can cause turbulence and reduce containment effectiveness",
            "C. 120-150 fpm; High face velocities can cause noise and increase energy consumption",
            "D. 150-200 fpm; Low face velocities can cause excessive exhaust and reduce airflow stability"
        ],
        answer: "B",
        explain: "The recommended range of face velocity for a fume hood is typically 80-120 fpm (feet per minute). Face velocity refers to the speed at which air is drawn into the fume hood's opening. If the face velocity is too high, it can cause turbulence inside the hood, which reduces the hood's ability to contain hazardous fumes effectively. This can lead to the escape of contaminants into the laboratory or workspace. On the other hand, if the face velocity is too low, the airflow may not be sufficient to capture and exhaust hazardous fumes, resulting in inadequate containment. Additionally, excessively high face velocities can increase energy consumption and noise levels, making the fume hood less efficient and potentially uncomfortable for users. Therefore, maintaining a face velocity within the recommended range ensures optimal performance and safety in laboratory environments."
    },
    {
        number: 49,
        description: "What are the main differences between a chemical fume hood and a biosafety cabinet?",
        choices: [
            "A. A chemical fume hood is used for dangerous chemicals, while a biosafety cabinet is used for infectious biological agents. The fume hood does not have a HEPA filter, and exhausts air outside the building, whereas the biosafety cabinet has a HEPA filter, and usuaaly does not exhaust air outside",
            "B. A chemical fume hood is used for infectious biological agents, while a biosafety cabinet is used for dangerous chemicals. Both protect the user and have HEPA filters.",
            "C. A chemical fume hood is used for dangerous chemicals and infectious biological agents, while a biosafety cabinet is only used for dangerous chemicals. Both do not exhaust air outside the building.",
            "D. A chemical fume hood and a biosafety cabinet are used for the same purpose and have the same functionality, including HEPA filters and exhausting air outside the building."
        ],
        answer: "A",
        explain: "A chemical fume hood is primarily used for handling dangerous chemicals and it protects the user by exhausting air outside the building without using a HEPA filter. In contrast, a biosafety cabinet is used for handling infectious biological agents, providing protection not only to the user but also to the environment and the material being worked with. Additionally, biosafety cabinets must have a HEPA filter and they usually do not exhaust air outside the building."
    },
    {
        number: 50,
        description: "In a hospital setting, negative pressure rooms are primarily used to:",
        choices: [
            "A. Enhance patient comfort",
            "B. Improve air circulation",
            "C. Prevent the spread of airborne infectious diseases",
            "D. Increase energy efficiency"
        ],
        answer: "C",
        explain: "Negative pressure rooms are used to isolate patients with infectious diseases so that the pathogens they produce do not spread to other patients or staff. The negative pressure in the room creates a flow of air from the outside of the room into the room, which helps to prevent the pathogens from escaping."
    },
    {
        number: 51,
        description: "Positive pressure rooms are commonly used in which of the following settings?",
        choices: [
            "A. Intensive care units (ICUs)",
            "B. Cleanrooms",
            "C. Radiology departments",
            "D. Hospital cafeterias"
        ],
        answer: "B",
        explain: "Positive pressure rooms are commonly used in cleanroom environments where maintaining a high level of cleanliness is crucial. These rooms have a higher air pressure inside compared to the surrounding areas, preventing the entry of contaminants from outside. Positive pressure helps to push air out of the room, minimizing the infiltration of particles and microorganisms."
    },
    {
        number: 52,
        description: "What is the term used to describe the resistance to airflow caused by the dense structure of HEPA filters?",
        choices: [
            "A. Airflow hindrance",
            "B. Pressure drop",
            "C. Flow impedance",
            "D. Resistance buildup"
        ],
        answer: "B",
        explain: "Pressure drop is the decrease in air pressure that occurs when air passes through a filter. The denser the filter, the greater the pressure drop. HEPA filters are very dense, so they cause a significant pressure drop.\n\nTo maintain optimal performance, it's important to monitor and maintain proper airflow rates in systems utilizing HEPA filters."
    },
    {
        number: 53,
        description: "What is the primary function of the slots in a plenum hood, and why is this design feature important for laboratory safety?",
        choices: [
            "A. The slots in a plenum hood are designed to increase airflow velocity, ensuring that hazardous fumes are effectively contained and exhausted.",
            "B. The slots in a plenum hood help to distribute air evenly across the work surface, reducing turbulence and ensuring uniform containment of fumes.",
            "C. The slots in a plenum hood serve as access points for maintenance and cleaning, allowing for easy upkeep of the hood.",
            "D. The slots in a plenum hood are meant to reduce noise levels within the hood, making it more comfortable for laboratory personnel to work."
        ],
        answer: "B",
        explain: "The primary function of the slots in a plenum hood is to help distribute air evenly across the work surface. This design feature is important because it reduces turbulence and ensures uniform containment of hazardous fumes, enhancing the overall effectiveness of the hood in protecting laboratory personnel from exposure to harmful substances."
    },
    {
        number: 54,
        description: "In which engineering applications is the Reynolds number commonly used?",
        choices: [
            "A. Structural analysis",
            "B. Electrical circuit design",
            "C. Fluid dynamics",
            "D. Material strength testing"
        ],
        answer: "C",
        explain: "The Reynolds number is a dimensionless quantity used in fluid mechanics to predict the flow patterns in different fluid flow situations. The Reynolds number can be calculated by the 6th formula in equation sheet under general sciences: Re = ρVL/μ. Re is the Reynolds number; ρ is the density of the fluid; V is the velocity of the fluid; L is a characteristic linear dimension (e.g., diameter of a pipe); μ is the dynamic viscosity of the fluid.\n\nThe Reynolds number is important in determining the type of flow (laminar or turbulent) that occurs in a fluid system. It is a dimensionless parameter, and the flow regime transitions from laminar to turbulent at a critical Reynolds number. In general, low Reynolds numbers indicate laminar flow, while high Reynolds numbers indicate turbulent flow."
    },
    {
        number: 55,
        description: "Which of the following mechanisms is most effective for capturing the smallest particles, including ultrafine particles?",
        choices: [
            "A. Interception",
            "B. Impaction",
            "C. Diffusion",
            "D. Electrostatic attraction"
        ],
        answer: "C",
        explain: "Diffusion is the most effective mechanism for capturing the smallest particles, including ultrafine particles. When air passes through a filter, these small particles exhibit random, erratic movement due to their thermal motion, a phenomenon known as Brownian motion. As they move, they collide with gas molecules and are eventually captured by the filter fibers through diffusion.\n\nInterception occurs when a particle which is following a gas streamline comes within one particle radius of a filter fiber. The particle touches the fiber and is captured, thus being removed from the gas flow.\n\nInertial impaction occurs when a particle is so large that it is unable to quickly adjust to the abrupt changes in streamline direction near a filter fiber. The particle, due to its inertia, will continue along its original path and hit the filter fiber."
    },
    {
        number: 56,
        description: "Which of the following statements accurately describes HEPA filters?",
        choices: [
            "A. HEPA filters are designed to remove particles of 0.3 micrometers in diameter with an efficiency of at least 99.97%.",
            "B. HEPA filters are primarily used to remove gaseous contaminants from the air.",
            "C. HEPA filters are only effective in filtering out larger particles and are not suitable for fine particulate matter.",
            "D. HEPA filters do not need to be replaced frequently to maintain their effectiveness."
        ],
        answer: "A",
        explain: "A HEPA (High-Efficiency Particulate Air) filter is a type of air filter that is highly efficient in capturing and removing airborne particles from the air. HEPA filters are composed of a dense arrangement of fibers that create a mesh-like structure, which can trap particles as air passes through.\n\nThe most common requirement is that a HEPA filter must be able to remove at least 99.97% of particles that are 0.3 micrometers in diameter or larger. This particle size of 0.3 µm is referred to as the Most Penetrating Particle Size (MPPS).\n\nThe MPPS is considered the most challenging particle size for a HEPA filter to capture because particles both larger and smaller than 0.3 µm are typically easier to trap. Larger particles tend to be caught by the filter's mechanical sieving action, while smaller particles are more effectively captured through diffusion and interception. HEPA filters can capture particles of different sizes, including larger and smaller ones.\n\nIt's important to note that HEPA filters are typically designed for air purification and particle removal. They are not specifically designed to remove gaseous pollutants or odors from the air. For that purpose, activated carbon filters or other specialized filters may be used in conjunction with HEPA filters."
    },
    {
        number: 57,
        description: "If 1,000 cfm of air is passing through a 10-inch diameter duct, what will be the resulting velocity if the airflow is redirected to a 5-inch diameter duct?",
        choices: [
            "A. 250 fpm",
            "B. 1,000 fpm",
            "C. 4,000 fpm",
            "D. 4,000 cfm"
        ],
        answer: "C",
        explain: "To find the resulting velocity when the airflow is redirected to a different duct diameter, we use the principle of continuity (Formula 2), which states that the product of cross-sectional area and velocity remains constant. Given the airflow rate and the change in duct diameter, the new diameter is half of the original. The new area is 1/4 of the original. And therefore the new velocity should be 4 times higher. Pay attention to the unit."
    },
    {
        number: 58,
        description: "If you need to reduce the power consumption of a fan by at least 10%. Which of the following actions will accomplish this?",
        choices: [
            "A. Reduce fan speed by 3%",
            "B. Reduce airflow by 3%",
            "C. Reduce impeller diameter by 3%",
            "D. Reduce pressure by 3%"
        ],
        answer: "C",
        explain: "Use Formula 27. D2/D1 = 0.97. The power will be reduced to 0.97\u2075 = 86%. This is a 14% reduction in power consumption. If the fan speed is reduced by 3%, the power will be reduced to 0.97\u00B3 = 91%, which is a 9% reduction."
    },
    {
        number: 59,
        description: "The static pressure is -3\" wg at the inlet/upstream of a fan,and the velocity pressure is 1\" wg. Downstream of the fan, the static pressure is 2\" wg. What is the fan total pressure?",
        choices: [
            "A. -2 \"wg",
            "B. 2 \"wg",
            "C. 3 \"wg",
            "D. 5 \"wg"
        ],
        answer: "D",
        explain: "Use Formula 29th. Total pressure at inlet = SP + VP = -3 + 1 = -2. If the question does not mention, the velocity pressure stay constant in the downstream. Therefore, the total pressure at outlet = SP + VP = 2 + 1 = 3. Therefore, total fan pressure = 3 - (-2) = 5 \"wg. "
    },
    {
        number: 60,
        description: "What is the static pressure of a fan given a static pressure of -2 in.wc at the inlet, a static pressure of 8 in.wc at the outlet, and a velocity pressure of 1 in.wc?",
        choices: [
            "A. 10 in.wc",
            "B. 24 in.wc",
            "C. 9 in.wc",
            "D. 8 in.wc"
        ],
        answer: "C",
        explain: 'Use Formula 28 in Ventilation. FSP = fan static pressure. SPout = static pressure measured on the outlet side of the fan. SPin =  static pressure measured on the inlet side of the fan. VPin = velocity pressure on the inlet side of the fan.\n\nTherefore, FSP = 8 - (-2) - 1 = 9 in.wc. Pay attention that the inlet Static Pressure will always be counted as negative, and the outlet Static Pressure is always positive. The Velocity Pressure is always positive at both ends.'
    }
]