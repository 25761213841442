export const toxicology_questions = [
    {
        number: 1,
        description: "Which of the following air contaminants is classified as a Group 1 carcinogen by the International Agency for Research on Cancer (IARC)?",
        choices: [
            "A. Carbon monoxide",
            "B. Nitrogen dioxide",
            "C. Benzene",
            "D. Carbon dioxide",
        ],
        answer: "C",
        explain: "The International Agency for Research on Cancer (IARC) has classified benzene as a Group 1 carcinogen, which means that there is sufficient evidence that it causes cancer in humans. Benzene is a colorless, flammable liquid that is used in the production of plastics, rubber, and other products. It is also a common air pollutant, which means that it can be inhaled from the air. Benzene is a volatile organic compound, which means it can easily transition from a liquid to a vapor at room temperature. This characteristic is common for many organic solvents, and it allows them to evaporate into the air."
    },
    {
        number: 2,
        description: "What is the LD50 of a chemical?",
        choices: [
            "A. The maximum tolerable dose of a chemical",
            "B. The minimum lethal dose of a chemical",
            "C. The median lethal dose of a chemical",
            "D. The average lethal dose of a chemical"
        ],
        answer: "C",
        explain: "The LD50 (lethal dose 50) of a chemical is the dose required to cause death in 50% of the test animals exposed to the chemical. It is a measure of the acute toxicity of a substance."
    },
    {
        number: 3,
        description: "In which type of work environment is methylene chloride commonly used, and what are the potential health effects associated with exposure to this chemical?",
        choices: [
            "A. Paint manufacturing -Liver damage and respiratory sensitization",
            "B. Metalworking - Kidney damage and leukemia",
            "C. Degreasing operations - Liver damage and central nervous system depression",
            "D. Agriculture - Respiratory sensitization and skin irritation"
        ],
        answer: 'C',
        explain: 'Methylene chloride, also known as dichloromethane, is commonly used in degreasing operations as a solvent for cleaning and stripping paint, as well as in other industrial processes. Exposure to methylene chloride can result in several health effects, including liver damage. Prolonged or high-level exposure to methylene chloride can cause headaches, dizziness, lightheadedness, nausea, and vomiting. In addition, methylene chloride has been linked to more serious health effects, such as liver and lung damage, central nervous system depression, and cancer.\n\nAdverse health effects of methylene chloride are due both to the parent compound and carbon monoxide which is a metabolite of methylene chloride. Carbon monoxide induces the formation of carboxyhemoglobin, thus depriving the brain from normal oxygen delivery and utilization.'
    },
    {
        number: 4,
        description: "Which of the following statements accurately describes nanoparticles?",
        choices: [
            "A. Nanoparticles are particles with a size ranging from 10 to 100 micrometers.",
            "B. Nanoparticles are typically visible to the naked eye due to their large size.",
            "C. Nanoparticles exhibit unique properties due to their small size and high surface-to-volume ratio.",
            "D. Nanoparticles are only found in natural environments and not in industrial settings."
        ],
        answer: "C",
        explain: "A) Nanoparticles are particles with a size ranging from 10 to 100 micrometers.\n\nThis statement is incorrect. Nanoparticles have a size range of 1 to 100 nanometers in diameter. \n\nB) Nanoparticles are typically visible to the naked eye due to their large size.\n\nThis statement is incorrect. Nanoparticles are extremely small and not visible to the naked eye. They require specialized equipment, such as electron microscopes, to be observed and characterized.\n\nD) Nanoparticles are only found in natural environments and not in industrial settings.\n\nThis statement is incorrect. Nanoparticles can be found in both natural and industrial settings. They can be naturally occurring, such as in volcanic ash or forest fire smoke, but they can also be generated through various industrial processes, such as combustion, manufacturing, or nanotechnology applications."
    },
    {
        number: 5,
        description: "Which of the following best describes pneumoconiosis?",
        choices: [
            "A. An acute respiratory condition caused by exposure to high levels of airborne irritants.",
            "B. A chronic lung disease caused by the inhalation of certain dust particles over an extended period.",
            "C. A bacterial infection affecting the respiratory system and lungs.",
            "D. An allergic reaction triggered by exposure to specific environmental pollutants."
        ],
        answer: "B",
        explain: "Pneumoconiosis is a chronic lung disease that is caused by the inhalation and accumulation of certain types of dust particles over an extended period of time. It is typically associated with occupational exposure to dusts generated during mining, construction, or other industrial activities. These dust particles, such as silica, coal dust, or asbestos fibers, can enter the lungs and cause inflammation, scarring, and damage to the lung tissues.\n\nPneumoconiosis is characterized by the gradual development of fibrosis (scarring) in the lungs, which can impair the normal lung function and lead to symptoms such as coughing, shortness of breath, and decreased exercise tolerance. The severity of the disease depends on the type and amount of dust inhaled, as well as the duration of exposure."
    },
    {
        number: 6,
        description: "Which of the following is a type of pneumoconiosis caused by the inhalation of talc dust?",
        choices: [
            "A. Stannosis",
            "B. Siderosis",
            "C. Silicosis",
            "D. Talcosis"
        ],
        answer: "D",
        explain: "Talcosis is a form of pneumoconiosis caused by the inhalation of talc dust. Stannosis is caused by tin dust or fumes, siderosis by iron or iron oxide dust, and silicosis by silica dust."
    },
    {
        number: 7,
        description: "Which metal oxide is the most common cause of metal fume fever in welders?",
        choices: [
            "A. Cadmium oxide",
            "B. Manganese oxide",
            "C. Zinc oxide",
            "D. Iron oxide"
        ],
        answer: "C",
        explain: "etal fume fever is a disease most often associated with welders. Welding may cause pulmonary inflammation from the submicron particles of metal oxides in the fumes. Most often, the metal oxide is zinc oxide, but cadmium and manganese and their oxides are also present in some welding processes.\n\nSymptoms of metal fume fever include fever, chills, muscle aches, fatigue, and a metallic taste in the mouth."
    },
    {
        number: 8,
        description: "What is the primary health concern associated with chronic exposure to vinyl chloride?",
        choices: [
            "A. Central nervous system effects",
            "B. Respiratory issues",
            "C. Liver damage",
            "D. Kidney failure"
        ],
        answer: "C",
        explain: "Most vinyl chloride is used to make polyvinyl chloride (PVC) plastic and vinyl products.  Acute (short-term) exposure to high levels of vinyl chloride in air has resulted in central nervous system effects (CNS), such as dizziness, drowsiness, and headaches in humans.  Chronic (long-term) exposure to vinyl chloride through inhalation and oral exposure in humans has resulted in liver damage.  Cancer is a major concern from exposure to vinyl chloride via inhalation, as vinyl chloride exposure has been shown to increase the risk of liver cancer in humans.  EPA has classified vinyl chloride as a Group A, human carcinogen."
    },
    {
        number: 9,
        description: "In toxicology studies, what does NOAEL (No Observed Adverse Effect Level) represent?",
        choices: [
            "A. The highest dose of a substance that causes adverse effects in test animals.",
            "B. The lowest dose of a substance that causes adverse effects in test animals.",
            "C. The highest dose of a substance at which no adverse effects are observed in test animals.",
            "D. The lowest dose of a substance at which no adverse effects are observed in test animals."
        ],
        answer: "C",
        explain: "NOAEL (No Observed Adverse Effect Level) is the highest dose or exposure level of a substance at which no adverse effects or significant toxicological changes are observed in a specific test population, typically animals. It is an important parameter used in determining safe exposure limits for humans and assessing the potential risks associated with chemical exposures."
    },
    {
        number: 10,
        description: "Which of the following statements accurately describes the terms 'In Vitro' and 'In Vivo' in toxicology studies?",
        choices: [
            "A. 'In Vitro' refers to experiments conducted on live organisms, while 'In Vivo' refers to experiments conducted on isolated cells or tissues.",
            "B. 'In Vitro' refers to experiments conducted on isolated cells or tissues, while 'In Vivo' refers to experiments conducted on live organisms.",
            "C. 'In Vitro' and 'In Vivo' are interchangeable terms representing experiments conducted in laboratory settings.",
            "D. 'In Vitro' and 'In Vivo' both refer to the same experimental approach, but with different names used in different scientific disciplines."
        ],
        answer: "B",
        explain: "In toxicology studies, 'In Vitro' refers to experiments that are conducted in a controlled laboratory setting using isolated cells, tissues, or organs outside of their normal biological context. These experiments are typically performed in test tubes or culture dishes and allow researchers to directly expose the cells or tissues to a substance of interest. 'In Vitro' studies are useful for investigating specific mechanisms of toxicity, studying cellular responses, and screening potential hazards.\n\nOn the other hand, 'In Vivo' experiments involve studying the effects of a substance on living organisms, typically animals. In these experiments, the substance is administered to the whole organism, either through ingestion, inhalation, or injection, to observe its effects within the intact biological system. 'In Vivo' studies provide a more comprehensive understanding of the substance's toxicity, taking into account factors such as absorption, distribution, metabolism, and excretion."
    },
    {
        number: 11,
        description: "Which of the following statements best explains why children are more vulnerable to toxicants absorbed through the skin?",
        choices: [
            "A. Children have a higher metabolic rate, leading to faster absorption of toxicants.",
            "B. Children have a thicker and more mature skin barrier, reducing the absorption of toxicants.",
            "C. Children have a smaller body mass, resulting in higher concentration of toxicants per unit of body weight.",
            "D. Children have a more efficient detoxification system, allowing them to eliminate toxicants more effectively."
        ],
        answer: "C",
        explain: "Children have a higher body surface area to body weight ratio compared to adults, which means that the same amount of toxicant absorbed through the skin can result in a higher concentration in their bodies. This increased concentration can potentially lead to greater toxicity and adverse effects. Additionally, children's organs and systems, including their detoxification system, are still developing and may not be as efficient at processing and eliminating toxicants compared to adults."
    },
    {
        number: 12,
        description: "Which of the following best describes antagonistic effects in toxicology?",
        choices: [
            "A. Antagonistic effects occur when two or more toxic substances interact to enhance each other's toxicity.",
            "B. Antagonistic effects occur when a non-toxic substance makes another toxic substance more toxic.",
            "C. Antagonistic effects occur when the toxic effects of a substance are potentiated by the presence of other substances.",
            "D. Antagonistic effects occur when the presence of one substance reduces or counteracts the toxic effects of another substance."
        ],
        answer: "D",
        explain: "Antagonistic effects refer to the interaction between two or more substances where the presence of one substance reduces or counteracts the toxic effects of another substance. In this interaction, the combined effect of the substances is lower than what would be expected based on their individual effects."
    },
    {
        number: 13,
        description: "PM2.5 refers to particulate matter with a diameter of:",
        choices: [
            "A. 2.5 centimeters or smaller",
            "B. 2.5 millimeters or smaller.",
            "C. 2.5 micrometers or smaller.",
            "D. 2.5 nanometers or smaller."
        ],
        answer: "C",
        explain: "PM2.5 has been identified as a major air pollutant that can have significant adverse health effects on human beings. These fine particles, when inhaled, can penetrate deep into the respiratory system and reach the lungs, potentially causing or exacerbating respiratory and cardiovascular problems, including asthma, bronchitis, heart attacks, and premature death."
    },
    {
        number: 14,
        description: "Which of the following definitions accurately describes aerosol?",
        choices: [
            "A. Aerosol refers to the suspension of liquid droplets in the air, typically larger than 100 micrometers (μm) in diameter.",
            "B. Aerosol refers to the suspension of solid particles in the air, typically smaller than 1 micrometer (μm) in diameter.",
            "C. Aerosol refers to the dispersion of gas molecules in the air, resulting in a homogeneous mixture.",
            "D. Aerosol refers to the suspension of solid, liquid, or combination of particles in the air, ranging in size from nanometers to micrometers."
        ],
        answer: "D",
        explain: "Aerosol refers to a suspension of solid or liquid particles in a gas, typically air. It is a colloidal system in which tiny particles, known as aerosol particles, are dispersed and suspended in the air. These particles can vary in size, ranging from nanometers to micrometers. \n\nAerosols can be generated naturally, such as through volcanic eruptions or dust storms, or they can be created artificially through processes like combustion, spraying, or grinding. Aerosols play a significant role in various fields, including atmospheric science, environmental monitoring, and industrial hygiene, as they can impact air quality, climate, and human health."
    },
    {
        number: 15,
        description: "Which of the following definitions accurately describes dusts, mists, smoke, and fumes?",
        choices: [
            "A. Dusts are solid particles generated through mechanical processes, mists are liquid droplets suspended in the air, smoke is a mixture of solid and liquid particles resulting from combustion, and fumes are particles formed by condensation of vaporized solid materials.",
            "B. Dusts are liquid droplets suspended in the air, mists are solid particles generated through mechanical processes, smoke is a mixture of solid and liquid particles resulting from combustion, and fumes are particles formed by condensation of vaporized solid materials.",
            "C. Dusts are solid particles generated through mechanical processes, mists are particles formed by condensation of vaporized liquid materials, smoke is a mixture of solid and liquid particles resulting from combustion, and fumes are liquid droplets suspended in the air.",
            "D. There is no distinction between those terms."
        ],
        answer: "A",
        explain: "Dusts: Dusts are solid particles that are generated through mechanical processes such as grinding, crushing, or handling of materials. They are typically larger in size and can be composed of various materials, such as minerals, metals, or organic substances.\n\nMists: Mists are liquid droplets that are suspended in the air. They are formed when a liquid material is atomized or dispersed into small droplets, usually through processes such as spraying, fogging, or condensation. Mists can vary in size and composition depending on the liquid being dispersed.\n\nSmoke: Smoke is a mixture of solid and liquid particles that results from the combustion or burning of materials. It is typically generated from processes such as industrial processes, burning of fuels, or fires. Smoke particles are usually very small and can contain a combination of solid carbon particles, liquid droplets, and gases.\n\nFumes: Fumes refer to particles that are formed by the condensation of vaporized solid materials. They are typically generated when solid materials are heated to a high temperature, causing them to vaporize and then re-condense into small particles. Fumes are often associated with processes such as welding, soldering, or high-temperature industrial operations."
    },
    {
        number: 16,
        description: 'What is the ACGIH TLV for hydrogen fluoride (HF), primarily intended to guard against?',
        choices: [
            "A. Eye irritation",
            "B. Skin allergies",
            "C. Respiratory tract irritation",
            "D. Neurological disorders"
        ],
        answer: "C",
        explain: "Hydrogen fluoride is used in the production of aluminum and chlorofluorocarbons, and in the glass etching and chemical industries. Acute (short-term) inhalation exposure to gaseous hydrogen fluoride can cause severe respiratory damage in humans, including severe irritation and lung edema."
    },
    {
        number: 17,
        description: "How does the windpipe protect the respiratory system from potential infections?",
        choices: [
            "A. It produces a thin layer of oil to repel pathogens.",
            "B. It houses immune cells and antibodies in its mucus lining.",
            "C. It contracts to reduce the risk of foreign particle entry.",
            "D. It absorbs harmful bacteria and viruses during inhalation."
        ],
        answer: "B",
        explain: "The windpipe, or trachea, protects the respiratory system from potential infections by housing immune cells and antibodies in its mucus lining. The mucus lining the trachea contains specialized immune cells that can detect and neutralize pathogens, such as bacteria and viruses, before they can cause harm. These immune cells work together with antibodies to identify and eliminate potential threats, providing an important defense mechanism against respiratory infections. \n\nAdditionally, the mucus traps and removes pathogens from the air, preventing them from reaching the deeper parts of the respiratory system and causing infections."
    },
    {
        number: 18,
        description: "Which of the following statements accurately describes hydrogen cyanide (HCN)?",
        choices: [
            "A. Hydrogen cyanide is a naturally occurring gas found in abundance in the atmosphere.",
            "B. Hydrogen cyanide is a highly corrosive acid commonly used in industrial cleaning processes.",
            "C. Hydrogen cyanide is a colorless, flammable gas with a distinctive bitter almond odor.",
            "D. Hydrogen cyanide is an inert gas commonly used as a fire suppressant in industrial settings."
        ],
        answer: "C",
        explain: "Hydrogen cyanide (HCN) is a colorless or pale-blue liquid or gas with a bitter, almond-like odor. Hydrogen cyanide interferes with the body’s use of oxygen and may cause harm to the brain, heart, blood vessels, and lungs. Exposure can be fatal. Workers may be harmed from exposure to hydrogen cyanide. It is produced naturally in some plants and is also produced synthetically for various industrial applications.\n\n Hydrogen cyanide is a weak acid, meaning that it does not dissociate completely in water. This means that it does not have the same corrosive properties as strong acids, such as hydrochloric acid or sulfuric acid.\n\nIn industrial settings, hydrogen cyanide is used in processes such as fumigation, electroplating, and the production of certain chemicals. It can be released as a byproduct of combustion, particularly in fires involving materials that contain nitrogen and carbon, such as plastics and textiles.\n\nExposure to hydrogen cyanide can occur through inhalation, ingestion, or skin contact. Inhalation of high concentrations can lead to rapid poisoning, affecting the respiratory and cardiovascular systems. Symptoms of acute exposure may include difficulty breathing, rapid breathing, headache, dizziness, nausea, and confusion. Severe exposure can result in loss of consciousness, seizures, and even death.\n\nIt's important to note that hydrogen cyanide has been used historically as a chemical warfare agent due to its extreme toxicity. However, its use in this capacity is regulated and controlled by international treaties and conventions."
    },
    {
        number: 19,
        description: "Which of the following statements accurately describes hydrogen sulfide (H\u2082S)?",
        choices: [
            "A. Hydrogen sulfide is a colorless gas with a sweet, fruity odor.",
            "B. Hydrogen sulfide is a non-toxic gas commonly used in the food and beverage industry.",
            "C. Hydrogen sulfide is a naturally occurring gas found in abundance in the atmosphere.",
            "D. Hydrogen sulfide is a highly toxic gas with a characteristic rotten egg odor."
        ],
        answer: "D",
        explain: "Hydrogen sulfide (H\u2082S) is a toxic gas that consists of two hydrogen atoms bonded to a sulfur atom. It is commonly known for its strong odor, which resembles the smell of rotten eggs. Hydrogen sulfide is produced naturally by various biological processes, such as the breakdown of organic matter in anaerobic conditions.\n\nHydrogen sulfide is highly toxic and can pose serious health risks. Even at low concentrations, it can irritate the respiratory system, eyes, and skin. At higher concentrations, it can cause more severe effects, including headaches, dizziness, nausea, difficulty breathing, and loss of consciousness. Prolonged exposure to high levels of hydrogen sulfide can result in serious injury or even death.\n\nDue to its toxicity, hydrogen sulfide is regulated in many jurisdictions, and strict control measures are implemented in workplaces to minimize the risk of exposure. Personal protective equipment, such as gas masks or respirators, may be required when working in areas where hydrogen sulfide is present.\n\nMonitoring and detection methods, such as gas detectors or hydrogen sulfide meters, are used to measure the concentration of hydrogen sulfide in the air to ensure that exposure levels are within safe limits. Proper ventilation and engineering controls are also implemented to minimize the accumulation of hydrogen sulfide in confined spaces or enclosed areas."
    },
    {
        number: 20,
        description: "Which type of hydrocarbon is considered the most toxic?",
        choices: [
            "A. Aliphatic",
            "B. Aromatic",
            "C. Halogenated",
            "D. Terpene"
        ],
        answer: "B",
        explain: "Hydrocarbons, compounds comprised of hydrogen and carbon, come in four structural classes, each with its own characteristics: \n\nAromatic hydrocarbons contain a benzene ring and are considered the most toxic. They are commonly used in solvents, glues, paint, and paint remover.\n\nAliphatic hydrocarbons are petroleum distillates found in polishes, lamp oils, and lighter fluid.\n\nHalogenated hydrocarbons are fluorinated, chlorinated, or brominated, and are used for refrigeration (e.g., freon) and as insecticides and herbicides. Their toxicity can vary depending on multiple variables, including compound properties, viscosity, surface tension, volatility, and additives.\n\nTerpene hydrocarbons are found in turpentine and pine oil. Some of these hydrocarbons may be found in various mixed forms and used as an aerosol spray propellant."
    },
    {
        number: 21,
        description: "Which of the following conditions is commonly associated with chronic lead exposure?",
        choices: [
            "A. Wrist drop",
            "B. Anemia",
            "C. Skin rash",
            "D. Vision loss"
        ],
        answer: "A",
        explain: "Chronic exposure to lead can cause a condition known as 'wrist drop', which is characterized by weakness in the extensor muscles of the wrist and fingers, leading to an inability to extend the wrist and fingers. This occurs due to lead's neurotoxic effects, which can damage peripheral nerves."
    },
    {
        number: 22,
        description: "Which of the following options correctly differentiates between a simple asphyxiant and a chemical asphyxiant?",
        choices: [
            "A. A simple asphyxiant is a substance that displaces oxygen in the air, while a chemical asphyxiant is a toxic gas that interferes with the body's ability to utilize oxygen.",
            "B. A simple asphyxiant is a toxic gas that interferes with the body's ability to utilize oxygen, while a chemical asphyxiant is a substance that displaces oxygen in the air.",
            "C. A simple asphyxiant and a chemical asphyxiant are two terms used interchangeably to describe substances that displace oxygen in the air.",
            "D. A simple asphyxiant and a chemical asphyxiant both refer to substances that directly poison the respiratory system, leading to asphyxiation."
        ],
        answer: "A",
        explain: "A simple asphyxiant is a substance that can cause asphyxiation by displacing or diluting the oxygen in the air. Examples of simple asphyxiants include gases like nitrogen, helium, and argon. These gases do not have any inherent toxic properties but can be dangerous if they displace the oxygen necessary for breathing, leading to suffocation.\n\nOn the other hand, a chemical asphyxiant is a toxic gas that interferes with the body's ability to utilize oxygen, even when present in sufficient amounts. Chemical asphyxiants, such as carbon monoxide (CO) and hydrogen sulfide (H2S), bind to hemoglobin in the blood or interfere with cellular respiration, preventing the delivery of oxygen to tissues. These gases can cause severe health effects and even death, even in the presence of normal oxygen levels."
    },
    {
        number: 23,
        description: "Which of the following statements accurately describes carbon disulfide (CS\u2082)?",
        choices: [
            "A. Carbon disulfide is a colorless gas commonly used as a refrigerant in industrial cooling systems.",
            "B. Carbon disulfide is a highly corrosive liquid used as a solvent in various industrial processes.",
            "C. Carbon disulfide is a non-toxic gas commonly found in the atmosphere as a natural component.",
            "D. Carbon disulfide is a flammable gas commonly used as a fuel for heating and combustion processes."
        ],
        answer: "B",
        explain: "Carbon disulfide (CS\u2082) is a colorless liquid with an ether-like odor. It is highly volatile. It is commonly used as a solvent in various industrial processes, particularly in the production of viscose rayon, cellophane, and rubber.\n\nCarbon disulfide is known for its strong odor, which is often described as similar to rotten cabbage. It is highly toxic and can cause a range of health effects when exposed to it. Inhalation of carbon disulfide vapors can irritate the respiratory system, cause headaches, dizziness, and even lead to more severe effects on the nervous system."
    },
    {
        number: 24,
        description: "Which of the following statements about mercury as a neurotoxin is true?",
        choices: [
            "A. Only elemental mercury is toxic to humans.",
            "B. The health effects of mercury exposure are independent of the duration of exposure.",
            "C. Unborn infants are particularly vulnerable to mercury exposure.",
            "D. Skin contact with mercury is the only significant route of exposure."
        ],
        answer: "C",
        explain: "Mercury is a neurotoxin, and its health effects depend on several factors, including the form of mercury (e.g., methylmercury or elemental mercury), the amount of mercury in the exposure, the age of the person exposed (with unborn infants being the most vulnerable), the duration of the exposure, the route of exposure (breathing, eating, skin contact), and the overall health of the person exposed."
    },
    {
        number: 25,
        description: "Exposure to methylmercury most commonly occurs through which of the following?",
        choices: [
            "A. Inhalation of contaminated air.",
            "B. Eating fish and shellfish with high levels of methylmercury.",
            "C. Drinking water contaminated with elemental mercury.",
            "D. Skin contact with mercury-containing products."
        ],
        answer: "B",
        explain: "Exposure to methylmercury most commonly occurs when people eat kinds of fish and shellfish that have high levels of methylmercury in their tissues. Almost all people have at least small amounts of methylmercury in their bodies, reflecting its widespread presence in the environment. Methylmercury is a powerful neurotoxin, and high levels of exposure can lead to adverse health effects. Possible symptoms of methylmercury poisoning include loss of peripheral vision, 'pins and needles' feelings, lack of coordination, impairment of speech, hearing, and walking, and muscle weakness. The U.S. CDC data show that most people have blood mercury levels below levels associated with possible health effects."
    }
    ,
    {
        number: 26,
        description: "Which of the following statements accurately describes argyria?",
        choices: [
            "A. Argyria is a lung disease caused by exposure to airborne particulates in industrial settings.",
            "B. Argyria is a condition characterized by a blue-gray discoloration of the skin and mucous membranes due to the deposition of silver particles.",
            "C. Argyria is an acute poisoning syndrome caused by ingestion of contaminated food or water containing high levels of heavy metals.",
            "D. Argyria is a chronic respiratory condition caused by inhalation of asbestos fibers in occupational settings."
        ],
        answer: "B",
        explain: "Argyria is a condition characterized by the deposition of silver particles in the skin and mucous membranes, resulting in a distinctive blue-gray discoloration. It occurs as a result of prolonged exposure to or ingestion of silver or silver compounds.\n\nSilver particles can be deposited in the skin and other tissues through various routes, such as occupational exposure to silver dust or fumes, ingestion of silver-containing medications or supplements, or use of certain topical products containing silver. Over time, these silver particles accumulate and react with sunlight or other sources of light, causing the skin to turn a bluish-gray color.\n\nArgyria is considered a cosmetic condition and does not typically cause any other significant health problems. However, the visible discoloration of the skin can have psychological and social impacts on individuals affected by it."
    },
    {
        number: 27,
        description: "Which of the following statements accurately describes selenium?",
        choices: [
            "A. Selenium is a highly toxic element commonly found in industrial waste.",
            "B. Selenium is an essential trace element that is necessary for the proper functioning of various enzymes in the body.",
            "C. Selenium is a radioactive isotope used in medical imaging and cancer treatment.",
            "D. Selenium is a volatile gas known for its strong odor and irritating effects on the respiratory system."
        ],
        answer: "B",
        explain: "Selenium is an essential trace element that is required for the proper functioning of several enzymes in the body. While it is important for human health in small amounts, excessive selenium exposure can lead to adverse health effects. The health effects of selenium depend on the dose and duration of exposure.\n\nInadequate selenium intake can result in selenium deficiency, which can lead to health problems such as muscle weakness, fatigue, impaired immune function, and cognitive decline.\n\nOn the other hand, excessive selenium intake, usually from contaminated water or certain foods, can cause a condition called selenosis. Symptoms of selenosis can include hair and nail brittleness, skin rashes, gastrointestinal disturbances, and garlic-like breath odor. In severe cases, it can lead to neurological symptoms and damage to the liver and kidneys."
    },
    {
        number: 28,
        description: "Which of the following routes of exposure is the most common and significant for lead in occupational settings?",
        choices: [
            "A. Inhalation of lead fumes and dust particles",
            "B. Ingestion of contaminated food and water",
            "C. Skin contact with lead-containing materials",
            "D. Injection of lead-containing substances through accidental needlestick injuries"
        ],
        answer: "A",
        explain: "Inhalation of lead fumes and dust particles is the most common and significant route of exposure to lead in occupational settings. Lead is often present in the form of fumes and dust generated during various industrial processes such as smelting, welding, and battery manufacturing. These airborne particles can be easily inhaled and absorbed into the body, posing a significant risk of lead poisoning. Other routes of exposure, such as ingestion and skin contact, may also occur but are generally less significant compared to inhalation."
    },
    {
        number: 29,
        description: "Which of the following health effects is associated with lead exposure?",
        choices: [
            "A. Skin rashes and dermatitis",
            "B. Gastrointestinal disorders and food poisoning",
            "C. Neurological and cognitive impairments",
            "D. Respiratory diseases and lung cancer"
        ],
        answer: "C",
        explain: "Lead exposure is known to have significant neurological and cognitive effects. When lead enters the body, it can cross the blood-brain barrier and accumulate in the central nervous system. This can lead to various neurological symptoms and impairments, particularly in children.\n\nIn children, lead exposure can cause developmental delays, learning difficulties, reduced IQ, and behavioral problems. It can affect attention span, memory, and academic performance. Prolonged or high levels of lead exposure in children can result in permanent neurological damage.\n\nIn adults, lead exposure can also affect the nervous system, leading to symptoms such as memory loss, mood disorders, and difficulties with coordination and balance. Additionally, chronic lead exposure in adults may contribute to increased risk of hypertension, cardiovascular diseases, and kidney damage."
    },
    {
        number: 30,
        description: "Which of the statement is true about chelation therapy?",
        choices: [
            "A. Chelation therapy is a preventive measure used to remove heavy metals from the body before exposure occurs.",
            "B. Chelation therapy is a medical treatment that uses chemical agents to bind and remove toxic metals from the body.",
            "C. Chelation therapy is a process of administering high doses of essential minerals to counteract the effects of heavy metal poisoning.",
            "D. Chelation therapy is a form of alternative medicine that focuses on balancing the body's energy and removing toxins through specialized diets and therapies."
        ],
        answer: "B",
        explain: "Chelation therapy is a medical treatment that involves the administration of chelating agents, which are chemical compounds capable of binding to heavy metals in the body.These chelating agents form stable complexes with the metals, allowing them to be excreted from the body through urine or feces.The purpose of chelation therapy is to remove toxic metals, such as lead, mercury, or arsenic, from the body and reduce their harmful effects."
    },
    {
        number: 31,
        description: "Which of the following is a major health risk associated with long-term exposure to arsenic in drinking water and food?",
        choices: [
            "A. Respiratory infections",
            "B. Skin lesions and cancer",
            "C. Acute kidney failure",
            "D. Bone fractures"
        ],
        answer: "B",
        explain: "Arsenic is highly toxic in its inorganic form and is naturally present at high levels in the groundwater of several countries. Contaminated water used for drinking, food preparation, and irrigation of food crops poses the greatest threat to public health from arsenic. Long-term exposure to arsenic from drinking-water and food can cause cancer and skin lesions. It has also been associated with cardiovascular disease and diabetes. In utero and early childhood exposure has been linked to negative impacts on cognitive development and increased deaths in young adults. The most important action in affected communities is the prevention of further exposure to arsenic by provision of a safe water supply."
    },
    {
        number: 32,
        description: "Which of the following statements about methyl ethyl ketone (MEK) is true?",
        choices: [
            "A. MEK is a colorless, non-flammable liquid with no odor.",
            "B. Exposure to MEK is only harmful if swallowed.",
            "C. Workers in printing plants, shoe factories, and those exposed to certain paints, coatings, or glues are at risk of exposure to MEK.",
            "D. MEK is not used in the manufacture of synthetic rubber products."
        ],
        answer: "C",
        explain: "Methyl ethyl ketone (MEK) is a colorless, flammable liquid with a sharp odor. It can be harmful to the eyes, skin, and if inhaled or swallowed. Workers may be harmed from exposure to MEK, with the level of exposure depending on the dose, duration, and type of work being done. MEK is used as a solvent and in the manufacture of synthetic rubber, paraffin wax, and other chemical products. Workers in printing plants, those exposed to certain paints, coatings, or glues, and those in shoe and sporting goods factories are at risk of being exposed to MEK."
    }
    ,
    {
        number: 33,
        description: "Which of the following statements about formaldehyde is true?",
        choices: [
            "A. Formaldehyde is a colorless, non-toxic, and non-flammable gas at room temperature.",
            "B. Formaldehyde is only used in the production of fertilizers and paper.",
            "C. Exposure to formaldehyde can irritate the skin, throat, lungs, and eyes, and repeated exposure can possibly lead to cancer.",
            "D. Only morticians are at risk of being exposed to formaldehyde."
        ],
        answer: "C",
        explain: "Formaldehyde (CH₂O) is a colorless, highly toxic, and flammable gas at room temperature with a strong pickle-like odor. It is used in the production of fertilizer, paper, plywood, and some resins. It is also used as a food preservative and in household products, such as antiseptics, medicines, and cosmetics. Exposure to formaldehyde can irritate the skin, throat, lungs, and eyes. Repeated exposure to formaldehyde can possibly lead to cancer. Workers may be harmed by exposure to formaldehyde, with the level of exposure depending on the dose, duration, and type of work being done. Formaldehyde is used in many industries, including glues and resins, dyes, textiles, disinfectants, building materials, automobile parts, embalming, and laboratories. Examples of workers at risk include agricultural workers, construction workers, workers in plastics and resin manufacturing, morticians, and beauticians."
    },
    {
        number: 34,
        description: "Which of the following statements accurately describes the use and health effects of benzene?",
        choices: [
            "A. Benzene is primarily used as a food additive and has no significant health effects on human exposure.",
            "B. Benzene is commonly used as a solvent in various industries, and inhalation or skin exposure can lead to harmful health effects, including cancer.",
            "C. Benzene is a naturally occurring gas found in the atmosphere, and long-term exposure has been shown to have positive health benefits.",
            "D. Benzene is used as a fire suppressant in industrial settings, and inhalation exposure can cause immediate respiratory distress."
        ],
        answer: "B",
        explain: "Benzene is a widely used chemical in various industries, including the production of plastics, rubber, dyes, detergents, and pharmaceuticals. It is commonly used as a solvent due to its excellent ability to dissolve other substances.\n\nBenzene is highly flammable and is also known to be highly toxic and poses significant health risks to humans. Inhalation or skin exposure to benzene can lead to various harmful health effects. The most notable and concerning health effect is its carcinogenic properties. Benzene exposure has been linked to the development of leukemia, particularly acute myeloid leukemia (AML). Long-term exposure to benzene has also been associated with other cancers, such as lymphomas and multiple myeloma.\n\nIn addition to its carcinogenic effects, benzene exposure can cause other health problems. It is known to irritate the respiratory system, leading to symptoms such as coughing, wheezing, and shortness of breath. Benzene can also affect the central nervous system, causing symptoms like dizziness, headaches, confusion, and even loss of consciousness in severe cases."
    },
    {
        number: 35,
        description: "Which of the following statements accurately describes Biological Exposure Indices (BEIs)?",
        choices: [
            "A. BEIs are numerical values that represent the maximum allowable concentrations of airborne contaminants in the workplace.",
            "B. BEIs are guidelines established by regulatory agencies to assess the potential health risks associated with exposure to biological hazards in the workplace.",
            "C. BEIs are biomarkers or biological indicators used to evaluate and monitor occupational exposure to specific substances through analysis of biological samples, such as blood or urine.",
            "D. BEIs are safety measures implemented in industrial settings to prevent biological hazards and protect workers from potential exposure."
        ],
        answer: "C",
        explain: "BEIs are guidelines established by recognized scientific and professional organizations, such as the American Conference of Governmental Industrial Hygienists (ACGIH), and they provide recommendations for the levels of certain substances or their metabolites in biological samples that are associated with occupational exposure. These indices help assess the extent of exposure to hazardous substances and assist in determining appropriate control measures to protect workers' health."
    },
    {
        number: 36,
        description: "Which of the following statements about toluene-induced hearing loss is true?",
        choices: [
            "A. Toluene exposure is primarily associated with visual impairments.",
            "B. Toluene exposure has no impact on the auditory system.",
            "C. Chronic exposure to toluene can cause hearing loss, especially at high frequencies.",
            "D. Toluene is a non-toxic solvent used only in small-scale laboratory settings."
        ],
        answer: "C",
        explain: "Toluene is an aromatic hydrocarbon commonly used as an industrial solvent. Chronic exposure to toluene, particularly in occupational settings, has been associated with hearing loss, particularly at high frequencies. This auditory impairment results from toluene’s neurotoxic effects on the auditory system. Toluene exposure is also linked to other health issues, including effects on the central nervous system, but hearing loss is a specific risk for those with prolonged exposure."
    },
    {
        number: 37,
        description: "Which of the following statements accurately describes the use and health effects of carbon tetrachloride?",
        choices: [
            "A. Carbon tetrachloride is commonly used as a food preservative and has no significant health effects on human exposure.",
            "B. Carbon tetrachloride can be used as a solvent in various industrial processes, and exposure through inhalation or skin contact can lead to harmful health effects, including liver and kidney damage.",
            "C. Carbon tetrachloride is a naturally occurring gas found in the environment, and long-term exposure has been shown to have positive health benefits.",
            "D. Carbon tetrachloride is used as a fuel in industrial settings, and inhalation exposure can cause immediate respiratory distress."
        ],
        answer: "B",
        explain: "Carbon tetrachloride is indeed used as a solvent in various industrial applications such as cleaning agents, degreasers, and as a precursor in the production of refrigerants and plastics. Inhalation or skin contact with carbon tetrachloride can result in serious health effects, particularly affecting the liver and kidneys. It is important to note that carbon tetrachloride is a hazardous substance and its use has been significantly reduced due to its toxic nature and environmental concerns."
    },
    {
        number: 38,
        description: "Which of the following statements about silica is true?",
        choices: [
            "A. Both crystalline and amorphous silica are known human carcinogens.",
            "B. Amorphous silica exposure can cause silicosis, a serious lung disease.",
            "C. Crystalline silica exposure at levels normally found in the environment causes lung cancer.",
            "D. Prolonged inhalation of crystalline silica particles that are small enough to reach the lungs can cause lung cancer and silicosis."
        ],
        answer: "D",
        explain: "Silica is found naturally in the environment and can come in different forms, including crystalline and amorphous silica. The most common type of crystalline silica is quartz. Crystalline silica is known to cause lung cancer when particles small enough to reach the lungs are inhaled over a long period. This has been classified as a known human carcinogen by the Department of Health and Human Services and the International Agency for Research on Cancer. Crystalline silica exposure can also cause silicosis, COPD, kidney failure, autoimmune diseases, and increased susceptibility to tuberculosis. In contrast, there are no known health effects from exposure to amorphous silica at the levels found in the environment or commercial products, though some reports suggest it can cause respiratory diseases in workers."
    },
    {
        number: 39,
        description: "Which of the following statements accurately describes the use and health effects of asbestos?",
        choices: [
            "A. Asbestos is commonly used as a food preservative and has no significant health effects on human exposure.",
            "B. Asbestos is widely used as a flame retardant in various industrial processes, and inhalation exposure can lead to harmful health effects, including lung diseases and cancer.",
            "C. Asbestos is a naturally occurring gas found in the atmosphere, and long-term exposure has been shown to have positive health benefits.",
            "D. Asbestos is used as a fuel in industrial settings, and inhalation exposure can cause immediate cardiovascular effects."
        ],
        answer: "B",
        explain: "Asbestos is a naturally occurring mineral fiber that was commonly used in various industries due to its heat resistance and durability. It was widely used as a flame retardant in insulation materials, construction products, and automotive parts.\n\nThe health effects of asbestos exposure are well-documented and can be severe. Inhalation of asbestos fibers is the primary route of exposure, and long-term exposure can lead to lung diseases such as asbestosis, lung cancer, and mesothelioma, a rare and aggressive form of cancer that affects the lining of the lungs, abdomen, or heart.\n\nIt is important to note that asbestos is a known carcinogen, and there is no safe level of exposure. Therefore, strict regulations and safety measures have been implemented to protect workers from asbestos exposure in industrial settings."
    },
    {
        number: 40,
        description: "How does chronic exposure to high levels of manganese affect the risk of developing Parkinsonism?",
        choices: [
            "A. It has no effect on Parkinsonism.",
            "B. It decreases the risk of developing Parkinsonism.",
            "C. It increases the risk of developing Parkinsonism.",
            "D. It causes immediate symptoms of Parkinsonism."
        ],
        answer: "C",
        explain: "Chronic exposure to high levels of manganese increases the risk of developing Parkinsonism. Manganese-induced Parkinsonism is a condition that shares similarities with Parkinson's disease, including symptoms such as tremors, muscle stiffness, and impaired movement. This condition is caused by the accumulation of manganese in the brain, which can occur through occupational exposure or certain medical conditions."
    },
    {
        number: 41,
        description: "Which of the following is a carcinogen?",
        choices: [
            "A. Asbestos",
            "B. Benzene",
            "C. Formaldehyde",
            "D. All of the above"
        ],
        answer: "D",
        explain: "Asbestos, benzene, and formaldehyde are all known carcinogens. They have been classified as Group 1 carcinogens by the International Agency for Research on Cancer (IARC), indicating that there is sufficient evidence to establish their carcinogenicity in humans. These substances have been linked to various types of cancer and are associated with increased health risks when individuals are exposed to them."
    },
    {
        number: 42,
        description: "Which of the following statements about new composite wood products is true?",
        choices: [
            "A. They do not emit any volatile organic compounds (VOCs).",
            "B. They are not associated with health risks.",
            "C. They can emit benzene, a volatile organic compound (VOC).",
            "D. They are not used in the construction industry."
        ],
        answer: "C",
        explain: "New composite wood products can emit benzene, a volatile organic compound (VOC), which is a health concern. VOC emissions from composite wood products can contribute to indoor air pollution and can have negative effects on human health. It is important to consider these emissions when using composite wood products in construction or indoor environments."
    },
    {
        number: 43,
        description: "Why are tungsten carbide and cobalt usually found together in industrial settings, potentially leading to metal fume fever?",
        choices: [
            "A. To increase the hardness of cobalt.",
            "B. To decrease the toxicity of tungsten carbide.",
            "C. To form a hard and durable material called cemented carbide.",
            "D. To reduce the cost of tungsten carbide production."
        ],
        answer: "C",
        explain: "Tungsten carbide and cobalt are often combined in industrial settings to form a hard and durable material called cemented carbide or hardmetal. This material is widely used in cutting tools, mining tools, and wear-resistant parts due to its excellent hardness and toughness. However, when tungsten carbide and cobalt are heated to high temperatures, they can produce fumes containing fine particles of tungsten carbide and cobalt, which can cause metal fume fever and hard metal lung diseases when inhaled."
    }
    ,
    {
        number: 44,
        description: "What are the initial symptoms of breathing high concentrations of n-hexane?",
        choices: [
            "A. Numbness in the feet and hands",
            "B. Muscle weakness in the feet and lower legs",
            "C. Dizziness and nausea",
            "D. Headaches and chest tightness"
        ],
        answer: "A",
        explain: "Breathing high concentrations of n-hexane can cause numbness in the feet and hands as an initial symptom, followed by muscle weakness in the feet and lower legs. n-Hexane is a naturally occurring chemical produced by plants, forest fires, and volcanos. It is also made from crude oil. Pure n-hexane is a colorless liquid with a slightly disagreeable odor. It is highly flammable, and its vapors can be explosive. Pure n-hexane is used in laboratories. Most of the n-hexane used in industry is mixed with similar chemicals called solvents. The major use for solvents containing n-hexane is to extract vegetable oils from crops such as soybeans."
    },
    {
        number: 45,
        description: "Which of the following statements accurately describes the use and health effects of cadmium?",
        choices: [
            "A. Cadmium is commonly used as a food preservative and has no significant health effects on human exposure.",
            "B. Cadmium is widely used in various industrial processes, such as battery manufacturing and metal plating, and exposure can lead to harmful health effects, including lung and kidney damage.",
            "C. Cadmium is a naturally occurring gas found in the atmosphere, and long-term exposure has been shown to have positive health benefits.",
            "D. Cadmium is used as a fuel additive in industrial settings, and inhalation exposure can cause immediate cardiovascular effects."
        ],
        answer: "B",
        explain: "Occupational exposure to cadmium can lead to a variety of adverse health effects including cancer. Acute inhalation exposure (high levels over a short period of time) to cadmium can result in flu-like symptoms (chills, fever, and muscle pain) and can damage the lungs. Chronic exposure (low level over an extended period of time) can result in kidney, bone and lung disease. \n\nCadmium (Cd) is a soft, malleable, bluish white metal found in zinc ores, and to a much lesser extent, in the cadmium mineral greenockite. Most of the cadmium produced today is obtained from zinc byproducts and recovered from spent nickel-cadmium batteries."
    },
    {
        number: 46,
        description: "Which of the following is a factor that can influence the toxicity of a chemical?",
        choices: [
            "A. The size of the molecule",
            "B. The color of the substance",
            "C. The temperature at which it is used",
            "D. The route of exposure"
        ],
        answer: "D",
        explain: "The toxicity of a chemical can be influenced by various factors such as the route of exposure, the dose or concentration, the duration of exposure, the frequency of exposure, the chemical properties, and the susceptibility of the exposed individual. The route of exposure refers to the way in which the chemical enters the body, such as inhalation, ingestion, dermal contact, or injection. The toxicity of a chemical can vary depending on the route of exposure. For example, a chemical that is not toxic when ingested may be highly toxic when inhaled."
    },
    {
        number: 47,
        description: "Which of the following best defines the Threshold Limit Value (TLV) in occupational health and safety?",
        choices: [
            "A. The maximum allowable concentration of a hazardous substance in ambient air.",
            "B. The minimum concentration of a hazardous substance required to cause adverse health effects.",
            "C. The concentration at which a hazardous substance becomes highly reactive.",
            "D. The average concentration of a hazardous substance measured over a specific time period."
        ],
        answer: 'A',
        explain: 'The Threshold Limit Value (TLV) in occupational health and safety refers to the maximum allowable concentration of a hazardous substance to which workers can be exposed in the ambient air during their work shift without experiencing adverse health effects. TLVs are typically established by organizations such as the American Conference of Governmental Industrial Hygienists (ACGIH) and are based on available scientific research and data.'
    },
    {
        number: 48,
        description: "Which of the following statements accurately describes the concept of control banding?",
        choices: [
            "A. Control banding is a process of identifying and evaluating workplace hazards using specific measurement techniques.",
            "B. Control banding is a regulatory requirement for all industries to comply with workplace safety and health standards.",
            "C. Control banding is a generic protection strategy that groups hazardous chemicals according to related physical or chemical characteristics.",
            "D. Control banding involves the use of administrative controls, such as training and education, to manage workplace hazards."
        ],
        answer: "C",
        explain: "Control banding is a risk assessment and management strategy that groups hazardous chemicals, agents, or processes into categories or bands based on their physical and/or chemical properties, as well as the potential risk they pose to workers and the environment. Control banding is used to quickly and easily identify hazards and select appropriate control measures for a specific group or category of hazards.\n\nFor example, if a group of chemicals is found to have similar hazardous properties, such as causing respiratory irritation, control banding could be used to group these chemicals together and recommend the same control measures for all chemicals in that group, such as the use of personal protective equipment, ventilation, or substitution with a less hazardous chemical."
    },
    {
        number: 49,
        description: "Which of the following is not a mechanism of pulmonary defense against airborne particles?",
        choices: [
            "A. Mucociliary clearance",
            "B. Alveolar macrophages",
            "C. Surfactant production",
            "D. Pulmonary vasodilation"
        ],
        answer: "D",
        explain: "Mucociliary clearance, alveolar macrophages, and surfactant production are all mechanisms of pulmonary defense against airborne particles. Pulmonary vasodilation, on the other hand, is not directly involved in this process. \n\nA) Mucociliary clearance: The respiratory tract is lined with a layer of mucus that traps airborne particles, including bacteria, viruses, and other harmful substances. The cilia, tiny hair-like structures on the surface of the respiratory epithelium, move in a coordinated fashion to transport the mucus and trapped particles up and out of the respiratory tract. This is known as mucociliary clearance.\n\nB) Alveolar macrophages: Alveolar macrophages are specialized immune cells located in the alveoli, the small air sacs in the lungs. They play a critical role in the clearance of inhaled particles, including bacteria and other harmful substances. Alveolar macrophages can engulf and digest these particles, helping to prevent infection and other adverse effects.\n\nC) Surfactant production: Surfactant is a substance produced by specialized cells in the lungs known as type II pneumocytes. It helps to reduce the surface tension within the alveoli, which in turn helps to keep the alveoli open and prevents their collapse. Surfactant also plays a role in the clearance of inhaled particles by enhancing the ability of alveolar macrophages to phagocytose and clear particles. \n\nPulmonary vasodilation is the widening of the blood vessels in the lungs. This is a mechanism that helps to increase blood flow to the lungs. It is not a mechanism of pulmonary defense against airborne particles."
    },
    {
        number: 50,
        description: "Which of the following statements is true regarding cholinesterase, acetylcholine, and cholinesterase inhibitors?",
        choices: [
            "A. Acetylcholine is an enzyme that breaks down cholinesterase, which in turn inhibits acetylcholine.",
            "B. Cholinesterase is a neurotransmitter that is broken down by acetylcholine, which is inhibited by cholinesterase inhibitors.",
            "C. Cholinesterase breaks down acetylcholine, which is a neurotransmitter, and cholinesterase inhibitors block the action of cholinesterase.",
            "D. Cholinesterase inhibitors increase the levels of acetylcholine, which in turn breaks down cholinesterase."
        ],
        answer: "C",
        explain: "Cholinesterase is an enzyme that breaks down the neurotransmitter acetylcholine in the nervous system. Acetylcholine is a crucial neurotransmitter involved in many functions, including muscle movement, memory, and cognitive function. Cholinesterase inhibitors are substances that block the activity of cholinesterase, leading to an increase in acetylcholine levels in the brain and body. These inhibitors are used in medicine to treat conditions like Alzheimer's disease, where increasing acetylcholine levels can help improve cognitive function."
    }
    ,
    {
        number: 51,
        description: "Which of the following best describes synergistic effects in toxicology?",
        choices: [
            "A. Synergistic effects occur when two or more toxic substances interact to enhance each other's toxicity.",
            "B. Synergistic effects occur when a non-toxic substance makes another toxic substance more toxic.",
            "C. Synergistic effects occur when the toxic effects of a substance are reduced by the presence of other substances.",
            "D. Synergistic effects occur when a non-toxic substance enhances the toxicity of another substance."
        ],
        answer: "A",
        explain: "Synergism is a type of potentiation in which the combined effect of two or more substances is much greater than the sum of their individual effects. This can occur when the substances work together to produce a new and more toxic effect.\n\nHere are some examples of synergistic effects:\n\nNicotine and carbon monoxide: Nicotine is a stimulant found in tobacco smoke and carbon monoxide is a gas produced by incomplete combustion. If they are inhaled together, the nicotine can potentiate the toxicity of the carbon monoxide, which can lead to heart attack and stroke.\n\nChlorine and ammonia: Chlorine is a disinfectant and ammonia is a common household cleaner. If they are mixed together, they can produce a toxic gas called chloramine. Chloramine can cause respiratory irritation, coughing, and wheezing. In high concentrations, it can even be fatal."
    },
    {
        number: 52,
        description: "Which of the following statements best describes the purpose of dose-response assessments in toxicology?",
        choices: [
            "A. To determine the optimal dosage of a chemical for therapeutic use",
            "B. To evaluate the safety of a chemical at different exposure levels",
            "C. To assess the potential for allergic reactions to a chemical",
            "D. To investigate the environmental impact of a chemical"
        ],
        answer: "B",
        explain: "Dose-response assessments in toxicology are conducted to understand how the magnitude of exposure to a chemical or substance relates to its toxic effects on living organisms. By examining the relationship between the dose (amount) of a substance administered and the resulting response or adverse effect, toxicologists can determine the safety profile of the chemical at various exposure levels. This information is crucial for setting appropriate exposure limits, establishing safe practices, and mitigating potential risks to human health and the environment."
    },
    {
        number: 53,
        description: "What are the primary processes that toxicokinetics focuses on in relation to toxic substances in the body?",
        choices: [
            "A. Absorption, distribution, metabolism/biotransformation, and excretion (ADME)",
            "B. Bioaccumulation, bioavailability, and bioactivation",
            "C. Toxicity assessment and risk characterization",
            "D. Pharmacokinetics and pharmacodynamics"
        ],
        answer: "A",
        explain: "Toxicokinetics primarily focuses on the processes of absorption, distribution, metabolism/biotransformation, and excretion (ADME) of toxic substances in the body. Let's break down each of these processes:\n\nAbsorption: This refers to the uptake of toxic substances into the body. It involves the transfer of substances from the site of exposure, such as the lungs or gastrointestinal tract, into the bloodstream.\n\nDistribution: Once absorbed, toxic substances are distributed throughout the body via the bloodstream. They can reach various organs, tissues, and cellular compartments, potentially causing toxic effects in specific target sites.\n\nMetabolism/Biotransformation: Toxic substances often undergo metabolic transformations in the body. Metabolism is the process of converting toxicants into different chemical forms through enzymatic reactions. These transformations can alter the toxicity, bioavailability, and elimination of the substances.\n\nExcretion: Toxic substances and their metabolites are eliminated from the body through various routes, such as urine, feces, sweat, and exhaled air. Excretion plays a crucial role in removing toxicants to prevent their accumulation and potential harm to the body."
    },
    {
        number: 54,
        description: "What is hard metal lung disease?",
        choices: [
            "A. A disease caused by inhaling dust of tungsten carbide, primarily affecting workers in the agriculture industry.",
            "B. An occupational interstitial lung disease caused by exposure to dust of tungsten carbide, likely due to cobalt used as a binder, and primarily affecting workers in the manufacture, utilization, or maintenance of tools composed of hard metal. It starts with asthma and progresses to lung fibrosis.",
            "C. A condition characterized by lung fibrosis caused by exposure to cobalt alone.",
            "D. An acute condition caused by inhaling fumes of tungsten carbide and cobalt, leading to symptoms such as fever, chills, cough, and muscle aches."
        ],
        answer: "B",
        explain: "Hard metal lung disease is an occupational interstitial lung disease caused by exposure to dust of tungsten carbide, likely due to cobalt used as a binder. It primarily affects workers in the manufacture, utilization, or maintenance of tools composed of hard metal. The disease starts with asthma and can progress to fibrosis of the lung."
    }
    ,
    {
        number: 55,
        description: "What is the difference between a teratogen and a mutagen?",
        choices: [
            "A. A teratogen causes birth defects, while a mutagen causes changes in DNA.",
            "B. A teratogen causes changes in DNA, while a mutagen causes birth defects.",
            "C. A teratogen affects genetics, while a mutagen affects the environment.",
            "D. A teratogen affects the environment, while a mutagen affects genetics."
        ],
        answer: "A",
        explain: "A teratogen is an agent that causes physical and functional defects in the embryo after the mother is exposed to those agents, leading to birth defects. Mutagens, on the other hand, are agents that alter the DNA sequence of an individual and cause a permanent change in their genetic makeup."
    }
    ,
    {
        number: 56,
        description: "Which of the following diseases is not primarily caused by inhaling asbestos fibers?",
        choices: [
            "A. Mesothelioma",
            "B. Asbestosis",
            "C. Asbestos-Related Lung Cancer",
            "D. Tuberculosis"
        ],
        answer: "D",
        explain: "Both Mesothelioma and Asbestosis are diseases primarily caused by inhaling asbestos fibers. Mesothelioma is a rare form of cancer that affects the lining of the lungs, abdomen, or heart, and it is strongly associated with asbestos exposure. Asbestosis is a chronic lung condition caused by the inhalation of asbestos fibers, leading to scarring of the lung tissue.\n\n Tuberculosis (TB) is an infectious disease caused by the bacterium Mycobacterium tuberculosis. It primarily affects the lungs but can also impact other organs. Unlike the other options, tuberculosis is not caused by asbestos exposure. It spreads from person to person through the air when someone with active TB coughs, sneezes, or speaks, releasing bacteria into the air."
    },
    {
        number: 57,
        description: "What are the potential implications of endocrine disruption in toxicology?",
        choices: [
            "A. Developmental abnormalities and reproductive disorders",
            "B. Respiratory irritation and lung damage",
            "C. Neurological disorders and cognitive impairment",
            "D. Skin sensitization and allergic reactions"
        ],
        answer: "A",
        explain: "Endocrine disruption can have significant implications in toxicology, particularly in relation to developmental abnormalities and reproductive disorders. Exposure to certain chemicals that exhibit endocrine-disrupting properties can interfere with the normal hormonal signaling and regulation during critical periods of development. This can result in adverse effects such as birth defects, impaired fertility, altered sexual development, and hormonal imbalances. \n\nThe endocrine system is crucial for the proper growth, development, and functioning of various organs and systems, and disruptions to this system can have long-lasting effects on individuals and populations. While it is true that some chemicals with endocrine-disrupting properties could potentially have effects on neurological function and cognitive processes, the most well-established and primary effects of endocrine disruption are related to developmental abnormalities and reproductive disorders. "
    },
    {
        number: 58,
        description: "Which of the following sources is a primary contributor to the release of dioxins and furans into the environment?",
        choices: [
            "A. Agricultural activities",
            "B. Residential cooking",
            "C. Renewable energy production",
            "D. Industrial combustion processes"
        ],
        answer: "D",
        explain: "Dioxins and furans are a group of highly toxic and persistent environmental pollutants that are formed as byproducts of various industrial processes, such as waste incineration, chemical manufacturing, and certain combustion processes. They are known as persistent organic pollutants (POPs) due to their ability to persist in the environment for long periods and bioaccumulate in the food chain.\n\nIndustrial combustion processes, such as waste incineration, industrial boilers, and certain manufacturing processes, are primary contributors to the release of dioxins and furans into the environment. These processes involve the burning of organic materials or the use of certain chemical reactions that can generate dioxins and furans as byproducts. The improper management or inadequate control of these industrial activities can lead to the release of these toxic pollutants into the air, soil, and water, posing risks to human health and the environment."
    },
    {
        number: 59,
        description: "What does bioavailability refer to in toxicology?",
        choices: [
            "A. The ability of a substance to be absorbed and used by living organisms",
            "B. The amount of a substance that is present in the environment",
            "C. The rate at which a substance breaks down in the environment",
            "D. The process by which a substance is excreted from the body"
        ],
        answer: "A",
        explain: "Bioavailability refers to the ability of a substance to be absorbed and used by living organisms, which is an important factor in determining its toxicity."
    },
    {
        number: 60,
        description: "What is the main difference between a toxic substance and a hazardous substance?",
        choices: [
            "A. Toxic substances are more likely to cause immediate harm, while hazardous substances have long-term effects.",
            "B. Toxic substances refer to chemicals, while hazardous substances include physical agents as well.",
            "C. Toxic substances have the potential to cause harm to living organisms, while hazardous substances pose risks beyond toxicity.",
            "D.  Toxic substances are regulated by specific agencies, while hazardous substances are not subject to regulation."
        ],
        answer: "C",
        explain: "Toxic substances specifically refer to chemicals or physical agents that have the potential to cause harm or adverse effects to living organisms. They are primarily associated with the potential for toxicity and can cause injury, illness, or death depending on the level and duration of exposure.\n\nOn the other hand, hazardous substances encompass a broader range of risks or dangers beyond just toxicity. While they may include toxic substances, they can also include substances with other hazardous properties such as flammability, explosiveness, corrosiveness, or reactivity. Hazardous substances pose risks to health, safety, property, or the environment beyond their potential for toxicity alone."
    },
    {
        number: 61,
        description: "The process by which a toxic substance moves from the site of exposure to the bloodstream is called:",
        choices: [
            "A. Absorption",
            "B. Distribution",
            "C. Metabolism",
            "D. Excretion"
        ],
        answer: "A",
        explain: "Absorption refers to the process by which a toxic substance enters the body and moves from the site of exposure, such as the lungs or the gastrointestinal tract, into the bloodstream. It involves the passage of the substance through biological barriers, such as cell membranes, to reach systemic circulation. Absorption can occur through various routes, including inhalation, ingestion, and dermal contact. Once absorbed, the toxic substance can then be distributed to target organs or tissues where it exerts its effects."
    },
    {
        number: 62,
        description: "The study of the adverse effects of chemicals on the developing fetus is known as:",
        choices: [
            "A. Reproductive toxicology",
            "B. Developmental toxicology",
            "C. Pediatric toxicology",
            "D. Teratology"
        ],
        answer: "B",
        explain: "Developmental toxicology is the branch of toxicology that focuses on studying the adverse effects of chemicals on the development of embryos, fetuses, and children. It involves examining the potential harmful effects of chemical exposures during pregnancy and early life stages, including the impact on organ development, growth, and functional outcomes. \n\nDevelopmental toxicology helps in identifying and understanding the risks associated with prenatal and early-life exposures to toxic substances and plays a crucial role in guiding regulatory decisions and public health interventions. Teratology is a closely related term that often overlaps with developmental toxicology, but it refers specifically to the study of abnormalities and malformations that occur during development, especially in relation to birth defects."
    },
    {
        number: 63,
        description: "What is the primary focus of teratology?",
        choices: [
            "A. Studying the effects of chemicals on organ development in adults",
            "B. Investigating the relationship between genetics and disease susceptibility",
            "C. Examining the causes and outcomes of abnormal development and birth defects",
            "D. Assessing the risks associated with exposure to radiation during pregnancy"
        ],
        answer: "C",
        explain: "Teratology primarily focuses on studying and understanding the causes and outcomes of abnormal development and birth defects. It involves examining the impact of various factors, including genetic, environmental, and chemical exposures, on the development of embryos and fetuses. \n\nTeratology aims to identify the teratogenic effects of these factors, assess the risks associated with them, and provide insights into preventive measures. By investigating abnormal development and birth defects, teratology plays a crucial role in improving our understanding of reproductive health and guiding interventions to promote healthy pregnancies."
    },
    {
        number: 64,
        description: "Which of the following best describes the concept of IDLH (Immediately Dangerous to Life or Health) in occupational health and safety?",
        choices: [
            "A. The maximum allowable exposure limit for airborne contaminants in the workplace",
            "B. The concentration of a hazardous substance that poses an immediate threat to life or health",
            "C. The recommended exposure limit for noise levels in the workplace",
            "D. The threshold concentration of a chemical at which it becomes acutely toxic"
        ],
        answer: "B",
        explain: "The Immediately dangerous to life or health air concentration values (IDLH values) developed by the National Institute for Occupational Safety and Health (NIOSH) characterize these high-risk exposure concentrations and conditions and are used as a component of respirator selection criteria first developed in the mid-1970s. IDLH values are established (1) to ensure that the worker can escape from a given contaminated environment in the event of failure of the respiratory protection equipment and (2) to indicate a maximum level above which only a highly reliable breathing apparatus, providing maximum worker protection, is permitted."
    },
    {
        number: 65,
        description: "Which of the following is an example of a carcinogen?",
        choices: [
            "A. Benzene",
            "B. Acetone",
            "C. Ammonia",
            "D. Carbon dioxide"
        ],
        answer: "A",
        explain: "Benzene is an example of a known human carcinogen. Prolonged exposure to benzene has been associated with an increased risk of developing leukemia, a type of cancer affecting the blood-forming tissues. It is important to minimize exposure to benzene and take appropriate safety precautions in occupational and environmental settings where it may be present. Acetone, ammonia, and carbon dioxide, on the other hand, are not known to be carcinogenic."
    },
    {
        number: 66,
        description: "Which of the following statements about ammonia is true?",
        choices: [
            "A. Ammonia is classified as a carcinogen.",
            "B. Ammonia is primarily used as a fuel source.",
            "C. Ammonia can cause respiratory irritation.",
            "D. Ammonia is a non-flammable gas."
        ],
        answer: "C",
        explain: "Ammonia is known to cause respiratory irritation when inhaled. Exposure to high concentrations of ammonia can result in coughing, sore throat, and difficulty breathing. It is important to handle and use ammonia with caution, ensuring proper ventilation in areas where it is used or stored to prevent excessive exposure and potential respiratory health effects. \n\nAmmonia is not classified as a carcinogen and it is not primarily used as a fuel source. Additionally, ammonia is a flammable gas and should be handled and stored appropriately to prevent fire hazards.\n\nIt is a common industrial chemical and is used in a variety of products, including fertilizers, cleaning products, and refrigerants."
    },
    {
        number: 67,
        description: "Which statement is true regarding the absorption of polar and non-polar chemicals by the skin?",
        choices: [
            "A. Polar chemicals are more readily absorbed by the skin due to their hydrophilic nature.",
            "B. Non-polar chemicals are more readily absorbed by the skin due to their lipophilic nature.",
            "C. Both polar and non-polar chemicals are absorbed equally by the skin.",
            "D. Neither polar nor non-polar chemicals are absorbed by the skin."
        ],
        answer: "B",
        explain: "Non-polar chemicals are more readily absorbed by the skin due to their lipophilic nature, which allows them to pass through the lipid-rich outer layer of the skin more easily than polar chemicals. Polar chemicals have an uneven distribution of electrical charge, making them hydrophilic (water-attracting), while non-polar chemicals have an even distribution of electrical charge, making them lipophilic (fat-attracting). Lipids, which form the skin's outer layer, are non-polar. Examples of non-polar toxicants include benzene, toluene, and n-hexane."
    }
    ,
    {
        number: 68,
        description: "Which of the following is NOT an example of a physical agent in the field of toxicology?",
        choices: [
            "A. Noise",
            "B. Heat",
            "C. Radiation",
            "D. Asbestos"
        ],
        answer: "D",
        explain: "In the field of toxicology, a physical agent refers to a non-chemical factor or stressor that has the potential to cause adverse health effects. These agents primarily exert their effects through mechanical, thermal, or electromagnetic means. Examples of physical agents include noise, radiation (e.g., ionizing radiation), temperature extremes (e.g., heat or cold), vibration, and pressure. \n\nUnlike chemical agents, physical agents do not involve the interaction of specific chemicals with biological systems but rather the direct impact of physical forces on the body."
    },
    {
        number: 69,
        description: "Which of the following statements about friable and non-friable asbestos is correct?",
        choices: [
            "A. Friable asbestos is more common in older buildings, while non-friable asbestos is prevalent in newer constructions.",
            "B. Friable asbestos poses a higher health risk compared to non-friable asbestos, as it can easily release harmful fibers into the air.",
            "C. Non-friable asbestos is typically found in insulation materials, while friable asbestos is commonly used in floor tiles and roofing.",
            "D. Both friable and non-friable asbestos are equally hazardous, regardless of their condition or potential for fiber release."
        ],
        answer: "B",
        explain: "Friable asbestos is a type of asbestos-containing material that is easily crumbled or reduced to powder by hand pressure, while non-friable asbestos is more tightly bound and less likely to release fibers into the air under normal conditions. Friable asbestos presents a higher risk because its loose fibers can become airborne and be inhaled, potentially leading to serious health issues such as lung diseases and cancer. On the other hand, non-friable asbestos, when left undisturbed, generally poses a lower risk, but proper handling and maintenance are still necessary to prevent fiber release during renovations or demolitions."
    },
    {
        number: 70,
        description: "What is the primary function of the liver in toxicology?",
        choices: [
            "A. Metabolism and detoxification of chemicals",
            "B. Production of red blood cells",
            "C. Regulation of blood pressure",
            "D. Transport of oxygen to body tissues"
        ],
        answer: "A",
        explain: "In toxicology, the primary function of the liver is to metabolize and detoxify chemicals that enter the body. The liver plays a crucial role in the biotransformation of toxicants, including drugs, pollutants, and other foreign substances. It converts these substances into less harmful or more easily excreted forms through various enzymatic reactions. \n\nAdditionally, the liver also helps in the synthesis of certain proteins, storage of vitamins and minerals, and regulation of blood glucose levels. However, its main role in toxicology is the metabolism and detoxification of chemicals."
    },
    {
        number: 71,
        description: "What is the most severe consequence of high-level carbon monoxide exposure?",
        choices: [
            "A. Nausea and vomiting",
            "B. Fatigue and weakness",
            "C. Loss of consciousness and coma",
            "D. Chest pain and shortness of breath"
        ],
        answer: "C",
        explain: "The most severe consequence of high-level carbon monoxide (CO) exposure is loss of consciousness and coma. When carbon monoxide is inhaled in high concentrations, it binds to hemoglobin in red blood cells, reducing their ability to carry oxygen. This results in a decreased oxygen supply to the body's tissues and organs, including the brain.\n\nAs carbon monoxide accumulates in the bloodstream, it can lead to severe hypoxia (oxygen deprivation) in the brain. This lack of oxygen can cause a person to lose consciousness and potentially enter a coma state. Loss of consciousness refers to a complete or partial loss of awareness and responsiveness to the surrounding environment. Coma is a state of deep unconsciousness where an individual cannot be awakened and shows minimal or no response to stimuli."
    },
    {
        number: 72,
        description: "Which of the following substances is known to have nephrotoxic effects?",
        choices: [
            "A. Antidepressant medications",
            "B. Vitamins",
            "C. Heavy metals",
            "D. Antihistamines"
        ],
        answer: "C",
        explain: "Heavy metals are known to have nephrotoxic effects, meaning they can cause damage to the kidneys. Heavy metals are naturally occurring elements with high atomic weights, such as lead, mercury, cadmium, and arsenic. These metals can enter the body through various sources, including contaminated food or water, occupational exposure, or environmental pollution.\n\nWhen heavy metals accumulate in the kidneys, they can interfere with the normal functioning of these organs. They may cause direct injury to the kidney cells or disrupt important cellular processes involved in filtration and elimination of waste products. Prolonged or excessive exposure to heavy metals can lead to chronic kidney damage and impaired renal function."
    }
    ,
    {
        number: 73,
        description: "Which of the following substances is known to be a hepatotoxin?",
        choices: [
            "A. Vitamin C",
            "B. Calcium",
            "C. Alcohol",
            "D. Iron"
        ],
        answer: "C",
        explain: "Alcohol is a known hepatotoxin, meaning it can damage the liver. Excessive and chronic alcohol consumption can lead to alcoholic liver disease, which includes conditions like fatty liver, alcoholic hepatitis, and cirrhosis. Alcohol metabolism generates toxic byproducts, causing oxidative stress and inflammation in liver cells. This can lead to cellular injury and damage. \n\nProlonged alcohol abuse can progress to more severe liver conditions, such as alcoholic hepatitis and cirrhosis, which involve inflammation, scarring, and impaired liver function."
    }
    ,
    {
        number: 74,
        description: "Why are PCBs (polychlorinated biphenyls) considered a significant environmental and health problem?",
        choices: [
            "A. They are highly volatile and cause immediate respiratory issues.",
            "B. They are persistent organic pollutants that accumulate in the environment and food chains.",
            "C. They are easily biodegradable and pose no long-term risks.",
            "D. They are beneficial for industrial processes with no known health effects."
        ],
        answer: "B",
        explain: "PCBs (polychlorinated biphenyls) are a group of man-made chemicals that were widely used in various industrial and commercial applications, such as coolants and insulating fluids for transformers and capacitors, plasticizers in paints, plastics, and rubber products. PCBs are considered a significant environmental and health problem because they are persistent organic pollutants that accumulate in the environment and food chains. This persistence means they do not break down easily, leading to long-term contamination. PCBs can cause a variety of adverse health effects, including cancer, immune system suppression, and endocrine disruption."
    }
    ,
    {
        number: 75,
        description: "Which industry is more likely to have potential exposure to arsine gas?",
        choices: [
            "A. Semiconductor and computer chip crystal manufacturing industry",
            "B. Textile production",
            "C. Agriculture and farming",
            "D. Food and beverage manufacturing"
        ],
        answer: "A",
        explain: "Arsine (SA) is a highly toxic gas, formed by the reaction of arsenic-containing substances with hydrogen in water or acids. It is used in the semiconductor and computer chip crystal manufacturing industry. Exposure to arsine (SA) in sufficient quantities can be fatal. Arsine (SA) is nonirritating, produces no immediate symptoms, and odor is not an adequate indicator of arsine’s (SA) presence, so persons exposed to hazardous levels may be unaware of its presence. The established PEL is 10 times lower than the concentration at which people begin to smell arsine (SA) (mild garlic odor)."
    },
    {
        number: 76,
        description: "In which industries is beryllium commonly used, and what is the primary health risk associated with beryllium exposure?",
        choices: [
            "A. Aerospace and electronics; Respiratory disease",
            "B. Food and beverage; Skin cancer",
            "C. Construction and architecture; Neurological disorders",
            "D. Textile and fashion; Kidney failure"
        ],
        answer: "A",
        explain: "Beryllium is commonly used in industries such as aerospace and electronics. Its unique properties, including high strength and conductivity, make it valuable for applications in these sectors. For example, it is used in aerospace components, nuclear reactors, X-ray equipment, and electronic devices.\n\nThe primary health risk associated with beryllium exposure is respiratory disease. When beryllium dust or fumes are inhaled, they can cause inflammation and scarring of the lung tissue, leading to a condition called berylliosis. This can result in symptoms such as shortness of breath, chronic cough, fatigue, and chest pain. If not properly managed, berylliosis can progress to chronic beryllium disease (CBD), a more severe form that can cause long-term lung damage and impaired lung function."
    },
    {
        number: 77,
        description: "Which type of asbestos is considered the most commonly used form?",
        choices: [
            "A. Chrysotile (white asbestos)",
            "B. Amosite (brown asbestos)",
            "C. Crocidolite (blue asbestos)",
            "D. Tremolite"
        ],
        answer: "A",
        explain: "Chrysotile, also known as white asbestos, is considered the most commonly used form of asbestos. It has been widely used in various industries for its heat-resistant and insulating properties. Chrysotile asbestos has been employed in the production of a range of materials, including roofing, insulation, and friction materials. It is important to note that all forms of asbestos, including chrysotile, are now recognized as hazardous to human health, and their use has been restricted or banned in many countries due to the associated risks of diseases such as lung cancer, asbestosis, and mesothelioma.\n\nAmong the different types of asbestos, Crocidolite (blue asbestos) is generally considered the most toxic. Crocidolite fibers are thin and easily inhaled, making them more hazardous to human health."
    },
    {
        number: 78,
        description: "Which industry or occupation is at a higher risk of exposure to cotton dust, and what is the primary health concern associated with such exposure?",
        choices: [
            "A. Mining and excavation; Skin rashes and irritation",
            "B. Agriculture and farming; Respiratory issues and allergies",
            "C. IT and software development; Eye infections and conjunctivitis",
            "D. Banking and finance; Gastrointestinal problems and food poisoning"
        ],
        answer: "B",
        explain: "Agriculture and farming occupations are at a higher risk of exposure to cotton dust. This includes workers involved in cotton cultivation, harvesting, processing, and textile manufacturing. These occupations often involve direct contact with raw cotton fibers, which can release cotton dust into the air.\n\nThe primary health concern associated with exposure to cotton dust is respiratory issues and allergies. Inhaling cotton dust can irritate the respiratory system, leading to symptoms such as coughing, wheezing, shortness of breath, and chest tightness. Prolonged or repeated exposure to cotton dust can also result in the development of respiratory conditions like occupational asthma or hypersensitivity pneumonitis."
    },
    {
        number: 79,
        description: "Which work is commonly associated with exposure to hexavalent chromium, and what is the primary route of exposure to this toxic compound?",
        choices: [
            "A. Food and beverage; Inhalation",
            "B. Welding and other types of 'hot work' on stainless steel ; Inhalation",
            "C. Education and research; Ingestion",
            "D. Tourism and hospitality; Dermal contact"
        ],
        answer: "B",
        explain: "Hexavalent chromium [Cr(VI)] is one of the valence states (+6) of the element chromium. It is usually produced by an industrial process. Cr(VI) is known to cause cancer. In addition, it targets the respiratory system, kidneys, liver, skin and eyes. Chromium metal is added to alloy steel to increase hardenability and corrosion resistance. A major source of worker exposure to Cr(VI) occurs during 'hot work' such as welding on stainless steel and other alloy steels containing chromium metal. Cr(VI) compounds may be used as pigments in dyes, paints, inks, and plastics. It also may be used as an anticorrosive agent added to paints, primers, and other surface coatings. The Cr(VI) compound chromic acid is used to electroplate chromium onto metal parts to provide a decorative or protective coating."
    },
    {
        number: 80,
        description: "Which activity is most likely to contribute to the release of PAHs into the environment, and how do PAHs primarily enter the human body?",
        choices: [
            "A. Recycling paper and plastics; Through dermal contact",
            "B. Burning fossil fuels; Through inhalation",
            "C. Using renewable energy sources; Through ingestion",
            "D. Composting organic waste; Through vaccination"
        ],
        answer: "B",
        explain: "PAHs, or polycyclic aromatic hydrocarbons, are a group of organic compounds consisting of multiple fused aromatic rings made up of carbon and hydrogen atoms. Aromatic rings are hexagonal or ring-like structures that are particularly stable due to the delocalization of electrons in their molecular bonds.\n\nPAHs are formed primarily during incomplete combustion processes, such as those occurring in the burning of fossil fuels, wood, tobacco, and other organic materials. They can also be produced naturally through volcanic eruptions, forest fires, and other geological processes.\n\nBurning fossil fuels, such as coal, oil, and gas, is a major contributor to the release of PAHs into the environment. During the combustion process, organic matter undergoes incomplete burning, leading to the formation of PAHs as byproducts. This can occur in various settings, including industrial operations, vehicle emissions, and residential heating systems.\n\nOnce released into the environment, PAHs can enter the human body primarily through inhalation. When PAH-containing particles are present in the air, they can be inhaled into the respiratory system. This route of exposure allows PAHs to directly enter the bloodstream and be distributed throughout the body, potentially causing adverse health effects. Some PAHs may cause cancer and may affect the eyes, kidneys, and liver."
    },
    {
        number: 81,
        description: "Which of the following components of the lymphatic system is responsible for filtering the lymph and removing harmful substances?",
        choices: [
            "A. Lymphatic vessels",
            "B. Lymph nodes",
            "C. Spleen",
            "D. Thymus"
        ],
        answer: "B",
        explain: "Lymph nodes are responsible for filtering the lymph and removing harmful substances, such as bacteria, viruses, and cancer cells. Lymph nodes contain immune cells that help in the defense against infections and other foreign particles. When lymph flows through the lymphatic vessels, it passes through the lymph nodes, where the immune cells within the nodes detect and eliminate any pathogens or foreign materials present in the lymph."
    },
    {
        number: 82,
        description: "What is the main cause of farmer's lung?",
        choices: [
            "A. Inhalation of mold spores or bacteria",
            "B. Exposure to chemical pesticides",
            "C. Allergic reaction to pollen",
            "D. Genetic predisposition"
        ],
        answer: "A",
        explain: "The main cause of farmer's lung is the inhalation of mold spores or certain bacteria present in the dust from moldy hay, straw, or grain. These inhaled particles trigger an immune response in susceptible individuals, leading to inflammation and lung damage. It is important for individuals working in agricultural settings to take preventive measures to minimize exposure to these allergens and reduce the risk of developing farmer's lung."
    },
    {
        number: 83,
        description: "Which statement about the toxicity and carcinogenicity of hydrogen peroxide is correct?",
        choices: [
            "A. Hydrogen peroxide is non-toxic and safe for all uses.",
            "B. Hydrogen peroxide is toxic if ingested, inhaled, or through skin contact, but it is not classified as a carcinogen by the IARC.",
            "C. Hydrogen peroxide is only toxic in very high concentrations and is classified as a carcinogen by the IARC.",
            "D. Hydrogen peroxide is always safe to use in household concentrations and poses no health risks."
        ],
        answer: "B",
        explain: "Hydrogen peroxide is a colorless liquid used in household applications at low concentrations (3-9%) for medicinal purposes and as a bleach for clothes and hair. In industry, it is used at higher concentrations as a bleach for textiles and paper, a component of rocket fuels, and for producing foam rubber and organic chemicals. Hydrogen peroxide can be toxic if ingested, inhaled, or through skin or eye contact. Higher concentrations can cause severe health effects, such as pulmonary irritation, gastrointestinal issues, and skin burns. \n\nThe International Agency for Research on Cancer (IARC) has determined that hydrogen peroxide is not classifiable as to its carcinogenicity to humans, indicating that it is not currently recognized as a cancer-causing agent."
    }
    ,
    {
        number: 84,
        description: "Which of the following health effect is primarily associated with long-term exposure to trichloroethylene (TCE)?",
        choices: [
            "A. Skin rashes and irritation",
            "B. Respiratory problems and asthma",
            "C. Neurological disorders and impaired coordination",
            "D. Gastrointestinal issues and food poisoning"
        ],
        answer: "C",
        explain: "Trichloroethylene (TCE) is a volatile organic compound commonly used as a solvent in various industrial processes, such as metal degreasing, dry cleaning, and manufacturing of consumer products.\n\nLong-term exposure to trichloroethylene (TCE) has been associated with neurological effects, including headaches, dizziness, confusion, and impaired coordination. These effects can be persistent and may worsen with continued exposure to TCE over time."
    },
    {
        number: 85,
        description: "What are the differences between inhalable dust, thoracic dust, and respirable dust in terms of particle size and where they can reach in the lungs?",
        choices: [
            "A. Inhalable dust includes particles up to 100 micrometers, thoracic dust includes particles up to 25 micrometers, and respirable dust includes particles up to 10 micrometers.",
            "B. Inhalable dust includes particles up to 10 micrometers, thoracic dust includes particles up to 100 micrometers, and respirable dust includes particles up to 4 micrometers.",
            "C. Inhalable dust includes particles up to 4 micrometers, thoracic dust includes particles up to 10 micrometers, and respirable dust includes particles up to 100 micrometers.",
            "D. Inhalable dust includes particles up to 4 micrometers, thoracic dust includes particles up to 100 micrometers, and respirable dust includes particles up to 10 micrometers."
        ],
        answer: "A",
        explain: "Inhalable dust refers to particles up to 100 micrometers, thoracic dust includes particles up to 25 micrometers, and respirable dust includes particles up to 10 micrometers. This means that inhalable dust can be inhaled but not deeply enough to reach the thoracic or respirable region of the lungs. Thoracic dust can reach the thoracic region (tracheobronchial region) of the lungs but not small enough to reach the deepest part of the lungs. Respirable dust can reach the deepest part of the lungs (alveolar region)."
    },
    {
        number: 86,
        description: "What is the purpose of Biological Exposure Indices (BEIs) in biological monitoring of workers?",
        choices: [
            "A. To diagnose occupational illnesses in workers",
            "B. To provide guidance values for evaluating biological monitoring results",
            "C. To determine the exact levels of chemicals in the air at the workplace",
            "D. To measure the effectiveness of personal protective equipment (PPE) in reducing exposure"
        ],
        answer: "B",
        explain: "Biological Exposure Indices (BEIs) provide guidance values for evaluating biological monitoring results. They indicate the levels of chemical determinants in biological media that are most likely to be observed in healthy workers exposed to chemicals to the same extent as workers with inhalation exposure at the Threshold Limit Value–Time Weighted Average (TLV–TWA)."
    },
    {
        number: 87,
        description: "What are some potential health effects of exposure to isocyanates?",
        choices: [
            "A. Skin irritation and inflammation",
            "B. Respiratory sensitization and asthma attacks",
            "C. Eye irritation and gastrointestinal irritation",
            "D. All of the above"
        ],
        answer: "D",
        explain: "Isocyanates are powerful irritants to the mucous membranes of the eyes and gastrointestinal and respiratory tracts. Direct skin contact can also cause marked inflammation. Isocyanates can also sensitize workers, making them subject to severe asthma attacks if they are exposed again.\n\nIsocyanates are a family of highly reactive, low molecular weight chemicals. They are widely used in the manufacture of flexible and rigid foams, fibers, coatings such as paints and varnishes, and elastomers, and are increasingly used in the automobile industry, autobody repair, and building insulation materials. \n\nSpray-on polyurethane products containing isocyanates have been developed for a wide range of retail, commercial, and industrial uses to protect cement, wood, fiberglass, steel and aluminum, including protective coatings for truck beds, trailers, boats, foundations, and decks."
    },
    {
        number: 88,
        description: "How do organophosphates and carbamates inhibit cholinesterase, and what are the potential health effects of this inhibition?",
        choices: [
            "A. They inhibit the production of cholinesterase, leading to muscle weakness and paralysis.",
            "B. They bind irreversibly to cholinesterase, preventing it from breaking down acetylcholine, which can lead to overstimulation of nerves and muscles.",
            "C. They enhance the activity of cholinesterase, causing excessive breakdown of acetylcholine, leading to muscle cramps and spasms.",
            "D. They block the receptors for acetylcholine, reducing its effects on muscles and nerves."
        ],
        answer: "B",
        explain: "Organophosphates and carbamates inhibit cholinesterase by binding irreversibly to the enzyme, preventing it from breaking down acetylcholine. This leads to an accumulation of acetylcholine, which can overstimulate nerves and muscles, causing symptoms such as muscle twitching, weakness, paralysis, and even death."
    },
    {
        number: 89,
        description: "What is the primary function of Schwann cells?",
        choices: [
            "A. Transmitting nerve impulses",
            "B. Regulating blood flow to neurons",
            "C. Providing structural support to neurons",
            "D. Insulating nerve fibers"
        ],
        answer: "D",
        explain: "The primary function of Schwann cells is to insulate nerve fibers in the peripheral nervous system. Schwann cells wrap around and form a myelin sheath around the axons of neurons, which helps to electrically insulate the nerve fibers. This insulation is crucial for the rapid and efficient conduction of nerve impulses along the axons. The myelin sheath, composed of layers of Schwann cell membranes, acts as an insulator and allows for saltatory conduction, where the nerve impulse jumps from one node of Ranvier to the next, speeding up the transmission of signals."
    },
    {
        number: 90,
        description: "Which of the following anesthetic gases is commonly used for general anesthesia in surgical procedures?",
        choices: [
            "A. Nitrous oxide",
            "B. Carbon dioxide",
            "C. Oxygen",
            "D. Nitric oxide"
        ],
        answer: "A",
        explain: "Nitrous oxide, also known as laughing gas, is commonly used as an anesthetic gas for general anesthesia in surgical procedures. It is an odorless and colorless gas that, when inhaled, produces analgesic and sedative effects, making it useful for inducing a state of unconsciousness and pain relief during surgery. Nitrous oxide is often used in combination with other anesthetic agents to achieve the desired level of anesthesia.\n\nIt's important to note that when nitrous oxide is used in a controlled medical setting by trained professionals, the risks are minimized. However, prolonged or excessive exposure to nitrous oxide, particularly in non-medical settings or in high concentrations, can pose health risks."
    }
]