import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import getStripe from '../lib/getStrip';

function Air_sampling_video({ videoLink, currUser }) {
    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);
    const [width, setWidth] = useState(window.innerWidth);
    let navigate = useNavigate();
    const clickeToLogIn = () => {
        navigate('/login')
    }
    async function handleCheckout() {
        const stripe = await getStripe();
        const { error } = await stripe.redirectToCheckout({
            lineItems: [
                {
                    price: `price_1NnwwvFt8mEmrzFEMjAOYg1e`,
                    quantity: 1,
                },
            ],
            mode: 'payment',
            successUrl: `https://www.industrialsafetyhub.com/purchasesuccess`,
            cancelUrl: `https://www.industrialsafetyhub.com//cancel`,
            // successUrl: `http://localhost:3001/purchasesuccess`,
            // cancelUrl: `http://localhost:30001/cancel`,
            customerEmail: currUser.email,
        });
        console.warn(error.message);
    }
    const validAccess = (expire_date) => {
        let currDate = new Date();
        let date2 = new Date(expire_date);
        if (date2 >= currDate) {
            return true
        } else {
            return false
        }
    }
    if (!currUser) {
        return (
            <div className='video-container'>
                <div className='notification-words' style={{ color: 'red' }}>Please log in to view all the training videos</div>
                <div onClick={clickeToLogIn} className='sign-in-button'>Log in</div>
            </div>
        )
    }
    if (!validAccess(currUser.expire_date)) {
        return (
            <div className='video-container'>
                <div className='notification-words' style={{ color: 'red' }}>Your access has expired on {currUser.expire_date}</div>
                <div onClick={handleCheckout} className='sign-in-button'>Purchase</div>
            </div>
        )
    }
    return (
        <>
            <div className='video-container'>
                {width > 920 && <iframe width="920" height="515"
                    src={`${videoLink}?vq=hd1080`}
                // allow="autoplay; encrypted-media; picture-in-picture"
                >
                </iframe>}
                {width <= 920 && <iframe width="360" height="315"
                    src={`${videoLink}?vq=hd1080`}>
                </iframe>}
                {width > 480 && <div>Please set your YouTube video quality to 1080p for optimal clarity.</div>}
                {width <= 480 && <div>Please use a computer or laptop to view all the videos</div>}
            </div >
        </>
    )
}

export default Air_sampling_video