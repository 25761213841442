export const air_sampling_questions = [
    {
        number: 1,
        description: "What is the purpose of the sampling media used in air sampling?",
        choices: ['A. To collect and retain airborne contaminants for analysis', 'B. To filter out non-target particles in the air', 'C. To generate a measurable signal proportional to the concentration of the contaminant', 'D. To calibrate the air sampling instrument before use'],
        answer: 'A',
        explain: 'Sampling media can include filters, sorbents, and other materials that can capture and retain particles, gases, vapors, or other contaminants present in the air. Once the sampling media is collected, it can be analyzed in a laboratory to determine the concentration of the contaminant in the sample.'
    },
    {
        number: 2,
        description: "Which of the following is the primary purpose of personal air sampling?",
        choices: ['A. To measure the concentration of contaminants in the ambient air of a workplace', 'B. To measure the concentration of contaminants in the breathing zone of a worker', 'C. To measure the concentration of contaminants in the outdoor air near a workplace', 'D. To measure the concentration of contaminants in the general vicinity of a worker'],
        answer: 'B',
        explain: `Personal air sampling involves placing a sampling device near the worker's breathing zone to measure the concentration of contaminants to which the worker is actually exposed. This method provides a more accurate assessment of the worker's exposure to contaminants compared to measuring the concentration of contaminants in the general vicinity of the worker or in the outdoor air near the workplace.`
    },
    {
        number: 3,
        description: "A workplace is suspected to have carbon monoxide and several spots have been tested by a direct-reading instrument for CO but nothing has been detected. Which type of air sampler would be the most appropriate for collecting air samples for CO analysis, and why?",
        choices: [
            "A. Filter sampler, because CO is a particulate matter and can be collected on filters",
            "B. Passive sampler, because it can collect air samples over a longer period of time",
            "C. Impinger sampler, because it is more sensitive to CO than other types of samplers",
            "D. High-volume sampler, because it can collect a large volume of air and therefore more CO"
        ],
        answer: "B",
        explain: "A passive sampler would be the most appropriate for collecting air samples for CO analysis because it can collect air samples over a longer period of time, which is important for detecting CO levels that may fluctuate over time. \n\nImpingers are typically used for collecting particulate matter or gaseous pollutants that can be trapped or dissolved in a liquid medium. They may not be specifically designed for CO sampling. \n\nHigh-volume samplers are designed for collecting large volumes of air and may not be necessary for CO sampling, which can be detected at low concentrations."
    },
    {
        number: 4,
        description: "Which of the following is not an advantage of using a real-time monitoring instrument for air sampling?",
        choices: [
            "A. Immediate feedback on the concentration of a hazardous substance",
            "B. Ability to detect short-term exposure peaks",
            "C. Ability to collect a representative sample for laboratory analysis",
            "D. Ability to determine the effectiveness of engineering controls"
        ],
        answer: "C",
        explain: "Real-time monitoring instruments can provide immediate feedback on the concentration of a hazardous substance, which allows for prompt action to be taken if necessary. They can also detect short-term exposure peaks, which can be missed by traditional air sampling methods. \n\n Additionally, real-time monitors can be used to evaluate the effectiveness of engineering controls. However, they are not designed to collect samples for laboratory analysis, which is an advantage of traditional air sampling methods."
    },
    {
        number: 5,
        description: "A worker in a foundry is exposed to high levels of respirable crystalline silica. Which type of air sampler would be the most appropriate for collecting air samples for silica analysis, and why?",
        choices: [
            "A. Cyclone sampler, because it can collect respirable dust on a filter",
            "B. Passive sampler, because it can collect air samples over a longer period of time",
            "C. Impinger sampler, because it is more sensitive to silica than other types of samplers",
            "D. High-volume sampler, because it can collect a large volume of air and therefore more silica"
        ],
        answer: "A",
        explain: "A cyclone sampler is essential in occupational health for collecting respirable dust because it effectively separates and collects particles small enough to penetrate deep into the lungs. By using a high-flow pump, the cyclone sampler directs the air containing dust particles through a cyclonic motion, causing larger, non-respirable particles to be separated and discarded, while the smaller, respirable dust particles are collected on a filter."
    },
    {
        number: 6,
        description: "Which of the following is a disadvantage of using a passive air sampling device?",
        choices: [
            "A. Can collect air samples over a longer period of time",
            "B. Does not require a power source or pump",
            "C. Can be less accurate than active sampling devices",
            "D. Can be used for a wide range of chemicals and contaminants"
        ],
        answer: "C",
        explain: "One disadvantage of using a passive air sampling device is that it can be less accurate than active sampling devices. Passive samplers rely on the natural diffusion or sorption of contaminants into a sampling media over a period of time. The accuracy of passive samplers may be affected by factors such as variations in environmental conditions and the sampling rate of the specific contaminant. Active sampling devices, on the other hand, use a pump or other mechanical means to actively pull air through a sampling medium, providing more precise control over the sampling process."
    },
    {
        number: 7,
        description: "When should sorbent tubes be used instead of passive sampling badges for volatile organic compounds (VOCs) sampling in an occupational setting?",
        choices: [
            "A. When a long-term, time-weighted average measurement of VOC exposure is needed without the use of a pump.",
            "B. When a high concentration of VOCs is expected, requiring active sampling with a pump for precise quantification.",
            "C. When the sampling needs to be done unobtrusively over an extended period without interference from workplace activities.",
            "D. When the primary concern is particulate matter rather than VOCs."
        ],
        answer: "B",
        explain: "Sorbent tubes should be used instead of passive sampling badges when a high concentration of VOCs is expected and precise quantification is necessary. Sorbent tubes require the use of a calibrated pump to actively draw air through the tube, allowing for accurate measurement of VOCs even at high concentrations or in variable conditions. In contrast, passive sampling badges are suitable for long-term, time-weighted average measurements where a pump is not used. Passive sampling are usually less acurate."
    },
    {
        number: 82,
        description: "What is the standard procedure for sampling sulfur dioxide (SO₂) in an occupational setting?",
        choices: [
            "A. Using a passive sampling badge to collect air samples over an 8-hour work shift.",
            "B. Using a high-volume air sampler with a cellulose filter to collect particulate matter.",
            "C. Using an impinger filled with a specific absorbing solution to capture SO₂ from the air.",
            "D. Using a photoionization detector (PID) to directly measure the concentration of SO₂ in the air."
        ],
        answer: "C",
        explain: "The standard procedure for sampling sulfur dioxide (SO₂) in an occupational setting involves using an impinger filled with a specific absorbing solution. Air is drawn through the impinger using a calibrated pump, and the SO₂ in the air reacts with the absorbing solution, forming a stable compound. This method allows for the accurate capture and subsequent laboratory analysis of SO₂."
    },
    {
        number: 9,
        description: "Which of the following best defines selection bias in the context of air sampling?",
        choices: [
            "A. The inclusion of samples that accurately represent the target population ",
            "B. The exclusion of samples that accurately represent the target population",
            "C. The accurate selection of samples that represent the target population",
            "D. The selection of samples that are unrelated to the target population"
        ],
        answer: "B",
        explain: "Selection bias in the context of air sampling refers to the situation where certain samples that accurately represent the target population are excluded from the sampling process. This exclusion can lead to an inaccurate representation of the true characteristics of the air being sampled. By excluding certain samples, the selected samples may not be truly representative of the overall air quality, potentially biasing the results and conclusions drawn from the sampling process."
    },
    {
        number: 10,
        description: "Which of the following is an example of a direct-reading instrument for air sampling?",
        choices: [
            "A. Flame ionization detector",
            "B. High-volume sampler",
            "C. Impinger sampler",
            "D. Filter sampler"
        ],
        answer: "A",
        explain: "A flame ionization detector is an example of a direct-reading instrument for air sampling. It measures organic compounds in the air by burning the sample, which produces ions that can be detected by the instrument. This type of instrument provides immediate feedback on the concentration of a hazardous substance in the air."
    },
    {
        number: 11,
        description: "What is a Homogeneous Exposure Group (HEG) in occupational health? ",
        choices: [
            "A. A group of workers who have been exposed to the same substance in the workplace",
            "B. A group of workers who have similar job titles and duties",
            "C. A group of workers who have the same ethnicity and cultural background",
            "D. A group of workers who have the same age and gender"
        ],
        answer: "A",
        explain: "A Homogeneous Exposure Group (HEG) is a group of workers who have been exposed to the same substance or substances in the workplace in a similar manner, such as through inhalation, dermal contact, or ingestion. HEGs are commonly used in occupational health to simplify exposure assessments and minimize the number of exposure measurements needed. By identifying workers who have similar exposure levels and patterns, HEGs can help occupational health professionals determine appropriate exposure controls and monitor worker health."
    },
    {
        number: 12,
        description: "Which of the following is a type of direct-reading instrument commonly used for measuring the concentration of hydrogen sulfide in the air?",
        choices: [
            "A. FID",
            "B. PID",
            "C. Electrochemical sensor",
            "D. NDIR analyzer"
        ],
        answer: "C",
        explain: "The electrochemical sensor is a type of direct-reading instrument used for measuring the concentration of certain gases in the air, such as hydrogen sulfide (H\u2082S). The sensor contains an electrode and an electrolyte that react with the gas to produce a measurable electrical signal, which is then used to determine the gas concentration."
    },
    {
        number: 13,
        description: "What is the primary advantage of using an IOM sampler for collecting inhalable dust samples in an occupational setting?",
        choices: [
            "A. It is capable of sampling only respirable dust particles.",
            "B. It can collect both inhalable and respirable dust fractions simultaneously.",
            "C. It mimics the collection efficiency of the human respiratory system for inhalable particles.",
            "D. It measures the concentration of airborne gases."
        ],
        answer: "C",
        explain: "The primary advantage of using an IOM sampler for collecting inhalable dust samples is that it mimics the collection efficiency of the human respiratory system for inhalable particles. The IOM sampler works by drawing air through an inlet that captures particles entering the nose and mouth. \n\n The IOM Sampler houses a reusable 25 mm filter cassette that holds a specified filter for the collection of inhalable particles. The sampler is worn on a worker's chest within their breathing zone, using a pump to draw air at a constant flow rate. "
    },
    {
        number: 14,
        description: "What is the primary purpose of a diffusion sampler in air sampling?",
        choices: [
            "A. To remove particles larger than 10 microns from the sampled air",
            "B. To measure the concentration of airborne contaminants in real-time",
            "C. To actively collect and concentrate gases and vapors from the sampled air with a pump",
            "D. To passively collect samples of airborne contaminants over a period of time"
        ],
        answer: "D",
        explain: "The primary purpose of a diffusion sampler in air sampling is to passively collect samples of airborne contaminants over a period of time. Diffusion samplers do not require a sampling pump, instead, they rely on the movement of contaminants through a permeable membrane or a diffusion barrier. The contaminants diffuse through the membrane and into a sorbent or other collection medium."
    },
    {
        number: 15,
        description: "Which of the following is a commonly used method for sampling and analyzing airborne mold spores?",
        choices: [
            "A. Gravimetric sampling",
            "B. Direct-reading instruments",
            "C. Transmission electron microscopy",
            "D. Spore trap sampling"
        ],
        answer: "D",
        explain: "Sampling and analyzing airborne mold spores is an important aspect of indoor air quality assessment, especially in buildings with water damage or other moisture-related issues. Spore trap sampling is a commonly used method for collecting and analyzing airborne mold spores. In this method, a spore trap device is used to capture a sample of airborne particles onto a sticky surface. \n\nThe collected sample is then analyzed in a laboratory using a microscope and various staining techniques to identify and quantify different types of mold spores present in the sample."
    },
    {
        number: 16,
        description: "What is the purpose of a zero filter in terms of particle counting?",
        choices: [
            "A. To measure the background level of particles in the sampling system",
            "B. To remove potential sources of interference from the measurement",
            "C. To adjust for instrument drift over time",
            "D. To remove particles larger than 10 microns from the sampled air"
        ],
        answer: "A",
        explain: "The purpose of a zero filter in terms of particle counting is to measure the background level of partivles present in the sampling system/environment. A zero filter is a blank filter that is placed into the sampling media holder and attached to the sampling pump, but it is not exposed to the air being sampled. \n\nInstead, it is used to collect the particles that may have come from the sampling equipment, such as the tubing or filter holder. This background level is subtracted from the sample filter reading to get an accurate measurement of the actual particle counts in the sampled air.\n\nThis filter should be used when transporting the particle counter between rooms to prevent cross contamination. It is also a great troubleshooting tool and can also validate sample tubing to verify the tubing is particle free and eliminate false positives."
    },
    {
        number: 17,
        description: "What is the primary purpose of using a 37 mm cassette in total dust sampling for occupational exposure assessment?",
        choices: [
            "A. To measure the concentration of airborne gases.",
            "B. To selectively capture only respirable dust particles.",
            "C. To collect and measure the total dust concentration in the air.",
            "D. To monitor noise levels in the process of dust generation"
        ],
        answer: "C",
        explain: "The primary purpose of using a 37 mm cassette in total dust sampling is to collect and measure the total dust concentration in the air. The 37 mm cassette is a widely used sampling device that consists of a plastic or aluminum housing containing a filter. Air is drawn through the cassette using a calibrated pump, and the dust particles in the air are captured on the filter. This method allows for the collection of all airborne particulate matter, providing an accurate assessment of the total dust exposure in the workplace. It is not selective for respirable dust, making it suitable for assessing the overall dust load in the environment."
    },
    {
        number: 18,
        description: "What is the primary advantage of using activated charcoal to remove volatile organic compounds (VOCs) from the air in an occupational setting?",
        choices: [
            "A. It absorbs particulate matter effectively.",
            "B. It provides a visual indication of VOC presence.",
            "C. It has a high surface area that adsorbs a wide range of VOCs.",
            "D. It measures the concentration of airborne VOCs."
        ],
        answer: "C",
        explain: "The primary advantage of using activated charcoal to remove volatile organic compounds (VOCs) from the air is its high surface area, which allows it to adsorb a wide range of VOCs effectively. Activated charcoal is a porous material with a large surface area that can trap VOC molecules from the air through adsorption. This makes it highly efficient in removing various VOCs, contributing to improved air quality in occupational settings. It does not measure the concentration of VOCs or provide visual indications, but it is very effective in adsorbing and removing them from the environment."
    },
    {
        number: 19,
        description: "What is the purpose of a size-selective inlet when using a particle counter for air sampling?",
        choices: [
            "A. To remove particles larger than 10 microns",
            "B. To remove particles smaller than 10 microns",
            "C. To measure the concentration of gases and vapors in the air",
            "D. To measure the particle size distribution of airborne contaminants"
        ],
        answer: "A",
        explain: "The purpose of a size-selective inlet when using a particle counter for air sampling is to remove particles that are larger than a specific size. In this case, the size-selective inlet is designed to filter out particles larger than 10 microns. This ensures that only particles within the desired size range are counted and measured by the particle counter, providing a more accurate representation of the airborne particulate matter in the specified size range. Different size-selective inlets can be used to target specific size ranges, allowing researchers and environmental professionals to focus on particles of particular interest for their studies and monitoring purposes."
    },
    {
        number: 20,
        description: "Which of the following is a limitation of using passive samplers for air monitoring?",
        choices: [
            "A. They require a power source to operate",
            "B. They cannot provide real-time data",
            "C. They are not accurate for measuring low concentrations of gases",
            "D. They are expensive to use for long-term monitoring"
        ],
        answer: "B",
        explain: "One limitation of using passive samplers for air monitoring is that they cannot provide real-time data. Passive samplers are designed to collect samples over a specific period of time, allowing for the integration of pollutant levels during that duration. However, they do not offer immediate or continuous measurements of air quality. \n\nReal-time data is essential in certain situations where immediate feedback or prompt action is required based on air quality conditions. Passive samplers need to be retrieved and analyzed in a laboratory setting to obtain the results, which can introduce a delay in obtaining the data."
    },
    {
        number: 21,
        description: "What is the purpose of a calibration factor in the use of a direct-reading instrument for air sampling?",
        choices: [
            "A. To adjust for instrument drift over time",
            "B. To measure the concentration of airborne contaminants in real-time",
            "C. To identify specific chemical compounds in a sample",
            "D. To remove potential sources of interference from the measurement"
        ],
        answer: "A",
        explain: "A calibration factor is a value that is used to adjust for instrument drift over time. Instrument drift is a gradual change in the performance of an instrument over time. This can be caused by a number of factors, such as temperature changes, humidity changes, and wear and tear.\n\nThe calibration factor is determined by comparing the instrument's readings to known standards. This is done periodically, such as every 6 months or every year. The calibration factor is then used to correct the instrument's readings for drift."
    },
    {
        number: 22,
        description: "Which of the following is a commonly used method for analyzing asbestos air samples?",
        choices: [
            "A. Gravimetric sampling",
            "B. Direct-reading instruments",
            "C. Transmission electron microscopy",
            "D. X-ray fluorescence analysis"
        ],
        answer: "C",
        explain: "When conducting clearance sampling after asbestos abatement or remediation, the Environmental Protection Agency (EPA) recommends the use of transmission electron microscopy (TEM) to analyze the collected air samples. TEM is capable of detecting and quantifying very small asbestos fibers, which is crucial to ensure that the air is safe for reoccupation after asbestos removal.\n\nTransmission Electron Microscopy (TEM) operates by utilizing an electron gun to generate a high-energy electron beam, which passes through an ultra-thin specimen. This specimen, often less than 100 nanometers thick, interacts with the electrons, leading to processes like scattering and absorption. The transmitted electrons are then collected on the other side of the specimen, and electromagnetic lenses magnify and focus the resulting pattern to create an image. TEM provides exceptionally high resolution, surpassing the capabilities of light microscopes."
    },
    {
        number: 23,
        description: "In dust sampling, what does the term '50% cut point' refer to?",
        choices: [
            "A. The particle size at which 50% of particles are filtered out by the sampling device.",
            "B. The efficiency of the filter in removing all particle sizes.",
            "C. The maximum flow rate of the sampling pump.",
            "D. The time duration for which sampling should be conducted."
        ],
        answer: "A",
        explain: "The term '50% cut point' in dust sampling refers to the particle size at which 50% of the particles are captured or filtered out by the sampling device. This is a critical parameter for devices such as cyclones and impactors, which are designed to selectively sample particles of specific sizes. For example, a cyclone sampler with a 50% cut point of 4 micrometers will capture 50% of particles that are 4 micrometers in diameter and allow the other 50% to pass through."
    },
    {
        number: 24,
        description: "What is a primary difference between electron microscopy and light microscopy?",
        choices: [
            "A. Electron microscopy uses a beam of electrons for imaging, while light microscopy uses visible light.",
            "B. Electron microscopy can only be used for living specimens, while light microscopy can be used for both living and non-living specimens.",
            "C. Electron microscopy provides lower resolution images compared to light microscopy.",
            "D. Electron microscopy uses fluorescent dyes for imaging, while light microscopy does not."
        ],
        answer: "A",
        explain: "The primary difference between electron microscopy and light microscopy is the type of imaging beam used. Electron microscopy uses a beam of electrons to create images, which allows for much higher resolution and magnification compared to light microscopy, which uses visible light. This higher resolution enables electron microscopy to reveal much finer details of a specimen's structure, making it invaluable for studying the ultrastructure of cells, tissues, and materials at the nanometer scale."
    },
    {
        number: 25,
        description: "Which of the following is a type of direct-reading instrument commonly used to measure the total concentration of volatile organic compounds in the air?",
        choices: [
            "A. Particle counter",
            "B. Gravimetric sampler",
            "C. Photoionization detector",
            "D. Gas chromatograph"
        ],
        answer: "C",
        explain: "A photoionization detector (PID) is a direct-reading instrument commonly used to measure the concentration of specific gases and vapors in the air. PIDs work by utilizing high-energy ultraviolet (UV) light to ionize gas molecules, generating a measurable electrical current. This current is then correlated with the concentration of the target gases or vapors. \n\nPIDs are particularly effective in detecting volatile organic compounds (VOCs) and are widely used in various applications, including environmental monitoring, industrial hygiene, and indoor air quality assessments."
    },
    {
        number: 26,
        description: "What is a primary difference between Transmission Electron Microscopy (TEM) and Scanning Electron Microscopy (SEM)?",
        choices: [
            "A. TEM provides 3D images, while SEM provides 2D images.",
            "B. TEM requires thin samples, while SEM can be used with bulkier samples.",
            "C. TEM uses visible light, while SEM uses a beam of electrons.",
            "D. TEM is used for surface imaging, while SEM is used for internal structure imaging."
        ],
        answer: "B",
        explain: "A primary difference between Transmission Electron Microscopy (TEM) and Scanning Electron Microscopy (SEM) is the type of samples they require and the type of imaging they provide. TEM requires very thin samples, typically less than 100 nanometers thick, because it transmits electrons through the specimen to form an image. This allows TEM to reveal detailed internal structures at high resolution. SEM, on the other hand, scans a focused beam of electrons over the surface of bulkier samples, providing detailed 3D surface images and compositional information. This makes SEM suitable for analyzing the surface topography and composition of specimens."
    },
    {
        number: 27,
        description: "Which of the following is a limitation of using colorimetric detector tubes for gas monitoring?",
        choices: [
            "A. They are only suitable for measuring low concentrations of gases",
            "B. They require frequent calibration to maintain accuracy",
            "C. They are not portable and require a laboratory setting for analysis",
            "D. They cannot detect all types of gases and vapors"
        ],
        answer: "D",
        explain: "Colorimetric detector tubes are a type of gas monitoring device that uses a chemical reaction to detect the presence of a specific gas or vapor. Each tube is designed to detect a specific gas or vapor, and there are many different types of tubes available. However, there are some gases and vapors that cannot be detected using colorimetric detector tubes."
    },
    {
        number: 28,
        description: 'When collecting asbestos flooring samples for analysis: ',
        choices: [
            "A. The accompanying mastic is not suspect",
            "B. 12'*12' tiles are not suspect",
            "C. A sawzall is the prefered tool",
            "D. All sublayers must be sampled"
        ],
        answer: "D",
        explain: "When collecting flooring samples for analysis, it is essential to sample all the sublayers of the flooring material. Asbestos-containing materials might be present in various layers of the flooring, including the primary material and any associated adhesive or mastic.\n\n Using a sawzall for sample collection can potentially generate airborne asbestos fibers, posing health risks to those performing the sampling. It is essential to use proper, low-dust methods for sample collection, such as using hand tools, to minimize fiber release."
    },
    {
        number: 29,
        description: "What is the primary difference between a media blank and a field blank in environmental sampling?",
        choices: [
            "A. Media blanks are used to test for contamination during sample transport, while field blanks test for contamination during sample analysis.",
            "B. Media blanks are unopened media from the same lot, testing for contamination from the supplier, while field blanks accompany the actual sample and are opened briefly without drawing air, testing for contamination from the supplier and sampling procedures.",
            "C. Media blanks are used for quality control in the laboratory, while field blanks are used for quality control in the field.",
            "D. Media blanks are prepared with the sample media in the lab, while field blanks are prepared with the sample media in the field."
        ],
        answer: "B",
        explain: "Media blanks are unopened media from the same lot used in sampling, testing for any contamination that may have originated from the supplier. Field blanks, on the other hand, accompany the actual sample and are opened briefly at the sampling site without drawing air. They test for contamination from both the supplier and the sampling procedures, ensuring the accuracy and reliability of the environmental sampling process."
    }
    ,
    {
        number: 30,
        description: "Which of the following is a type of direct-reading instrument commonly used for measuring the concentration of particulate matter in the air?",
        choices: [
            "A. FID",
            "B. PID",
            "C. Electrochemical sensor",
            "D. Laser particle counter"
        ],
        answer: "D",
        explain: "A laser particle counter is a direct-reading instrument used for measuring the concentration of airborne particulate matter. The instrument works by drawing in a sample of air through a size-selective inlet, and then using a laser beam to detect and count the number of particles within specific size ranges. \n\nThe concentration of particulate matter in the air can then be calculated based on the particle count and the volume of air sampled. Some laser particle counters can also provide real-time data and particle size distribution information, allowing for more detailed analysis of airborne particulate matter."
    },
    {
        number: 31,
        description: "Which of the following is a type of direct-reading instrument commonly used for measuring the concentration of carbon monoxide in the air?",
        choices: [
            "A. FID",
            "B. PID",
            "C. Electrochemical sensor ",
            "D. NDIR analyzer"
        ],
        answer: "C",
        explain: "Electrochemical sensor is a type of direct-reading instrument commonly used for measuring the concentration of carbon monoxide in the air. Electrochemical sensors use a chemical reaction to produce an electrical signal that is proportional to the concentration of the gas being measured. The sensor contains an electrode that is coated with a material that reacts with carbon monoxide, producing an electric current that can be measured and converted to a concentration reading."
    },
    {
        number: 32,
        description: "Which of the following are common sorbent materials used in sorbent tubes for air sampling?",
        choices: [
            "A. Activated carbon, silica gel, tenax",
            "B. Sodium chloride, potassium bromide, calcium sulfate",
            "C. Copper, zinc, aluminum oxide",
            "D. Magnesium sulfate, sodium bicarbonate, calcium carbonate"
        ],
        answer: "A",
        explain: "Common sorbent materials used in sorbent tubes for air sampling include activated carbon, silica gel, and tenax. These materials are effective at adsorbing various airborne contaminants, which can then be analyzed to determine their concentrations. Activated carbon is often used for organic vapors, silica gel for polar compounds, and tenax for a wide range of volatile organic compounds. \n\nTenax is a porous polymer resin that is particularly useful for trapping and analyzing volatile organic compounds (VOCs) due to its high thermal stability and inertness."
    },
    {
        number: 33,
        description: "Which of the following is a type of direct-reading instrument commonly used for measuring the concentration of hydrogen sulfide in the air?",
        choices: [
            "A. FID",
            "B. PID",
            "C. Electrochemical sensor",
            "D. NDIR analyzer"
        ],
        answer: "C",
        explain: "Electrochemical sensors are commonly used for measuring the concentrations of gases such as carbon monoxide, hydrogen sulfide, and ozone. These sensors operate based on the principle of electrochemical reactions that occur at the sensor's electrodes in the presence of the target gas."
    },
    {
        number: 34,
        description: "Which of the following components is used in estimating the effect of solar radiation on humans in the WBGT thermometer?",
        choices: [
            "A. Wet bulb",
            "B. Black globe",
            "C. Dry bulb",
            "D. Wind speed"
        ],
        answer: "B",
        explain: "The WBGT (Wet Bulb Globe Temperature) thermometer is a specialized instrument used to measure the WBGT index, which is an indicator of the combined effects of temperature, humidity, wind speed, and solar radiation on the human body in direct sunlight. The WBGT index is particularly important for assessing the risk of heat stress and determining appropriate safety measures in environments where individuals are exposed to high temperatures.The WBGT thermometer typically integrates the measurements from three different thermometers: \n\nWet Bulb Thermometer: Measures the temperature with a thermometer covered in a wet cloth. The cooling effect from water evaporation mimics how the human body cools itself with sweat.\n\nDry Bulb Thermometer: Calculates the air temperature measured in the shade. It represents the ambient temperature you would see on a regular thermometer outside.\n\nBlack Globe Thermometer:The black globe absorbs sunlight and measures solar radiation."
    },
    {
        number: 35,
        description: "Which of the following statements accurately compares Transmission Electron Microscopy (TEM) and Phase Contrast Microscopy (PCM) in identifying fiber type?",
        choices: [
            "A. TEM can identify fiber type, while PCM cannot differentiate between different types of fibers.",
            "B. PCM is more effective in identifying fiber type compared to TEM.",
            "C. Both TEM and PCM are equally capable of identifying fiber type.",
            "D. Neither TEM nor PCM are suitable methods for identifying fiber type."
        ],
        answer: "A",
        explain: "TEM is a powerful technique that uses an electron beam to image specimens at a high magnification. It is capable of providing detailed information about the structure and composition of fibers, including their size, shape, and crystal structure. This makes TEM suitable for identifying specific types of fibers, such as asbestos fibers, based on their unique characteristics. TEM can distinguish between different fiber types and provide valuable information for fiber analysis.\n\nOn the other hand, Phase Contrast Microscopy (PCM) is an optical microscopy technique commonly used for the detection and quantification of airborne fibers. PCM is used for asbestos compliance but it cannot identify fiber type. PCM relies on the differences in refractive index between fibers and the surrounding medium to create contrast and enhance their visibility. While PCM is effective in detecting fibers and estimating their concentration, it does not provide detailed information about the specific fiber types. It is primarily used for screening purposes and may require additional techniques, such as polarized light microscopy, to identify specific fiber types."
    },
    {
        number: 36,
        description: "What is the principle behind the operation of a diffusive sampler in air monitoring?",
        choices: [
            "A. Active pumping of air through the sampler",
            "B. Adsorption of gases onto a solid sorbent material",
            "C. Diffusion of gases through a permeable membrane",
            "D. Electrochemical detection of specific gases"
        ],
        answer: "C",
        explain: "The principle behind the operation of a diffusive sampler in air monitoring is the diffusion of gases through a permeable membrane at a certain rate or speed. Diffusive samplers are passive sampling devices that rely on the natural movement of gases from an area of higher concentration to an area of lower concentration. \n\nThe sampler consists of a permeable membrane that allows the target gases to pass through. As the gases diffuse through the membrane, they come into contact with a sorbent material inside the sampler, where they are trapped or adsorbed."
    },
    {
        number: 37,
        description: "A sampling pump is used to collect a 4-hour air sample. If the total volume of air sampled is 480 liters, what is the actual flow rate of the pump during sampling?",
        choices: [
            "A. 2 lpm",
            "B. 3 lpm",
            "C. 2.2 lpm",
            "D. 3.3 lpm"
        ],
        answer: "A",
        explain: "In this case, the total volume of air sampled is 480 liters and the sampling time is 240 minutes (4 hours x 60 minutes per hour). Plugging these values into the formula, we get: Actual flow rate = 480 liters ÷ 240 minutes = 2 liters per minute."
    },
    {
        number: 38,
        description: "A laboratory analysis of an air sample collected with a filter weighing 0.2 grams indicates a concentration of 2.5 milligrams of particulate matter per cubic meter of air. What is the air volume sampled?",
        choices: [
            "A. 50 m³",
            "B. 80 m³",
            "C. 100 m³",
            "D. 120 m³"
        ],
        answer: "B",
        explain: "Air volume sampled = Mass of particulate matter / Concentration of particulate matter = 0.2 g * (1000 mg/g) / 2.5 mg/m³ Air volume sampled = 80 m³."
    },
    {
        number: 39,
        description: "A laboratory analysis of an air sample collected with a sorbent tube indicates a concentration of 50 micrograms per cubic meter (μg/m\u00B3) of benzene. If the air volume sampled is 240 liters, what is the total mass of benzene collected in milligrams (mg)?",
        choices: [
            "A. 0.012 mg",
            "B. 0.024 mg",
            "C. 0.048 mg",
            "D. 0.12 mg"
        ],
        answer: "A",
        explain: "First, convert the air volume sampled from liters to cubic meters: 240 liters = 0.240 cubic meters. Then, Calculate the total mass of benzene collected: Total mass = concentration x volume = 50 x 0.240 = 12 μg ÷ 1000 = 0.012 mg."
    },
    {
        number: 40,
        description: "Which of the following definitions accurately describes dusts, mists, smoke, and fumes?",
        choices: [
            "A. Dusts are solid particles generated through mechanical processes, mists are liquid droplets suspended in the air, smoke is a mixture of solid and liquid particles resulting from combustion, and fumes are particles formed by condensation of vaporized solid materials.",
            "B. Dusts are liquid droplets suspended in the air, mists are solid particles generated through mechanical processes, smoke is a mixture of solid and liquid particles resulting from combustion, and fumes are particles formed by condensation of vaporized solid materials.",
            "C. Dusts are solid particles generated through mechanical processes, mists are particles formed by condensation of vaporized liquid materials, smoke is a mixture of solid and liquid particles resulting from combustion, and fumes are liquid droplets suspended in the air.",
            "D. There is no distinction between those terms."
        ],
        answer: "A",
        explain: "Dusts: Dusts are solid particles that are generated through mechanical processes such as grinding, crushing, or handling of materials. They are typically larger in size and can be composed of various materials, such as minerals, metals, or organic substances.\n\nMists: Mists are liquid droplets that are suspended in the air. They are formed when a liquid material is atomized or dispersed into small droplets, usually through processes such as spraying, fogging, or condensation. Mists can vary in size and composition depending on the liquid being dispersed.\n\nSmoke: Smoke is a mixture of solid and liquid particles that results from the combustion or burning of materials. It is typically generated from processes such as industrial processes, burning of fuels, or fires. Smoke particles are usually very small and can contain a combination of solid carbon particles, liquid droplets, and gases.\n\nFumes: Fumes refer to particles that are formed by the condensation of vaporized solid materials. They are typically generated when solid materials are heated to a high temperature, causing them to vaporize and then re-condense into small particles. Fumes are often associated with processes such as welding, soldering, or high-temperature industrial operations."
    },
    {
        number: 41,
        description: "What principle does a traditional mercury-in-glass thermometer use to measure temperature?",
        choices: [
            "A. Electrical resistance",
            "B. Thermal expansion",
            "C. Electromagnetic radiation",
            "D. Chemical reaction"
        ],
        answer: "B",
        explain: "A traditional mercury-in-glass thermometer measures temperature based on the principle of thermal expansion. When the temperature increases, the mercury inside the glass tube expands and rises. Conversely, when the temperature decreases, the mercury contracts and falls. This expansion and contraction of mercury with temperature changes allow for accurate measurement of temperature."
    },
    {
        number: 42,
        description: "What are the differences between inhalable dust, thoracic dust, and respirable dust in terms of particle size and where they can reach in the lungs?",
        choices: [
            "A. Inhalable dust includes particles up to 100 micrometers, thoracic dust includes particles up to 25 micrometers, and respirable dust includes particles up to 10 micrometers.",
            "B. Inhalable dust includes particles up to 10 micrometers, thoracic dust includes particles up to 100 micrometers, and respirable dust includes particles up to 4 micrometers.",
            "C. Inhalable dust includes particles up to 4 micrometers, thoracic dust includes particles up to 10 micrometers, and respirable dust includes particles up to 100 micrometers.",
            "D. Inhalable dust includes particles up to 4 micrometers, thoracic dust includes particles up to 100 micrometers, and respirable dust includes particles up to 10 micrometers."
        ],
        answer: "A",
        explain: "Inhalable dust refers to particles up to 100 micrometers, thoracic dust includes particles up to 25 micrometers, and respirable dust includes particles up to 10 micrometers. This means that inhalable dust can be inhaled but not deeply enough to reach the thoracic or respirable region of the lungs. Thoracic dust can reach the thoracic region (tracheobronchial region) of the lungs but not small enough to reach the deepest part of the lungs. Respirable dust can reach the deepest part of the lungs (alveolar region)."
    },
    {
        number: 43,
        description: "Which of the following best describes the definition of a fiber in industrial hygiene?",
        choices: [
            "A. A particle with a diameter of less than 1 micron",
            "B. A particle that can be inhaled into the lungs",
            "C. A particle with an aspect ratio of 3:1 or greater",
            "D. A particle that is visible under a light microscope"
        ],
        answer: "C",
        explain: "In industrial hygiene, a fiber is defined as a particle with an aspect ratio of 3:1 or greater. This means that the length of the particle is at least three times its width. This definition is important for identifying fibers that may pose respiratory health risks, such as asbestos fibers, which can cause lung diseases when inhaled."
    },
    {
        number: 44,
        description: "Which of the following is a type of air sampler that separates particles based on their aerodynamic diameter?",
        choices: [
            "A. Filter sampler",
            "B. Impactor",
            "C. Sorbent tube",
            "D. Direct-reading instrument"
        ],
        answer: "B",
        explain: "An impactor is a type of air sampler that separates particles based on their aerodynamic diameter. The sampler works by drawing air through a nozzle or series of nozzles, which accelerate the air to a high velocity. As the air flows through the nozzle, particles in the air are impacted onto a collection surface, which can be a filter or a collection plate. \n\nThe size of the particles that are collected depends on the size of the nozzle and the velocity of the air, with larger particles being collected on the collection surface nearest the nozzle and smaller particles being collected on the surfaces farther from the nozzle. By analyzing the collection surfaces, researchers can determine the size distribution of particles in the air, which can be useful for understanding the health effects of different particle sizes."
    },
    {
        number: 45,
        description: 'As per the Asbestos Hazard Emergency Response Act (AHERA) regulations, which bulk sampling container is required for asbestos bulk sampling?',
        choices: [
            "A. 25 mm cassette",
            "B. 37 mm cassette",
            "C. Rigid container",
            "D. plastic bag"
        ],
        answer: "C",
        explain: "A rigid container is used for the collection and transportation of bulk samples of suspected asbestos-containing materials. It ensures the safe storage and transport of the sample to a laboratory for analysis, helping to maintain the integrity of the sample and prevent the release of asbestos fibers. The use of proper rigid containers is crucial to comply with AHERA guidelines and ensure accurate asbestos identification and assessment."
    },
    {
        number: 46,
        description: "In asbestos sampling with a 25-mm filter, an average asbestos fiber density of 200 fibers/mm², and a sampling volume of 2000 liters, what is the asbestos concentration in fibers/ml?",
        choices: [
            "A. 0.08 fibers/ml",
            "B. 0.08 fibers/m³",
            "C. 0.008 fibers/m³",
            "D. 8 fibers/ml"
        ],
        answer: "A",
        explain: "For a 25-mm filter, the effective area of 385 mm²(You can find this in equation sheet, constant section). Using the 18th formula, Casb = asbestos concentration in fibers/ml, E = fiber density on filters (200 fibers/mm²), Ac = effective collection area (385 mm²), Vs = sampling volumn (2000 litters). \n\nCasb = 200 * 385 / (1000*1000) = 0.08 fibers/ml"
    },
    {
        number: 47,
        description: "Calculate the asbestos concentration in fibers per liter when 1000 liters of air are sampled, 70 fibers are counted on a total of 100 fields, and the field blank has 1 fiber counted in a total of 10 fields. Assume a 25 mm filter was used.",
        choices: [
            "A. 0.029 fibers/ml",
            "B. 0.029 fibers/l",
            "C. 0.29 fibers/ml",
            "D. 0.32 fibers/ml"
        ],
        answer: "A",
        explain: "For a 25-mm filter, the effective area of 385 mm² and the graticule field area is 0.00785 mm² (You can find this in equation sheet, constant section). To calculate the asbestos concentration, use the 17th formula in general science.\n\n Cs = average number of fibers per graticule field in the sample. Cb = average number of fibers per graticule field in the field blank. Ac = effective area. Af = graticule field area. Vs = sampling volumn. \n\n Casb = (0.7-0.1) * 385 / (1000*0.00785*1000) = 0.029 fibers/ml."
    },
    {
        number: 48,
        description: "For a 25-mm filter, what is the fiber density of an asbestos sample when there a total of 70 fiber counted on 100 graticule fields and no fiber counted the filed blanks",
        choices: [
            "A. 89 fibers/mm²",
            "B. 98 fibers/mm²",
            "C. 29 fibers/mm²",
            "D. 0.32 fibers/mm²"
        ],
        answer: "A",
        explain: "For a 25-mm filter, the effective area of 385 mm² and the graticule field area is 0.00785 mm² (You can find this in equation sheet, constant section). To calculate the asbestos concentration, use the 19th formula in general science.\n\n f/Nf = fiber counts in the sample/ number of graticule field = 70/100. B/Nb = fiber counts in the blank/ number of graticule field = 0. Af = graticule field area. \n\n Fiber density = (0.7 - 0) / 0.00785 = 89 fibers/mm²."
    },
    {
        number: 49,
        description: "The parameters measured in indoor air quality assessments often include:",
        choices: [
            "A. Criteria pollutants such as nitrogen dioxide and ozone",
            "B. Particulate matter, volatile organic compounds, temperature and humidity",
            "C. Sulfur dioxide and carbon monoxide",
            "D. Radon and formaldehyde"
        ],
        answer: "B",
        explain: "One important parameter measured in indoor air quality assessments is particulate matter (PM). Particulate matter refers to tiny solid or liquid particles suspended in the air, which can come from various sources such as dust, smoke, pollen, and combustion processes. The measurement of particulate matter helps to evaluate the level of airborne particles present in indoor spaces, as well as their size distribution.\n\nVolatile organic compounds (VOCs) are another parameter commonly measured in indoor air quality assessments. VOCs are organic chemicals that can easily vaporize at room temperature and can be emitted from a variety of sources such as building materials, furniture, cleaning products, and personal care products. VOCs can contribute to indoor air pollution and can have both short-term and long-term health effects. Measurement of VOCs helps to identify potential sources and assess their concentrations in indoor environments.\n\nOn the other hand, the parameters listed in options A, C, and D (nitrogen dioxide, ozone, sulfur dioxide, carbon monoxide, radon, and formaldehyde) are often associated with outdoor air monitoring rather than indoor air quality assessments. These parameters are considered criteria pollutants and are typically regulated by environmental agencies for outdoor air quality standards. While some of these pollutants can also enter indoor spaces from outdoor sources, their measurement is not typically included as a primary focus in indoor air quality assessments."
    },
    {
        number: 50,
        description: "What is the main purpose of using Tedlar bags in air sampling?",
        choices: [
            "A. To provide a reference sample for comparison with field samples ",
            "B. To measure the concentration of a particular contaminant in the air ",
            "C. To check for contamination that may occur during sample collection or transportation ",
            "D. To store air samples for later analysis "
        ],
        answer: "D",
        explain: "Tedlar bags are often used in air sampling to store air samples for later analysis in the laboratory. These bags are made of a type of polymer that is inert and does not react with the air or the chemicals in the sample, so it does not affect the accuracy of the measurements. \n\nThe bags are also airtight, which prevents the loss of the sample during transportation and storage. The use of Tedlar bags can allow for more convenient and cost-effective air sampling by enabling the collection of larger samples over longer periods of time, which can then be analyzed in the laboratory at a later time."
    },
    {
        number: 51,
        description: "What is the primary purpose of using an impinger in air sampling?",
        choices: [
            "A. A device used to measure the volume of a gas sample",
            "B. A device used to collect and measure airborne chemicals",
            "C. A device used to extract liquid from a sample",
            "D. A device used to analyze the composition of a solid sample"
        ],
        answer: "B",
        explain: "Impingers, sometimes known as bubblers, are small bottles used with an air sample pump to collect airborne hazards into designated collection liquids for analysis. During the sampling process, air containing particles or gases is drawn through the narrow neck of the impinger by a pump or another sampling device. As the air passes through the impinger, particles or gases get captured or dissolved in the collection liquid. After sampling, the liquid containing the captured substances is typically analyzed using various laboratory techniques, depending on the nature of the target pollutants."
    },
    {
        number: 52,
        description: "What is the primary advantage of isokinetic sampling over other air sampling methods?",
        choices: [
            "A. It can be used to sample a wide range of pollutants.",
            "B. It provides a representative sample of the gas stream being sampled.",
            "C. It is less expensive than other air sampling methods.",
            "D. It requires less equipment than other air sampling methods."
        ],
        answer: "B",
        explain: 'Isokinetic sampling is a method of sampling air or other gas streams to measure the concentration of pollutants or other constituents. The term "isokinetic" refers to the fact that the sampling velocity is maintained equal to the gas stream velocity, ensuring that the sample is representative of the gas stream at the point of sampling. Isokinetic sampling can abe achieved by adjusting the sampling pump to match the gas stream velocity.\n\nIf the sampling velocity is less than the exhaust stream velocity, it will result in oversampling of large particles because large particles (heavier) can still go to the tube due to inertia and the small particles (lighter) will not be sampled. This will result in contaminant concentration (mass/volumn of air) more than the actual concentration. \n\nOn the other end, if the sampling velocity is more than the exhaust stream velocity, it will result in oversampling of small particles, which will show a result lower than the actual concentration.'
    },
    {
        number: 53,
        description: "Which method is commonly used for particulate matter analysis in the quantitative analysis of airborne contaminants?",
        choices: [
            "A. Direct-reading instruments",
            "B. Gravimetric analysis",
            "C. Chromatography",
            "D. Spectrometry"
        ],
        answer: "B",
        explain: 'Gravimetric analysis is the method commonly used for particulate matter analysis in the quantitative analysis of airborne contaminants. It involves collecting airborne particles on a filter medium, typically a pre-weighed filter, and then weighing the filter before and after sampling. The difference in weight corresponds to the mass of the collected particles.\n\nThis method is effective for measuring the concentration of particulate matter in the air because it directly measures the mass of the particles. It is often used in industrial hygiene to assess the levels of dust, smoke, aerosols, and other solid particulate contaminants in the air.'
    },
    {
        number: 54,
        description: "What is the purpose of determining a representative sampling duration in air sampling for occupational health assessments?",
        choices: [
            "A. To minimize the cost of sampling materials",
            "B. To meet regulatory requirements only",
            "C. To accurately reflect workers' exposure scenarios",
            "D. To reduce the time required for sampling"
        ],
        answer: "C",
        explain: 'The purpose of determining a representative sampling duration is to obtain air samples that provide an accurate representation of the airborne contaminant levels in the workplace over a specified period. By doing so, it allows for a comprehensive assessment of the potential health risks and helps in determining if the exposure levels comply with regulatory limits or guidelines.\n\nA representative sampling duration takes into account factors such as task variability, exposure variability, and the duration of exposure events. Different tasks or activities may result in different levels of exposure, and certain periods or tasks may contribute more significantly to higher exposures. Therefore, the sampling duration should cover these variations adequately to ensure a reliable assessment of the overall exposure scenario.'
    },
    {
        number: 55,
        description: "What is the definition of the limit of detection (LOD) in analytical chemistry?",
        choices: [
            "A. The lowest concentration of an analyte that can be reliably detected but not necessarily quantified.",
            "B. The maximum concentration of an analyte that can be reliably detected and quantified.",
            "C. The concentration of an analyte that is considered safe for human exposure.",
            "D. The average concentration of an analyte found in a series of samples."
        ],
        answer: "A",
        explain: 'The definition of the limit of detection (LOD) in analytical chemistry is that it represents the lowest concentration of an analyte that can be reliably detected by a given analytical method or instrument. The LOD indicates the point at which the signal generated by the analyte is distinguishable from the background noise or interference present in the measurement system.\n\nHowever, it is important to note that the LOD does not necessarily indicate the ability to accurately quantify the analyte. It merely signifies the presence of the analyte above the detection threshold. In other words, concentrations below the LOD may be detected, but the quantification may not be precise or reliable.'
    },
    {
        number: 56,
        description: "What is the purpose of maintaining a chain of custody in sample collection and analysis?",
        choices: [
            "A. To ensure the confidentiality of the sample information.",
            "B. To establish a chronological record of sample handling and custody.",
            "C. To prevent cross-contamination during sample transportation.",
            "D. To determine the final analytical results of the sample."
        ],
        answer: "B",
        explain: "The purpose of maintaining a chain of custody in sample collection and analysis is to establish a chronological record that documents the entire process of handling and custody of the sample from the moment it is collected until the final analysis is performed. It ensures the integrity, traceability, and reliability of the sample throughout its journey.\n\nMaintaining a chain of custody involves documenting important information such as the date, time, and location of sample collection, the names and identities of individuals involved in sample collection and transfer, any relevant observations or conditions, and any other pertinent details. This documentation creates a clear and transparent trail of custody for the sample, allowing for accountability and validation of the sample's integrity."
    },
    {
        number: 57,
        description: "Which method is suitable for quantifying the concentration of viable microorganisms in bioaerosol samples?",
        choices: [
            "A. Microscopy.",
            "B. Polymerase chain reaction (PCR).",
            "C. Culture-based methods.",
            "D. Spectrophotometry."
        ],
        answer: "C",
        explain: "Culture-based methods are the most commonly used methods for quantifying the concentration of viable microorganisms in bioaerosol samples. These methods involve collecting the bioaerosol sample on a growth medium, incubating the sample at an appropriate temperature, and counting the number of colonies that form.\n\nMicroscopy can be used to visualize microorganisms, but it cannot be used to determine whether or not the microorganisms are viable. PCR is a molecular biology technique that can be used to amplify specific DNA sequences, but it cannot be used to determine whether or not the microorganisms are viable. Spectrophotometry is a technique that can be used to measure the concentration of certain molecules, but it cannot be used to measure the concentration of viable microorganisms."
    },
    {
        number: 58,
        description: 'What does the term "Particulates Not Otherwise Regulated" (PNOR) indicate in occupational health and safety?',
        choices: [
            "A. Particles that are considered harmless and do not pose any health risks.",
            "B. Particles that have specific regulatory limits and standards for exposure.",
            "C. Particles that are potential respiratory hazards without specific regulatory limits.",
            "D. Particles that are regulated based on their physical properties rather than health effects."
        ],
        answer: "C",
        explain: 'The term "Particulates Not Otherwise Regulated" (PNOR) in occupational health and safety refers to particles that are considered potential respiratory hazards but do not have specific regulatory limits or standards established for their permissible exposure levels. These particles may pose health risks to individuals who are exposed to them in the workplace environment.'
    },
    {
        number: 59,
        description: 'Which of the following statements is true regarding inhalable dust?',
        choices: [
            "A. It includes particles with an aerodynamic diameter of up to 100 micrometers.",
            "B. It can reach the gas-exchange region of the lungs.",
            "C. It is used to evaluate the potential for serious respiratory health effects.",
            "D. It refers to particles that can enter the nose and mouth during breathing."
        ],
        answer: "A",
        explain: 'Inhalable dust can refer to particles as large as 100 microns in diameter (about the width of a human hair). It affects the upper respiratory system including the mouth, nose, throat, and upper respiratory tract. Examples of inhalable dust sources include the grinding of metals such as lead-containing alloys and earth-moving activities in construction and remediation. '
    },
    {
        number: 60,
        description: 'Which region of the respiratory system can respirable dust particles reach?',
        choices: [
            "A. Nasal passages",
            "B. Alveoli",
            "C. Upper respiratory tract",
            "D. Lower respiratory tract"
        ],
        answer: "B",
        explain: "Respirable dust particles are small enough to reach the gas-exchange region of the lungs, known as the alveoli. These particles have an aerodynamic diameter of up to 10 micrometers, enabling them to penetrate deep into the lungs. The alveoli are the site where gas exchange occurs, and the presence of respirable dust particles in this region can potentially lead to severe respiratory health effects."
    },
    {
        number: 61,
        description: "What does the term 'total dust' refer to in industrial hygiene?",
        choices: [
            "A. The sum of all particulate matter in the air, regardless of size",
            "B. Dust particles that are visible to the naked eye",
            "C. The fraction of dust particles that can be inhaled and deposited in the lungs",
            "D. Dust particles that are less than 10 microns in diameter"
        ],
        answer: "A",
        explain: "In industrial hygiene, 'total dust' refers to the sum of all particulate matter present in the air, regardless of the size of the particles. This includes both respirable and non-respirable particles, providing a comprehensive measure of dust exposure in a given environment."
    },
    {
        number: 62,
        description: 'Which of the following technique is not appropriate for asbestos bulk analysis?',
        choices: [
            "A. X-ray diffraction (XRD)",
            "B. Polarized light Microscopy (PLM)",
            "C. Transmission Electron Microscopy (TEM)",
            "D. Gas Chromatography-Mass Spectrometry (GC-MS)"
        ],
        answer: "D",
        explain: "Gas Chromatography-Mass Spectrometry (GC-MS) is not suitable for asbestos analysis. GC-MS is primarily used to identify and analyze organic compounds, while asbestos is a mineral fiber and cannot be detected using this method. \n\n Polarized Light Microscopy (PLM) is the EPA-required method for the analysis of bulk building materials.  This method is relatively inexpensive. The light microscopy technique utilizes the unique features of polarized light to observe mineral specific optical properties. The identification of asbestos fiber bundles is determined by the visual properties displayed when the sample is treated with various dispersion staining liquid. The technician is trained to give a visual estimation of the percentage of asbestos in a sample.\n\nX-ray Diffraction (XRD) is another method for analyzing bulk materials for asbestos. X-ray Diffraction (XRD) can be used to identify the types of asbestos minerals. When a sample containing asbestos is subjected to XRD analysis, X-rays are directed at the sample, and the resulting diffraction pattern is measured. The diffraction pattern is characteristic of the crystal structure of the minerals present in the sample. It is sometimes used to confirm the presence of asbestos in a sample already analyzed by PLM. XRD is not used routinely since it is not as sensitive as PLM in detecting asbestos.\n\nTransmission Electron Microscopy (TEM) represents the most sophisticated technology available for characterizing asbestos minerals. This technique is now the standard for most airborne investigations including post abatement clearance testing as well as diagnostic and environmental monitoring activities. TEM can be used to identify asbestos fibers in air and bulk samples. Using magnifications routinely at 20,000X or greater, the TEM can differentiate, not only asbestos from non-asbestos fibers, but also can classify the several species that comprise asbestos minerals."
    },
    {
        number: 63,
        description: "A gravimetric analysis was conducted by sampling air for 4 hours at a flow rate of 10 L/min. The initial weight of the filter was 2.000 g, and the final weight after sampling was 2.015 g. Calculate the dust mass concentration in the air, expressed in mg/m³.",
        choices: [
            "A. 0.0625 mg/m³",
            "B. 6.25 mg/m³",
            "C. 0.1875 mg/m³",
            "D. 0.250 mg/m³"
        ],
        answer: "B",
        explain: "First, determine the total volume of air sampled: 4 hours × 60 minutes/hour × 10 L/min = 2400 L. Convert this to cubic meters: 2400 L × 0.001 m³/L = 2.4 m³. The mass of dust collected is the difference between the final and initial weights of the filter: 2.015 g - 2.000 g = 0.015 g, which is 15 mg. Finally, calculate the dust mass concentration: 15 mg / 2.4 m³ = 6.25 mg/m³."
    },
    {
        number: 64,
        description: 'Which technique is commonly used for the analysis of asbestos air samples?',
        choices: [
            "A. Polarized Light Microscopy (PLM)",
            "B. Phase Contrast Microscopy (PCM)",
            "C. Transmission Electron Microscopy (TEM)",
            "D. Both B and C"
        ],
        answer: "D",
        explain: "Polarized Light Microscopy (PLM) is used for asbestos bulk sampling but not for air sampling.\n\nThe most common methodology used for asbestos air testing is Phase Contrast Microscopy (PCM). This testing method involves using a PCM microscope to count the fibers present on the cassette filter in order to determine if the total fiber concentration is less than the EPA clearance level. Phase Contrast Microscopy (PCM) is an optical microscopy technique specifically designed for enhancing the contrast of transparent specimens with low inherent contrast, such as biological cells or airborne particles.  However, the primary problem with PCM is that it can be skewed high by non-asbestos fibers. PCM testing doesn’t specifically look for asbestos itself; only fibers around the size of asbestos and is only concerned with the total fiber concentration in the air. \n\nIn comparison, TEM sampling methods use a higher magnification thereby allowing smaller fibers to be detected and allowing the technician to positively identify an asbestos fiber or a non-asbestos fiber. TEM air sampling is the superior method due its precision and accuracy. TEM is also much more expensive than a PCM sample. At the laboratory, TEM air samples can cost 5 times more than a PCM for a standard turnaround time. "
    },
    {
        number: 65,
        description: 'Passive dosimeter badges required a minimum face velocity of?',
        choices: [
            "A. 15 fpm",
            "B. 10 fpm",
            "C. 25 fpm",
            "D. 20 fpm"
        ],
        answer: "A",
        explain: "Passive dosimeter badges require a minimum face velocity of 15 feet per minute (fpm). This is the minimum air velocity that is required to ensure that the badge is exposed to enough of the contaminant to provide a valid measurement. If the face velocity is too low, the badge may not collect enough of the contaminant, and the measurement will be inaccurate.\n\nThe Occupational Safety and Health Administration (OSHA) requires a minimum face velocity of 15 fpm for passive dosimeter badges used to measure airborne contaminants. This requirement is found in OSHA Method ID-205, which is the method for sampling airborne contaminants using passive dosimeter badges.\n\nIf you are using a passive dosimeter badge to measure airborne contaminants, it is important to ensure that the face velocity is at least 15 fpm."
    },
    {
        number: 66,
        description: "Which of the following sampling media is commonly used to capture bioaerosols, specifically fungal spores?",
        choices: [
            "A. Impaction plates",
            "B. Sorbent tubes",
            "C. Air-O-Cell spore traps",
            "D. Filter cassettes"
        ],
        answer: "C",
        explain: "Air-O-Cell spore traps are widely used in industrial hygiene and environmental monitoring to collect bioaerosols, particularly fungal spores. They consist of a small, transparent cassette with a sticky adhesive surface. When air is drawn through the cassette, airborne particles, including fungal spores, get impacted onto the adhesive surface and are trapped.\n\nThe collected samples from Air-O-Cell spore traps can be analyzed under a microscope to identify and quantify the types of fungal spores present. This information is important for assessing indoor air quality, investigating mold contamination, and determining potential health risks associated with fungal exposure."
    },
    {
        number: 67,
        description: "Which of the following equations represents the Ideal Gas Law?",
        choices: [
            "A. PV = nRT",
            "B. E = mc^2",
            "C. F = ma",
            "D. V = IR"
        ],
        "answer": "A",
        "explain": "The Ideal Gas Law is represented by the equation PV = nRT, where P is pressure, V is volume, n is the number of moles of gas, R is the universal gas constant, and T is temperature. This law describes the relationship between these variables for an ideal gas, which is a hypothetical gas that perfectly follows the gas laws without any deviations. Essentially, the Ideal Gas Law means that for a given amount of gas, the product of pressure and volume is directly proportional to the product of the number of moles, the gas constant, and the temperature. This allows us to predict how a gas will behave under different conditions of pressure, volume, and temperature."
    },
    {
        number: 68,
        description: "A mixture contains: 50 mL benzene (vapor pressure = 75 mm) ; 25 mL carbon tetrachloride (vapor pressure = 91 mm); and 25 mL trichloroethylene (vapor pressure = 58 mm). Assuming Raoult's Law is obeyed, what will be the total vapor pressure of the above mixture?",
        choices: [
            "A. 68 mm",
            "B. 91 mm",
            "C. 74.75 mm",
            "D. 58 mm"
        ],
        answer: "C",
        explain: "The ideal gas law is given by the equation: PV = nRT. Therefore mole fraction of the concentration is equal to the volumn fraction. The mole fractions of the components in the mixture are:\n\nBenzene: 50/100 = 0.5\n\nCarbon tetrachloride: 25/100 = 0.25\n\nTrichloroethylene: 25/100 = 0.25\n\nRaoult's Law states that the vapor pressure of an ideal solution is the sum of the partial pressures of its individual components, each multiplied by its mole fraction in the solution. Therefore total pressure = 0.5 * 75 + 0.25 * 91 + 0.25 * 58 = 74.75 mm."
    }
    ,
    {
        number: 69,
        description: 'In an indoor air quality investigation where a mixture of unknown organic vapors is suspected, how can the utilization of a handheld PID (Photoionization Detector) instrument be beneficial?',
        choices: [
            "A. It can evaluate the overall air quality in the building. ",
            "B. It can assist the investigator in tracking the origin of the organic vapors within the building.",
            "C. It can provide real-time data on the lower explosive limit in the air.",
            "D. It can provide immediate identification of the specific organic vapors present."
        ],
        answer: "B",
        explain: "While a handheld PID instrument can provide information about the presence and concentration of organic vapors, it does not provide a comprehensive evaluation of overall air quality. Other parameters, such as temperature, humidity, particulate matter, and specific chemical compounds, need to be considered to assess the overall air quality in a building.\n\nPID instruments are based on the response of ionizable compounds and provide a general indication of total VOC concentrations. For specific identification of organic compounds, additional analysis using techniques such as gas chromatography-mass spectrometry (GC-MS) is typically required."
    },
    {
        number: 70,
        description: "An ideal gas at a pressure of 1 atm and temperature of 300 K is compressed to a volume of 1/3 of its original volume while its temperature is increased to 900K. What is the new pressure of the gas relative to its initial pressure?",
        choices: [
            "A. 3 atm",
            "B. 6 atm",
            "C. 9 atm",
            "D. 1/9 atm"
        ],
        answer: "C",
        explain: "Use Equation 4 in general science. Substituting these values into the ideal gas law, we can solve for P\u2082: P\u2082 = (P\u2081V\u2081T\u2082) / (V\u2082T\u2081) =(1 x 900 ) / (1/3 x 300) * 1 atm = 9 atm."
    }
]