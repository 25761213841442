export const noise_questions = [
    {
        number: 1,
        description: "Suppose there are two sources of noise. Source 1 has a sound pressure level of 80 dB; Source 2 has a sound pressure level of 80 dB as well. What is the resulting sound pressure level?",
        choices: [
            "A. 86 dB",
            "B. 81 dB",
            "C. 83 dB",
            "D. 80 dB"
        ],
        answer: "C",
        explain: "A simple rule for adding up the sound levels is to assign a value between 0 – 3 dB to the sound level caused by the loudest sound source. This value depends on the difference between two sound sources. If two sources have a difference of 1 or less dB, it is sufficient to add 3 dB to the loundest source to obtain the total sound pressure level."
    },
    {
        number: 2,
        description: "Suppose there are two sources of noise. Source 1 has a sound pressure level of 80 dB; Source 2 has a sound pressure level of 83. What is the resulting sound pressure level?",
        choices: [
            "A. 85 dB",
            "B. 84 dB",
            "C. 83 dB",
            "D. 86 dB",
        ],
        answer: "A",
        explain: "A simple rule for adding up the sound levels is to assign a value between 0 – 3 dB to the sound level caused by the loudest sound source. If the difference is between 2-3 dB, add 2 dB to the loudest source. In this case, since the difference is 3 dB, we add 2 dB to the loudest source, which is Source 2 at 83 dB. This gives us a resulting sound pressure level of 85 dB."
    },
    {
        number: 3,
        description: "Suppose there are two sources of noise. Source 1 has a sound pressure level of 80 dB; Source 2 has a sound pressure level of 88 dB. What is the resulting sound pressure level?",
        choices: [
            "A. 88 dB",
            "B. 89 dB",
            "C. 90 dB",
            "D. 91 dB",
        ],
        answer: "B",
        explain: "A simple rule for adding up the sound levels is to assign a value between 0 – 3 dB to the sound level caused by the loudest sound source. If the difference is between 4-9 dB, add 1 dB to the loudest source. In this case, since the difference is 8 dB, we add 1 dB to the the loudest source, which is Source 2 at 88 dB. This gives us a resulting sound pressure level of 89 dB."
    },
    {
        number: 4,
        description: "Suppose there are two sources of noise. Source 1 has a sound pressure level of 80 dB; Source 2 has a sound pressure level of 92 dB. What is the resulting sound pressure level?",
        choices: [
            "A. 95 dB",
            "B. 94 dB",
            "C. 93 dB",
            "D. 92 dB",
        ],
        answer: "D",
        explain: "A simple rule for adding up the sound levels is to assign a value between 0 – 3 dB to the sound level caused by the loudest sound source. If the difference is more than 10 dB, the sound level is equal to the loudest sound source. In this case, the difference between the two sound sources is 12 dB, which is more than 10 dB, so the resulting sound pressure level is equal to the loudest source, which is Source 2 at 92 dB."
    },
    {
        number: 5,
        description: "Suppose there are 3 sources of noise. Source 1 has a sound pressure level of 79 dB; Source 2 has a sound pressure level of 85 dB. Source 3 has a sound pressure level of 86 dB. What is the resulting sound pressure level?",
        choices: [
            "A. 89 dB",
            "B. 94 dB",
            "C. 93 dB",
            "D. 92 dB"
        ],
        answer: "A",
        explain: "If there are more than 2 sources of sound, just calculate 2 of them at a time. In this question, the resulting SPL of source 1 and source 2 is 86 dB. Then we calcuate the resulting SPL of 86 dB and 86 dB, which is 89 dB."
    },
    {
        number: 6,
        description: "What is the frequency range that humans can typically detect sounds in?",
        choices: [
            "A. 0 Hz to 10 kHz",
            "B. 20 Hz to 20 kHz",
            "C. 50 Hz to 30 kHz",
            "D. 100 Hz to 50 kHz"
        ],
        answer: "B",
        explain: "Humans can typically detect sounds in the frequency range from about 20 Hz to 20 kHz. Sounds that fall outside this range will not be heard by humans. For example, infrasound (below 20 Hz) like the rumble of earthquakes or distant thunder (some animals can hear this!), and ultrasound (above 20 kHz) such as the high-frequency sounds used in dog whistles or medical ultrasound equipment, are inaudible to the human ear."
    }
    ,
    {
        number: 7,
        description: "What is the most common type of hearing loss caused by workplace noise exposure?",
        choices: [
            "A. Conductive hearing loss",
            "B. Sensorineural hearing loss",
            "C. Mixed hearing loss",
            "D. Central hearing loss"
        ],
        answer: "B",
        explain: "Sensorineural hearing loss occurs when there is damage to the inner ear (cochlea) or the auditory nerve pathway that transmits sound signals to the brain.\n\nThe hair cells are most sensitive to high-frequency sounds, so sensorineural hearing loss typically starts with a loss of high-frequency hearing. As the damage to the hair cells progresses, the loss of hearing can extend to lower frequencies.\n\nExposure to loud noise in the workplace, such as machinery, construction equipment, or loud music, can lead to sensorineural hearing loss over time.Prolonged exposure to high levels of noise can damage the delicate hair cells in the cochlea, which are responsible for converting sound vibrations into electrical signals that can be interpreted by the brain.\n\nSensorineural hearing loss is typically permanent and irreversible.The severity of the hearing loss can vary, ranging from mild to profound. People with sensorineural hearing loss may experience difficulty hearing certain frequencies or distinguishing speech in noisy environments.They may also perceive sounds as muffled or have trouble understanding conversations.\n\nOn the other hand, conductive hearing loss occurs when there is an issue with the outer or middle ear, such as blockage or damage to the ear canal, eardrum, or middle ear bones. Mixed hearing loss refers to a combination of sensorineural and conductive hearing loss, where both the inner ear and the outer or middle ear are affected. Central hearing loss, on the other hand, is a result of damage or impairment to the auditory nerve pathways in the brain."
    },
    {
        number: 8,
        description: "What type of hearing loss is in the outer or middle ear that prevent sound from reaching the inner ear?",
        choices: [
            "A. Conductive hearing loss",
            "B. Sensorineural hearing loss",
            "C. Mixed hearing loss",
            "D. Central hearing loss"
        ],
        answer: "A",
        explain: "Conductive hearing loss refers to a type of hearing loss that occurs when there is an obstruction or damage in the outer or middle ear, which hampers the conduction of sound waves to the inner ear. Some common causes of conductive hearing loss include:\n\nBlockage: Conditions such as earwax buildup, foreign objects in the ear canal, or fluid accumulation due to infection can obstruct the passage of sound waves.\n\nEardrum damage: A perforated or ruptured eardrum can disrupt the normal transmission of sound.\n\nMiddle ear infections: Infections such as otitis media can cause inflammation and fluid buildup in the middle ear, leading to conductive hearing loss.\n\nProblems with the ossicles: Damage or malformation of the ossicles can impair their ability to transmit sound effectively."
    },
    {
        number: 9,
        description: "What type of hearing loss is caused by damage to the brain's auditory pathways and is relatively rare?",
        choices: [
            "A. Conductive hearing loss",
            "B. Sensorineural hearing loss",
            "C. Mixed hearing loss",
            "D. Central hearing loss"
        ],
        answer: "D",
        explain: "Central hearing loss (D) is specifically due to damage or dysfunction in the central auditory pathways or the auditory cortex in the brain. This type of hearing loss involves the brain's ability to process and interpret sounds rather than the initial reception of sound waves by the ear. It is relatively rare compared to conductive and sensorineural hearing loss."
    },
    {
        number: 10,
        description: "A machine in a factory produces a sound level of 105 dB. If the distance between the worker and the machine is doubled, what is the new sound level at the worker's position?",
        choices: [
            "A. 210 dB", "B. 26 dB", "C. 52 dB", "D. 99 dB"],
        answer: "D",
        explain: "Use Equation 3 in Noise: L\u2082 = L\u2081 + 20 log (d\u2081/d\u2082). L\u2082 = 105 + 20 log (1/2) = 105 - 6.02 = 98.98 dB. \n\n A rule of thumb to remember is doubling the distance will reduce the sound level by 6 dB."
    },
    {
        number: 11,
        description: "A worker is exposed to a noise level of 60 dB for 4 hours, and to a noise level of 70 dB for the remaining 8 hours. What is the equivalent sound level (L_eq) for this exposure?",
        choices: [
            "A. 67 dB",
            "B. 70 dB",
            "C. 68 dB",
            "D. 88 dB"
        ],
        answer: "C",
        explain: "Use the 6th Equation in Sound. The formula for calculating L_eq when exposed to different noise levels and durations is: \n\nL_eq = 10 log (1/T[t1 * 10^(L1/10) + t2 * 10^(L2/10)]) where T is the total time of exposure, which is 4+8 = 12 hours. \n\nTherefore, L_eq = 10 log (1/12) * [4 * 10^(60/10) + 8 * 10^(70/10)] = 68.45 dB."
    },
    {
        number: 12,
        description: "A machine produces a sound level of 110 dB at a distance of 2 meters. What will be the sound level if the distance is increased to 8 meters?",
        choices: [
            "A. 98 dB",
            "B. 100 dB",
            "C. 104 dB",
            "D. 114 dB"
        ],
        answer: "A",
        explain: "Use Equation 3 in Noise: L2 = L1 + 20 log (d1/d2). L2 = 110 + 20 log (2/8) = 110 - 12.04 = 97.96 dB. \n\nAlternatively, you can use the rule of thumb twice (reducing 6 dB when doubling the distance). "
    },
    {
        number: 13,
        description: "What is the amount of pressure, in pascals, needed to generate a sound pressure level of 120 dB? Assuming the reference (ambient) sound pressure is 20 μPa.",
        choices: [
            "A. 20 Pa",
            "B. 61.2 Pa",
            "C. 44.2 Pa",
            "D. 54.7 Pa"
        ],
        answer: "A",
        explain: "Use Equation 1 in Noise. SPL or Lp = sound pressure level in dB. P\u2080 = reference sound pressure = 20 μPa. Therefore, 120 = 20 log(p/20*10\u207B\u2076). p = 20 Pa."
    },
    {
        number: 14,
        description: "When the sound intensity is 15 W/m\u00B2, what is the corresponding value for sound intensity level? Assuming the reference (ambient) sound intensity is 10⁻¹² W/m\u00B2?",
        choices: [
            "A. 122 dB",
            "B. 132 dB",
            "C. 142 dB",
            "D. 92 dB"
        ],
        answer: "B",
        explain: "Use Equation 2 in Noise. Li = sound intensity level in dB. I = sound intensity, in watts per square meters. I\u2080 = reference sound intensity. Therefore, Li = 10 log(15/10⁻¹²) = 132 dB. \n\nSound intensity level refers to the amount of energy carried by a sound wave per unit of area."
    },
    {
        number: 15,
        description: "At a distance of 8 feet from a noise source, a measurement of 100 dB was obtained. What would be the decibel level measured at a distance of 40 feet from the same noise source?",
        choices: [
            "A. 76 dB",
            "B. 86 dB",
            "C. 66 dB",
            "D. 56 dB"
        ],
        answer: "B",
        explain: "Use Equation 3 in Noise. SPL\u2082 = 100 + 20*log(8/40) = 86 dB."
    },
    {
        number: 16,
        description: "Could you provide an estimation of the sound power level produced by a siren with a power output of 20 watts? Assuming the reference (ambient) sound power is 10⁻¹² watts?",
        choices: [
            "A. 93 dB",
            "B. 113 dB",
            "C. 123 dB",
            "D. 133 dB"
        ],
        answer: "D",
        explain: "Use Equation 4 in Noise. Lw = sound power level in dB. W = sound power of the source, in watts. W\u2080 = reference sound power. Therefore, Lw = 10 log(20/10⁻¹²) = 133 dB.\n\nSound power level refers to the total acoustic power radiated by a sound source. It quantifies the amount of energy emitted by the source per unit of time. Sound power level is expressed in decibels (dB) and is based on a logarithmic scale. Unlike sound pressure level (SPL), which measures the intensity of sound at a specific location, sound power level represents the inherent characteristics of the sound source itself. It indicates how much acoustic power the source emits, regardless of the distance or environment in which it is measured."
    },
    {
        number: 17,
        description: "What is the cumulative sound pressure level of the following readings: 70 dB, 75 dB, and 80 dB?",
        choices: [
            "A. 80.5 dB",
            "B. 81.5 dB",
            "C. 83.5 dB",
            "D. 71.5 dB"
        ],
        answer: "B",
        explain: "Use Equation 7 in Noise. Lpt = total sound pressure level = 10*log(10^(70/10) + 10^(75/10) + 10^(80/10)) = 81.5 dB."
    },
    {
        number: 18,
        description: "What is the amount of transmission loss exhibited by a wall with a sound transmission coefficient of 0.00002?",
        choices: [
            "A. 57 dB",
            "B. 27 dB",
            "C. 37 dB",
            "D. 47 dB"
        ],
        answer: "D",
        explain: "Use Equation 8 in Noise. TL = transmission loss = 10 * log(1/0.00002) = 47 dB. \n\nSound transmission loss refers to the reduction in sound energy or sound pressure level that occurs when sound passes through a barrier, such as a wall, partition, or material. It quantifies the effectiveness of the barrier in blocking or attenuating sound transmission from one side to the other. For this question, the result means the sound level will decrease by 47 dB after passing through the wall."
    },
    {
        number: 19,
        description: "In a free field, with a sound power level of 90 dB and both the directivity index (DI) and the temperature and pressure correction factor (CF) being zero, what is the sound pressure level at a distance of 10 feet from the source?",
        choices: [
            "A. 69.5 dB",
            "B. 60.5 dB",
            "C. 64.5 dB",
            "D. 59.5 dB"
        ],
        answer: "A",
        explain: "Use Equation 9 in Noise. Pay attention that  r is distance from the source in feet. Therefore Lp = 90 - 20log10 - 0.5 = 69.5 dB."
    },
    {
        number: 20,
        description: "In a free field, with a sound power level of 90 dB and both the directivity index (DI) and the temperature and pressure correction factor (CF) being zero, what is the sound pressure level at a distance of 3 meters from the source?",
        choices: [
            "A. 90 dB",
            "B. 39 dB",
            "C. 59 dB",
            "D. 69 dB"
        ],
        answer: "D",
        explain: "Use Equation 10 in Noise. Pay attention that  r is distance from the source in meters. Therefore Lp = 90 - 20log3 - 11 = 69 dB."
    },
    {
        number: 21,
        description: "What is the directivity index of a sound system when it is mounted on a wall (Q = 2)?",
        choices: [
            "A. 1 dB",
            "B. 6 dB",
            "C. 2 dB",
            "D. 3 dB"
        ],
        answer: "D",
        explain: "Use Equation 11 in Noise. DI = 10*log(2)=3 dB. The directivity factor (Q) is a measure of how directionally focused or dispersed the sound energy is from a source. It quantifies the directivity pattern or radiation pattern of the source. A higher Q value indicates a more focused or directional sound source, while a lower Q value indicates a more diffuse or omnidirectional source. \n\nA directivity index of 6 dB means that a sound source is 6 dB louder in one direction compared to spreading the sound equally in all directions."
    },
    {
        number: 22,
        description: "What is the does of noise exposure when the worker is exposed to a noise source of 85 dB for 2.5 hours, and another noise source of 90 dB for 2 hours, and a third noise source of 95 dB for 3.5 hours? (based on OSHA standard)",
        choices: [
            "A. 108 %",
            "B. 128 %",
            "C. 118 %",
            "D. 138 %"
        ],
        answer: "B",
        explain: "First calculate the allowable exposure time for each noise source. The OSHA 8-hour Permissible Exposure Level is 90 dB and the exchange rate is 5 dB. Therefore, the allowable time of exposure to 85 dB is 16 hours, and the allowable time to 95 dB is 4 hours. Use Equation 12 in Noise. %D = (2.5/16 + 2/8 + 3.5/4) = 128 % (over exposure)."
    },
    {
        number: 23,
        description: "According to OSHA, what is the maximum permissible duration of exposure for a worker exposed to a sound level of 97 dB for an 8-hour shift?",
        choices: [
            "A. 1 hours",
            "B. 6 hours",
            "C. 3  hours",
            "D. 9 hours"
        ],
        answer: "C",
        explain: "Use Equation 13 in Noise. Tp = permitted exposure time in hr. Tc = total exposure time, usually 8 hours. ER= exchange rate (5 for OSHA and 3 for ACIGH). Lc = permissible exposure level (OSHA 90 dB, ACGIH 85 dB). LAS = actual sound (A-weighted and slow response). Therefore, Tp = 8 / 2^((97-90)/5) = 3 hours."
    },
    {
        number: 24,
        description: "What is the ACGIH (American Conference of Governmental Industrial Hygienists) equivalent time-weighted average for a noise dose percentage of 80%?",
        choices: [
            "A. 68.0 dB",
            "B. 84.9 dB",
            "C. 84.0 dB",
            "D. 85.2 dB"
        ],
        answer: "C",
        explain: "Use Equation 14 in Noise. This is the equation for ACGIH values. TWAeq = 10 log(80%) + 85 = 84.0 dB."
    },
    {
        number: 25,
        description: "What is the OSHA equivalent time-weighted average for a noise dose percentage of 80%?",
        choices: [
            "A. 82.9 dB",
            "B. 84.5 dB",
            "C. 88.4 dB",
            "D. 84.2 dB"
        ],
        answer: "C",
        explain: "Use Equation 15 in Noise. This is the equation for OSHA values. TWAeq = 16.61 log(80%) + 90 = 88.4 dB."
    },
    {
        number: 26,
        description: "What is the rotational frequency of a fan with 30 blades that spins at 1200 RPM?",
        choices: [
            "A. 800 Hz",
            "B. 1000 Hz",
            "C. 900 Hz",
            "D. 600 Hz"
        ],
        answer: "D",
        explain: "Use Equation 16 in Noise. f = frequency. N = number of fan blades. RPM = speed of fan, rounds per minute. Frequency in Hertz (Hz) is how many cycles or rotations per second.  If we have 1200 rounds per minute, we have 1200/60=20 rounds per second. And we have 30 blades. So f = 30*20 = 600 Hz."
    },
    {
        number: 27,
        description: "What is the frequency of a sound wave in air when the speed is 250 m/s and the wavelength is 0.5 meters?",
        choices: [
            "A. 400 Hz",
            "B. 600 Hz",
            "C. 250 Hz",
            "D. 500 Hz"
        ],
        answer: "D",
        explain: "Use Equation 17 in Noise. C = speed of sound in meters/sec. λ = wavelength. Therefore, f = 250/0.5 = 500 Hz."
    },
    {
        number: 28,
        description: "What is the upper frequency of an full octave band if the lower frequency is 88 Hz?",
        choices: [
            "A. 88 Hz",
            "B. 176 Hz",
            "C. 44 Hz",
            "D. 167 Hz"
        ],
        answer: "B",
        explain: "Use Equation 18 in Noise. If the lower frequency is 88, the upper frequency of a full octave band can be calculated by doubling the lower frequency. Therefore, the upper frequency would be 176 Hz.\n\nIf we consider the frequency range of an octave, the lower frequency is the starting point or the lowest pitch of that octave, and the upper frequency is the ending point or the highest pitch of that octave."
    },
    {
        number: 29,
        description: "What is the center frequency of a full octave band if the lower frequency is 88 Hz?",
        choices: [
            "A. 124 Hz",
            "B. 88 Hz",
            "C. 176 Hz",
            "D. 131 Hz"
        ],
        answer: "A",
        explain: "First, use Equation 18 in Noise to calculate upper frequency = 176 Hz. Then use Equation 19 in Noise. fc = sqrt(88*176) = 124 Hz."
    },
    {
        number: 30,
        description: "For one-third-octave band (standardized for scientific instruments), what is the upper frequency if the lower frequency is 600 Hz?",
        choices: [
            "A. 600 Hz",
            "B. 700 Hz",
            "C. 756 Hz",
            "D. 656 Hz"
        ],
        answer: "C",
        explain: "Use Equation 19 in Noise. f₂=2^⅓*600 = 756 Hz."
    },
    {
        number: 31,
        description: "Which of the following statements is true regarding the transmission of sound in a vacuum?",
        choices: [
            "A. Sound can propagate in a vacuum with reduced speed.",
            "B. Sound waves in a vacuum travel at the same speed as in air.",
            "C. Sound cannot be transmitted in a vacuum.",
            "D. Sound travels faster in a vacuum compared to other mediums."
        ],
        answer: "C",
        explain: "In a vacuum, sound cannot be transmitted because sound requires a medium, such as air, water, or solid materials, to propagate. Sound waves are created by the vibration of particles in a medium, which then transfer the vibrations to adjacent particles, allowing the sound to travel. In a vacuum, there are no particles or molecules present to vibrate and carry the sound waves."
    },
    {
        number: 32,
        description: "Which of the following statements is true regarding the energy of sound?",
        choices: [
            "A. The energy of sound depends solely on its frequency.",
            "B. Sound energy is related to its amplitude and frequency.",
            "C. Sound energy is directly proportional to its wavelength.",
            "D. The energy of sound is not affected by the medium through which it propagates."
        ],
        answer: "B",
        explain: "The energy of sound is a combination of its amplitude and frequency. Amplitude is the loudness of the sound, while frequency is the pitch of the sound. The greater the amplitude and frequency of a sound, the more energy it has.\n\nStatement A is incorrect because the energy of sound also depends on its amplitude. Statement C is incorrect because the energy of sound is inversely proportional to its wavelength, not directly proportional. Statement D is incorrect because the energy of sound is affected by the medium through which it propagates."
    },
    {
        number: 33,
        description: "What role do hair cells in the cochlea play in the process of hearing?",
        choices: [
            "A. They amplify sound waves before they reach the eardrum.",
            "B. They convert sound vibrations into electrical signals sent to the brain.",
            "C. They regulate the pressure within the inner ear.",
            "D. They protect the ear from loud noises by dampening sound waves."
        ],
        answer: "B",
        explain: "Hair cells in the cochlea are crucial for the process of hearing as they convert sound vibrations into electrical signals. These signals are then transmitted to the brain via the auditory nerve, allowing us to perceive and interpret sounds. When sound waves enter the ear, they cause the fluid in the cochlea to move, which in turn bends the hair cells. This bending action creates electrical impulses that are sent to the brain. Without functioning hair cells, the conversion of sound vibrations into signals that the brain can understand would not occur, leading to hearing loss."
    }
    ,
    {
        number: 34,
        description: "Which of the following statements accurately describes tinnitus?",
        choices: [
            "A. Tinnitus is a medical condition characterized by a constant ringing sound in the ears.",
            "B. Tinnitus is caused exclusively by exposure to high-frequency noise.",
            "C. Tinnitus is a temporary condition that resolves on its own without treatment.",
            "D. Tinnitus is primarily a psychological disorder unrelated to noise exposure."
        ],
        answer: "A",
        explain: "Tinnitus is a medical condition characterized by the perception of sound in the absence of an external source. It is commonly described as a ringing, buzzing, humming, whistling, or hissing sound in the ears or head. Tinnitus can vary in intensity and may be continuous or intermittent. It is not a disease itself but rather a symptom of an underlying condition.\n\nTinnitus can have various causes, including prolonged exposure to loud noise, age-related hearing loss, earwax buildup, certain medications, ear infections, cardiovascular disorders, and neurological conditions. It can also be a result of damage to the auditory system or the brain's auditory pathways."
    },
    {
        number: 35,
        description: "Which of the following chemicals is most associated with hearing loss?",
        choices: [
            "A. Carbon dioxide",
            "B. Aromatic solvents",
            "C. Chlorinated solvents",
            "D. Asbestos"
        ],
        answer: "B",
        explain: "Aromatic solvents are most associated with hearing loss. These chemicals can damage the inner ear and affect hearing, particularly when there is also noise exposure. Aromatic solvents, such as toluene, xylene, and benzene, are used in various industrial applications and can be hazardous to auditory health with prolonged or high-level exposure."
    }
    ,
    {
        number: 36,
        description: "Presbycusis refers to:",
        choices: [
            "A. Age-related hearing loss.",
            "B. Noise-induced hearing loss",
            "C. Tinnitus.",
            "D. Occupational hearing loss."
        ],
        answer: "A",
        explain: "Presbycusis specifically refers to the gradual, age-related hearing loss that occurs as a natural part of the aging process. It is a common condition that affects many individuals as they get older. Presbycusis typically involves the gradual loss of sensitivity to high-frequency sounds and can also affect the ability to understand speech clearly, especially in noisy environments. This type of hearing loss is typically bilateral and affects both ears."
    },
    {
        number: 37,
        description: "When using double hearing protection (earplugs and earmuffs), why don't the Noise Reduction Ratings (NRR) simply add up?",
        choices: [
            "A. The combined NRR is limited by the higher rating of the two.",
            "B. Sound can still enter through the bones of the skull.",
            "C. The two forms of protection can interfere with each other, reducing effectiveness.",
            "D. Double hearing protection only provides marginal additional protection."
        ],
        answer: "B",
        explain: "When using double hearing protection, such as earplugs and earmuffs, the Noise Reduction Ratings (NRR) do not simply add up because sound can still enter through the bones of the skull. While using both earplugs and earmuffs together can provide better protection than either alone, the combined reduction is less than the sum of their individual NRRs due to the bone conduction of sound. This results in a total noise reduction that is generally around 5-10 dB greater than the higher of the two individual NRRs."
    },
    {
        number: 38,
        description: "In noise monitoring, what is the difference between the criteria level and the threshold level?",
        choices: [
            "A. The criteria level is the maximum allowable noise level, while the threshold level is the level at which noise monitoring data will begin to be analyzed.",
            "B. The criteria level is the level above which hearing protection is mandatory, while the threshold level is the level at which noise-induced hearing loss begins.",
            "C. The criteria level is used to determine compliance with noise regulations, while the threshold level is the level at which noise exposure is considered hazardous.",
            "D. The criteria level is the baseline noise level for comparison, while the threshold level is the lowest noise level detected by monitoring equipment."
        ],
        answer: "A",
        explain: "In noise monitoring, the criteria level is the maximum allowable noise level, often set by regulatory bodies to ensure a safe working environment. The threshold level, on the other hand, is the level at which noise monitoring begins, meaning any noise at or above this level will be recorded and analyzed. These levels help in assessing and managing noise exposure to prevent hearing loss and other health issues."
    },
    {
        number: 39,
        description: "Which of the following statements accurately describes the outer ear?",
        choices: [
            "A. The outer ear consists of the cochlea, which is responsible for converting sound vibrations into electrical signals.",
            "B. The outer ear is composed of the pinna (auricle) and the external auditory canal.",
            "C. The outer ear houses the ossicles, a chain of three small bones that transmit sound vibrations to the inner ear.",
            "D. The outer ear contains the vestibule, a structure responsible for maintaining balance and spatial orientation."
        ],
        answer: "B",
        explain: "The outer ear, also known as the external ear, includes the pinna (auricle) and the external auditory canal. The pinna is the visible part of the ear on the outside of the head and helps to collect sound waves and direct them into the ear canal. The external auditory canal is a tube-like structure that leads from the pinna to the middle ear. It plays a role in channeling sound waves toward the middle ear."
    },
    {
        number: 40,
        description: "Which of the following statements accurately describes the middle ear?",
        choices: [
            "A. The middle ear consists of the cochlea, which is responsible for converting sound vibrations into electrical signals.",
            "B. The middle ear is composed of the pinna (auricle) and the external auditory canal.",
            "C. The middle ear houses the ossicles, a chain of three small bones that transmit sound vibrations to the inner ear.",
            "D. The middle ear contains the vestibule, a structure responsible for maintaining balance and spatial orientation."
        ],
        answer: "C",
        explain: "The middle ear is a small, air-filled space located behind the eardrum (tympanic membrane). It contains three tiny bones called the ossicles, namely the malleus (hammer), incus (anvil), and stapes (stirrup). These ossicles form a chain that amplifies and transmits sound vibrations from the eardrum to the inner ear. The middle ear plays a crucial role in the mechanical conduction of sound."
    },
    {
        number: 41,
        description: "Which of the following statements accurately describes the inner ear?",
        choices: [
            "A. The inner ear consists of the cochlea, which is responsible for converting sound vibrations into electrical signals.",
            "B. The inner ear is composed of the pinna (auricle) and the external auditory canal.",
            "C. The inner ear houses the ossicles, a chain of three small bones that transmit sound vibrations to the inner ear.",
            "D. The inner ear contains the vestibule, a structure responsible for maintaining balance and spatial orientation."
        ],
        answer: "A",
        explain: "The inner ear is a complex structure that plays a vital role in both hearing and balance. It consists of several components, including the cochlea, vestibule, and semicircular canals. Among these structures, the cochlea is responsible for converting sound vibrations into electrical signals that can be interpreted by the brain. The cochlea contains specialized sensory cells called hair cells, which respond to different frequencies of sound and transmit electrical signals to the auditory nerve."
    },
    {
        number: 42,
        description: "What are the three bones located in the middle ear called?",
        choices: [
            "A. Tympanum, stapes, cochlea",
            "B. Incus, auricle, malleus",
            "C. Ossicles, vestibule, eustachian tube",
            "D. Malleus, incus, stapes"
        ],
        answer: "D",
        explain: "The three bones located in the middle ear are known as the malleus, incus, and stapes (MIS) or hammer, anvil, and stirrup(HAS), These bones are also referred to as the ossicles. They form a chain that connects the eardrum (tympanic membrane) to the inner ear."
    },
    {
        number: 43,
        description: "What is the primary function of the Eustachian tube?",
        choices: [
            "A. To transmit sound vibrations from the middle ear to the inner ear.",
            "B. To maintain balance and spatial orientation.",
            "C. To equalize air pressure between the middle ear and the environment.",
            "D. To protect the inner ear from loud noises."
        ],
        answer: "C",
        explain: "The Eustachian tube, also known as the auditory tube, connects the middle ear to the back of the throat. Its primary function is to equalize air pressure on both sides of the eardrum. It achieves this by allowing air to flow in and out of the middle ear, helping to maintain proper pressure balance. This is important because changes in air pressure can cause discomfort and affect hearing. \n\nThe Eustachian tube opens briefly during activities such as swallowing, yawning, or chewing to equalize pressure. If the Eustachian tube becomes blocked or fails to function properly, it can lead to conditions like Eustachian tube dysfunction or middle ear infections."
    },
    {
        number: 44,
        description: "In noise monitoring, what does the exchange rate represent?",
        choices: [
            "A. The rate at which noise levels decrease with distance.",
            "B. The increase in sound level that results in a halving of permissible exposure time.",
            "C. The ratio of peak noise levels to average noise levels over a given period.",
            "D. The rate at which hearing loss occurs due to continuous noise exposure."
        ],
        answer: "B",
        explain: "In noise monitoring, the exchange rate (also known as the doubling rate) represents the increase in sound level that results in a halving of the permissible exposure time. For example, with a 3 dB exchange rate, every increase of 3 dB in noise level halves the allowable exposure time."
    }
    ,
    {
        number: 45,
        description: "In noise monitoring, what is the difference between A-weighting, C-weighting, and Z-weighting?",
        choices: [
            "A. A-weighting is used for high-frequency noise, C-weighting for low-frequency noise, and Z-weighting for the entire frequency range.",
            "B. A-weighting approximates human hearing sensitivity, C-weighting measures peak noise levels, and Z-weighting provides a flat frequency response.",
            "C. A-weighting is used for environmental noise measurements, C-weighting for occupational noise exposure, and Z-weighting for calibration purposes.",
            "D. A-weighting measures continuous noise, C-weighting measures impulse noise, and Z-weighting measures background noise."
        ],
        answer: "B",
        explain: "In noise monitoring, A-weighting, C-weighting, and Z-weighting are different frequency weighting scales used to measure sound levels. A-weighting approximates the human ear's sensitivity to different frequencies, with greater emphasis on mid-range frequencies where the ear is most sensitive. This makes A-weighting suitable for assessing potential hearing damage as it reflects how humans perceive loudness. C-weighting is used to measure peak noise levels and captures both high and low frequencies more accurately, making it suitable for evaluating the impact of loud, sudden noises. Z-weighting provides a flat frequency response, meaning it does not adjust for the sensitivity of human hearing and measures all frequencies equally. This makes Z-weighting useful for calibration and technical measurements."
    }
    ,
    {
        number: 46,
        description: "Which of the following statements correctly describes the three classes of noise?",
        choices: [
            "A. Impact noise refers to a continuous and steady sound, intermittent noise consists of sudden and brief bursts of high-intensity sound, and continuous noise occurs periodically with periods of silence in between.",
            "B. Impact noise consists of sudden and brief bursts of high-intensity sound, intermittent noise occurs periodically with periods of silence in between, and continuous noise refers to a continuous and steady sound.",
            "C. Impact noise occurs periodically with periods of silence in between, intermittent noise refers to a continuous and steady sound, and continuous noise consists of sudden and brief bursts of high-intensity sound.",
            "D. Impact noise refers to a continuous and steady sound, intermittent noise occurs periodically with periods of silence in between, and continuous noise consists of sudden and brief bursts of high-intensity sound."
        ],
        answer: "B",
        explain: "Impact noise refers to sudden, brief bursts of high-intensity sound resulting from direct contact between objects. Examples include a hammer hitting a surface, a door slamming, or an object falling. It has a sharp, distinct waveform.\n\nIntermittent noise occurs periodically with silent intervals in between. It follows a recurring pattern with varying durations and sound levels. Examples include machinery operating in cycles or passing vehicles. It is characterized by an on-and-off pattern. \n\nContinuous noise is a steady, uninterrupted sound that persists without significant changes. It includes constant machinery operation, HVAC systems, or urban traffic noise. Examples are the hum of an air conditioner or office background noise. It has a relatively constant waveform."
    },
    {
        number: 47,
        description: "What is the difference between sound power level (SWL) and sound pressure level (SPL) in noise measurements?",
        choices: [
            "A. SWL measures the total acoustic energy radiated by a source, while SPL measures the intensity of sound at a specific location.",
            "B. SWL measures the intensity of sound at a specific location, while SPL measures the total acoustic energy radiated by a source.",
            "C. SWL measures low-frequency noise, while SPL measures high-frequency noise.",
            "D. SWL measures the average noise level over time, while SPL measures peak noise levels."
        ],
        answer: "A",
        explain: "Sound power level (SWL) and sound pressure level (SPL) serve different purposes. SWL measures the total acoustic energy radiated by a noise source and is independent of distance from the source. It quantifies how much sound energy the source emits. On the other hand, SPL measures the intensity of sound at a specific location and depends on the distance from the source. SPL indicates how loud the sound is perceived by a listener at a given location. The unit for both SWL and SPL is dB."
    },
    {
        number: 48,
        description: "Which of the following materials is most effective at absorbing sound?",
        choices: [
            "A. Concrete",
            "B. Metal",
            "C. Carpet",
            "D. Glass"
        ],
        answer: "C",
        explain: "Carpet is most effective at absorbing sound among the options provided. Materials like carpet, with its soft and fibrous texture, can absorb sound waves by reducing their reflection off hard surfaces. This property makes carpeting useful for reducing echo and noise in indoor environments. In contrast, materials like concrete and glass tend to reflect sound rather than absorb it, contributing to higher levels of reverberation and noise buildup."
    },
    {
        number: 49,
        description: "What is an audiogram used to measure?",
        choices: [
            "A. Blood pressure levels",
            "B. Lung capacity",
            "C. Hearing sensitivity",
            "D. Vision acuity"
        ],
        answer: "C",
        explain: "An audiogram is a graph used to measure hearing sensitivity. It represents the softest sounds a person can hear at different frequencies, typically ranging from low to high frequencies. This helps audiologists diagnose hearing loss and determine its type and severity."
    },
    {
        number: 50,
        description: "Which of the following heavy metals is associated with potential ototoxic effects?",
        choices: [
            "A. Iron",
            "B. Copper",
            "C. Lead",
            "D. Zinc"
        ],
        answer: "C",
        explain: "Lead is associated with potential ototoxic effects among the options provided. Chronic exposure to lead can affect the central nervous system, including the auditory system, leading to hearing loss or impairment. Additionally, heavy metals like arsenic, mercury, and manganese have also been linked to neurological and auditory damage with prolonged exposure. Arsenic is known to cause various health issues including hearing loss, while mercury in its methylmercury form and manganese can contribute to neurological disorders. In contrast, iron (choice A), copper (choice B), and zinc (choice D) are essential metals needed in appropriate amounts for various physiological functions and are not typically associated with ototoxic effects."
    },
    {
        number: 51,
        description: "Which of the following statements accurately describes temporary threshold shift (TTS)?",
        choices: [
            "A. TTS refers to a permanent hearing loss that occurs after a single exposure to loud noise.",
            "B. TTS is a temporary decrease in hearing sensitivity that occurs immediately after exposure to loud noise and typically recovers within hours to a few days.",
            "C. TTS is a gradual deterioration of hearing sensitivity that occurs over an extended period due to chronic noise exposure.",
            "D. TTS is a condition characterized by ringing in the ears, known as tinnitus, caused by exposure to loud noise."
        ],
        answer: "B",
        explain: "Temporary threshold shift (TTS) refers to a temporary reduction in hearing sensitivity that occurs immediately after exposure to loud noise. TTS is a reversible condition, and the hearing thresholds usually recover within hours to a few days after the exposure ends.\n\nDuring exposure to intense or prolonged noise, the sensory cells in the inner ear become fatigued, resulting in a temporary decrease in hearing sensitivity. This decrease is typically observed across various frequencies and can affect both the high and low frequencies.\n\nTTS is a significant indicator of noise-induced hearing damage and serves as a warning sign for potential permanent hearing loss if exposure to loud noise continues without appropriate hearing protection."
    },
    {
        number: 52,
        description: "What is a Standard Threshold Shift (STS) according to OSHA regulations?",
        choices: [
            "A. A change in hearing threshold of 5 dB or more at 1000 Hz in one ear",
            "B. A change in hearing threshold of 10 dB or more at 2000, 3000, and 4000 Hz in one or both ears",
            "C. A change in hearing threshold of 15 dB or more at any frequency in both ears",
            "D. A change in hearing threshold of 20 dB or more at 500 Hz in one ear"
        ],
        answer: "B",
        explain: "According to OSHA regulations, a Standard Threshold Shift (STS) is defined as a change in hearing threshold, relative to the baseline audiogram for that employee, of an average of 10 decibels (dB) or more at 2000, 3000, and 4000 hertz (Hz) in one or both ears. This measurement is used to determine if an employee's hearing has deteriorated significantly due to occupational noise exposure."
    }
    ,
    {
        number: 53,
        description: "What is the directivity index of a sound system?",
        choices: [
            "A. A measure of its maximum sound output",
            "B. A measure of its ability to control sound dispersion",
            "C. A measure of its frequency response",
            "D. A measure of its signal-to-noise ratio"
        ],
        answer: "B",
        explain: "The directivity index of a sound system quantifies how well it can focus sound energy in a specific direction and reduce sound radiation in unwanted directions. It is not related to the maximum sound output, frequency response, or signal-to-noise ratio of the system.\n\nA higher directivity index indicates better control and focus of sound energy in the desired direction, while a lower directivity index signifies a broader dispersion pattern with less focused sound. Sound systems with high directivity index values are commonly used in applications such as public address systems, concert venues, theaters, and auditoriums, where precise control over sound directionality is crucial to achieving optimal sound coverage and clarity."
    },
    {
        number: 54,
        description: "What is the primary advantage of using octave or third-octave band analysis in sound level meters?",
        choices: [
            "A. It provides a more accurate measurement of sound pressure levels.",
            "B. It allows for precise identification of specific sound frequencies.",
            "C. It offers detailed information about the sound energy distribution across different frequency ranges.",
            "D. It enables better control of sound dispersion in different directions."
        ],
        answer: "C",
        explain: "Octave or third-octave band analysis divides the audible spectrum into smaller bands, which allows for detailed information about the sound energy distribution across different frequency ranges. This information can be used to identify the source of noise, assess the impact of noise on human hearing, and develop strategies for noise control."
    },
    {
        number: 55,
        description: "How does a dosimeter calculate noise exposure levels? ",
        choices: [
            "A. By integrating and averaging sound pressure levels over time ",
            "B. By analyzing sound waveforms",
            "C. By measuring peak sound pressure levels",
            "D. By determining the direction of sound sources"
        ],
        answer: "A",
        explain: "A dosimeter calculates noise exposure levels by continuously measuring and recording the sound pressure levels over a specific period of time. It integrates the sound pressure levels, taking into account the duration and intensity of the measured sound, to calculate the overall noise exposure level experienced by an individual during that time. By integrating and averaging the sound pressure levels, the dosimeter provides an accurate representation of the individual's cumulative noise exposure."
    },
    {
        number: 56,
        description: "Which of the following best describes the recommended degree to which noise should be attenuated in a workplace setting?",
        choices: [
            "A. It should be attenuated to the lowest dB level achievable",
            "B. It should be attenuated to the Occupational Exposure Limit (OEL) level",
            "C. It should be attenuated to a level commensurate with background noise",
            "D. It should be attenuated to at least below Occupational Exposure Limit (OEL) level , yet still allow for normal speech hearing"
        ],
        answer: "D",
        explain: "The recommended approach for noise attenuation in a workplace is to attenuate the noise to at least bewlow Occupational Exposure Limit (OEL) level but still allows for normal speech hearing. This ensures that workers can communicate effectively while wearing hearing protection. If hearing protection attenuates noise excessively and hinders communication, workers may resist wearing it, which compromises their safety."
    }
    ,
    {
        number: 57,
        description: "Which of the following examples represents an engineering control measure for noise reduction?",
        choices: [
            "A. Balancing equipment",
            "B. Noise-canceling headphones for workers",
            "C. Mandatory annual hearing tests",
            "D. Posting warning signs about noise hazards"
        ],
        answer: "A",
        explain: "Engineering control measures for noise reduction focus on modifying or eliminating noise sources at their origin. Examples include balancing equipment, which reduces vibrations that can cause noise, and other measures like vibration damping, absorption materials, barriers, shields, and isolation booths. These methods aim to reduce noise levels before they reach workers, thereby minimizing exposure and protecting hearing health."
    }
    ,
    {
        number: 58,
        description: "What is the frequency response characteristic of white noise?",
        choices: [
            "A. Flat frequency response",
            "B. Increasing frequency response",
            "C. Decreasing frequency response",
            "D. Varying frequency response"
        ],
        answer: "A",
        explain: "White noise has a flat frequency response, which means that the power spectral density is constant across all frequencies. This means that white noise contains equal power at all frequencies.\n\nWhite noise finds applications in sleep therapy and relaxation, sound masking for privacy and concentration in offices, audio testing and calibration, tinnitus relief by masking the perceived ringing sounds, sound therapy for relaxation and stress reduction, and enhancing focus and concentration during studying or work. Its ability to provide a consistent and neutral background sound makes it versatile in various contexts."
    },
    {
        number: 59,
        description: "Which frequency weighting is commonly used when a neutral and unaltered representation of the overall sound energy is desired?",
        choices: [
            "A. C-weighting",
            "B. Z-weighting",
            "C. A-weighting",
            "D. B-weighting"
        ],
        answer: "B",
        explain: "The Z-weighting, sometimes referred to as linear, represents an unweighted or flat response across the audible frequency spectrum. Unlike A and C-weighting, Z-weighting does not apply any frequency-specific adjustments. Instead, it provides a measurement that reflects the total sound energy without emphasizing or de-emphasizing specific frequency ranges. Z-weighted measurements are useful when a neutral and unaltered representation of the overall sound energy is desired."
    },
    {
        number: 60,
        description: "Which of the following components are typically included in a comprehensive hearing conservation program?",
        choices: [
            "A. Annual noise level measurements",
            "B. Quarterly safety inspections",
            "C. Bi-annual hearing tests",
            "D. Measuring noise levels, Hearing protection devices, annual hearing tests, and training"
        ],
        answer: "D",
        explain: "A comprehensive hearing conservation program typically includes measuring noise levles, providing hearing protection devices (HPDs) suitable for noise levels, conducting annual hearing tests to monitor employees' hearing health, and providing training on the proper use and care of HPDs."
    }


]