import React from 'react'
import celebrate from '../assets/Pass_CIH_Exam.png'

function Features() {
    return (
        <div className='key-feature'>
            <div className='our-features'>KEY FEATURES</div>
            <div><img src={celebrate} alt='CIH exam online practice' className='celebrate' /></div>
            <div className='features'>
                <div className='single-feature'>
                    <div className='feature-title'>800+ Carefully Designed Questions</div>
                    <div className='feature-describe'>Our questions are developed by CIHs and comply with the latest references, standards and regulations in 2024. The database inclues all rubrics for CIH exam.</div>
                </div>
                <div className='single-feature'>
                    <div className='feature-title'>Questions for Every Formula</div>
                    <div className='feature-describe'>Being able to solve problems by the equation sheet is essential to pass the exam. We provide at least one question for each formula.</div>
                </div>
                <div className='single-feature'>
                    <div className='feature-title'>Training Videos</div>
                    <div className='feature-describe'>Explore our high-definition training videos that comprehensively address key rubrics with the CIH exam.</div>
                </div>
                <div className='single-feature'><div className='feature-title'>Mark Your Knowledge Gap</div>
                    <div className='feature-describe'>Save the questions you have not mastered yet and practice again and again.</div></div>
                <div className='single-feature'><div className='feature-title'>Money Back Guarantee</div>
                    <div className='feature-describe'>
                        If you have successfully completed all the questions but did not pass the CIH exam, we offer a Money Back.</div></div>
            </div>
        </div>
    )
}
export default Features