export const work_environment_questions = [
    {
        number: 1,
        description: "In a confined space, what air should you continuously monitor?",
        choices: [
            "A. Hydrogen sulfide (H₂S)",
            "B. Oxygen",
            "C. Flammable vapors",
            "D. All of the above",
        ],
        answer: "D",
        explain: "Confined spaces often have limited ventilation, which can lead to the accumulation of toxic gases like H₂S. Hydrogen sulfide (H₂S) is known for its characteristic 'rotten egg' smell, which is detectable even at low concentrations. However, it's essential to note that individuals can become desensitized to the smell over time. Without proper monitoring, individuals entering confined spaces might not be aware of the presence of H₂S. \n\nIn a confined space, oxygen levels can decrease due to various reasons, including the consumption of oxygen by chemical reactions, displacement by other gases, or oxygen depletion caused by combustion or oxidation processes. Continuous monitoring of oxygen levels ensures that the space remains safe and breathable. \n\nConfined spaces can sometimes contain flammable substances or materials that can produce flammable vapors. Monitoring for flammable vapors is essential to detect any dangerous buildup that could potentially lead to explosions or fires. This is particularly important in environments where there is a risk of ignition sources, such as sparks or open flames.flammable vapors are often monitored using the Lower Explosive Limit (LEL) measurement in gas monitors. LEL refers to the minimum concentration of a flammable gas or vapor in the air below which combustion will not occur if an ignition source is present. It is usually expressed as a percentage of the gas in the air.\n\nIn addition, CO should be monitored as well. CO is a colorless, odorless gas that is produced by incomplete combustion of carbon-containing fuels such as wood, gasoline, natural gas, and other fossil fuels. CO is highly toxic to humans and animals. When inhaled, it binds to hemoglobin in the blood, reducing the blood's ability to carry oxygen. This can lead to symptoms such as headaches, dizziness, nausea, and, in high concentrations, it can be lethal."
    },
    {
        number: 2,
        description: "Which of the following is a common source of Volatile Organic Compounds (VOCs) in the workplace?",
        choices: [
            "A. Paints and solvents",
            "B. Office equipment such as printers and copiers",
            "C. Cleaning products and disinfectants",
            "D. All of the above"
        ],
        answer: "D",
        explain: "Volatile Organic Compounds (VOCs) are emitted as gases from a variety of sources commonly found in workplaces. Common sources include:\n\n- Paints and solvents: VOCs are often found in products used for painting and coating surfaces.\n- Office equipment such as printers and copiers: These devices can emit VOCs during operation.\n- Cleaning products and disinfectants: Many cleaning agents contain VOCs that can be released into the air.\n\nExposure to VOCs can lead to various health effects, making it important to control their presence in indoor environments."
    },
    {
        number: 3,
        description: "What are the health risks associated with poor ventilation during welding?",
        choices: [
            "A. Burns and eye injuries due to sparks and radiation.",
            "B. Lung damage and cancer due to exposure to welding fumes and gases.",
            "C. Hearing loss and tinnitus due to high levels of noise.",
            "D. Musculoskeletal disorders due to awkward postures and repetitive motions."
        ],
        answer: "B",
        explain: 'Welding produces fumes and gases that can be hazardous to the health of the welder and those working nearby. The fumes can contain metals, such as chromium, manganese, and nickel, as well as gases, such as carbon monoxide and ozone. Exposure to these fumes and gases can cause lung damage, respiratory irritation, and even cancer. Proper ventilation during welding is critical to remove these hazards from the breathing zone and protect workers from the associated health risks. \n\nWhile burns and eye injuries are also potential risks during welding, these are primarily addressed through the use of personal protective equipment, such as welding helmets and gloves, rather than through ventilation.'
    },
    {
        number: 4,
        description: "What is the definition of a confined space, according to OSHA?",
        choices: [
            "A. A space that has limited or restricted means of entry, is not designed for continuous occupancy, and is large enough and configured so that a person can enter the space and maneuver well enough to perform tasks.",
            "B. Any space that has a potential for hazardous atmosphere, engulfment, or entrapment.",
            "C. Any space that is designed for continuous worker occupancy and has adequate ventilation and lighting.",
            "D. Any space that is fully enclosed and has no means of entry or exit."
        ],
        answer: "A",
        explain: 'According to OSHA, a confined space is a space that is not intended for continuous human occupancy, has limited or restricted means of entry or exit, and is large enough for a worker to enter and perform work. Examples of confined spaces include tanks, vessels, silos, storage bins, hoppers, vaults, and pits. It is important for employers to identify and evaluate confined spaces at their worksites and take appropriate measures to protect workers who enter these spaces, such as by providing adequate ventilation, lighting, and personal protective equipment.'
    },
    {
        number: 5,
        description: "Which of the following is a common source of formaldehyde in the work environment?",
        choices: [
            "A. Pressed wood products",
            "B. Insulation materials",
            "C. Laboratory chemicals and preservatives",
            "D. All of the above"
        ],
        answer: "D",
        explain: "Formaldehyde is a common chemical found in various workplace environments. Common sources include:\n\n- Pressed wood products: Items such as particleboard, plywood, and fiberboard often contain formaldehyde-based resins.\n- Insulation materials: Certain insulation products can emit formaldehyde.\n- Laboratory chemicals and preservatives: Formaldehyde is used in laboratories for preservation and as a chemical reagent.\n\nDue to its potential health hazards, controlling exposure to formaldehyde in the workplace is crucial."
    },
    {
        number: 6,
        description: "Which of the following chemical exposures is most commonly associated with health risks in beauty salons?",
        choices: [
            "A. Formaldehyde from keratin treatments and nail products.",
            "B. Ammonia from hair dyes and bleaches.",
            "C. Toluene from nail polish and adhesives.",
            "D. All of the above."
        ],
        answer: "D",
        explain: "Beauty salon workers and clients can be exposed to various chemicals, each associated with specific health risks:\n\nFormaldehyde: Used in some keratin treatments and nail products, it is a known carcinogen and can cause respiratory issues and skin irritation.\nAmmonia: Commonly found in hair dyes and bleaches, it can cause respiratory irritation and damage mucous membranes.\nToluene: Present in nail polish and adhesives, it is associated with neurological effects and respiratory irritation.\n\nAll of these chemicals pose health risks, making it crucial to ensure proper ventilation and protective measures in beauty salons."
    },
    {
        number: 7,
        description: "Which of the following statements best describes the purpose of using intrinsically safe equipment in a confined space?",
        choices: [
            "A. To ensure that the equipment is easy to use and maintain",
            "B. To eliminate the need for a permit to enter the confined space",
            "C. To minimize the risk of ignition or explosion in potentially explosive atmospheres",
            "D. To prevent the buildup of hazardous gases in the confined space"
        ],
        answer: "C",
        explain: 'Intrinsically safe equipment is designed to operate safely in potentially explosive atmospheres by limiting electrical energy to non-incendive levels. This helps to reduce the risk of ignition or explosion in a confined space where flammable gases or vapors may be present.'
    },
    {
        number: 8,
        description: "What does IDLH stand for in industrial hygiene?",
        choices: [
            "A. Immediate Danger to Life and Health",
            "B. Integrated Dose Limit for Hazardous chemicals",
            "C. Inherently Dangerous Laboratory Hazards",
            "D. Industrial Development of Life and Health"
        ],
        answer: "A",
        explain: 'IDLH is a term used in industrial hygiene to describe a level of exposure to a hazardous substance that poses an immediate threat to life or would cause irreversible harm to health. The IDLH level is determined based on the toxic properties of the substance and is used to establish emergency response procedures and maximum allowable exposure limits in the workplace.\n\nThe term was coined by the National Institute for Occupational Safety and Health (NIOSH), which is a U.S. federal agency responsible for conducting research and making recommendations to prevent work-related injuries, illnesses, and deaths.'
    },
    {
        number: 9,
        description: "Which of the following statements about carbon monoxide (CO) is true?",
        choices: [
            "A. CO is a colorless and odorless gas that is not toxic to humans.",
            "B. CO can only be produced by industrial processes and is not commonly found in homes or offices.",
            "C. Exposure to high levels of CO can lead to symptoms such as headache, dizziness, nausea, and even death.",
            "D. CO is less dense than air and therefore tends to rise to the ceiling in indoor environments."
        ],
        answer: "C",
        explain: 'Carbon monoxide (CO) is a colorless, odorless, and tasteless gas that can be produced by incomplete combustion of fuels such as natural gas, propane, gasoline, oil, and wood. It is highly toxic to humans and animals, as it binds to hemoglobin in the blood more readily than oxygen, reducing the amount of oxygen that can be transported to the tissues of body.\n\nOne of the challenges with CO is that it is not easily detectable by human senses, as it has no color, taste, or odor. Therefore, it is important to have CO detectors installed in buildings where there is a potential for CO exposure, such as those with fuel - burning appliances or attached garages.\n\nCarbon monoxide (CO) is slightly less dense than air, but it does not significantly rise to the ceiling in indoor environments. Instead, CO tends to disperse evenly throughout the space because it mixes well with the air.'
    },
    {
        number: 10,
        description: "Which of the following correctly describes the potential health risks of breathing air with an oxygen concentration higher than the usual 21 percent found in Earth's atmosphere?",
        choices: [
            "A. It enhances physical performance and improves cognitive function.",
            "B. It can cause organ damage, seizures, and even death, especially if oxygen levels exceed the body's needs.",
            "C. It has no significant impact on health unless inhaled for prolonged periods.",
            "D. It is beneficial for all individuals, particularly those with respiratory conditions."
        ],
        answer: "B",
        explain: "Breathing air with higher oxygen levels than the typical 21% found in the atmosphere can pose serious health risks. Excess oxygen can lead to oxidative stress, resulting in organ damage, seizures, and potentially fatal outcomes, particularly when oxygen levels are significantly above what the body requires. This condition, known as oxygen toxicity, is especially dangerous in environments like hyperbaric oxygen therapy or when using high-concentration oxygen devices."
    },
    {
        number: 11,
        description: "At what point is the air considered to have an oxygen deficiency?",
        choices: [
            "A. less than 16.5%",
            "B. less than 19.5%",
            "C. less than 22.5%",
            "D. less than 25.5%"
        ],
        answer: "B",
        explain: "Oxygen deficiency refers to a situation in which the concentration of oxygen in the air is lower than the normal breathable level, which is typically around 19.5%. Oxygen is necessary for sustaining life, and when the available oxygen levels in the air drop below this threshold, it can pose serious health risks to individuals in that environment.\n\nOxygen deficiency can occur in various settings, including confined spaces, poorly ventilated areas, or environments where oxygen is displaced by other gases. Some common causes of oxygen deficiency include the presence of gases like nitrogen, carbon dioxide, or certain chemicals that displace oxygen or consume it through chemical reactions."
    },
    {
        number: 12,
        description: "Which of the following syndromes is associated with poor indoor air quality?",
        choices: [
            "A. Sudden Infant Death Syndrome (SIDS)",
            "B. Sudden Acute Respiratory Syndrome (SARS)",
            "C. Sick Building Syndrome (SBS)",
            "D. Acute Radiation Syndrome (ARS)"
        ],
        answer: "C",
        explain: 'Sick Building Syndrome (SBS) is a condition that is associated with poor indoor air quality and is characterized by a range of symptoms, including headache, fatigue, eye irritation, and respiratory problems. SBS is typically caused by a combination of factors, including poor ventilation, the presence of indoor air pollutants, and inadequate temperature and humidity control.'
    },
    {
        number: 13,
        description: "Which of the following statements about dew point is true?",
        choices: [
            "A. Dew point is the temperature at which water freezes.",
            "B. Dew point is the temperature at which water boils.",
            "C. Dew point is the temperature at which air becomes saturated with water vapor and condensation begins to form.",
            "D. Dew point is a measure of the amount of water vapor in the air."
        ],
        answer: "C",
        explain: 'Dew point is the temperature at which the water vapor in the air begins to condense. When the air reaches its dew point, it is said to be at 100% relative humidity. If the air continues to cool beyond the dew point, water vapor in the air will condense into visible water droplets, forming dew, frost, or other forms of condensation on surfaces like grass, cars, or windows.'
    },
    {
        number: 14,
        description: "Which of the following statements about humidity and mold growth is true?",
        choices: [
            "A. Mold growth is not affected by high humidity levels.",
            "B. Mold growth is only a concern in very humid environments, with relative humidity above 80%.",
            "C. Maintaining indoor humidity levels below 60% can help prevent mold growth.",
            "D. Mold growth is not a health concern and does not pose any risks to human health."
        ],
        answer: "C",
        explain: 'High humidity levels can create a favorable environment for mold growth, which can have adverse effects on indoor air quality and human health. To prevent mold growth, it is recommended to maintain indoor humidity levels below 60%. This can be achieved through proper ventilation, air conditioning, and dehumidification.'
    },
    {
        number: 15,
        description: "Which of the following is true regarding mold as an indoor air quality concern?",
        choices: [
            "A. Mold growth is not a concern in indoor environments as long as the air is well-ventilated.",
            "B. Mold growth can only occur in areas with visible water damage or leaks.",
            "C. Exposure to mold can cause allergic reactions, respiratory problems, and other health effects.",
            "D. All types of mold are toxic and pose a significant risk to human health."
        ],
        answer: "C",
        explain: 'Mold can grow in damp or humid indoor environments, even if there is no visible water damage or leaks. Exposure to mold can cause a range of health effects, from mild allergic reactions to more serious respiratory problems and infections, especially for individuals with pre-existing conditions such as asthma or allergies. It is important to identify and remediate mold growth in indoor environments to maintain good indoor air quality.'
    },
    {
        number: 16,
        description: "Which of the following hazards are associated with 'bright dipping' and 'pickling tanks'?",
        choices: [
            "A. Skin irritation",
            "B. Respiratory irritation",
            "C. Fire and explosion",
            "D. All of the above"
        ],
        answer: "D",
        explain: 'Bright dipping is a process that involves immersing metal in a solution of strong acids, such as hydrochloric acid or sulfuric acid, to remove surface impurities and create a shiny, reflective surface. This process is commonly used for aluminum, copper, and brass.\n\nPickling is a process used to remove impurities, such as rust, from metal surfaces by immersing the metal in an acidic solution, such as hydrochloric acid or sulfuric acid. This process is commonly used for steel and stainless steel.\n\nBoth bright dipping and pickling tanks involve the use of strong acids and other hazardous chemicals, which can pose health and safety risks if not handled properly. Employees who work with these processes must use appropriate personal protective equipment, such as gloves and respiratory protection, to protect themselves from exposure to these chemicals.'
    },
    {
        number: 17,
        description: "Which of the following industries is most commonly associated with exposure to hexavalent chromium?",
        choices: [
            "A. Agriculture",
            "B. Construction",
            "C. Manufacturing",
            "D. Retail"
        ],
        answer: "C",
        explain: 'Hexavalent chromium, also known as chromium VI or Cr(VI), is a hazardous chemical commonly used in various manufacturing processes, such as electroplating, welding, and painting. Exposure to hexavalent chromium can cause respiratory irritation, lung cancer, and other health effects. While hexavalent chromium may be present in other industries, such as construction, it is most commonly associated with manufacturing.'
    },
    {
        number: 18,
        description: "What is the primary purpose of using compressed gas or flux in welding?",
        choices: [
            "A. To increase the temperature of the welding arc.",
            "B. To prevent oxidation and contamination of the weld area.",
            "C. To enhance the electrical conductivity of the welding materials.",
            "D. To accelerate the cooling process of the welded joint."
        ],
        answer: "B",
        explain: "The primary purpose of using compressed gas (in processes like MIG and TIG welding) or flux (in processes like stick welding) is to create a protective shield around the weld area. This shield prevents oxidation and contamination from the surrounding atmosphere, ensuring a clean and strong weld. Compressed gas, like argon or carbon dioxide, displaces air, while flux creates a slag layer that protects the weld during and after the process."
    },
    {
        number: 19,
        description: "Which of the following best describes metal inert gas (MIG) welding?",
        choices: [
            "A. A welding process that uses a tungsten electrode to produce the weld",
            "B. A welding process that uses a consumable wire electrode and an inert gas to protect the weld",
            "C. A welding process that uses a flux-coated electrode to protect the weld",
            "D. A welding process that uses a laser to produce the weld"
        ],
        answer: "B",
        explain: 'Metal Inert Gas (MIG) welding is an arc welding process that uses a continuous solid wire electrode heated and fed into the weld pool from a welding gun. The two base materials are melted together forming a join. The gun feeds a shielding gas alongside the electrode helping protect the weld pool from airborne contaminants.'
    },
    {
        number: 20,
        description: "Which of the following is a significant health risk associated with Gas Metal Arc Welding (GMAW)?",
        choices: [
            "A. Exposure to harmful ultraviolet (UV) and infrared (IR) radiation.",
            "B. Inhalation of lead fumes from the welding rod.",
            "C. Exposure to high levels of noise due to the welding process.",
            "D. Inhalation of asbestos fibers released during welding."
        ],
        answer: "A",
        explain: "Gas Metal Arc Welding (GMAW) is associated with significant health risks due to exposure to harmful ultraviolet (UV) and infrared (IR) radiation. This radiation can cause severe eye and skin damage, including conditions like 'arc eye' (a painful inflammation of the cornea). Proper protective gear, including a welding helmet with the appropriate filter shade, is essential to protect against these risks. While welding does produce fumes, they generally do not contain lead unless specific materials are being welded, and asbestos is not typically involved in the process."
    },
    {
        number: 21,
        description: "Which of the following sources is most likely to contribute to the presence of tungsten in homes?",
        choices: [
            "A. Lead-based paint",
            "B. Asbestos-containing materials",
            "C. Old plumbing pipes made of galvanized steel",
            "D. Compact fluorescent light bulbs"
        ],
        answer: "D",
        explain: 'Tungsten is a metal that is commonly used in the filament of incandescent light bulbs, as well as in the electrodes of some types of fluorescent light bulbs. Compact fluorescent light bulbs, in particular, may contain small amounts of tungsten in the form of a coiled coil filament. When these light bulbs are disposed of improperly, they can release tungsten and other hazardous materials into the environment.'
    },
    {
        number: 22,
        description: "What is the primary hazard associated with abrasive blasting work?",
        choices: [
            "A. Exposure to hazardous gases in the abrasive material",
            "B. Fire and explosion from the use of flammable materials",
            "C. Musculoskeletal injuries from the physical demands of the job",
            "D. Respiratory hazards from inhalation of dust and other airborne particles"
        ],
        answer: "D",
        explain: 'Abrasive blasting is a process that involves the use of compressed air or water to propel abrasive materials at high speeds to clean, polish, or shape surfaces. The primary hazard associated with abrasive blasting work is the generation of airborne dust and other particles that can be inhaled by workers, causing respiratory problems and other health issues. While exposure to hazardous chemicals, fire and explosion risks, and musculoskeletal injuries are all potential hazards associated with abrasive blasting work, they are secondary to the primary respiratory hazard.'
    },
    {
        number: 23,
        description: "What is a common health effect associated with exposure to metal degreasing agents?",
        choices: [
            "A. Skin irritation and dermatitis",
            "B. Hearing loss and tinnitus",
            "C. Cardiovascular disease and high blood pressure",
            "D. Liver damage and kidney failure"
        ],
        answer: "A",
        explain: 'Exposure to metal degreasing agents, which often contain chemicals such as solvents, can commonly lead to skin irritation and dermatitis. These chemicals can strip the natural oils from the skin, leading to dryness, redness, and irritation. It is essential to use proper personal protective equipment, including gloves, when working with such agents to minimize skin contact and potential health effects.'
    },
    {
        number: 24,
        description: "Which of the following statements about lead-based paint is true?",
        choices: [
            "A. Lead-based paint was banned in all countries in the early 1990s",
            "B. Lead-based paint is not a health hazard unless it is disturbed or deteriorating",
            "C. Lead-based paint is only found in older homes built before 1950",
            "D. Lead-based paint can be a health hazard even if it is in good condition"
        ],
        answer: "D",
        explain: 'Lead-based paint can be a health hazard even if it is in good condition because it can still be disturbed during normal wear and tear. For example, lead-based paint can chip or flake when it is bumped or scratched. When lead-based paint is disturbed, it can release lead dust into the air. Lead dust can be inhaled or ingested, and it can cause a variety of health problems, including lead poisoning.\n\nThe use of lead-based paint in residential settings was banned in the United States in 1978 by the Consumer Product Safety Commission (CPSC). This ban was implemented due to the serious health hazards associated with lead exposure, especially in young children who may be exposed to lead through ingestion or inhalation of lead-containing paint dust or chips. According to the U.S. Environmental Protection Agency (EPA), lead-based paint can still be used in some industrial and commercial settings, such as bridges, steel structures, or other applications where it provides functional benefits or where alternatives are not available.'
    },
    {
        number: 25,
        description: "Which of the following is a potential hazard associated with carburizing?",
        choices: [
            "A. Noise-induced hearing loss",
            "B. Skin irritation",
            "C. Eye injury",
            "D. Fire and explosion"
        ],
        answer: "D",
        explain: 'Carburizing is a heat treatment process used to increase the surface hardness of metal components. It involves heating the metal in a carbon-rich environment, which can create a potential fire and explosion hazard due to the flammability of the hydrocarbon gases used in the process. To minimize the risk of fire and explosion during carburizing, it is important to ensure proper ventilation, monitor gas concentrations, and follow safe handling and storage practices.'
    },
    {
        number: 26,
        description: "Which of the following statements correctly differentiates Gas Welding from Arc Welding?",
        choices: [
            "A. Gas Welding uses an electric arc to generate heat, while Arc Welding uses a chemical reaction between gases.",
            "B. Gas Welding typically uses a flame produced by burning gases, whereas Arc Welding uses an electric arc to melt the metals.",
            "C. Gas Welding is generally faster and more suitable for heavy-duty industrial applications than Arc Welding.",
            "D. Gas Welding produces fewer fumes and is safer than Arc Welding."
        ],
        answer: "B",
        explain: "Gas Welding and Arc Welding are distinct in their methods of generating heat to join metals:\n\nGas Welding: Utilizes a flame produced by burning a fuel gas (such as acetylene) in the presence of oxygen to create heat that melts the metal. It's commonly used for welding thin materials and for repair work.\nArc Welding (B): Employs an electric arc struck between an electrode and the metal to generate intense heat, melting the metals at the joint. It is widely used for various industrial applications, especially where stronger, deeper welds are needed.\n\nArc Welding is generally faster and more efficient for heavy-duty tasks, but it also produces more fumes and requires stringent safety measures."
    },
    {
        number: 27,
        description: "Which of the following conditions is necessary for a dust explosion to occur?",
        choices: [
            "A. Low humidity and high temperature",
            "B. Presence of a flammable gas and high pressure",
            "C. Accumulation of combustible dust, sufficient oxygen, and an ignition source",
            "D. Excessive moisture and inadequate ventilation"
        ],
        answer: "C",
        explain: "Firstly, there needs to be a sufficient amount of combustible dust present in the air. Combustible dust refers to finely divided solid particles that can catch fire and propagate a flame when dispersed in the air in certain concentrations.\n\nSecondly, there must be an adequate supply of oxygen, as it is necessary for combustion. The presence of oxygen allows the dust particles to react with the oxygen and sustain the combustion process.\n\nLastly, an ignition source is required to initiate the combustion of the dust-air mixture. An ignition source can be a spark, flame, hot surface, electrical equipment, or any other heat source with enough energy to ignite the combustible dust.\n\nWhen these three elements come together in the right conditions, such as in an enclosed space or a confined area, a dust explosion can occur. The rapid combustion of the combustible dust particles releases a large amount of energy, leading to an explosion with the potential for significant damage and harm.\n\nTo prevent dust explosions, it is important to control and minimize the accumulation of combustible dust, implement proper housekeeping measures, ensure adequate ventilation, and use appropriate explosion prevention and mitigation strategies."
    },
    {
        number: 28,
        description: "What does the flashpoint of a liquid substance indicate?",
        choices: [
            "A. The temperature at which the substance will spontaneously ignite in the presence of an ignition source.",
            "B. The temperature at which the substance releases flammable vapors that can ignite when exposed to an ignition source.",
            "C. The temperature at which the substance undergoes a phase change from liquid to gas.",
            "D. The temperature at which the substance evaporates completely."
        ],
        answer: "B",
        explain: "The flashpoint of a liquid substance refers to the lowest temperature at which the substance emits enough vapors to form an ignitable mixture with the air. It is the temperature at which the substance's vapors can ignite when exposed to an ignition source such as a flame or spark.\n\nWhen a liquid reaches its flashpoint, it means that its vapors have reached a concentration that can sustain combustion. However, it's important to note that the flashpoint does not indicate that the substance will spontaneously ignite on its own. It simply signifies the temperature at which the substance's vapors become flammable and can ignite when in the presence of an ignition source."
    },
    {
        number: 29,
        description: "Which of the following represents the components of the fire tetrahedron?",
        choices: [
            "A. Fuel, heat, oxygen, and chemical reaction",
            "B. Fuel, temperature, air, and pressure",
            "C. Combustible material, flame, air, and heat",
            "D. Ignition source, extinguisher, flammable gas, and ventilation"
        ],
        answer: "A",
        explain: "Fuel: This refers to the combustible material or substance that can be ignited. It can be in the form of solids, liquids, or gases.\n\nHeat: Heat is the energy required to raise the temperature of the fuel to its ignition point. It provides the initial activation energy for the combustion process.\n\nOxygen: Oxygen is the oxidizing agent that supports the combustion reaction. It is necessary for most fires to sustain and continue burning.\n\nChemical Reaction: The chemical reaction involves the interaction between the fuel and oxygen, resulting in the release of heat and the production of combustion byproducts, such as smoke and gases."
    },
    {
        number: 30,
        description: "Which of the following health risks are associated with Arc Welding processes such as Stick, MIG, and TIG welding?",
        choices: [
            "A. Exposure to harmful UV and IR radiation, leading to 'arc eye' and skin burns.",
            "B. Inhalation of hazardous fumes and gases, which can cause respiratory issues.",
            "C. Risk of electric shock due to the use of high-voltage equipment.",
            "D. All of the above."
        ],
        answer: "D",
        explain: "Arc Welding processes, including Stick (Shielded Metal Arc Welding), MIG (Metal Inert Gas Welding), and TIG (Tungsten Inert Gas Welding), pose several health risks:\n\nUV and IR Radiation: All Arc Welding processes produce intense UV and IR radiation, which can cause 'arc eye' (a painful condition resulting from corneal inflammation) and skin burns if proper protection is not used.\nInhalation of Fumes: The welding process generates fumes and gases that can be hazardous to the respiratory system, potentially leading to chronic respiratory conditions or metal fume fever.\n Electric Shock (C): Arc Welding involves the use of high-voltage equipment, creating a risk of electric shock, especially in wet or humid environments.\n\nProper safety measures, including the use of personal protective equipment (PPE), ventilation, and electrical safety protocols, are essential to mitigate these risks."
    },
    {
        number: 31,
        description: "Which of the following is a potential health effect associated with exposure to isocyanates in the workplace?",
        choices: [
            "A. Eye irritation",
            "B. Liver damage",
            "C. Bone fractures",
            "D. Blood clotting disorders"
        ],
        answer: "A",
        explain: "Isocyanates are a family of highly reactive, low molecular weight chemicals. They are widely used in the manufacture of flexible and rigid foams, fibers, coatings such as paints and varnishes, and elastomers, and are increasingly used in the automobile industry, autobody repair, and building insulation materials. Spray-on polyurethane products containing isocyanates have been developed for a wide range of retail, commercial, and industrial uses to protect cement, wood, fiberglass, steel and aluminum, including protective coatings for truck beds, trailers, boats, foundations, and decks.\n\nIsocyanates are powerful irritants to the mucous membranes of the eyes and gastrointestinal and respiratory tracts. Direct skin contact can also cause marked inflammation. Isocyanates can also sensitize workers, making them subject to severe asthma attacks if they are exposed again. "
    },
    {
        number: 32,
        description: "What is a primary health concern associated with radon exposure in a work environment?",
        choices: [
            "A. Increased risk of skin cancer due to UV radiation.",
            "B. Respiratory issues and lung cancer from inhalation of radon gas.",
            "C. Hearing loss from prolonged exposure to high-frequency noise.",
            "D. Chemical burns from contact with radon-absorbing substances."
        ],
        answer: "B",
        explain: "Radon is a radioactive gas that can accumulate in indoor environments, including workplaces, particularly in areas like basements or poorly ventilated spaces. Prolonged exposure to radon increases the risk of developing lung cancer, as radon decay products can be inhaled and damage lung tissue. Radon does not cause skin cancer or hearing loss, and it does not result in chemical burns from contact."
    },
    {
        number: 33,
        description: "Which of the following is a common source of asbestos in the workplace?",
        choices: [
            "A. Insulation materials in older buildings.",
            "B. Electronic circuit boards.",
            "C. Plastic packaging materials.",
            "D. Modern office furniture."
        ],
        answer: "A",
        explain: "Asbestos was commonly used in insulation materials, particularly in older buildings, due to its fire-resistant properties. Common sources include insulation around pipes, boilers, and ducts, as well as in some ceiling tiles and floor tiles. Asbestos is not typically found in electronic circuit boards, plastic packaging materials, or modern office furniture, which do not generally contain asbestos."
    },
    {
        number: 34,
        description: "Which tool or equipment is commonly associated with the development of Hand-Arm Vibration Syndrome (HAVS)?",
        choices: [
            "A. Computer keyboards",
            "B. Power drills",
            "C. Desk chairs",
            "D. Safety goggles"
        ],
        answer: "B",
        explain: "Hand-Arm Vibration Syndrome (HAVS) is commonly associated with the use of power drills. Power drills are handheld tools that produce vibrations, especially when they are used for extended periods or at high intensities. The continuous exposure to the vibrations transmitted from the power drill to the hands and arms can contribute to the development of HAVS.\n\nThe vibrations generated by power drills can affect the blood vessels, nerves, and muscles in the hands and arms, leading to various symptoms and health effects. These may include numbness, tingling, and loss of dexterity in the fingers and hands, as well as vascular and musculoskeletal disorders such as vibration-induced white finger (VWF) and carpal tunnel syndrome."
    },
    {
        number: 35,
        description: "Which of the following industrial processes is commonly associated with silica exposure?",
        choices: [
            "A. Sandblasting and abrasive blasting.",
            "B. Welding and metal cutting.",
            "C. Painting and coating.",
            "D. Plastic molding and extrusion."
        ],
        answer: "A",
        explain: "Silica exposure is commonly associated with sandblasting and abrasive blasting, where silica sand is used to clean or texture surfaces. This process generates airborne respirable crystalline silica dust, which can pose significant health risks if proper protective measures are not taken."
    },
    {
        number: 36,
        description: "Which of the following is the primary purpose of pressure relief devices in a piping system?",
        choices: [
            "A. To control the flow rate of fluids in the system",
            "B. To monitor the temperature of fluids in the system",
            "C. To prevent over-pressurization of the system",
            "D. To reduce the noise level in the piping system"
        ],
        answer: "C",
        explain: "The primary purpose of pressure relief devices in a piping system is to prevent over-pressurization. These devices are designed to open and relieve excess pressure when it exceeds a predetermined safe level. By doing so, pressure relief devices help protect the integrity of the piping system and prevent potential catastrophic failures or ruptures."
    },
    {
        number: 37,
        description: "Which of the following are signs of chronic exposure to lead?",
        choices: [
            "A. Wrist drop and slowed nerve conduction.",
            "B. Increased appetite and weight gain.",
            "C. Persistent joint pain and swelling.",
            "D. Elevated liver enzymes and jaundice."
        ],
        answer: "A",
        explain: "Chronic exposure to lead can lead to neurological symptoms such as wrist drop (a condition where the wrist cannot be lifted due to nerve damage) and slowed nerve conduction. These symptoms result from lead's toxic effects on the nervous system."
    },
    {
        number: 38,
        description: "What is the primary objective of conducting a Process Hazard Analysis (PHA) in industrial settings?",
        choices: [
            "A. To identify potential hazards and risks associated with a process or system.",
            "B. To improve operational efficiency and productivity.",
            "C. To ensure compliance with environmental regulations.",
            "D. To determine the financial impact of potential accidents."
        ],
        answer: "A",
        explain: "The primary objective of conducting a Process Hazard Analysis (PHA) in industrial settings is to identify potential hazards and risks associated with a process or system. PHA helps in systematically analyzing the process, equipment, materials, and operating conditions to identify any potential sources of risk."
    },
    {
        number: 39,
        description: "Why is employee participation important in process safety management (PSM)?",
        choices: [
            "A. To increase operational productivity and efficiency.",
            "B. To reduce compliance-related costs and penalties.",
            "C. To foster a culture of safety and enhance hazard identification.",
            "D. To delegate responsibility for safety solely to management."
        ],
        answer: "C",
        explain: "Employee participation is important in process safety management (PSM) because it helps foster a culture of safety within the organization and enhances hazard identification. When employees actively participate in PSM, they become more engaged in identifying potential hazards, reporting near misses, and suggesting improvements to existing safety measures. Their involvement leads to increased awareness, knowledge, and ownership of safety within the workplace."
    },
    {
        number: 40,
        description: "Which of the following health risks is commonly associated with the forging process in manufacturing?",
        choices: [
            "A. Exposure to high levels of noise and vibration.",
            "B. Risk of respiratory issues from inhaling fumes and dust.",
            "C. Increased risk of ultraviolet radiation burns.",
            "D. Both A and B"
        ],
        answer: "D",
        explain: "The forging process, which involves shaping metal through high heat and pressure, can pose significant health risks such as respiratory issues from inhaling fumes and dust. This includes exposure to metal oxides and particulate matter released during the forging process. High levels of noise and vibration are also risks in forging. UV radiation are not typically associated with forging."
    },
    {
        number: 41,
        description: "What is a common symptom of metal fume fever, which is associated with certain industrial processes?",
        choices: [
            "A. Fever, chills, and muscle aches.",
            "B. Persistent coughing and shortness of breath.",
            "C. Skin rash and itching.",
            "D. Frequent headaches and dizziness."
        ],
        answer: "A",
        explain: "Metal fume fever is a condition caused by inhaling metal fumes, commonly from welding or other high-temperature metalworking processes. The primary symptoms include fever, chills, and muscle aches, similar to flu-like symptoms."
    },
    {
        number: 42,
        description: "Which of the following is a known health risk associated with cobalt and tungsten carbide tools in industrial settings?",
        choices: [
            "A. Hard metal lung disease.",
            "B. Chronic dermatitis and skin rashes.",
            "C. Acute gastrointestinal distress.",
            "D. Long-term vision impairment."
        ],
        answer: "A",
        explain: "Tungsten carbide and cobalt are commonly used together in various industrial applications, particularly in the manufacture of hard metal tools like cutting tools and wear-resistant components. The addition of cobalt to the carbide increases resistance to wear, hardness and toughness.\n\nInhalation of dust generated during the processing of tungsten carbide-cobalt materials poses significant health risks, particularly to the respiratory system. Prolonged exposure can lead to chronic respiratory diseases such as asthma, chronic bronchitis, and pulmonary fibrosis. Additionally, cobalt exposure has been associated with an increased risk of lung cancer, as it is classified as a possible human carcinogen by the IARC."
    },
    {
        number: 43,
        description: "What is the primary difference between 'good' ozone and 'bad' ozone in terms of their location and effects on health?",
        choices: [
            "A. 'Good' ozone is found in the stratosphere and protects against UV radiation, while 'bad' ozone is found at ground level and can cause respiratory issues.",
            "B. 'Good' ozone is located at ground level and contributes to smog formation, while 'bad' ozone is high in the atmosphere and blocks UV radiation.",
            "C. 'Good' ozone is a result of industrial pollution, while 'bad' ozone is a natural occurrence in the atmosphere.",
            "D. Both 'good' and 'bad' ozone are harmful to health regardless of their location."
        ],
        answer: "A",
        explain: "'Good' ozone is found in the stratosphere, where it forms the ozone layer that absorbs and protects against harmful ultraviolet (UV) radiation from the sun. This high-altitude ozone is beneficial for protecting life on Earth. In contrast, 'bad' ozone is found at ground level in the troposphere and is a major component of smog, which can cause respiratory problems and other health issues."
    },
    {
        number: 44,
        description: "Which of the following best describes the phenomenon of inversion aloft in meteorology?",
        choices: [
            "A. The cooling of air near the surface, causing it to sink and disperse pollutants vertically.",
            "B. The occurrence of warm air aloft acting as a lid, trapping cooler air and pollutants near the surface.",
            "C. The formation of cumulonimbus clouds associated with thunderstorms and severe weather conditions.",
            "D. The change in wind direction with increasing altitude, commonly observed during frontal passages."
        ],
        answer: "B",
        explain: "Inversion aloft is a meteorological phenomenon where a layer of warm air lies above cooler air near the Earth's surface. Normally, air temperature decreases with increasing altitude, but during an inversion aloft, the temperature gradient is inverted, resulting in a layer of warm air above a layer of cooler air.\n\nThis inversion layer acts as a lid or barrier that restricts the vertical movement of air and pollutants. As a result, pollutants emitted near the surface, such as from industrial or urban sources, can become trapped and accumulate within the lower atmospheric layer. This can lead to increased pollution levels and reduced air quality near the surface.\n\nInversion aloft is a common occurrence during stable weather conditions and is often associated with calm winds and clear skies. It can persist for hours or even days, depending on atmospheric conditions. The presence of inversion aloft can have significant implications for air pollution, as it hinders the dispersion and dilution of pollutants, leading to localized impacts on human health and the environment."
    },
    {
        number: 45,
        description: "Which of the following is an example of an active fall protection system?",
        choices: [
            "A. Guardrails",
            "B. Safety nets",
            "C. Warning signs",
            "D. Safety harnesses"
        ],
        answer: "D",
        explain: "Safety harnesses are an example of an active fall protection system. Active fall protection systems are designed to stop a fall in progress. Passive fall protection systems, on the other hand, are designed to prevent a fall from happening in the first place.\n\nGuardrails, safety nets, and warning signs are all examples of passive fall protection systems."
    }
]