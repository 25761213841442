import React, { useState, useEffect } from 'react'
import CIH_Exam_Online_Prep from '../assets/CIH_Exam_Online_Prep.png'
import getStripe from '../lib/getStrip';
function Title({ currUser }) {
    async function handleCheckout() {
        const stripe = await getStripe();
        const { error } = await stripe.redirectToCheckout({
            lineItems: [
                {
                    price: `price_1NnwwvFt8mEmrzFEMjAOYg1e`,
                    quantity: 1,
                },
            ],
            mode: 'payment',
            successUrl: `https://www.industrialsafetyhub.com/purchasesuccess`,
            cancelUrl: `https://www.industrialsafetyhub.com/cancel`,
            // successUrl: `http://localhost:3001/purchasesuccess`,
            // cancelUrl: `http://localhost:30001/cancel`,
            customerEmail: currUser.email,
        });
        console.warn(error.message);
    }
    const handlePurchase = () => {
        console.log("current user is ", currUser)
        if (!currUser) {
            window.location.href = '/login'
        } else {
            console.log("will direct to purchase page directly")
            handleCheckout()
        }
    }
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 842;
    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);
    return (
        <div className='title'>
            <img src={CIH_Exam_Online_Prep} alt='CIH Exam Prep with lots of sample questions' className='landing-image' />
            <h1 className='title-name'>Prepare CIH Exam With High Quality Questions</h1>
            <div className='firstcomponent-container'>
                {width > breakpoint && <div className='three-features'>
                    <h2>Self-paced training for CIH Exam with 800+ practice questions and training videos that cover everything you need.</h2>
                    <h2>Our program is designed to equip you with the knowledge and skills to pass the CIH exam in just 30 days.</h2>
                    <h2>Enjoy top-notch training at the most affordable price point in the market.</h2>
                    <div />
                </div>}
                <div className='register-price'>
                    <div>Register today to start free (1-day access)</div>
                    {/* <div><del>$199 for 1-year access</del></div> */}
                    <div>$199 (1-year access)</div>
                    {/* <div> Thanksgiving Discount: $99 for 1-year access.</div> */}
                    {/* <div> Offer expires on Dec 1st, 2023.</div> */}
                    <div className='purchase-button' onClick={handlePurchase}>{currUser ? 'Purchase Training' : "Log in to purchase"}</div>
                </div>
            </div>
        </div>
    )
}
export default Title