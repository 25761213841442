export const analytical_chemistry_questions = [
    {
        number: 1,
        description: "Which of the following analytical techniques is best suited for identifying and quantifying volatile organic compounds (VOCs) in air samples?",
        choices: ['A. Gas chromatography/mass spectrometry (GC/MS)', 'B. High-performance liquid chromatography (HPLC)', 'C. Fourier transform infrared spectroscopy (FTIR)', 'D. Atomic absorption spectroscopy (AAS)'],
        answer: 'A',
        explain: 'GC/MS combines the separation power of gas chromatography (GC) with the identification and quantification capabilities of mass spectrometry (MS). GC separates individual VOCs based on their unique physical properties, and MS identifies each compound based on its mass-to-charge ratio (m/z) and fragmentation pattern. This combination allows for highly specific and sensitive analysis of even complex mixtures of VOCs.'

    },
    {
        number: 2,
        description: "Which of the following types of analytical methods is best suited for determining the size and shape of particles in a sample?",
        choices: ['A. Gas chromatography', 'B. Mass spectrometry', 'C. X-ray diffraction', 'D. Scanning electron microscopy'],
        answer: 'D',
        explain: 'A scanning electron microscope (SEM) is a type of electron microscope that produces images of a sample by scanning the surface with a focused beam of electrons. The electrons interact with atoms in the sample, producing various signals that contain information about the surface topography and composition of the sample.'
    },
    {
        number: 3,
        description: "Which of the following is a limitation of UV-Visible spectroscopy?",
        choices: [
            "A. It can measure absorption beyond the visible range.",
            "B. It has unlimited sensitivity for detecting low concentrations.",
            "C. It is unaffected by background absorption in complex matrices.",
            "D. It has a limited range of detectable wavelengths."
        ],
        answer: "D",
        explain: "UV-Visible spectroscopy is limited to the ultraviolet (UV) and visible regions of the electromagnetic spectrum, typically ranging from 200 to 800 nanometers (nm). It cannot measure absorption beyond this range. This limitation restricts the application of UV-Visible spectroscopy to samples that exhibit absorption within this wavelength range."
    }
    ,
    {
        number: 4,
        description: "Which chemical analysis technique involves the reaction of an analyte with a known reagent in a controlled reaction to determine the concentration of the analyte?",
        choices: [
            "A. Gravimetry",
            "B. Spectrophotometry",
            "C. Titration",
            "D. Electrochemical analysis"
        ],
        answer: "C",
        explain: "Titration: This technique involves adding a standardized solution of a known reactant (titrant) to the sample containing the analyte (analyte) until they completely react. The volume of titrant used reflects the amount of analyte present, allowing for concentration calculation."
    },
    {
        number: 5,
        description: "Which of the following analytical techniques can be used to measure the concentration of lead in environmental samples such as soil or dust?",
        choices: [
            "A. Atomic absorption spectroscopy (AAS) ",
            "B. X-ray fluorescence (XRF)",
            "C. Ion chromatography (IC)",
            "D. Both A and B "
        ],
        answer: "D",
        explain: "Both Atomic Absorption Spectroscopy (AAS) and X-ray Fluorescence (XRF) are commonly used analytical techniques to measure the concentration of lead in environmental samples such as soil or dust. AAS measures the absorption of specific wavelengths of light by the atoms in a sample, while XRF measures the emitted fluorescent X-rays after irradiating the sample with X-rays. Both methods provide reliable results for the determination of lead concentrations in environmental samples."
    },
    {
        number: 6,
        description: "How is High-Performance Liquid Chromatography (HPLC) used to separate formaldehyde from a mixture?",
        choices: [
            "A. HPLC separates formaldehyde based on its molecular weight using a mass spectrometer",
            "B. HPLC separates formaldehyde based on its boiling point using a thermal detector",
            "C. HPLC separates formaldehyde based on its chemical properties using a stationary phase and a mobile phase",
            "D. HPLC separates formaldehyde by its color using a colorimetric detector"
        ],
        answer: "C",
        explain: "High-Performance Liquid Chromatography (HPLC) separates formaldehyde from a mixture based on its chemical properties using a stationary phase and a mobile phase. In HPLC, a sample is injected into a column containing a stationary phase, and a solvent (mobile phase) is pumped through the column. Different compounds, including formaldehyde, interact differently with the stationary phase, causing them to elute at different times. This separation is based on factors such as polarity and size, allowing for the isolation and analysis of formaldehyde in the mixture."
    },
    {
        number: 7,
        description: "Which of the following statements is true regarding high-performance liquid chromatography (HPLC)?",
        choices: [
            "A. HPLC uses low-pressure pumps to improve separation efficiency.",
            "B. HPLC is a type of gas chromatography that utilizes high-pressure pumps.",
            "C. HPLC employs high-pressure pumps to enhance separation efficiency and speed.",
            "D. HPLC is a chromatographic technique that does not require any pumps."
        ],
        answer: "C",
        explain: 'High-performance liquid chromatography (HPLC) is a chromatographic technique that utilizes high-pressure pumps to push the liquid (mobile phase) through a column containing the stationary phase. The high pressure is employed to achieve faster and more efficient separations of components in the sample.'
    },
    {
        number: 8,
        description: "How can adjusting the polarity of the mobile phase in chromatography improve the separation of compounds?",
        choices: [
            "A. It increases the flow rate of the mobile phase, speeding up the analysis",
            "B. It changes the interaction strength between the compounds and the stationary phase, enhancing separation",
            "C. It decreases the pressure within the column, preventing column damage",
            "D. It increases the detector sensitivity, making it easier to identify compounds"
        ],
        answer: "B",
        explain: "Adjusting the polarity of the mobile phase in chromatography changes the interaction strength between the compounds and the stationary phase. By optimizing these interactions, the separation of compounds is enhanced. Compounds that have different polarities will interact differently with the mobile and stationary phases, leading to better resolution and separation of the peaks in the chromatogram."
    },
    {
        number: 9,
        description: "What are the limit of detection (LOD) and limit of quantification (LOQ), and how do they differ?",
        choices: [
            "A. LOD is the highest concentration of an analyte that can be accurately measured, while LOQ is the lowest concentration that can be detected.",
            "B. LOD is the lowest concentration of an analyte that can be detected but not necessarily quantified, while LOQ is the lowest concentration that can be quantified with acceptable precision and accuracy.",
            "C. LOD and LOQ both refer to the highest concentration of an analyte that can be detected and quantified, respectively.",
            "D. LOD is used to determine the purity of a sample, while LOQ is used to identify the presence of contaminants."
        ],
        answer: "B",
        explain: "In analytical chemistry, the limit of detection (LOD) is the lowest concentration of an analyte that can be detected but not necessarily quantified. It represents the smallest amount of substance that can be distinguished from the absence of that substance (a blank value). The limit of quantification (LOQ), on the other hand, is the lowest concentration of an analyte that can be quantified with acceptable precision and accuracy. For example, if the LOD of a method for detecting formaldehyde is 0.1 ppm, it means that concentrations below this level cannot be reliably detected. If the LOQ is 0.5 ppm, it means that concentrations above this level can be quantified with acceptable accuracy."
    },
    {
        number: 10,
        description: "What is the purpose of a standard reference material (SRM) in analytical chemistry? ",
        choices: [
            "A. To calibrate instruments and validate analytical methods. ",
            "B. To identify the components in a mixture. ",
            "C. To measure the concentration of a specific analyte. ",
            "D. To test the performance of laboratory personnel. "
        ],
        answer: "A",
        explain: "Standard reference materials (SRMs) are certified materials that have a known composition and are used in analytical chemistry to calibrate instruments, validate analytical methods, and ensure the accuracy and precision of measurements. SRMs are produced and certified by organizations such as the National Institute of Standards and Technology (NIST) and are used as a reference for comparison with unknown samples."
    },
    {
        number: 11,
        description: "Which of the following is a common technique used for elemental analysis in analytical chemistry?",
        choices: [
            "A. X-ray diffraction (XRD) ",
            "B. Infrared (IR) spectrometry",
            "C. X-ray fluorescence (XRF) ",
            "D. UV-Vis spectrometry"
        ],
        answer: "C",
        explain: "X-ray fluorescence (XRF) is a technique used to analyze the elemental composition of a sample by measuring the characteristic X-rays that are emitted when the sample is exposed to a high-energy X-ray source. When the X-rays hit the atoms of the sample, they knock out electrons from the inner shells of the atoms, causing electrons from the outer shells to move in to fill the vacancies. \n\nWhen this happens, energy is released in the form of X-rays that are specific to the element being analyzed. By detecting and measuring the intensity of these X-rays, the elemental composition of the sample can be determined. \n\nX-ray diffraction (XRD) is a technique used to determine the crystal structure of a material. Infrared (IR) spectroscopy is a technique used to identify the functional groups in a molecule. UV-Vis spectrometry is used to analyze the electronic transitions in molecules, providing information about the concentration and electronic structure of compounds, but it is not specifically used for elemental analysis."
    },
    {
        number: 12,
        description: "In scientific measurements, what is the correct relationship between the units nanograms (ng), micrograms (µg), milligrams (mg), liters (L), cubic centimeters (cc), and cubic meters (m³)?",
        choices: [
            "A. 1 mg = 1,000 ng; 1 µg = 1,000 mg; 1 L = 1,000 cc; 1 m³ = 1,000 L",
            "B. 1 µg = 1,000 ng; 1 mg = 1,000 µg; 1 L = 1,000 cc; 1 m³ = 1,000 L",
            "C. 1 ng = 1,000 µg; 1 mg = 1,000 ng; 1 cc = 1,000 L; 1 m³ = 1,000 cc",
            "D. 1 ng = 1,000 mg; 1 µg = 1,000 cc; 1 L = 1,000 m³; 1 m³ = 1,000 µg"
        ],
        answer: "B",
        explain: "The correct relationships between the units are as follows: 1 microgram (µg) equals 1,000 nanograms (ng), 1 milligram (mg) equals 1,000 micrograms (µg), 1 liter (L) equals 1,000 cubic centimeters (cc) or ml, and 1 cubic meter (m³) equals 1,000 liters (L). For example, if you have 1 mg of a substance, it is equivalent to 1,000 µg or 1,000,000 ng. Similarly, if you have 1 m³ of a liquid, it is equivalent to 1,000 liters or 1,000,000 cc."
    },
    {
        number: 13,
        description: "Which of the following best describes the applications of ion chromatography in analytical chemistry?",
        choices: [
            "A. Ion chromatography is mainly used for the analysis of heavy metals in soil samples.",
            "B. Ion chromatography is used to separate and quantify ions in various samples, including the detection of ammonia in environmental water samples and the analysis of organic and inorganic acids in food and beverage products.",
            "C. Ion chromatography is primarily used for the measurement of gas concentrations in the atmosphere.",
            "D. Ion chromatography is used to identify the molecular structure of complex organic compounds."
        ],
        answer: "B",
        explain: "Ion chromatography is a widely used technique in analytical chemistry for the separation and quantification of ions in diverse samples. Its applications include detecting ammonia in environmental water samples, which is crucial for monitoring water quality and environmental health. Additionally, it is used to analyze organic and inorganic acids in food and beverage products to ensure quality and safety standards. "
    },
    {
        number: 14,
        description: "Given an analytical method with a limit of detection (LOD) of 0.1 µg, an anticipated concentration of 0.5 µg/m³, and a sampling flow rate of 2 L/min, calculate the minimum sampling time required to detect the analyte.",
        choices: [
            "A. 50 minutes",
            "B. 75 minutes",
            "C. 100 minutes",
            "D. 150 minutes"
        ],
        answer: "C",
        explain: "To determine the minimum sampling time, first convert the sampling flow rate to cubic meters per minute: 2 L/min = 0.002 m³/min. Then, calculate the amount of analyte collected per minute: 0.5 µg/m³ × 0.002 m³/min = 0.001 µg/min. Finally, divide the LOD by the amount of analyte collected per minute to find the minimum sampling time: 0.1 µg ÷ 0.001 µg/min = 100 minutes. Therefore, the minimum sampling time required is 100 minutes."
    },
    {
        number: 15,
        description: "How does mass spectrometry work, and what role do ionization and the m/z ratio play in the process?",
        choices: [
            "A. Mass spectrometry detects the color of compounds; Ionization adds color to molecules, and the m/z ratio indicates color intensity",
            "B. Mass spectrometry measures molecular weight by ionizing compounds and analyzing the m/z ratio; Ionization turns molecules into charged particles, and the m/z ratio helps identify and quantify them",
            "C. Mass spectrometry separates molecules by size; Ionization breaks molecules into smaller pieces, and the m/z ratio determines their size",
            "D. Mass spectrometry identifies compounds by their shape; Ionization changes the shape of molecules, and the m/z ratio assesses shape variations"
        ],
        answer: "B",
        explain: "Mass spectrometry measures molecular weight by ionizing compounds and analyzing the m/z ratio. During ionization, molecules are turned into charged particles (ions). The mass-to-charge (m/z) ratio is then used to separate and identify these ions based on their mass and charge. This process allows scientists to identify and quantify compounds within a sample, making mass spectrometry a powerful analytical tool in chemistry and biochemistry."
    },
    {
        number: 16,
        description: "What is the definition of polarity in the context of chemistry?",
        choices: [
            "A. The tendency of atoms to form covalent bonds",
            "B. The distribution of electrical charge over the atoms in a molecule",
            "C. The ability of a substance to dissolve in water",
            "D. The strength of intermolecular forces between molecules"
        ],
        answer: "B",
        explain: "In chemistry, polarity refers to the distribution of electrical charge over the atoms in a molecule. A polar molecule has regions with partial positive and negative charges due to the uneven distribution of electrons, which leads to the formation of dipoles. This characteristic influences how molecules interact with each other and with solvents, following the principle that 'like attracts like' and 'unlike repels unlike.' Polar molecules will attract other polar molecules and repel non-polar molecules."
    },
    {
        number: 17,
        description: "What is the purpose of method validation in analytical chemistry?",
        choices: [
            "A. To determine the concentration of an analyte in a sample",
            "B. To establish the performance characteristics and limitations of an analytical method",
            "C. To identify the functional groups present in a compound",
            "D. To separate and analyze components based on their differential interactions"
        ],
        answer: "B",
        explain: "The purpose of method validation in analytical chemistry is to establish the performance characteristics and limitations of an analytical method to ensure its suitability for a specific application. It involves a series of tests and evaluations to assess various aspects of the method's performance, including accuracy, precision, linearity, range, selectivity, specificity, and robustness."
    },
    {
        number: 18,
        description: "What is the purpose of a mobile phase in chromatography? ",
        choices: [
            "A. To dissolve the analyte and create a homogeneous solution. ",
            "B. To separate the components in a mixture. ",
            "C. To elute the analyte from the stationary phase.",
            "D. To detect the analyte using a specific detector. "
        ],
        answer: "C",
        explain: "The mobile phase is a solvent or a mixture of solvents that flows through the stationary phase in chromatography. The purpose of the mobile phase is to elute the analyte from the stationary phase by carrying it along with the flow. The elution time or retention time of the analyte depends on its interactions with the stationary phase and the mobile phase. \n\nThe mobile phase can be adjusted to control the elution time and improve the separation of the components in a mixture. Once the analyte is eluted, it can be detected and quantified using a suitable detector."
    },
    {
        number: 19,
        description: "Which of the following factors can affect the absorbance of a molecule in UV-Visible spectroscopy?",
        choices: [
            "A. Molecular weight of the molecule",
            "B. Path length of the light through the sample",
            "C. Concentration of the molecule ",
            "D. All of the above"
        ],
        answer: "D",
        explain: "The molecular weight of the molecule can influence its electronic structure and, consequently, its absorbance properties. \n\nThe path length of the light through the sample affects the amount of interaction between the light and the molecules, thereby impacting the observed absorbance. \n\nLastly, the concentration of the molecule in the sample directly relates to its absorbance, following the Beer-Lambert law. As the concentration increases, the absorbance also increases."
    },
    {
        number: 20,
        description: "In asbestos sampling with a 25-mm filter, an average asbestos fiber density of 200 fibers/mm², and a sampling volume of 2000 liters, what is the asbestos concentration in fibers/ml?",
        choices: [
            "A. 0.08 fibers/ml",
            "B. 0.08 fibers/m³",
            "C. 0.008 fibers/m³",
            "D. 8 fibers/ml"
        ],
        answer: "A",
        explain: "For a 25-mm filter, the effective area of 385 mm²(You can find this in equation sheet, constant section). Using the 18th formula, Casb = asbestos concentration in fibers/ml, E = fiber density on filters (200 fibers/mm²), Ac = effective collection area (385 mm²), Vs = sampling volumn (2000 litters). \n\nCasb = 200 * 385 / (1000*1000) = 0.08 fibers/ml"
    },
    {
        number: 21,
        description: "Calculate the asbestos concentration in fibers per liter when 1000 liters of air are sampled, 70 fibers are counted on a total of 100 fields, and the field blank has 1 fiber counted in a total of 10 fields. Assume a 25 mm filter was used.",
        choices: [
            "A. 0.029 fibers/ml",
            "B. 0.029 fibers/l",
            "C. 0.29 fibers/ml",
            "D. 0.32 fibers/ml"
        ],
        answer: "A",
        explain: "For a 25-mm filter, the effective area of 385 mm² and the graticule field area is 0.00785 mm² (You can find this in equation sheet, constant section). To calculate the asbestos concentration, use the 17th formula in general science.\n\n Cs = average number of fibers per graticule field in the sample. Cb = average number of fibers per graticule field in the field blank. Ac = effective area. Af = graticule field area. Vs = sampling volumn. \n\n Casb = (0.7-0.1) * 385 / (1000*0.00785*1000) = 0.029 fibers/ml."
    },
    {
        number: 22,
        description: "Which of the following statements best describes capillary electrophoresis?",
        choices: [
            "A. A technique used for separating and analyzing analytes based on their molecular weight. ",
            "B. A technique used for separating and analyzing analytes based on their charge-to-size ratios.",
            "C. A technique used for separating and analyzing analytes based on their solubility in a specific solvent.",
            "D. A technique used for separating and analyzing analytes based on their volatility and boiling points."
        ],
        answer: "B",
        explain: "Capillary electrophoresis is a type of electrophoresis that uses a small, narrow capillary filled with a buffer solution. The analytes are introduced into the capillary at one end, and an electric field is applied. The analytes will migrate through the capillary at different speeds, depending on their charge-to-size ratios. \n\nThe smaller and more negatively charged analytes will migrate the fastest, while the larger and more positively charged analytes will migrate the slowest. The analytes are detected at the other end of the capillary, and their migration times are used to identify and quantify them."
    },
    {
        number: 23,
        description: "In which type of spectroscopy technique is light of a specific wavelength used to excite electrons in atoms or molecules, resulting in their emission at characteristic wavelengths?",
        choices: [
            "A. Ultraviolet-visible (UV-Vis) spectroscopy",
            "B. Atomic absorption spectroscopy",
            "C. Infrared spectroscopy",
            "D. Fluorescence spectroscopy"
        ],
        answer: "D",
        explain: "In fluorescence spectroscopy, light of a specific wavelength is used to excite electrons in atoms or molecules. When these electrons return to their ground state, they emit light at characteristic wavelengths. This emitted light can be measured to provide information about the substance being analyzed. \n\nHere is a brief explanation of why the other techniques do not fit the description: \n\nA. Ultraviolet-visible (UV-Vis) spectroscopy measures the absorption of UV and visible light by a sample, which provides information about the electronic transitions in molecules, but it does not involve the emission of light.\n\nB. Atomic absorption spectroscopy measures the absorption of light by free, ground-state atoms, but it does not involve the emission of light.\n\nC. Infrared spectroscopy measures the absorption of infrared light by molecules, which provides information about their vibrational transitions, but it does not involve the emission of light."
    },
    {
        number: 24,
        description: "Which of the following statements best describes the principle of chromatography?",
        choices: [
            "A. Chromatography separates components based on their different boiling points.",
            "B. Chromatography separates components based on their different solubilities in a solvent.",
            "C. Chromatography separates components based on their different degrees of interaction with mobile and stationary phases.",
            "D. Chromatography separates components based on their different molecular weights."
        ],
        answer: "C",
        explain: "The principle of chromatography is based on the differential interaction of components in a mixture with the mobile phase and stationary phase. Components that have stronger interactions with the stationary phase will move more slowly, while those with weaker interactions will move faster through the column. This differential interaction leads to the separation of components as they travel through the chromatographic system."
    },
    {
        number: 25,
        description: "Which of the following is a limitation of chromatography?",
        choices: [
            "A. Chromatography provides high-resolution separation for all complex samples.",
            "B. Chromatography is compatible with all types of samples, including volatile and reactive compounds.",
            "C. Chromatography may not provide sufficient resolution to separate all components in a complex mixture.",
            "D. Chromatography does not require method development or optimization."
        ],
        answer: "C",
        explain: "Chromatography has limitations in terms of providing high-resolution separation for all complex samples. The complexity of the sample can lead to overlapping peaks or co-elution, making it challenging to distinguish and quantify individual components accurately."
    },
    {
        number: 26,
        description: "Which statement best describes the primary difference between X-ray fluorescence (XRF) and X-ray diffraction (XRD) techniques?",
        choices: [
            "A. XRF identifies the elemental composition, while XRD identifies the crystal structure of a material.",
            "B. XRF measures the mass of elements, while XRD measures the density of a sample.",
            "C. XRF uses visible light, while XRD uses ultraviolet light.",
            "D. XRF is used for organic compounds, while XRD is used for inorganic compounds."
        ],
        answer: "A",
        explain: "X-ray fluorescence (XRF) is a technique used to identify the elemental composition of a sample by detecting the characteristic 'secondary' X-rays emitted from a material when it is excited by high-energy X-rays. X-ray diffraction (XRD), on the other hand, is used to determine the crystal structure of a material by measuring the angles and intensities of X-rays that are diffracted by the crystal lattice. XRF is best for qualitative and quantitative elemental analysis, while XRD provides detailed information about the crystallographic structure, chemical composition, and physical properties of materials."
    },
    {
        number: 27,
        description: " Which of the following is a common type of column used in gas chromatography that can handle larger sample sizes?",
        choices: [
            "A. Packed column",
            "B. Capillary column",
            "C. Ion-exchange column",
            "D. Size exclusion column "
        ],
        answer: "A",
        explain: "Gas chromatography (GC) is a technique used to separate and analyze mixtures of volatile compounds. The column used in GC is the heart of the separation process. The column is a long, narrow tube that is packed with a stationary phase or coated with a stationary phase. The stationary phase is a material that interacts with the molecules in the sample mixture and separates them based on their physical and chemical properties . \n\nThere are two main types of columns used in gas chromatography: packed columns and capillary columns. Packed columns are filled with a solid or liquid stationary phase that is coated onto a support material. The sample mixture is introduced into the column and moves through the stationary phase by diffusion or flow. Capillary columns, on the other hand, are coated with a very thin layer of stationary phase on the inside wall of a small diameter tube. Packed columns are generally less expensive and can handle larger sample sizes, but they have lower resolution and longer analysis times compared to capillary columns."
    },
    {
        number: 28,
        description: "Which spectroscopy technique relies on the interaction of atomic nuclei with a strong magnetic field and radiofrequency waves?",
        choices: [
            "A. UV-Visible Spectroscopy",
            "B. Infrared (IR) Spectroscopy",
            "C. Nuclear Magnetic Resonance (NMR) Spectroscopy",
            "D. Mass Spectrometry"
        ],
        answer: "C",
        explain: "Nuclear magnetic resonance (NMR) spectroscopy is a powerful analytical technique used to study the structure and dynamics of molecules. It is based on the principle that certain atomic nuclei possess a magnetic moment, which can interact with an external magnetic field. When these nuclei are placed in a strong magnetic field, they absorb radiofrequency (RF) radiation at specific frequencies that are characteristic of their chemical environment. By analyzing the pattern of these absorbed frequencies, known as an NMR spectrum, scientists can gain a wealth of information about the structure, dynamics, and interactions of molecules."
    },
    {
        number: 29,
        description: "Which of the following statements best describes the difference between absorption spectroscopy and fluorescence spectroscopy?",
        choices: [
            "A. Absorption spectroscopy measures the emission of light by a sample, while fluorescence spectroscopy measures the absorption of light.",
            "B. Absorption spectroscopy measures the absorption of light by a sample, while fluorescence spectroscopy measures the emission of light.",
            "C. Absorption spectroscopy and fluorescence spectroscopy are interchangeable terms representing the same concept.",
            "D. Absorption spectroscopy and fluorescence spectroscopy are unrelated techniques used for different purposes."
        ],
        answer: "B",
        explain: "Absorption spectroscopy involves measuring the amount of light absorbed by a sample across a range of wavelengths. It provides information about the substances present in the sample and their concentrations based on the absorption spectrum.\n\nOn the other hand, fluorescence spectroscopy measures the emission of light from a sample that has been excited by absorbing light at a specific wavelength. It provides information about the energy transitions and fluorescence properties of the sample, including the presence of fluorescent compounds and their characteristics."
    },
    {
        number: 30,
        description: "What information can be obtained through UV-Visible spectroscopy? ",
        choices: [
            "A. Molecular vibrations and functional groups",
            "B. Electronic structure and presence of chromophores",
            "C. Mass-to-charge ratio and molecular weight",
            "D. Chemical environment and atom connectivity"
        ],
        answer: "B",
        explain: "UV-Visible spectroscopy provides information about the electronic structure and presence of chromophores in a sample. It measures the absorption or transmission of ultraviolet (UV) and visible light by a sample, which is related to the energy transitions of electrons within the molecules. \n\nChromophores are groups of atoms within a molecule that have specific electronic properties, allowing them to absorb light at certain wavelengths. By analyzing the absorption spectrum in the UV-Visible range, one can determine the presence of chromophores and gain insights into the electronic structure of the sample."
    }
    ,
    {
        number: 31,
        description: "Which of the following factors can influence the separation of analytes in chromatography?",
        choices: [
            "A. Mobile phase flow rate",
            "B. Stationary phase composition",
            "C. Column length",
            "D. All of the above"
        ],
        answer: "D",
        explain: "All of the factors mentioned in the options can influence the separation of analytes in chromatography.\n\nA. Mobile phase flow rate: The flow rate of the mobile phase affects the residence time of analytes on the stationary phase. Higher flow rates can result in faster elution times and reduced separation efficiency, while slower flow rates allow for better separation but longer analysis times.\n\nB. Stationary phase composition: The composition of the stationary phase, including the type of packing material and the presence of functional groups, determines the interactions between the analytes and the stationary phase. Different stationary phases can provide different selectivity and separation characteristics.\n\nC. Column length: The length of the chromatography column affects the separation efficiency. Longer columns provide greater resolving power but may result in longer analysis times. Shorter columns can lead to faster analysis times but may sacrifice separation efficiency."
    },
    {
        number: 32,
        description: "Which of the following spectroscopy techniques is commonly used to analyze molecular vibrations and identify functional groups in organic and inorganic compounds?",
        choices: [
            "A. UV-Visible Spectroscopy",
            "B. Infrared (IR) Spectroscopy",
            "C. Nuclear Magnetic Resonance (NMR) Spectroscopy",
            "D. Mass Spectrometry"
        ],
        answer: "B",
        explain: "Infrared (IR) Spectroscopy is commonly used to analyze molecular vibrations and identify functional groups in organic and inorganic compounds. It measures the absorption or transmission of infrared light by a sample, which corresponds to the vibrational energies of the molecules present. Different functional groups exhibit characteristic absorption bands in the infrared region, allowing for their identification and structural analysis."
    },
    {
        number: 33,
        description: "Which of the following statements is true regarding sensitivity and specificity in analytical methods? ",
        choices: [
            "A. Sensitivity and specificity are interchangeable terms representing the same concept.",
            "B. Sensitivity focuses on the ability to detect low concentrations, while specificity focuses on distinguishing between different components.",
            "C. Sensitivity and specificity are measures of the precision and reproducibility of an analytical method.",
            "D. Sensitivity and specificity are measures of how well an analytical method agrees with the true value of the quantity being measured."
        ],
        answer: "B",
        explain: "Sensitivity refers to the ability of an analytical method to detect the presence of a substance or analyte at low concentrations. A sensitive method can detect even trace amounts of the analyte with a high level of accuracy.\n\nSpecificity, on the other hand, pertains to the ability of an analytical method to distinguish between different components or substances in a sample. A specific method can accurately identify and differentiate the target analyte from other interfering substances or components."
    },
    {
        number: 34,
        description: "Which of the following is a common mobile phase material used in gas chromatography?",
        choices: [
            "A. Water",
            "B. Methanol",
            "C. Helium",
            "D. Acetonitrile"
        ],
        answer: "C",
        explain: "In gas chromatography, the mobile phase is typically an inert gas. Common gases used as mobile phases include helium, hydrogen, and nitrogen. These gases help carry the sample through the column and interact with the stationary phase to separate the different components of the mixture."
    },
    {
        number: 35,
        description: "Which of the following statements is true regarding the difference between gas chromatography (GC) and liquid chromatography (LC)? ",
        choices: [
            "A. GC uses a gas mobile phase and LC uses a liquid mobile phase. ",
            "B. GC uses a liquid stationary phase and LC uses a gas stationary phase ",
            "C. GC is typically faster than LC ",
            "D. GC is typically more suited for polar compounds than nonpolar compounds "
        ],
        answer: "A",
        explain: "In gas chromatography, the mobile phase is a gas such as helium or nitrogen, while in liquid chromatography, the mobile phase is a liquid such as water or acetonitrile. In GC, the sample is vaporized and injected into the gas phase, while in LC, the sample is dissolved in a liquid and injected into the liquid phase."
    },
    {
        number: 36,
        description: "What is the limit of detection for an analytical method if the signal-to-noise ratio is 3:1 and the baseline noise is 0.1 units? ",
        choices: [
            "A. 0.03 units",
            "B. 0.10 units ",
            "C. 0.30 units ",
            "D. 1.00 units "
        ],
        answer: "C",
        explain: "To calculate the limit of detection (LOD), we need to determine the signal that corresponds to a signal-to-noise ratio of 3:1. Since the signal-to-noise ratio is 3:1, the signal must be three times the baseline noise: Signal = 3 x 0.1 units = 0.3 units."
    },
    {
        number: 37,
        description: "What is the main difference between absorption and adsorption in chemical processes?",
        choices: [
            "A. Absorption involves the adhesion of atoms or molecules to the surface of a solid or liquid, while adsorption involves the uptake of a gas or liquid by a solid or liquid.",
            "B. Absorption involves the uptake of a gas or liquid by a solid or liquid, while adsorption involves the adhesion of atoms or molecules to the surface of a solid or liquid.",
            "C. Absorption and adsorption are identical processes that describe the same phenomenon.",
            "D. Absorption and adsorption refer to different types of chemical reactions, with absorption being a physical process and adsorption being a chemical process."
        ],
        answer: "B",
        explain: "Absorption involves the uptake of a gas or liquid by a solid or liquid. This occurs when the molecules of the gas or liquid are dissolved or trapped within the bulk of the absorbing material, such as when a sponge soaks up water. Adsorption, on the other hand, involves the adhesion of atoms or molecules to the surface of a solid or liquid. \n\nIn adsorption, the molecules of the gas or liquid are attracted to and held on the surface of the adsorbent material, without being dissolved or absorbed into its bulk. An example of adsorption is the uptake of pollutants by activated carbon filters in air purification systems."
    },
    {
        number: 38,
        description: "Which of the following is not a common tool for industrial hygiene sampling?",
        choices: [
            "A. Pitot tube",
            "B. Bubble meter",
            "C. Spirometer",
            "D. Rotameter"
        ],
        answer: "C",
        explain: "A spirometer is a device used to measure lung function and is primarily used in respiratory assessments rather than industrial hygiene sampling.\n\nA. Pitot tube: A pitot tube is used to measure fluid velocity in a duct or pipe, typically used to assess air velocity and flow rates in industrial ventilation systems.\n\nB. Bubble meter: A bubble meter is an instrument used to measure gas flow rates by counting the number of gas bubbles produced in a specific time period.\n\nD. Rotameter: A rotameter is a device that measures the flow rate of a gas or liquid based on the height of a float inside a tapered tube."
    },
    {
        number: 39,
        description: " What does Raoult's law state about the vapor pressure of a solution?",
        choices: [
            "A. The vapor pressure of a solution is directly proportional to the mole fraction of the solute.",
            "B. The vapor pressure of a solution is inversely proportional to the mole fraction of the solute.",
            "C. The vapor pressure of a solution is directly proportional to the mole fraction of the solvent.",
            "D. The vapor pressure of a solution is independent of the mole fractions of the solute and solvent."
        ],
        answer: "C",
        explain: "Raoult's law states that the vapor pressure of a solution is directly proportional to the mole fraction of the solvent. In other words, the more solvent molecules there are in the solution, the higher the vapor pressure of the solution will be."
    },
    {
        number: 40,
        description: "What is the difference between precision and accuracy?",
        choices: [
            "A. Precision and accuracy are interchangable terms when it comes to analytical chemistry",
            "B. Precision is the degree to which a measurement agrees with the true value of the quantity being measured, while accuracy is the degree to which repeated measurements of the same quantity agree with each other.",
            "C. Precision is a measure of how close the measurements are to each other, while accuracy is a measure of how close the measurements are to the true value.",
            "D. Precision is a measure of how close the measurements are to the true value, while accuracy is a measure of how close the measurements are to each other."
        ],
        answer: "C",
        explain: "Precision relates to the reproducibility and consistency of a set of measurements. It is a measure of how close multiple measurements of the same quantity are to each other. If the measurements are highly consistent and clustered closely together, they are considered precise.\n\nAccuracy, on the other hand, refers to how close a measurement is to the true or accepted value of the quantity being measured. It is a measure of the correctness of a measurement. An accurate measurement is one that is very close to the true value, regardless of whether the measurements are precise or not."
    }
    ,
    {
        number: 41,
        description: "What is the mass of 2.5 moles of sodium chloride (NaCl)? (Note: The molar mass of NaCl is 58.44 g/mol.) ",
        choices: [
            "A. 74.5 g",
            "B. 111.8 g",
            "C. 128.5 g",
            "D. 146.1 g"
        ],
        answer: "D",
        explain: "The mass of 2.5 moles of NaCl can be calculated as:mass = moles × molar mass = 2.5 mol × 58.44 g/mol = 146.1 g."
    },
    {
        number: 42,
        description: "At Standard Ambient Temperature and Pressure (1 atm and 25°C), what is the volume of 10 grams of oxygen? ",
        choices: [
            "A. 8.73 L",
            "B. 7.64 L",
            "C. 5.00 L",
            "D. 10 L"
        ],
        answer: "B",
        explain: "The molar volume at Standard Ambient Temperature and Pressure is 24.45L (can be found in CIH equation sheet under the constant section). 1 mole of oxygen weighs 32 grams. Therefore molar = 10/32, and the volume = 10/32*24.45 = 7.64 L. Please note Standard Ambient Temperature and Pressure is not same with STP(standard temperature and pressure, 0 °C (273K), 1 atm)."
    },
    {
        number: 43,
        description: "What is the number of atoms in 2.5 moles of carbon?",
        choices: [
            "A. 1.5 x 10\u00B2\u2074 atoms",
            "B. 1.5 x 10\u00B2\u2075 atoms",
            "C. 1.5 x 10\u00B2\u2076atoms",
            "D. 1.5 x 10\u00B2\u2077 atoms"
        ],
        answer: "A",
        explain: "To calculate the number of atoms in 2.5 moles of carbon, we can use the Avogadro constant (can be found in CIH equation sheet under the constant section). Number of atoms = (number of moles) x (Avogadro constant) Number of atoms = 2.5 mol x 6.022 x 10\u00B2\u00B3 atoms/mol. Number of atoms = 1.5055 x 10\u00B2\u2074 atom."
    },
    {
        number: 44,
        description: "A pump is running at 2 liters per minute for 100 minutes. 50 ug of dust was reported by the lab analysis. What is the concentration of dust in this sample? ",
        choices: [
            "A. 0.25 ug/l",
            "B. 0.5 ug/l",
            "C. 2.5 ug/l",
            "D. 5 ug/l"
        ],
        answer: "A",
        explain: "The total volume of air sampled is 2 * 100 liters. The concentration is 50/200 = 0.25 ug/l."
    },

    {
        number: 45,
        description: "What is the purpose of using an Anderson impactor in air sampling? ",
        choices: [
            "A. To measure the concentration of gases in the air",
            "B. To collect airborne particles onto a filter",
            "C. To separate particles based on their aerodynamic size",
            "D. To measure the total suspended particulate matter in the air "
        ],
        answer: "C",
        explain: "The Anderson impactor is a type of air sampler that is used to collect airborne particles in a specific size range. It consists of a series of stages with nozzles of decreasing diameter that impact particles onto collection surfaces. The collection surfaces are then analyzed to determine the size distribution of the airborne particles. The Anderson impactor is often used in occupational health and environmental monitoring to assess exposure to particulate matter. "
    },
    {
        number: 46,
        description: "What is the relationship between absorbance and concentration in the Beer-Lambert Law? ",
        choices: [
            "A. Absorbance is inversely proportional to concentration",
            "B. Absorbance is directly proportional to concentration",
            "C. Absorbance is independent of concentration",
            "D. Absorbance is proportional to the square root of concentration"
        ],
        answer: "B",
        explain: "The Beer-Lambert law is a principle that describes the relationship between the absorption of light by a sample and its concentration. According to the law, the absorbance of light by a sample is proportional to the concentration of the absorbing species in the sample, the path length of the light through the sample, and the molar extinction coefficient of the species at the wavelength of the light. Here is the formular for Beer's Law: A=ε⋅c⋅l.\n\nA is the absorbance of the solution. ε (epsilon) is the molar absorptivity (or molar extinction coefficient) of the substance. c is the concentration of the analyte. l is the path length of the light through the solution."
    },
    {
        number: 47,
        description: "What does BTEX stand for in environmental chemistry?",
        choices: [
            "A. Benzene, toluene, ethylbenzene, and xylene",
            "B. Butane, toluene, xylose, and ethane",
            "C. Benzene, triethylene glycol, xylene, and ethylene",
            "D. Butane, tetraethylene glycol, xylose, and ethane"
        ],
        answer: "A",
        explain: "BTEX is an acronym that stands for Benzene, Toluene, Ethylbenzene, and Xylenes. These are a group of volatile organic compounds (VOCs) that are commonly found in environmental samples, particularly in air and groundwater.\n\nBTEX compounds are known for their toxic properties and potential health risks. They are often associated with industrial activities, fuel spills, and the use of certain chemicals and solvents. BTEX compounds are highly mobile in the environment and can migrate from contaminated sources into air, soil, and water.\n\nIn environmental chemistry, the analysis and monitoring of BTEX compounds are important for assessing the quality of air and water, as well as identifying and remediating contaminated sites. Analytical techniques such as gas chromatography (GC) combined with mass spectrometry (MS) or flame ionization detection (FID) are commonly used for the quantification and identification of BTEX compounds in environmental samples."
    },
    {
        number: 48,
        description: "Which of the following energy level is not common in PID?",
        choices: [
            "A. 9.8",
            "B. 10.6",
            "C. 11.7",
            "D. 4.9"
        ],
        answer: "D",
        explain: "A Photoionization Detector (PID) is a commonly used gas detector that utilizes the principle of ionization to detect and measure the concentration of volatile organic compounds (VOCs) and other gases. PIDs operate by ionizing gas molecules when exposed to ultraviolet (UV) light, and the resulting ion current is measured to determine the gas concentration.\n\nPIDs typically use specific energy levels for the ionization process. The common energy levels used in PIDs are:\n\nA. 9.8 eV (electron volts): This energy level is commonly used in PIDs for the detection of a wide range of VOCs, including benzene, toluene, ethylbenzene, and xylene (BTEX) compounds.\n\nB. 10.6 eV: This energy level is also commonly used in PIDs and is suitable for detecting a broad range of VOCs, including various industrial solvents and gases.\n\nC. 11.7 eV: This energy level is typically used in PIDs for detecting specific VOCs, such as halogenated compounds."
    },
    {
        number: 49,
        description: "Which of the following best describes the concept of discrete energy release in the context of nuclear reactions?",
        choices: [
            "A. Energy is released continuously over time during a nuclear reaction.",
            "B. Energy is released in quantized packets during a nuclear reaction.",
            "C. Energy is not released at all during a nuclear reaction.",
            "D. Energy release in a nuclear reaction depends solely on the temperature."
        ],
        answer: "B",
        explain: "Discrete energy release refers to the phenomenon where energy is emitted in quantized packets, known as quanta, rather than in a continuous flow. In the context of nuclear reactions, this concept is observed in the form of discrete amounts of energy being released as particles transition between energy levels. This is consistent with the principles of quantum mechanics, where energy levels are quantized and transitions between these levels result in the emission or absorption of discrete amounts of energy. Therefore, the correct answer is B. Energy is released in quantized packets during a nuclear reaction."
    },
    {
        number: 50,
        description: "A gravimetric analysis was conducted by sampling air for 4 hours at a flow rate of 10 L/min. The initial weight of the filter was 2.000 g, and the final weight after sampling was 2.015 g. Calculate the dust mass concentration in the air, expressed in mg/m³.",
        choices: [
            "A. 0.0625 mg/m³",
            "B. 6.25 mg/m³",
            "C. 0.1875 mg/m³",
            "D. 0.250 mg/m³"
        ],
        answer: "B",
        explain: "First, determine the total volume of air sampled: 4 hours × 60 minutes/hour × 10 L/min = 2400 L. Convert this to cubic meters: 2400 L × 0.001 m³/L = 2.4 m³. The mass of dust collected is the difference between the final and initial weights of the filter: 2.015 g - 2.000 g = 0.015 g, which is 15 mg. Finally, calculate the dust mass concentration: 15 mg / 2.4 m³ = 6.25 mg/m³."
    }
]